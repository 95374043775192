import store from '../store/store'
import { getI18next } from '../store/app-state/reducer'

export default (value, lowerCase = false) => {
    const i18next = getI18next(store.getState())
    if (i18next && value) {
        const parts = value.split(' ')
        const translatedParts = parts.map((part) => {
            const valWithoutSpaces = part.replace(/ |:/g, '_')
            let translation = i18next.t(valWithoutSpaces)

            if (translation === valWithoutSpaces) {
                translation = part
            }

            return lowerCase ? translation.toLowerCase() : translation
        });

        return translatedParts.join(' ')
    }
    return value
}