import * as types from './action-types';
import ObjectUtils from '../../utils/object-utils'

const initialState = {
    interfaces: [],
    events: [],
    codInterface: '',
    isFetching: false,
    isFetched: false,
    inputs: []
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.CLEAR_EVENTS: {
            return {
                ...state,
                events: []
            }
        }
        case types.FETCH_INTERFACES:
            return Object.assign({}, state, {
                interfaces: action.interfaces,
                codInterface: state.codInterface,
                isFetched: true,
            });
        case types.FETCH_EVENTS:
            return Object.assign({}, state, {
                events: action.interfaces,
                isFetched: true,
            });
        case types.SET_INTERFACE:
            return Object.assign({}, state, {
                codInterface: action.codInterface,
                desInterface: action.desInterface,
                isFetched: true,
            });
        case types.ADD_EVENT:
            return Object.assign({}, state, {
                events: [
                    ...state.events,
                    action.event
                ]
            });
        case types.SET_EVENTS:
            return Object.assign({}, state, {
                events: action.events,
            });
        case types.SET_INTERFACE_EVENTS:
            return {
                ...state,
                events: ObjectUtils.deepCopy(action.interfaceEvents)
            };
        case types.SET_NEW_INTERFACE:
            return Object.assign({}, state, {
                newInterface: action.newInterface,
                isFetched: true,
            });
        case types.SET_INPUTS:
            return Object.assign({}, state, {
                inputs: action.inputs
            });
        default:
            return state;
    }
}

export function getInterfacesInfo(state) {
    const interfaces = state._interface.interfaces;
    const codInterface = state._interface.codInterface;
    const desInterface = state._interface.desInterface;
    return [interfaces, codInterface, desInterface];
}

export function getEvents(state) {
    const events = state._interface.events;
    return [events];
}

export function getInputs(state) {
    const inputs = state._interface.inputs;
    return [inputs];
}

export function getCodStructure(state, position) {
    var positionSelected = position - 1
    const codStructure = state._interface.events[positionSelected].structures[0].codStructure;
    return codStructure;
}