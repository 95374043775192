import request from '../../utils/request';
import Session from '../../utils/session-info';

export default {
    GetInstance: codInstance => {
        return request.executeRequest(
            `${request.baseUrl}instances/get/${codInstance}`,
            request.getOptions()
        ).then(res => res.json());
    },
    GetInstances: (codCompany = Session().codCompany) => {
        return request.executeRequest(
            `${request.baseUrl}globals/companies/${codCompany}/instances`,
            request.getOptions()
        ).then(res => res.json());
    },
    GetUserPermissionsInstance: () => {
        return request.executeRequest(
            `${request.baseUrl}instances/userPermission`,
            request.getOptions()
        ).then((res) => {
            if (!res.ok) {
                return Promise.reject({ status: res.status, addPermission: false, editPermission: false });
            }
            return res.json(); 
        })
        .catch((error) => {
            return Promise.reject(error);
        });
    },
    PostInstance: instance => {
        return request.executeRequest(
            `${request.baseUrl}instances/insert`,
            {
                ...request.postOptions(),
                body: JSON.stringify(instance)
            }
        );
    },
    PutInstance: instance => {
        return request.executeRequest(
            request.baseUrl + `instances/edit`,
            {
                ...request.putOptions(),
                body: JSON.stringify(instance)
            }
        );
    }
}