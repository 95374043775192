import React, { Component } from "react"
import { connect } from "react-redux"
import { ListItemButton, Tooltip } from "@mui/material"
import Translate from "../../i18n/translate"
import AllVersionModal from "./version/allversions-modal"
import VersionService from "./version/version-service"
import SideBarNavigation from "./side-bar-navigation"
import {
    StyledDrawer,
    StyledListItemText,
    StyledAlertIcon,
    StyledVersionNotesIcon
} from "../../componentsUI/styledComponents/styledSideBar"

class SideBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appState: props.appState,
            versionModal: false,
            outdatedWorkerVersion: false,
            openRegistrations: false,
            openIntegrations: false,
            openAdministration: false
        }
    }

    componentDidMount() {
        VersionService.getVersionWorkerOfInstance()
            .then(response => {
                this.setState({outdatedWorkerVersion: this.checkOutdatedWorkerVersion(response.InstanceVersion, response.CurrentVersion)})
            })
    }

    checkOutdatedWorkerVersion = (instanceVersion, currentVersion) => {
        const versionIdentifiersLength = 4
        let outdated = false

        instanceVersion = instanceVersion
            .split('.')
            .map(Number)
            .concat(Array(versionIdentifiersLength).fill(0))
            .slice(0, versionIdentifiersLength)

        currentVersion = currentVersion
            .split('.')
            .map(Number)
            .concat(Array(versionIdentifiersLength).fill(0))
            .slice(0, versionIdentifiersLength)

        instanceVersion.forEach((value, index) => {
            if(outdated == false) {
                value < currentVersion[index] ? outdated = true : outdated
            }
        })

        return outdated
    }

    hideShowSidebar() {
        const { appState } = this.state;

        if(appState.openDrawer == true) appState.openDrawer = false
        else appState.openDrawer = true

        this.state.appState.refresh(appState)
    }

    openCloseVersionModal = () => {
        const { versionModal } = this.state

        this.setState({ versionModal: !versionModal });
    }

    renderVersionItemButton() {
        const { outdatedWorkerVersion, appState } = this.state

        let icon = outdatedWorkerVersion ? (<StyledAlertIcon />) : (<StyledVersionNotesIcon />)

        return (
            <Tooltip
                title={outdatedWorkerVersion ? <h4>{Translate('newWorkerVersionAvailable')}</h4> : ""}
                placement="top">
                <ListItemButton onClick={this.openCloseVersionModal.bind(this)}>
                    {icon}
                    <StyledListItemText hide={!appState.openDrawer} primary={Translate('versionNotes')} />
                </ListItemButton>
            </Tooltip>
        )
    }

    render() {
        const { appState, versionModal } = this.state

        return (
            <StyledDrawer
                hide={!appState.openDrawer}
                primary={Translate('integrations')}
                anchor='left'
                variant="permanent">
                <SideBarNavigation
                    appState={appState}
                    hideShowSidebar={this.hideShowSidebar.bind(this)} />
                {this.renderVersionItemButton()}
                <AllVersionModal open={versionModal} onClose={this.openCloseVersionModal.bind(this)} />
            </StyledDrawer>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccessLevel: (accesLevel) => { dispatch(setAccessLevel(accesLevel)) },
        setUserPermissions: permissions => { dispatch(setUserPermissions(permissions)) }
    }
}

export default connect(null, mapDispatchToProps)(SideBar)