
import * as types from './action-types';
import {globalService} from './service';


export const getAllGlobals =() => {
    return async (dispatch, getState) => {
        try {
            const globals = await globalService.getAll();
            dispatch({ type: types.FETCH_GLOBALS, globals });
        } catch (error) {
            console.error(error);
        }
    };
}