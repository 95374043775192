import React, {Component} from "react"
import Toast from "../../../components/toast/toast"
import Translate from "../../../i18n/translate"
import Session from "../../../utils/session-info"
import SchedulesStep from "./steps/interfaces-copy-modal-schedule-step"
import DefinitionsStep from "./steps/interfaces-copy-modal-definitions-step"
import ConnectionDetailsStep from "./steps/interfaces-copy-modal-connection-details-step"
import ConnectionParametersStep from "./steps/interfaces-copy-modal-connection-parameters-step"
import InterfacesService from "../interfaces-service"
import SchedulesService from "../../schedules/schedules-service"
import ConnectionsService from "../../connections/connections-service"
import Loading from "../../../componentsUI/loading"
import StyledButton from "../../../componentsUI/styledComponents/styledButton"
import { StyledLeftButton, StyledLoading } from "../../../componentsUI/styledComponents/styledInterfacesCopyModal"
import { DialogActions, DialogContent } from "@mui/material"

class InterfacesCopyModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            scheduleOptions: [],
            stepDisabled: true,
            currentStep: 0,
            isSaving: false,
            steps: [{
                key: 0,
                type: "definition",
                component: DefinitionsStep,
                targetInstance: Session().codInstance
            }]
        };
    }

    componentWillMount() {
        const { codInstanceInterface } = this.state.targetInterface;
        const {
            steps
        } = this.state;

        InterfacesService.GetConnections(codInstanceInterface).then((connections) => {
            connections.map(connection => {
                connection.oldConnection = connection.id;
                delete connection.id;

                connection.attributes.map(attribute => {
                    delete attribute.codInstanceConnection;
                });

                steps.push({
                    key: steps.length,
                    type: "connection",
                    title: "dest_connection",
                    component: ConnectionDetailsStep,
                    ...connection
                }, {
                    key: steps.length + 1,
                    type: "connection_param",
                    title: "dest_connection_params",
                    component: ConnectionParametersStep,
                    ...connection
                });
            });

            steps.push({
                key: steps.length,
                type: "schedule",
                title: "dest_schedule",
                component: SchedulesStep
            });

            this.setState({ steps });
        });
    }

    componentWillReceiveProps(props) {
        if (!Object.keys(props.targetInterface).length) {
            return;
        }

        this.setState({
            ...props,
            copyName: `${props.targetInterface.description} - ${Translate("copy_item")}`,
            targetInterface: props.targetInterface
        });
    }

    updateStepData(stepData) {
        let {
            steps,
            currentStep
        } = this.state;

        steps[currentStep] = {
            ...steps[currentStep],
            ...stepData
        }

        this.setState({
            steps: steps
        });
    }

    changeStepStatus(newStepStatus) {
        if (newStepStatus !== this.state.stepDisabled) {
            this.setState({ stepDisabled: newStepStatus });
        }
    }

    renderForm() {
        const {
            currentStep,
            steps,
            targetInterface
        } = this.state;
        const { targetInstance } = steps[0];

        return React.createElement(steps[currentStep].component, {
            changeStepStatus: this.changeStepStatus.bind(this),
            updateParent: this.updateStepData.bind(this),
            targetInterface,
            targetInstance,
            ...steps[currentStep]
        });
    }

    renderBackwardButton() {
        const { currentStep } = this.state;

        if (!currentStep) {
            return;
        }

        return (
            <StyledLeftButton variant="contained"
                onClick={this.prevStep.bind(this)}
                color="primary"
            >
                {Translate("back")}
            </StyledLeftButton>
        )
    }

    renderCancelButton() {
        return (
            <StyledButton variant="contained"
                onClick={this.props.close.bind(this)}
                color="primary"
            >
                {Translate("cancel")}
            </StyledButton>
        )
    }

    nextStep() {
        const {
            steps,
            currentStep
        } = this.state;

        let nextStep = currentStep + 1;

        if (steps[nextStep].type == "connection_param" && steps[currentStep].isIgnoreParamStep) {
            nextStep += 1;
        }

        this.setState({
            currentStep: nextStep
        });
    }

    prevStep() {
        const {
            steps,
            currentStep
        } = this.state;

        let prevStep = currentStep - 1;

        if (steps[prevStep].type == "connection_param" && steps[prevStep - 1].isIgnoreParamStep) {
            prevStep -= 1;
        }

        this.setState({
            currentStep: prevStep
        });
    }

    save() {
        const {
            targetInterface,
            steps
        } = this.state;
        const self = this;
        const definitionStep = steps[0];
        const targetInstance = definitionStep.targetInstance;
        const singleStep = (targetInterface.codInstance === targetInstance);

        if (singleStep) {
            steps[0].stepDisabled = true;

            this.setState({
                steps,
                isSaving: true
            }, () => {
                postNewInterface.call(self, targetInterface.codSchedule);
            });
        } else {
            steps[steps.length - 1].stepDisabled = true;

            this.setState({
                steps,
                isSaving: true
            }, saveCopy);
        }

        function saveCopy(step = 1, connections = {}) {
            const stepData = steps[step];

            if (stepData.type == "connection") {
                if (stepData.isChecked) {
                    connections[stepData.oldConnection] = stepData.connection;

                    saveCopy.call(self, step += 2, connections);
                } else {
                    stepData.codInstance = targetInstance;
                    stepData.attributes.map(attribute => attribute.codInstance = targetInstance);

                    const connectionPromise = ConnectionsService.PostConnection(stepData);

                    Promise.all([connectionPromise]).then(([connection]) => {
                        Promise.all([connection.json()]).then(([newConnection]) => {
                            connections[stepData.oldConnection] = newConnection;

                            saveCopy.call(self, step += 2, connections);
                        }).catch(() => {
                            errorCallBack.call(self);
                        });
                    }).catch(() => {
                        errorCallBack.call(self);
                    });
                }
            } else if (stepData.type == "schedule") {
                if (stepData.isChecked) {
                    postNewInterface.call(self, stepData.schedule, connections);
                } else {
                    stepData.codInstance = targetInstance;

                    const schedulePromise = SchedulesService.PostSchedule(stepData);

                    Promise.all([schedulePromise]).then(([schedule]) => {
                        Promise.all([schedule.json()]).then(([newSchedule]) => {
                            postNewInterface.call(self, newSchedule, connections);
                        }).catch(() => {
                            errorCallBack.call(self);
                        });
                    }).catch(() => {
                        errorCallBack.call(self);
                    });
                }
            }
        }

        const checkScheduleConnectionsPermissions = (codNewSchedule, codNewConnections) => {
            const notTokenAccessMessage = "The user of the informed token does not have access to perform this action on the instance."
            let withoutPermissionConnections = false
            let withoutPermissionSchedule = false
            if(codNewConnections != undefined){
                if(codNewConnections[Object.keys(codNewConnections)[0]] == notTokenAccessMessage) withoutPermissionConnections = true
            }
            if(codNewSchedule == notTokenAccessMessage) withoutPermissionSchedule = true
            if(withoutPermissionConnections || withoutPermissionSchedule) return true
            else return false
        }

        function postNewInterface(codNewSchedule, codNewConnections) {
            if(checkScheduleConnectionsPermissions(codNewSchedule, codNewConnections)){
                Toast.error(Translate("notNecessaryPermissionsToCopy"));
                this.props.close(true);
                return
            } else {
                InterfacesService.CopyInterface(
                    targetInstance,
                    codNewSchedule,
                    definitionStep.targetInterface.codInstanceInterface,
                    definitionStep.copyName,
                    codNewConnections
                ).then(res => {
                    if (res.ok) {
                        Toast.success(Translate("interface_success_copied"));
                        this.props.close(true);
                    } else {
                        if(res.status == 401) Toast.error(Translate("notNecessaryPermissionsToCopy"));
                        else Toast.error(Translate("error_saving_interface"));
                        this.props.close(true);
                    }
                })
            }
        }

        function errorCallBack() {
            if (singleStep) {
                steps[0].stepDisabled = false;
            } else {
                steps[steps.length - 1].stepDisabled = false;
            }

            this.setState({
                steps,
                isSaving: false
            });
        }
    }

    renderPrimaryButton() {
        const {
            currentStep,
            targetInterface,
            steps
        } = this.state;

        const step = steps[currentStep];
        const singleStep = targetInterface.codInstance === step.targetInstance;
        const lastStep = (steps.length - 1);

        return (
            <StyledButton variant="outlined"
                disabled={step.stepDisabled}
                onClick={
                    singleStep ? this.save.bind(this) : currentStep === lastStep ? this.save.bind(this) : this.nextStep.bind(this)
                }>
                {Translate(singleStep ? "save" : currentStep === lastStep ? "save" : "go_forward")}
            </StyledButton >
        )
    }

    render() {
        const {
            currentStep,
            steps,
            isSaving
        } = this.state;

        const { title } = steps[currentStep];
        
        const titleStyles = {
            marginBottom: "12px",
            fontSize: "19px",
            paddingBottom: "3px",
            fontWeight: "bold",
            color: "#949494",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            borderBottom: "1px solid #a7a7a7"
        }

        if (isSaving) {
            return (
                <StyledLoading>
                    <Loading />
                </StyledLoading>
            )
        }

        return (
            <div>
                <DialogContent>
                    {title ? <div style={titleStyles}>{Translate(title)}</div> : null}
                    {this.renderForm()}
                </DialogContent>
                <DialogActions>
                    {this.renderBackwardButton()}
                    {this.renderCancelButton()}
                    {this.renderPrimaryButton()}
                </DialogActions>
            </div>
        );
    }
}

InterfacesCopyModal.defaultProps = {
    targetInterface: {}
}

export default InterfacesCopyModal