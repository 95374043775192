import React, { Component } from "react"
import { connect } from "react-redux"
import Translate from "../../../i18n/translate"
import * as wizard from "../../../store/wizard/wizard-store-reducer"
import { setWizardState } from "../../../store/wizard/wizard-store-actions"
import { MdDelete, MdEdit, MdFileCopy } from "react-icons/md"
import {
    StyledContainer,
    StyledButtonContainer,
    StyledGridButton,
    StyledSeparator,
    StyledContainerTableAndRadio,
    StyledRadioGroup,
    StyledGridTable
} from "../../../componentsUI/styledComponents/styledFilter"
import {
    Typography,
    Grid2,
    Radio
} from "@mui/material";
import ConectorSelect from "../../../componentsUI/conectorSelect"
import ConectorInputText from "../../../componentsUI/inputText"
import StyledPaper from "../../../componentsUI/styledComponents/styledPaper"
import ConectorGridTable from "../../../componentsUI/gridTable"
import ConectorDialogConfirmation from "../../../componentsUI/dialogComponentConfirmation"
import CancelSaveButtons from "../../../componentsUI/cancelSaveButtons"

class Filter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gridOptions: {
                multiSelect: false,
                sortField: "indPosition",
                columns: [{
                    title: "name",
                    field: "desField"
                }, {
                    title: "condition",
                    field: "condition"
                }, {
                    title: "type",
                    field: "type"
                },{
                    title: "parameter",
                    field: "parameter"
                }],
                refresh: (gridState) => {
                    this.setState({
                        ...this.state,
                        ...gridState
                    });
                }
            },
            dialogOptions: {
                type: "danger",
                open: false,
                message: "remove_select_field",
                title: "field_exclusion",
                onClose: this.handleRemoveField,
                onDismiss: this.handleCloseExclusionModal
            },
            outputText: this.props.outputText,
            fieldsFilter: this.props.fieldsFilter,
            selectedField: null,
            fieldFilterSelected: null,
            nodeSelected: this.props.nodeSelected,
            filterType: this.props.filterType,
            operatorInput: "equal",
            typeInput: undefined,
            textInput: "",
            isEditing: false,
            isShowForm: false,
            inputingText: false,
            arrayNumberTables: new Array,
            selectedTable: 0,
            addTableButtonDisabled: false
        };

        props.setWizardState({disabledStep: true});
    }

    componentWillReceiveProps = props => {
        this.props = { ...props }
        const {inputingText, isEditing} = this.state
        const {disabledStep} = props.wizardStore

        if(!inputingText && isEditing == false && disabledStep == true){
            if (props.nodeSelected) {
                this.setState({
                    isShowForm: true,
                    nodeSelected: props.nodeSelected,
                    fieldForm: props.fieldForm,
                    isEditing: false,
                    operatorInput: "equal"
                })
            }
        } else this.state.inputingText = false
    }

    componentWillMount = () => {
        const { outputText, fieldsFilter } = this.state;
        
        if(outputText != undefined && outputText.length > 0) this.enableSaveEventButton()

        this.setState({
            addTableButtonDisabled: fieldsFilter.length == 0 ? true : false,
            operatorList: [{
                value: "equal",
                label: Translate("equal")
            }, {
                value: "different",
                label: Translate("different")
            }, {
                value: "less_than",
                label: Translate("less_than")
            }, {
                value: "less_or_equal",
                label: Translate("less_or_equal")
            }, {
                value: "greater_than",
                label: Translate("greater_than")
            }, {
                value: "greater_than_or_equal",
                label: Translate("greater_than_or_equal")
            }],
            operatorType: [{
                value: "string",
                label: "string"
            }, {
                value: "decimal",
                label: "Decimal"
            }, {
                value: "int",
                label: "Inteiro"
            }, {
                value: "datetime",
                label: "datetime"
            }, {
                value: "boolean",
                label: "boolean"
            }]
        }, () => this.reorderFieldsTableOrder(fieldsFilter))
    }

    reorderFieldsTableOrder = fieldsFilter => {
        let arrayNumberTablesNotOrdered = this.generateArrayNumberTables()
        let arrayNumberTablesOrdered = this.generateArrayNumberTables().map((tableNumber, index) => tableNumber = index)

        fieldsFilter
        .sort((a, b) => {return (a.comparisonOrTableOrder - b.comparisonOrTableOrder)})
        .map(field => {
            let fieldOrdered = undefined

            arrayNumberTablesNotOrdered
            .forEach((tableNumber, index) => {
                if(field.comparisonOrTableOrder == tableNumber) {
                    fieldOrdered = field.comparisonOrTableOrder = arrayNumberTablesOrdered[index]
                }
            })

            return fieldOrdered
        })

        this.setState({fieldsFilter, arrayNumberTables: this.generateArrayNumberTables()})
    }
    
    generateArrayNumberTables = () => {
        const {fieldsFilter} = this.state;

        let arrayNumberTables = fieldsFilter
        .map(field => field.comparisonOrTableOrder)
        .filter((valor, indice, self) => self.indexOf(valor) === indice)
        .sort((a, b) => {return (a - b)})

        return arrayNumberTables
    }

    handleCancel = () => {
        const {outputText} = this.state;

        this.setState({
            isEditing: false,
            isShowForm: false,
            operatorInput: "equal",
            textInput: "",
            typeInput: undefined
        }, () => {
            if(outputText != undefined && outputText.length > 0) this.enableSaveEventButton()
        })
    }

    handleSave = () => {
        const {
            nodeSelected,
            isEditing,
            fieldsFilter,
            gridOptions,
            operatorInput,
            textInput,
            typeInput,
            selectedTable,
            outputText
        } = this.state;

        let filter;

        if (isEditing) {
            filter = fieldsFilter[fieldsFilter.indexOf(gridOptions.selectedRow)];

            filter.fieldTransformAttributes.find(transformAttribute => transformAttribute.codAttribute == 11).desValue = textInput;
            filter.fieldTransformAttributes.find(transformAttribute => transformAttribute.codAttribute == 47).desValue = operatorInput;
            filter.fieldTransformAttributes.find(transformAttribute => transformAttribute.codAttribute == 1).desValue = typeInput;
        } else {
            filter = {
                desField: nodeSelected.title,
                indPosition: fieldsFilter.length,
                fieldTransformAttributes: [{
                    desValue: nodeSelected.title,
                    codAttribute: 26,
                    indPosition: 1
                }, {
                    desValue: textInput,
                    codAttribute: 11,
                    indPosition: 2
                }, {
                    desValue: typeInput,
                    codAttribute: 1,
                    indPosition: 3
                }, {
                    desValue: operatorInput,
                    codAttribute: 47,
                    indPosition: 4
                }, {
                    desValue: nodeSelected.path,
                    codAttribute: 103,
                    indPosition: 5
                }]
            };

            fieldsFilter.push(filter)
        }

        filter.condition = Translate(operatorInput);
        filter.parameter = textInput;
        filter.type = typeInput;

        filter.comparisonOrTableOrder = selectedTable

        this.props.wizardStore.event.structures[0].structureDetails[0].fields[0].fieldTransforms = fieldsFilter;

        this.setState({
            isEditing: false,
            isShowForm: false,
            typeInput: undefined,
            textInput: "",
            addTableButtonDisabled: fieldsFilter.length == 0 ? true : false
        }, () => this.reorderFieldsTableOrder(fieldsFilter))

        if(outputText != undefined && outputText.length > 0) this.enableSaveEventButton()
    }

    handleEdit = () => {
        const { selectedRow } = this.state.gridOptions;

        this.setState({
            isEditing: true,
            isShowForm: true,
            operatorInput: selectedRow.fieldTransformAttributes.find(transformAttribute => transformAttribute.codAttribute == 47).desValue,
            textInput: selectedRow.fieldTransformAttributes.find(transformAttribute => transformAttribute.codAttribute == 11).desValue,
            typeInput: selectedRow.fieldTransformAttributes.find(transformAttribute => transformAttribute.codAttribute == 1).desValue
        }, () => this.disableSaveEventButton())
    }

    handleRemove = () => {
        let { dialogOptions } = this.state;

        dialogOptions.open = true;
        
        this.setState({dialogOptions});
    }

    handleFilterTypeComponent = event => {
        this.setState({
            filterType: event.target.value
        });

        this.props.wizardStore.event.structures[0].structureExtras[0].value = event.target.value;
    }

    handleRemoveField = () => {
        let {
            fieldsFilter,
            gridOptions,
            dialogOptions
        } = this.state;

        const filterField = gridOptions.selectedRow;

        if (filterField.codFieldTransform) {
            this.props.wizardStore.event.deletedFieldTransforms.push(filterField.codFieldTransform);
        }

        fieldsFilter.splice(fieldsFilter.indexOf(filterField), 1);
        fieldsFilter.forEach((node, index) => node.indPosition = index);

        dialogOptions.open = false;
        gridOptions.selectedRow = null;

        this.setState({dialogOptions: dialogOptions, gridOptions: gridOptions, addTableButtonDisabled: fieldsFilter.length == 0 ? true : false}, () => this.reorderFieldsTableOrder(fieldsFilter))
    }

    handleCloseExclusionModal = () => {
        let { dialogOptions } = this.state;
        dialogOptions.open = false;

        this.setState({
            dialogOptions: dialogOptions
        });
    }

    toggleSaveEventButtonOnInputTextChange = event => {
        const { isShowForm } = this.state;
        let disableStep = true

        if(isShowForm == false) {
            if(event.target.value.length > 0 ) disableStep = false
            else disableStep = true
    
            if(disableStep == false){
                if(this.props.detailIn != undefined && this.props.detailIn.label.length > 0) disableStep = false
                else disableStep = true
            }
        }

        this.props.setWizardState({ disabledStep: disableStep })
        this.state.inputingText = true;
        this.setState({ outputText: event.target.value })
    }

    disabledSaveFormButton = () => {
        const { typeInput } = this.state
        return typeInput != undefined ? false : true
    }

    renderForm = () => {
        let {
            textInput,
            operatorList,
            operatorInput,
            operatorType,
            typeInput
        } = this.state;

        return (
            <Grid2 size={15}>
                <StyledContainer>
                    <Grid2>
                        <Typography variant="h6">{Translate("field_filter")}</Typography>
                        <Typography variant="subtitle1" gutterBottom>{Translate("change_field_filter")}</Typography>
                    </Grid2>
                    <Grid2>
                        <ConectorSelect 
                            label={"tipo"} 
                            options={operatorType}
                            onChange={event => this.setState({ typeInput: event.value })}
                            value={operatorType.find(operator => operator.value == typeInput)}/>
                    </Grid2>
                    <Grid2>
                        <ConectorSelect 
                            label={"operator"} 
                            options={operatorList}
                            onChange={event => this.setState({ operatorInput: event.value })}
                            value={operatorList.find(operator => operator.value == operatorInput)}/>
                    </Grid2>
                    <Grid2>
                        <ConectorInputText label={"to"} name="textInput" value={textInput} onChange={e => this.setState({ textInput: e.target.value })} />
                    </Grid2>
                    <Grid2 container justifyContent="flex-end" sx={{marginTop: "5px"}}>
                        <CancelSaveButtons onCancel={this.handleCancel.bind()} onSave={this.handleSave.bind()} disableSaveButton={this.disabledSaveFormButton()}/>
                    </Grid2>
                </StyledContainer>
            </Grid2>
        )
    }

    generateCopyOfTableFields = TableFields => {
        const { fieldsFilter, arrayNumberTables } = this.state
        const newNumberTable = arrayNumberTables[arrayNumberTables.length - 1] + 1
        const lastIndPositionOfFieldsFilter = fieldsFilter.at(-1).indPosition
        let copyTableFields = TableFields.map(fieldTransform => {
            let copyFieldTransform = { ...fieldTransform }
            copyFieldTransform.indPosition = 0
            copyFieldTransform.comparisonOrTableOrder = newNumberTable
            delete copyFieldTransform.codField
            delete copyFieldTransform.codFieldTransform
            copyFieldTransform.fieldTransformAttributes = copyFieldTransform.fieldTransformAttributes
            .map(attribute => {
                let copyAttribute = { ...attribute }
                delete copyAttribute.codFieldTransform
                return copyAttribute
            })
            return copyFieldTransform
        })
        copyTableFields.forEach(field => field.indPosition = lastIndPositionOfFieldsFilter + copyTableFields.indexOf(field) + 1)
        return copyTableFields
    }

    copyGridTable = () => {
        const { gridOptions, fieldsFilter } = this.state
        const selectedTableFields = fieldsFilter.filter(fieldTransform => fieldTransform.comparisonOrTableOrder == gridOptions.selectedRow.comparisonOrTableOrder)
        const copyTableFields = this.generateCopyOfTableFields(selectedTableFields)
        fieldsFilter.push(...copyTableFields)
        this.props.wizardStore.event.structures[0].structureDetails[0].fields[0].fieldTransforms = fieldsFilter
        let lastField = fieldsFilter.at(-1)
        gridOptions.selectedRow = lastField
        this.setState({ selectedTable: lastField.comparisonOrTableOrder}, () => this.reorderFieldsTableOrder(fieldsFilter))
    }

    generateCopyOfTableFields = TableFields => {
        const { fieldsFilter, arrayNumberTables } = this.state
        const newNumberTable = arrayNumberTables[arrayNumberTables.length - 1] + 1
        const lastIndPositionOfFieldsFilter = fieldsFilter.at(-1).indPosition
        let copyTableFields = TableFields.map(fieldTransform => {
            let copyFieldTransform = { ...fieldTransform }
            copyFieldTransform.indPosition = 0
            copyFieldTransform.comparisonOrTableOrder = newNumberTable
            delete copyFieldTransform.codField
            delete copyFieldTransform.codFieldTransform
            copyFieldTransform.fieldTransformAttributes = copyFieldTransform.fieldTransformAttributes
            .map(attribute => {
                let copyAttribute = { ...attribute }
                delete copyAttribute.codFieldTransform
                return copyAttribute
            })
            return copyFieldTransform
        })
        copyTableFields.forEach(field => field.indPosition = lastIndPositionOfFieldsFilter + copyTableFields.indexOf(field) + 1)
        return copyTableFields
    }

    copyGridTable = () => {
        const { gridOptions, fieldsFilter } = this.state
        const selectedTableFields = fieldsFilter.filter(fieldTransform => fieldTransform.comparisonOrTableOrder == gridOptions.selectedRow.comparisonOrTableOrder)
        const copyTableFields = this.generateCopyOfTableFields(selectedTableFields)
        fieldsFilter.push(...copyTableFields)
        this.props.wizardStore.event.structures[0].structureDetails[0].fields[0].fieldTransforms = fieldsFilter
        let lastField = fieldsFilter.at(-1)
        gridOptions.selectedRow = lastField
        this.setState({ selectedTable: lastField.comparisonOrTableOrder}, () => this.reorderFieldsTableOrder(fieldsFilter))
    }

    renderGridTableButtons = numberTable => {
        const { gridOptions, selectedTable } = this.state;

        let disabledButtons = true

        if(gridOptions.selectedRow != undefined && gridOptions.selectedRow.comparisonOrTableOrder == selectedTable){
            disabledButtons = false
        }

        if(numberTable == selectedTable){
            return (                                
                <StyledButtonContainer>
                    <StyledGridButton size="small" name={"edit-field"} variant="outlined" className={"iconNoMargin"}
                        onClick={this.handleEdit.bind(this, gridOptions.selectedRow)} disabled={disabledButtons}>
                        <MdEdit size={24} />
                    </StyledGridButton>
                    <StyledGridButton size="small" name={"delete-field"} variant="outlined" className={"iconNoMargin"}
                        onClick={this.handleRemove.bind(this)} disabled={disabledButtons}>
                        <MdDelete size={24} />
                    </StyledGridButton>
                    <StyledGridButton size="small" name={"delete-field"} variant="outlined" className={"iconNoMargin"}
                        onClick={this.copyGridTable.bind(this)} disabled={disabledButtons}>
                        <MdFileCopy size={24} />
                    </StyledGridButton>
                </StyledButtonContainer>)
        }
    }

    changeSelectedTable = tableNumber => {
        const { gridOptions } = this.state;

        gridOptions.selectedRow = null;
        this.setState({selectedTable: tableNumber, gridOptions})
    }

    renderGridTables = () => {
        const { gridOptions, arrayNumberTables, selectedTable, fieldsFilter } = this.state;

        return (
            <Grid2>
                {arrayNumberTables.map(number => {
                    return (
                        <StyledContainerTableAndRadio>
                            <StyledRadioGroup>
                                <Radio
                                    style={{width: "1px", paddingLeft: "0px", marginRight: "5px", color: "#0266B1"}}
                                    color="default"
                                    onChange={x => this.changeSelectedTable(Number(x.target.value))}
                                    checked={selectedTable === number}
                                    value={number}/>
                                <StyledGridTable>
                                    <ConectorGridTable
                                        dataSource={fieldsFilter.filter(field => field.comparisonOrTableOrder == number)}
                                        gridOptions={gridOptions}/>
                                </StyledGridTable>
                            </StyledRadioGroup>
                            {this.renderGridTableButtons(number)}
                        </StyledContainerTableAndRadio>
                    )
                })}
            </Grid2>
        )
    }

    addGridTable = () => {
        const { arrayNumberTables } = this.state;

        let newNumberTable = arrayNumberTables[arrayNumberTables.length - 1] + 1

        arrayNumberTables.push(newNumberTable)

        this.setState({
            arrayNumberTables: arrayNumberTables, 
            selectedTable: newNumberTable, 
            addTableButtonDisabled: true
        })
    }

    renderGrid = () => {
        const { dialogOptions, addTableButtonDisabled } = this.state;

        return (
            <Grid2 size={15}>
                <StyledButtonContainer>
                    <StyledGridButton 
                        size="small" 
                        name={"add-field"} 
                        variant="outlined" 
                        className={"iconNoMargin"}
                        onClick={this.addGridTable.bind(this)} 
                        style={addTableButtonDisabled ? {display: "none"} : {display: "inline"}}>
                        {Translate("addTable")}
                    </StyledGridButton>
                </StyledButtonContainer>
                {this.renderGridTables()}
                <ConectorDialogConfirmation dialogOptions={dialogOptions} />
            </Grid2>
        )
    }

    enableSaveEventButton = () => this.props.setWizardState({disabledStep: false})

    disableSaveEventButton = () => this.props.setWizardState({disabledStep: true})

    render() {
        const { isShowForm, outputText } = this.state;

        return (
            <StyledPaper>
                <Grid2 container>
                    <Grid2 size={15}>
                        <ConectorInputText name="outputName" label={"output_model"} value={outputText}
                            onChange={event => this.toggleSaveEventButtonOnInputTextChange(event)}
                            onBlur={_ => {
                                this.props.wizardStore.event.structures[1].structureDetails.map(detail => detail.output = outputText);
                            }}/>
                    </Grid2>
                    {isShowForm ? this.renderForm() : this.renderGrid()}
                </Grid2>
            </StyledPaper>
        )
    }
}

const mapStateToProps = state => {
    const wizardStore = wizard.getWizardState(state);
    const wizardStoreStructures = wizardStore.event.structures;
    let fieldsFilter = [];
    let outputText = "";

    const structureIn = wizardStoreStructures.filter((structure) => {
        if(structure.codEventProperty == 126) return structure
    })

    const structureOut = wizardStoreStructures.filter((structure) => {
        if(structure.codEventProperty == 112) return structure
    })

    if(structureIn != undefined && structureIn[0].structureDetails.length != 0){
        fieldsFilter = (structureIn[0].structureDetails[0].fields[0].fieldTransforms || []).map(fieldTransform => {
            let { fieldTransformAttributes } = fieldTransform;

            let condition = fieldTransformAttributes.find(transformAttribute => transformAttribute.codAttribute == 47)
            let parameter = fieldTransformAttributes.find(transformAttribute => transformAttribute.codAttribute == 11)
            let type = fieldTransformAttributes.find(transformAttribute => transformAttribute.codAttribute == 1)

            return {
                ...fieldTransform,
                condition: condition == undefined ? undefined : Translate(condition.desValue),
                parameter: parameter == undefined ? undefined : parameter.desValue,
                type: type == undefined ? undefined : type.desValue
            }
        })
    }

    if(structureOut != undefined && structureOut[0].structureDetails.length != 0)
        outputText = structureOut[0].structureDetails[0].output;
    else if(wizardStore.outputText != undefined && wizardStore.outputText.length > 0)
        outputText = wizardStore.outputText

    return {
        wizardStore,
        outputText,
        filterType: structureIn[0].structureExtras.length != 0 ? structureIn[0].structureExtras[0].value : undefined,
        fieldsFilter
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setWizardState: (wizardState) => { dispatch(setWizardState(wizardState)) }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Filter);