import styled from 'styled-components'

const StyledBottomDiv = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hidden { display: none; }

  &:not(.no-border) {
    border-right: 1px solid #B8C6D6;
    border-left: 1px solid #B8C6D6;
    border-top: 1px solid #B8C6D6;
  }

  &.padding-top-bottom {
    padding: 20px 0;
  }

  button {
    display: inline;

    &.MuiIconButton-disabled {
      opacity: 0.5;
    }
  }

  form {
    z-index: 5;
    position: relative;

    .select {
      display: inline-block;
      min-width: 300px;
    }

    .MuiInputBase-root {
      background-color: #fff;
      position: absolute;
      top: 5px;
      left: 10px;
      min-width: 220px;
      input:-webkit-autofill { box-shadow: 0 0 0px 1000px white inset; }
      input[type="search" i]::-webkit-search-cancel-button { -webkit-appearance: none; }
    }

    .MuiInputBase-root::before { border-bottom: 0; }
    .MuiInputBase-root::after { border-bottom: 0; }
    .MuiInputBase-root:hover:not(.MuiInput-disabled):not(.MuiInput-focused):not(.MuiInput-error):before {
      border-bottom: 0;
    }
  }
`

export default StyledBottomDiv;