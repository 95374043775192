import request from "../../../utils/request";

export default {
    SuggestTemplate: async (item) => {
        const res = await request.executeRequest(
            `${request.baseUrl}repositories`,
            {
                ...request.postOptions(),
                body: JSON.stringify(item)
            }
        );

        return await res.json();
    },
    Approve: async (item) => {
        const res = await request.executeRequest(
            `${request.baseUrl}repositories`,
            {
                ...request.putOptions(),
                body: JSON.stringify(item)
            }
        );

        return await res.json();
    },
    Update: async (item) => {
        const res = await request.executeRequest(
            `${request.baseUrl}repositories/${item.codItem}`,
            {
                ...request.postOptions(),
                body: JSON.stringify(item)
            }
        );

        return await res.json();
    },
    FindAll: async (canShowNotPublished) => {
        const res = await request.executeRequest(
            `${request.baseUrl}repositories/?showNotPublished=${canShowNotPublished}`,
            request.getOptions()
        );

        return await res.json();
    },
    Remove: async (id) => {
        const res = await request.executeRequest(
            `${request.baseUrl}repositories/${id}`,
            request.deleteOptions()
        );

        return await res.json();
    },
    Exists: async (codIntanceInterface) => {
        const res = await request.executeRequest(
            `${request.baseUrl}repositories/checkexists/${codIntanceInterface}`,
            request.getOptions()
        );

        return await res.json();
    },
    GetInterface: async (id) => {
        const res = await request.executeRequest(
            `${request.baseUrl}repositories/${id}/findinterface`,
            request.getOptions()
        );

        return await res.json();
    },
    Upload: async (codData, data) => {
        const res = await request.executeRequest(
            `${request.baseUrl}repositories/upload/${codData || ""}`,
            {
                ...request.multipartOptions(),
                body: data
            }
        );

        return await res.json();
    }
}
