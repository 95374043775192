import styled from 'styled-components'

export const StyledErrorImage = styled.img`
  max-width: 100%;
  max-height: 200px;
`;

export const StyledErrorMessage = styled.div`
  text-align: center;

  h1 {
    color: #004D7C;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  p {
    color: #424242;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const StyledErrorButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-bottom: 60px;
  margin-top: 5px;

  &:hover {
    background-color: #2b77b3;
  }
`;

export const StyledErrorSupportMessage = styled.p`
  color: #424242;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  a {
    color: #004D7C;
    font-weight: 700;
    text-decoration-line: underline;
  }
`;

export const StyledErrorScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  background-color: #FFFFFF;
`;