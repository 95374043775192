import React, { Component } from "react"
import { MdEdit } from "react-icons/md"
import Translate from "../../../../i18n/translate"
import TransformUtil from "../transform-util"
import { StyledGridButton } from "../../../../componentsUI/styledComponents/styledTransform"
import ConectorSelect from "../../../../componentsUI/conectorSelect"
import StyledPaper from "../../../../componentsUI/styledComponents/styledPaper"
import ConectorGridTable from "../../../../componentsUI/gridTable"
import ConectorInputNumber from "../../../../componentsUI/inputNumber"
import StyledButton from "../../../../componentsUI/styledComponents/styledButton"
import ConectorInputText from "../../../../componentsUI/inputText";
import {
    Grid2,
    FormControl,
    FormHelperText,
    FormControlLabel,
    Checkbox,
    Radio,
} from "@mui/material";

class TransformFieldSpecial extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props,
            gridOptions: {
                sortField: "indPosition",
                multiSelect: false,
                fullSize: true,
                columns: [{
                    title: "name",
                    field: "name"
                }],
                refresh: this.gridRefresh.bind(this)
            },
            mode: "form",
            formData: {
                fieldType: props.fieldTransform.specials[0].fieldType,
                fieldPath: props.fieldTransform.specials[0].fieldPath,
                isSubstring: props.fieldTransform.specials[0].isSubstring ? JSON.parse(props.fieldTransform.specials[0].isSubstring): false,
                indPosition: props.fieldTransform.specials[0].indPosition,
                initPosition: props.fieldTransform.specials[0].initPosition.toString(),
                endPosition: props.fieldTransform.specials[0].endPosition.toString(),
                isTrim: props.fieldTransform.specials[0].isTrim ? JSON.parse(props.fieldTransform.specials[0].isTrim): false,
                trimType:props.fieldTransform.specials[0].trimType ? props.fieldTransform.specials[0].trimType.toString() :"",
                isDelimiter: props.fieldTransform.specials[0].isDelimiter ? JSON.parse(props.fieldTransform.specials[0].isDelimiter): false,
                delimiter: props.fieldTransform.specials[0].delimiter,
                pos_delimiter: props.fieldTransform.specials[0].pos_delimiter,
            }
        };
    }

    componentWillMount = () => {
        const { fields } = this.state;

        this.setState({
            fieldList: fields.map(field => {
                return {
                    value: field.path,
                    label: field.title
                }
            })
        })
    }

    componentWillReceiveProps = props => {
        let state = {...this.state, ...props};
        state.gridOptions.selectedRow = null;

        this.setState(state)
    }

    gridRefresh = (gridState) => {
        this.setState({
            gridOptions: gridState.gridOptions
        })
    }

    sortSpecials = (fieldTransform) => {
        fieldTransform.specials.sort((special1, special2) => special1.indPosition - special2.indPosition);

        return fieldTransform;
    }

    handleSave = () => {
        let special;

        const {
            attributes,
            fieldTransform,
            formData,
            fields
        } = this.state;

        formData.fieldType = "field"

        if (formData.indPosition == null) {
            special = {
                ...this.getInitialSpecial()
            };
        } else {
            special = fieldTransform.specials[formData.indPosition];
        }

        special = fieldTransform.specials[0];

        var validationTrim = TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.is_trim);
        if(validationTrim == null){
            special.fieldTransformAttributes = [
                ...special.fieldTransformAttributes,
                {
                    codAttribute: attributes.is_trim,
                    desLabel: "is_trim",
                    indPosition: 6
                }, {
                    codAttribute: attributes.trim_type,
                    desLabel: "trim_type",
                    indPosition: 7
                }
            ]
        }
        var validationDelimiter = TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.is_delimiter);
        if(validationDelimiter == null){
            special.fieldTransformAttributes = [
                ...special.fieldTransformAttributes,
                {
                    codAttribute: attributes.is_delimiter,
                    desLabel: "is_delimiter",
                    indPosition: 8
                }, {
                    codAttribute: attributes.delimiter,
                    desLabel: "delimiter",
                    indPosition: 9
                }, {
                    codAttribute: attributes.pos_delimiter,
                    desLabel: "pos_delimiter",
                    indPosition: 10
                }
            ]
        }
        if (!formData.isSubstring) {
            formData.initPosition = "0";
            formData.endPosition = "0";
        }      
        if (!formData.isTrim) {
            formData.trim_type = "";
        }
        if (!formData.isDelimiter) {
            formData.delimiter = "";
            formData.pos_delimiter = "0";
        }  
        special = {
            ...special,
            ...formData,
            name: (TransformUtil.getItemByPath(fields, formData.fieldPath ? formData.fieldPath : special.fieldPath).title)
        };

        TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.field_type).desValue = formData.fieldType;
        TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.from).desValue = (formData.fieldPath);
        TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.is_substring).desValue = JSON.stringify(formData.isSubstring);
        TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.ini_substring).desValue = formData.initPosition;
        TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.end_substring).desValue = formData.endPosition;
        TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.is_trim).desValue = JSON.stringify(formData.isTrim);
        TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.trim_type).desValue = formData.trimType;
        TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.is_delimiter).desValue = JSON.stringify(formData.isDelimiter);
        TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.delimiter).desValue = formData.delimiter;
        TransformUtil.getAttributeByCode(special.fieldTransformAttributes, attributes.pos_delimiter).desValue = formData.pos_delimiter;

        if (formData.indPosition == null) {
            special.indPosition = 0
        } 
        
        fieldTransform.specials[0] = special;

        this.setState({
            fieldTransform: fieldTransform,
            mode: "grid"
        }, this.state.updateEvent);
    }

    getInitialSpecial = () => {
        const {
            attributes,
            formData,
            fields,
            fieldTransform
        } = this.state;

        return {
            name: TransformUtil.getItemByPath(fields, formData.fieldPath ? formData.fieldPath : fieldTransform.specials[0].fieldPath).title,
            desField: "special",
            fieldTransformAttributes: [{
                codAttribute: attributes.field_type,
                desLabel: "field_type",
                indPosition: 0
            }, {
                codAttribute: attributes.from,
                desLabel: "from",
                indPosition: 1
            }, {
                codAttribute: attributes.structure_in,
                desValue: false,
                desLabel: "structure_in",
                indPosition: 2
            }, {
                codAttribute: attributes.is_substring,
                desLabel: "is_substring",
                indPosition: 3
            }, {
                codAttribute: attributes.ini_substring,
                desLabel: "ini_substring",
                indPosition: 4
            }, {
                codAttribute: attributes.end_substring,
                desLabel: "end_substring",
                indPosition: 5
            }, {
                codAttribute: attributes.is_trim,
                desLabel: "is_trim",
                indPosition: 6
            }, {
                codAttribute: attributes.trim_type,
                desLabel: "trim_type",
                indPosition: 7
            },{
                codAttribute: attributes.is_delimiter,
                desLabel: "is_delimiter",
                indPosition: 8
            }, {
                codAttribute: attributes.delimiter,
                desLabel: "delimiter",
                indPosition: 9
            }, {
                codAttribute: attributes.pos_delimiter,
                desLabel: "pos_delimiter",
                indPosition: 10
            }]
        }
    }

    handleEdit = (selectedRow) => {
        this.setState({
            formData: {
                fieldType: selectedRow ? selectedRow.fieldType : "field",
                fieldPath: selectedRow ? selectedRow.fieldPath : "",
                indPosition: selectedRow ? selectedRow.indPosition : null,
                initPosition: selectedRow ? selectedRow.initPosition.toString() : "0",
                endPosition: selectedRow ? selectedRow.endPosition.toString() : "0",
                isTrim: selectedRow ? JSON.parse(selectedRow.isTrim) : false,
                trimType: selectedRow ? selectedRow.trimType.toString() : "",
                isDelimiter: selectedRow ? JSON.parse(selectedRow.isDelimiter) : false,
                delimiter: selectedRow ? selectedRow.delimiter.toString() : "",
                pos_delimiter: selectedRow ? selectedRow.pos_delimiter.toString() : ""
            },
            mode: "form",
            disableSave: !selectedRow
        });
    }

    handleChangeFormComponent = (event) => {
        let formData = { ...this.state.formData };

        if (event.hasOwnProperty("target")) {
            const property = event.target.name;
            if(property === "isSubstring")
                formData[property] = event.target[property == "isSubstring" ? "checked" : "value"];
            else if(property === "isDelimiter")
                formData[property] = event.target[property == "isDelimiter" ? "checked" : "value"];
            else
                formData[property] = event.target[property == "isTrim" ? "checked" : "value"];
        } else {
            formData[event.name] = event.value;
        }

        this.setState({
            formData: formData,
            disableSave: !this.isFormValid(formData)
        });
    }

    isFormValid = (formData) => {
        const { fieldTransform } = this.state;
            if(fieldTransform.specials[0].fieldPath != formData.fieldPath)
                return true;
            
            if (formData.isSubstring) {
                const initPosition = Number(formData.initPosition);
                const endPosition = Number(formData.endPosition);

                if (initPosition >= 0 && endPosition >= 0 &&
                    endPosition >= initPosition) {
                    return true;
                }
            } else {
                return true;
            }

        return false;
    }

    getFieldName(path){
        let name = [];
        name = path.split('.');
        return name[name.length-1].toString();
    }

    render() {
        const { mode } = this.state;

        return (
            <StyledPaper>
                {mode == "grid" ? this.renderGrid() : this.renderForm()}
            </StyledPaper>
        )
    }

    renderForm = () => {
        const {
            disableSave,
            formData,
            fieldList,
            fieldTransform
        } = this.state;

        if(!disableSave && !formData.fieldPath){
            if(fieldTransform.specials[0].fieldPath != ""){
                this.setState({disableSave: true});
            }
        }

        return (
            <Grid2>
                {
                    <FormControl style={{ width: "100%" }}>
                        <ConectorSelect 
                            name={"fieldPath"} 
                            label={"field"} 
                            placeholder={fieldTransform.specials[0].name != "" ? fieldTransform.specials[0].name : "choose"} options={fieldList}
                            value={fieldList.find(field => field.value == formData.fieldPath)}
                            onChange={this.handleChangeFormComponent.bind(this)}
                            showTooltip={true}/>
                                <FormHelperText>{Translate("select_field")}</FormHelperText>
                    </FormControl>
                            
                }
                {
                    <FormControlLabel label="Substring"
                    control={
                        <Checkbox style={{"color": "#6f8296"}} checked={formData.isSubstring} name="isSubstring" onChange={this.handleChangeFormComponent.bind(this)} />
                    } />
                }
                {
                    formData.isSubstring ? (
                        <Grid2 container style={{ marginBottom: "15px" }}>
                            <Grid2 style={{ paddingRight: "7.5px", boxSizing: "border-box" }}>
                                <ConectorInputNumber name="initPosition" required={true} label={"init_position"}
                                    value={formData.initPosition} onChange={this.handleChangeFormComponent.bind(this)} />
                            </Grid2>

                            <Grid2 style={{ paddingLeft: "7.5px", boxSizing: "border-box" }}>
                                <ConectorInputNumber name="endPosition" required={true} label={"end_position"}
                                    value={formData.endPosition} onChange={this.handleChangeFormComponent.bind(this)} />
                            </Grid2>
                        </Grid2>
                    ) : ""
                }
                {
                    <Grid2>
                        <FormControlLabel label={Translate("trim")}
                        control={
                            <Checkbox style={{"color": "#6f8296"}} checked={formData.isTrim} name="isTrim" onChange={this.handleChangeFormComponent.bind(this)} />
                        } />
                    </Grid2>
                }
                {
                    formData.isTrim ? (
                    <Grid2 style={{ 
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontSize: '0.9rem',
                        color: 'default'
                    }}>
                        <Grid2 container>
                            <Grid2>
                                <Radio
                                    name="trimType"
                                    color="default"
                                    onChange={this.handleChangeFormComponent}
                                    checked={formData.trimType === 'trim_start'}
                                    value="trim_start"
                                    />
                                {Translate("trim_start")}
                            </Grid2>
                            <Grid2>
                                <Radio
                                    name="trimType"
                                    color="default"
                                    onChange={this.handleChangeFormComponent}
                                    checked={formData.trimType === 'trim_end'}
                                    value="trim_end"
                                    title="Remove"
                                />
                                {Translate("trim_end")}                        
                            </Grid2>
                            <Grid2>
                                <Radio
                                    name="trimType"
                                    color="default"
                                    onChange={this.handleChangeFormComponent}
                                    checked={formData.trimType === 'trim_all'}
                                    value="trim_all"
                                />
                                {Translate("trim_all")}
                            </Grid2>
                            <Grid2>
                                <Radio
                                    name="trimType"
                                    color="default"
                                    onChange={this.handleChangeFormComponent}
                                    checked={formData.trimType === 'all'}
                                    value="all"
                                />
                                {Translate("all")}
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    ) : ""
                }
                {
                    <Grid2>
                    <FormControlLabel label={Translate("delimiterField")}
                    control={
                        <Checkbox style={{"color": "#6f8296"}} checked={formData.isDelimiter} name="isDelimiter" onChange={this.handleChangeFormComponent.bind(this)} />
                    } />
                    </Grid2>
                }
                {
                    formData.isDelimiter ? (
                        <Grid2 container style={{ marginBottom: "15px" }}>
                            <Grid2 item xs={6} style={{ paddingRight: "7.5px", boxSizing: "border-box" }}>
                                <ConectorInputText name="delimiter" required={true} label={"delimiterField"}
                                    value={formData.delimiter} onChange={this.handleChangeFormComponent.bind(this)}
                                    help={"delimiterHelp"}
                                    
                                />
                            </Grid2>

                            <Grid2 item xs={6} style={{ paddingLeft: "7.5px", boxSizing: "border-box" }}>
                                <ConectorInputNumber name="pos_delimiter" required={true} label={"pos_delimiter"}
                                    value={formData.pos_delimiter} onChange={this.handleChangeFormComponent.bind(this)}
                                    help={"delimiterPosEx"} />
                            </Grid2>
                        </Grid2>
                    ) : ""
                }

                <div style={{ width: "100%", marginTop: "15px" }}>
                    <StyledButton variant="contained" color="primary" onClick={() => this.setState({ mode: "grid" })}>{Translate("cancel")}</StyledButton>
                    <StyledButton variant="outlined" disabled={disableSave} onClick={this.handleSave}>{Translate("save")}</StyledButton>
                </div>
            </Grid2>
        );
    }

    renderGrid = () => {
        const {
            gridOptions,
            fieldTransform
        } = this.state;

        const disabledButton = (gridOptions.selectedRow == null || Object.keys(gridOptions.selectedRow).length == 0);

        fieldTransform.specials.map(field => {
            if(!field.name){
                field.fieldPath ? field.name = this.getFieldName(field.fieldPath) : null
            }
        })

        return (
            <Grid2>
                <div>
                    <StyledGridButton size="small" name={"edit-field"} variant="contained" className={"iconNoMargin"}
                        onClick={this.handleEdit.bind(this, gridOptions.selectedRow)} disabled={disabledButton}>
                        <MdEdit />
                    </StyledGridButton>
                </div>

                <ConectorGridTable dataSource={fieldTransform.specials} gridOptions={gridOptions} />
            </Grid2>
        );
    }

}

export default TransformFieldSpecial;