import request from '../../utils/request'
import Session from '../../utils/session-info';

export default {
    GetAllByWorker: (codInstance = Session().codInstance) => {
        return request.executeRequest(
            `${request.baseUrl}worker_panel/${codInstance}`,
            request.getOptions()
        ).then(res => res.json())
    },
    RequestLog: object => {
        return request.executeRequest(
            `${request.baseUrl}worker_panel/request_log`,
            {
                ...request.postOptions(),
                body: JSON.stringify(object)
            }
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText)
            }
            return res.json();
        })
    },
}
