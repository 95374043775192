import styled from 'styled-components'
import { Drawer, ListItemText, Grid2, Paper, MenuList } from "@mui/material"
import { MdOutlineKeyboardArrowDown } from "react-icons/md"
import { GrAlert } from "react-icons/gr"
import { GiHamburgerMenu } from "react-icons/gi"
import { AiOutlineCluster } from "react-icons/ai"
import { PiNotePencilBold } from "react-icons/pi"
import { AiOutlineTool } from "react-icons/ai"
import { IoDocumentTextOutline } from "react-icons/io5"
import { RiAdminLine } from "react-icons/ri";
import { TbPoint } from "react-icons/tb";

const sidebarColor = '#4C637B'
const sidebarItemColor = '#EEEEEE'
const iconsSize = 25

const StyledDrawer = styled(Drawer)(({ hide }) => ({
	'& .MuiDrawer-paper': {
	  zIndex: 99,
	  width: hide ? '65px' : '200px',
	  height: '100vh',
	  backgroundColor: sidebarColor,
	  transition: 'width 0.5s',
	  overflow: 'hidden'
	}
}))

const StyledListItemText = styled(ListItemText)(({ hide }) => ({
	color: sidebarItemColor, 
	textAlign: 'left',
	display: hide ? 'none' : '',
	paddingLeft: '5px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	'& span': {fontSize: '15px'}
}))

const StyledSubListItemText = styled(ListItemText)(({ hide }) => ({
	color: sidebarItemColor,
	textAlign: 'left',
	display: hide ? 'none' : '',
	'& span': {fontSize: '13px'}
}))

const StyledGridItemActions = styled(Grid2)({
	height: '100vh',
	paddingTop: '55px'
})

const StyledFloatingMenu = styled(Paper)(({ hide }) => ({
	display: hide ? 'none' : 'block',
	width: "auto", 
    maxWidth: '100%', 
    boxShadow: '5px 10px 30px !important',
    left: "75px",
    position: "fixed", 
    zIndex: 101
}))

const StyledMenuList = styled(MenuList)(() => ({
	color: '#6F8296'
}))

const StyledAlertIcon = styled(GrAlert)(() => ({
	color: sidebarItemColor,
	fontSize: iconsSize,
	animation: "blink 1s infinite",
	"@keyframes blink": {
	  "0%, 100%": { opacity: 1 },
	  "50%": { opacity: 0 },
	},
}))

const StyledArrowDownUpIcon = styled(MdOutlineKeyboardArrowDown)(({ rotateArrow, hiddenSidebar }) => ({
	rotate: hiddenSidebar ?  '-90deg' : (rotateArrow ? '-180deg' : '0deg'),
	transition: "rotate 0.5s",
	color: sidebarItemColor,
}))

const StyledIntegrationsIcon = styled(AiOutlineCluster)(() => ({
	color: sidebarItemColor,
	fontSize: iconsSize
}))

const StyledRegisterIcon = styled(PiNotePencilBold)(() => ({
	color: sidebarItemColor,
	fontSize: iconsSize
}))

const StyledAdministrationIcon = styled(RiAdminLine)(() => ({
	color: sidebarItemColor,
	fontSize: iconsSize
}))

const StyledMaintenanceIcon = styled(AiOutlineTool)(() => ({
	color: sidebarItemColor,
	fontSize: iconsSize
}))

const StyledVersionNotesIcon = styled(IoDocumentTextOutline)(() => ({
	color: sidebarItemColor,
	fontSize: iconsSize
}))

const StyledHamburgerMenuIcon = styled(GiHamburgerMenu)(() => ({
	color: sidebarItemColor,
	fontSize: iconsSize
}))

const StyledPointSubMenuIcon = styled(TbPoint)(() => ({
	color: sidebarItemColor
}))

export { 
	StyledDrawer, 
	StyledListItemText,
	StyledSubListItemText,
	StyledGridItemActions,
	StyledFloatingMenu,
	StyledMenuList,
	StyledAlertIcon, 
	StyledArrowDownUpIcon, 
	StyledHamburgerMenuIcon,
	StyledIntegrationsIcon,
	StyledRegisterIcon,
	StyledAdministrationIcon,
	StyledMaintenanceIcon,
	StyledVersionNotesIcon,
	StyledPointSubMenuIcon
}