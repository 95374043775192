import "regenerator-runtime";
import React, { Component, Fragment } from "react"
import { Grid2, IconButton, Typography } from "@mui/material"
import { connect } from "react-redux"
import { MdVisibility, MdVisibilityOff } from "react-icons/md"
import Translate from "../../i18n/translate"
import { setBreadcrumb } from "../../store/app-state/actions"
import Toast from "../../components/toast/toast"
import Session from "../../utils/session-info"
import request from "../../utils/request"
import validator from 'validator'
import ConectorSelect from "../../componentsUI/conectorSelect"
import ConectorCheckbox from "../../componentsUI/checkBox"
import ConectorInputText from "../../componentsUI/inputText"
import ConectorInputPassword from "../../componentsUI/inputPassword"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import StyledButton from "../../componentsUI/styledComponents/styledButton"

class MyAccount extends Component {
    constructor(props) {
        super(props);

        props.setBreadcrumb([{
            label: "my_account"
        }]);

        const session = Session();

        this.state = {
            user: {
                id: session.codUser,
                name: session.desName,
                hash: session.hash,
                avatar: session.avatar,
                changePassword: false,
                language: session.language
            },
            errors: [],
            passwordVisible: false
        };
    }

    handleChange(event) {
        const target = event.target;
        const property = target.name;
        let { user } = this.state;
        let { changePassword, password, passwordConfirmed } = this.state.user;
        let value = target[(target.type == "checkbox" ? "checked" : "value")];
        user[property] = value;

        if(target.name != undefined)
            switch (target.name) 
            {
                case "password":
                    password = target.value
                    break;
                case "passwordConfirmed":
                    passwordConfirmed = target.value
                    break;
                case "changePassword":
                    this.state.user.password = ""
                    this.state.user.passwordConfirmed = ""
            }

        this.saveDisabled()
        this.setState({"user": user});
    }

    handleChangeLanguage(language) {
        this.handleChange({
            target: {
                name: "language",
                value: language.value
            }
        });
    }

    uploadAvatarFinish(inputAvatar) {
        setTimeout(function () {
            let { user } = this.state;
            user.avatar = inputAvatar.previousSibling.src;

            this.setState({
                "user": user
            });
        }.bind(this), 100);
    }

    switchPasswordVisibility() {
        this.setState({
            "passwordVisible": !this.state.passwordVisible
        });
    }

    copyHash() {
        const inputAux = document.createElement("input");
        inputAux.value = this.state.user.hash;
        document.body.appendChild(inputAux);

        inputAux.select();

        document.execCommand("copy");
        document.body.removeChild(inputAux);
    }

    save() {
        this.validateForm();

        if (this.state.errors.length == 0) {
            const { user } = this.state;

            request.executeRequest(
                `${request.baseUrl}globals/edit-user`,
                {
                    ...request.postOptions(),
                    body: JSON.stringify({
                        codUser: user.id,
                        avatar: user.avatar,
                        desName: user.name,
                        language: user.language,
                        desPassword: user.changePassword ? user.password : null
                    })
                }
            ).then((response) => {
                switch(response.status) {
                    case 200:
                        this.updateSession();
                        this.resetPassword();
                        Toast.success(Translate("user_success_changed"));
                    break;
                    case 207:
                        Toast.error('same_pass')
                      break;
                    case 423:
                        Toast.error(Translate("time_edit"))
                      break;
                    case 401:
                        Toast.error(Translate("password_used_recently"));
                      break;
                    default:
                        Toast.error(Translate("internal_error"))
                    break;
                }
            })
        }
    }

    validateForm() {
        let { user, errors } = this.state;

        this.validateUserName(user.name, errors);
        this.validateUserPassword(user, errors);

        this.setState({
            "errors": errors
        });
    }

    validateUserName(name, errors) {
        this.removeError(errors, "name");

        if (this.isBlankOrNull(name)) {
            errors.push("name");
        }
    }

    validateUserPassword(user, errors) {
        this.removeError(errors, "password");
        this.removeError(errors, "passwordConfirmed");
        this.removeError(errors, "passwordStrength");

        if (user.changePassword) {
            const hasPassword = !this.isBlankOrNull(user.password);
            const hasPasswordConfirmed = !this.isBlankOrNull(user.passwordConfirmed);

            if (!hasPassword && !hasPasswordConfirmed) {
                errors.push("password");
                errors.push("passwordConfirmed");
            } else if (!hasPassword) {
                errors.push("password");
            } else if (!hasPasswordConfirmed || user.password != user.passwordConfirmed) {
                errors.push("passwordConfirmed");
                Toast.error("Passwords_Not_Match");
            }
            if(!this.validatePasswordStrength(user.password)){
                errors.push("passwordStrength");
                Toast.error("password_not_strong");
            }
        }
    }

    validatePasswordStrength = (password) => {
		var allowedToSave = false
  
		if (validator.isStrongPassword(password, {
		  minLength: 8, minLowercase: 1,
		  minUppercase: 1, minNumbers: 1, minSymbols: 1
		})) {
		  allowedToSave = true
		}

		return allowedToSave
	}

    isBlankOrNull(value) {
        return !value || value.trim() == "";
    }

    removeError(errors, error) {
        const errorIndex = errors.indexOf(error);

        if (errorIndex >= 0) {
            errors.splice(errorIndex, 1);
        }
    }

    updateSession() {
        const { user } = this.state;

        const session = Session();
        session.avatar = user.avatar;
        session.desName = user.name;
        session.language = user.language;

        localStorage.setItem("user", JSON.stringify(session));
    }

    resetPassword() {
        let { user } = this.state;
        user.changePassword = false;
        user.password = "";
        user.passwordConfirmed = "";

        this.setState({
            "user": user,
            "passwordVisible": false
        });
    }

    getLanguages() {
        return [{
            value: "en-US",
            label: "Inglês"
        }, {
            value: "pt-BR",
            label: "Português"
        }];
    }

    saveDisabled = () => {
        const { changePassword, password, passwordConfirmed } = this.state.user;

        if(changePassword)
            return password != undefined && 
            password != "" &&
            passwordConfirmed != undefined && 
            passwordConfirmed != "" ?
            false : true
        else
            return false;
    }

    render() {
        const {
            user,
            errors,
            passwordVisible
        } = this.state;

        const languages = this.getLanguages();

        return (
            <Fragment>
                <InternalPageTitle title={Translate("my_account")} />
                <StyledPaper>
                    <Grid2>
                        <Grid2 size={8}>
                            <StyledPaper className={"bg_darken_04"} >
                                <Grid2 size={8}>
                                    <Grid2 size={8} style={{textAlign: "center"}}>
                                        <Typography variant="h4">
                                            {Translate("welcome") + (this.isBlankOrNull(user.name) ? "" : (", " + user.name)) + "."}
                                        </Typography>

                                        <Typography variant="subtitle1">{Translate("details_your_account")}</Typography>
                                    </Grid2>
                                </Grid2>
                            </StyledPaper>
                        </Grid2>
                    </Grid2>
                    <Grid2 container columnSpacing={2} spacing={1}>
                        <Grid2 size={14}>
                            <ConectorInputText 
                                label={"name"} 
                                help={"enter_name"} 
                                name={"name"}
                                required={true} value={user.name}
                                onChange={this.handleChange.bind(this)}
                                errorCondition={errors.find(error => error === "name")}/>
                        </Grid2>
                        <Grid2 size={5}>
                            <ConectorInputText 
                                label={"hash"} 
                                name={"hash"} 
                                type={"text"}
                                disabled={true} 
                                value={user.hash} 
                                endIcon={"file_copy"}
                                endTooltip={"copy"} 
                                endActionButton={this.copyHash.bind(this)}/>
                        </Grid2>
                        <Grid2 size={7}>
                            <ConectorSelect label={Translate("language")} name={"language"} options={languages}
                                onChange={this.handleChangeLanguage.bind(this)}
                                value={languages.find(language => language.value == user.language)}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <ConectorInputPassword 
                                label={Translate("password")} 
                                name={"password"}
                                required={user.changePassword} disabled={!user.changePassword} value={user.password}
                                type={passwordVisible} onChange={this.handleChange.bind(this)}
                                errorCondition={user.changePassword ? errors.find(error => error === "password") : null}/>
                        </Grid2>
                        <Grid2 size={4}>
                            <ConectorInputPassword 
                                label={Translate("confirm_password")} 
                                name={"passwordConfirmed"}
                                required={user.changePassword} 
                                disabled={!user.changePassword} value={user.passwordConfirmed}
                                type={passwordVisible} 
                                onChange={this.handleChange.bind(this)}
                                errorCondition={user.changePassword ? errors.find(error => error === "passwordConfirmed") : null}/>
                        </Grid2>
                        <IconButton 
                            style={{ "margin-top": "20px" }} 
                            onClick={this.switchPasswordVisibility.bind(this)}
                            disabled={!user.changePassword}>
                            {passwordVisible ? <MdVisibility/> : <MdVisibilityOff/>}
                        </IconButton>
                        <Grid2 size={4}>
                            <ConectorCheckbox 
                                name="changePassword" 
                                color="default" 
                                value={user.changePassword}
                                onChange={this.handleChange.bind(this)} checked={user.changePassword}
                                label={Translate("change_password")}/>
                        </Grid2>
                    </Grid2>
                    <Grid2 container justifyContent="flex-end">
                        <Grid2 container spacing={1}>
                            <Grid2 item>
                                <StyledButton 
                                    className="no-margin"
                                    variant="outlined"
                                    onClick={this.save.bind(this)}
                                    disabled={this.saveDisabled()}>
                                    {Translate("save")}
                                </StyledButton>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </StyledPaper>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(null, mapDispatchToProps)(MyAccount);