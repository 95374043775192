import React, { Component, Fragment } from "react";
import Translate from "../../../../i18n/translate";
import { CSVLink } from 'react-csv';
import Session from '../../../../utils/session-info'
import LogService from "./resume-log-service";
import { DialogContent, Grid2, DialogActions, FormGroup, FormControlLabel, Switch } from "@mui/material";
import StyledButton from "../../../../componentsUI/styledComponents/styledButton"
import { StyledDialog, StyledDialogTitle } from "../../../../componentsUI/styledComponents/styledDialog"
import ConectorTable from "../../../../componentsUI/conectorTable"

class InterfacesAuditLogs extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            ...props,
            dataSource: [],
            id: props.id,
            gridOptions: {
				multiSelect: false,
                hideBtnNew: true,
                hideBtnEdit: true,
                hideBtnDelete: true,
                hideBtnCopy: true,
                hideFilter: true,
				columns: [{
                    title: "company",
                    field: "companyLog"
                }, {
                    title: "instance",
                    field: "instanceLog"
                }, {
                    title: "template",
                    field: "descriptionLog"
                }, {
                    title: "status",
                    field: "statusLog"
                }, {
                    title: "start",
                    field: "dateStartLog",
                    cellFilter: "ddMMyyyy-hh:mm"
                }, {
                    title: "end",
                    field: "dateEndLog",
                    cellFilter: "ddMMyyyy-hh:mm"
                }]
			},
            errorFilter: true,
            successFilter: true,
            warningFilter:true
        };
    }

    componentWillMount = () => {
        const { id, objectFilter } = this.state;
        const { desInstance } = Session();
        LogService.GetLogsResume(id, objectFilter).then(dataSource => {
            if(dataSource){
                const statusInterface = {1: "running", 2: "warning", 3: "error", 5: "awaiting"}
                dataSource.map((item) => {
                    item.statusLog = Translate(statusInterface[item.statusLog])
                    item.instanceLog = desInstance
                    delete item.id
                })
                this.setState({ dataSource });
            }
        });
    };

    fetchLogs = () => {
        const {
            id,
            objectFilter,
            errorFilter,
            successFilter,
            warningFilter
        } = this.state;

        const { desInstance } = Session();

        LogService.GetLogsResume(id, objectFilter, errorFilter, successFilter, warningFilter).then(dataSource => {
            if (dataSource) {
                const statusInterface = { 1: "running", 2: "warning", 3: "error", 5: "awaiting" };
                dataSource.map((item) => {
                    item.statusLog = Translate(statusInterface[item.statusLog]);
                    item.instanceLog = desInstance;
                    delete item.id;
                });
                this.setState({ dataSource });
            }
        });
    };    

    ApplyFilterSuccess = (event) => {
        const { checked } = event.target;
        this.setState(
            { successFilter: checked },
            () => this.fetchLogs()
        );
    };

    ApplyFilterWarning = (event) => {
        const { checked } = event.target;
        this.setState(
            { warningFilter: checked },
            () => this.fetchLogs()
        );
    };

    ApplyFilterError = (event) => {
        const { checked } = event.target;
        this.setState(
            { errorFilter: checked },
            () => this.fetchLogs()
        );
    }; 

    handleTable = () => {
        const {
            dataSource,
            gridOptions
        } = this.state

        if (dataSource){
            return(
                <Fragment>
                    <ConectorTable
                        hideToolbar={true}
                        gridOptions={gridOptions} 
                        dataSource={dataSource}>
                    </ConectorTable>
                </Fragment>
            )
        }
    }

    render() {
        const { 
            dataSource, 
            successFilter,
            warningFilter,
            errorFilter
        } = this.state;

        return (
            <StyledDialog
                open={true}
                disableEscapeKeyDown={false}
                disableBackdropClock={false}
                fullWidth={true}
                maxWidth={"lg"}>
                <StyledDialogTitle className="info">
                    {Translate("summarry-logs")}
                </StyledDialogTitle>
                <DialogContent>
                    <Grid2 container>
                        <Grid2 container spacing={1}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={successFilter} onChange={this.ApplyFilterSuccess}
                                    size="small" 
                                    color="primary" 
                                    />}

                                    label={Translate("successes")}
                                    labelPlacement="start"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch  checked={warningFilter} onChange={this.ApplyFilterWarning}
                                    size="small" 
                                    color="primary" 
                                    />}

                                    label={Translate("warnings")}
                                    labelPlacement="start" 
                                />
                            </FormGroup>
                            <FormGroup>     
                                <FormControlLabel
                                    control={<Switch  checked={errorFilter} onChange={this.ApplyFilterError}
                                    size="small" 
                                    color="primary" 
                                    />}

                                    label={Translate("error")}
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </Grid2>
                        <Grid2 size={15}>
                            {this.handleTable()}
                        </Grid2>
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={this.props.onDismiss}>
                        {Translate("cancel")}
                    </StyledButton>
                    <CSVLink data={dataSource} filename={"logResume.csv"} style={{ textDecoration: 'none' }}>
                        <StyledButton
                            variant="outlined">
                            {Translate("download")}
                        </StyledButton>
                    </CSVLink>
                </DialogActions>
            </StyledDialog>
        );
    }
}

export default InterfacesAuditLogs;
