import React, { Component } from 'react'
import { components } from 'react-select'
import Translate from "../i18n/translate"
import StyledBottomDiv from './styledComponents/styledBottomDiv'
import StyledSelect from './styledComponents/styledSelect'
import StyledButton from './styledComponents/styledButton'
import { GridToolBarDiv, TopDiv } from "./styledComponents/styledGridToolBar"
import { Input, Tooltip, Typography, IconButton, Grid2 } from '@mui/material'
import { 
  MdFileCopy, 
  MdDelete, 
  MdEdit, 
  MdSearch, 
  MdAddCircle, 
  MdExpandMore 
} from "react-icons/md"

class GridToolBar extends Component {
  constructor(props) {
    super(props)

    let filterOptions = []
    this.props.gridOptions.columns.forEach(column => {
      if (!column.hideFilter) {
        let option = { value: column.field, label: Translate(column.title) }
        filterOptions.push(option)
      }
    })

    this.state = {
      gridOptions: this.props.gridOptions,
      headerOptions: this.props.headerOptions,
      selectValue: null,
      filterOptions
    }
  }

  componentWillReceiveProps(props) {
    this.setState(props)
  }

  onChangeIconSelect = (value) => {

    const DropdownIndicator = (props) => {
      if (value) {
        return components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <MdSearch size={24} />
          </components.DropdownIndicator>
        )
      } else {
        return components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <MdExpandMore size={24}/>
          </components.DropdownIndicator>
        )
      }
    }

    return { DropdownIndicator }
  }

  setFilter = (target) => {

    let state = Object.assign(this.state)
    state.selectValue = target

    if (target === null) {
      state.gridOptions.filter.field = ""
      state.gridOptions.filter.value = ""
    } else if (state.gridOptions.filter.field !== target.value) {
      state.gridOptions.filter.field = target.value
      state.gridOptions.filter.value = ""
      state.gridOptions.filter.label = target.label
    }
  }

  onChangeSelect = (target) => {

    let state = Object.assign(this.state)
    state.selectValue = target

    if (target === null) {
      state.gridOptions.filter.field = ""
      state.gridOptions.filter.value = ""
    } else if (state.gridOptions.filter.field !== target.value) {
      state.gridOptions.filter.field = target.value
      state.gridOptions.filter.value = ""
      state.gridOptions.filter.label = target.label
    }

    this.setState(state, () => {
      this.state.gridOptions.refresh(this.state)
    })
  }

  onBlurInput = () => {
    this.state.gridOptions.refresh(this.state)
  }

  renderForm = () => {
    if(this.state.gridOptions.columns.length==1){
      this.setFilter(this.state.filterOptions[0])
    }
    if (!this.state.headerOptions.hideFilter) {
      return <form autoComplete="off">
        <StyledSelect
          className={"select"}
          components={this.onChangeIconSelect(!this.state.selectValue ? true : false)}
          onChange={this.onChangeSelect}
          classNamePrefix="select-default"
          placeholder={Translate("select_column")}
          options={this.state.filterOptions}>
        </StyledSelect>
        <Input
          id="standard-search"
          className={!this.state.selectValue ? "hidden" : ""}
          placeholder={
            !this.state.selectValue ?
              "" : `${Translate("existsincolumn")} ${Translate(this.state.selectValue.label)}`
          }
          onChange={
            e => {
              let state = Object.assign(this.state);
              state.gridOptions.filter.value = e.target.value;
              state.gridOptions.filter.field = this.state.selectValue.value;
              
              this.setState(state)
              this.state.gridOptions.refresh(this.state);
            }
          }
          type="search"
          value={this.state.gridOptions.filter.value}
        />
      </form>
    }
  }

  isEditable = _ => {
    const {
      gridOptions,
      headerOptions
    } = { ...this.state }

    if (!headerOptions.gridEditItem) {
      return;
    }

    if (gridOptions.multiSelect) {
      return gridOptions.selectedRows && gridOptions.selectedRows.length === 1;
    } else {
      return gridOptions.selectedRow && Object.keys(gridOptions.selectedRow).length > 0;
    }
  }

  isDeletable = _ => {
    const { gridOptions, headerOptions } = { ...this.state }

    if (!headerOptions.gridDeleteItem) {
      return
    }

    if (gridOptions.multiSelect) {
      return gridOptions.selectedRows && gridOptions.selectedRows.length;
    } else {
      return gridOptions.selectedRow && Object.keys(gridOptions.selectedRow).length > 0;
    }
  }

  isCopyable = _ => {
    const { gridOptions, headerOptions } = { ...this.state }

    if (!headerOptions.gridCopyItem) {
      return
    }

    if (gridOptions.multiSelect) {
      return gridOptions.selectedRows && gridOptions.selectedRows.length === 1;
    } else {
      return gridOptions.selectedRow && Object.keys(gridOptions.selectedRow).length > 0;
    }
  }

  getSelectedRows = _ => {
    const gridOptions = { ...this.state.gridOptions }
    return gridOptions.multiSelect
      ? gridOptions.selectedRows
      : gridOptions.selectedRow
  }

  render() {
    let headerOptions = { ...this.state.headerOptions }

    return (
      <GridToolBarDiv>
        <TopDiv style={headerOptions.hideTopDiv ? { display: 'none' } : {}}>
          <Typography>{Translate(headerOptions.gridTitle)}</Typography>
          <StyledButton
            variant="outlined"
            onClick={_ => { headerOptions.gridNewItem() }}
            disabled={!headerOptions.gridNewItem}
            className={headerOptions.hideBtnNew ? "hidden" : ""}>
              <Grid2 container spacing={0.5}>
                <Grid2 container><MdAddCircle size={25}/></Grid2>
                <Grid2 container>{Translate(headerOptions.gridBtn)}</Grid2>
              </Grid2>
          </StyledButton>
        </TopDiv>
        <StyledBottomDiv>
          <div>
            <Tooltip title={Translate("edit")} placement="top" className={headerOptions.hideBtnEdit ? "hidden" : ""}>
              <span>
                <IconButton
                  disabled={!this.isEditable()}
                  onClick={_ => {
                    headerOptions.gridEditItem(this.getSelectedRows())
                  }}
                >
                  <MdEdit className={"default"} />
                </IconButton>
              </span>
            </Tooltip>
            
            <Tooltip title={Translate("remove")} placement="top" className={headerOptions.hideBtnDelete ? "hidden" : ""}>
              <span>
                <IconButton
                  disabled={!this.isDeletable()}
                  onClick={_ => {
                    headerOptions.gridDeleteItem(this.getSelectedRows())
                  }}
                >
                  <MdDelete className={"default"} />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title={Translate("copy")} placement="top" className={headerOptions.hideBtnCopy ? "hidden" : ""}>
              <span>
                <IconButton
                  disabled={!this.isCopyable()}
                  onClick={_ => {
                    headerOptions.gridCopyItem(this.getSelectedRows())
                  }}
                >
                  <MdFileCopy size={24} className={"default"} />
                </IconButton>
              </span>
            </Tooltip>
          </div>
          {this.renderForm()}
        </StyledBottomDiv>
      </GridToolBarDiv>
    )
  }

}

export default GridToolBar