import React, {
    Component,
    Fragment
} from "react"
import {
    MdEdit,
    MdDelete,
    MdAddCircle,
    MdArrowDropUp,
    MdArrowDropDown
} from "react-icons/md"
import WizardStructureDetailsTabsFieldsModal from "./modals/wizard-structure-details-tabs-fields-modal"
import ObjectUtils from "../../../../../utils/object-utils"
import Translate from "../../../../../i18n/translate"
import StructureFields from "./structure-fields"
import Toast from "../../../../../components/toast/toast"
import ConectorGridTable from "../../../../../componentsUI/gridTable"
import ConectorDialogConfirmation from "../../../../../componentsUI/dialogComponentConfirmation"
import {StyledIconButton} from "../../../../../componentsUI/styledComponents/styledWizardStructureDetailsTabs"
import {Grid2} from "@mui/material"
import * as wizardStore from "../../../../../store/wizard/wizard-store-reducer";
import { setWizardState } from "../../../../../store/wizard/wizard-store-actions";
import { connect } from "react-redux";

class WizardStructureDetailsTabsFields extends Component {
    constructor(props) {
        super(props);
        props.setWizardState({disabledStepEdit: false})
        this.state = {
            ...props,
            dialog: { open: false },
            openModal: false,
            gridOptions: {
                multiSelect: false,
                noDataMessage: props.selectedNode
                    ? Translate("add_field")
                    : Translate("select_detail"),
                columns: [
                    {
                        title: "position",
                        field: "indPosition"
                    },
                    {
                        title: "from",
                        field: "from"
                    },
                    {
                        title: "format_from",
                        field: "formatFrom"
                    },
                    {
                        title: "to",
                        field: "to"
                    },
                    {
                        title: "type",
                        field: "desAttrType"
                    },
                    {
                        title: "function",
                        field: "desSubaction",
                        cellFilter: "capitalize"
                    }
                ],
                refresh: this.refresh
            }
        }
    }

    componentWillReceiveProps(props) {
        let state = { ...this.state }
        state.gridOptions.noDataMessage = Translate(props.selectedNode ? "add_field" : "select_detail");

        this.setState({ ...state, ...props })
    }

    addField = () => {
        const {
            selectedInputFields,
            gridOptions
        } = this.state;
        this.props.setWizardState({disabledStepEdit: true})
        this.setState({
            openModal: true,
            selectedField: null,
            selectedInputFields: selectedInputFields,
            gridOptions: {
                ...gridOptions,
                selectedRow: null
            }
        })

    }

    editField = () => {
        this.props.setWizardState({disabledStepEdit: true})
        this.setState({
            openModal: true
        })
    }

    deleteField = () => {
        this.setState({
            dialog: {
                type: "danger",
                open: true,
                message: "remove_select_field",
                title: "field_exclusion",
                onClose: () => {
                    let state = { ...this.state }

                    let idx = state.selectedNode.node.fields.findIndex(element =>
                        ObjectUtils.equals(element, state.gridOptions.selectedRow)
                    )

                    if (state.gridOptions.selectedRow.codField) {
                        state.wizardState.event.deletedFields.push(state.gridOptions.selectedRow.codField);
                    }

                    this.state.setChangedField("DELETED", state.gridOptions.selectedRow);

                    state.selectedNode.node.fields.splice(idx, 1);
                    state.selectedNode.node.fields.sort((o1, o2) => {
                        return o1.indPosition < o2.indPosition
                    }).forEach((node, index) => node.indPosition = index)

                    this.setState({
                        ...state,
                        dialog: {
                            open: false
                        },
                        selectedNode: state.selectedNode,
                        gridOptions: {
                            ...state.gridOptions,
                            selectedRow: null
                        }
                    })
                },
                onDismiss: () => {
                    this.setState({
                        dialog: {
                            open: false
                        }
                    })
                }
            }
        })
    }

    refresh = gridState => {
        const selectedRow = gridState.gridOptions.selectedRow;

        this.setState({
            ...this.state,
            ...gridState,
            selectedField: selectedRow
        })
    }

    checkIfAnotherFieldHasTheSameName = modalState => {
        let state = { ...this.state }
        let selectedNode = state.selectedNode
        const fieldName = modalState.selectedField != undefined ? modalState.selectedField.to : undefined
        const selectedGridField = state.gridOptions.selectedRow == undefined ? undefined : state.gridOptions.selectedRow.to

        if(selectedNode.node.fields != undefined && selectedNode.node.fields.length > 0){
            if(fieldName === selectedGridField) return false

            let field = selectedNode.node.fields.find(field => {
                if(field.to === undefined) return undefined
                else return field.to === fieldName
            })

            return field === undefined ? false : true
        } else return false
    }

    updateGridWithModalState = modalState => {
        let state = { ...this.state }

        state.openModal = !!modalState.openModal;
        state.noAttrs = !!modalState.noAttrs;

        if (modalState.selectedField) {
            modalState.selectedField = StructureFields(modalState.selectedField)

            if (typeof (modalState.selectedField.indPosition) === "number") {
                state.selectedNode.node.fields.splice(modalState.selectedField.indPosition, 1)
                state.selectedNode.node.fields.push(modalState.selectedField)
            } else {
                if (!state.selectedNode.node.fields) {
                    modalState.selectedField.indPosition = 0
                    state.selectedNode.node.fields = [modalState.selectedField]
                } else {
                    modalState.selectedField.indPosition = state.selectedNode.node.fields.length
                    state.selectedNode.node.fields.push(modalState.selectedField)
                }
            }
        }

        this.setState(state)
    }
    
    modalCallback = modalState => {
        let state = { ...this.state }
        let codConnectionType = state.wizardState.event.codConnectionType

        //codConnectionType 5 SOAP
        if(codConnectionType == 5) this.updateGridWithModalState(modalState)
        else {
            if(this.checkIfAnotherFieldHasTheSameName(modalState)) Toast.error(Translate("fieldWithTheSameName"))
            else this.updateGridWithModalState(modalState)
        }
    }

    moveUp = () => {
        let state = { ...this.state }
        let i = 0

        for (i = 0; i < state.selectedNode.node.fields.length; i++) {
            const el = state.selectedNode.node.fields[i]
            if (el.indPosition === state.gridOptions.selectedRow.indPosition) {
                break
            }
        }

        if (i === 0) {
            return
        }

        let currentField = state.selectedNode.node.fields[i];
        let prevField = state.selectedNode.node.fields[i - 1];

        currentField.indPosition = currentField.indPosition - 1;
        this.state.setChangedField("CHANGED", currentField);

        prevField.indPosition = prevField.indPosition + 1;
        this.state.setChangedField("CHANGED", prevField);

        state.selectedNode.node.fields.sort((o1, o2) => {
            return o1.indPosition - o2.indPosition
        });

        this.setState(state)
    }

    moveDown = () => {
        let state = { ...this.state }
        let i = 0

        for (i = 0; i < state.selectedNode.node.fields.length; i++) {
            const el = state.selectedNode.node.fields[i]
            if (el.indPosition === state.gridOptions.selectedRow.indPosition) {
                break
            }
        }

        if (i === state.selectedNode.node.fields.length - 1) {
            return
        }

        let currentField = state.selectedNode.node.fields[i];
        let nextField = state.selectedNode.node.fields[i + 1];

        currentField.indPosition = currentField.indPosition + 1;
        this.state.setChangedField("CHANGED", currentField);

        nextField.indPosition = nextField.indPosition - 1;
        this.state.setChangedField("CHANGED", nextField);

        state.selectedNode.node.fields.sort((o1, o2) => {
            return o1.indPosition - o2.indPosition
        });

        this.setState(state)
    }

    render() {
        let {
            attributeGroups,
            dialog,
            noAttrs,
            disabled,
            openModal,
            gridOptions,
            structureModel,
            selectedNode,
            selectedField,
            stepProperties,
            stepStructure,
            selectedInputFields,
            wizardState
        } = this.state

        let dataSource
        if (selectedNode && selectedNode.node && selectedNode.node.fields) {
            dataSource = selectedNode.node.fields;
        } else {
            dataSource = [];
        }

        dataSource.forEach(item => {
            let fieldAttributeType = item.fieldAttributes.find(fa => fa.codAttribute === 1);
            if (fieldAttributeType) {
                item.desAttrType = (fieldAttributeType.desValue || '').replace('?', '');
            }

            let fieldFormatFrom = item.fieldAttributes.find(fa => fa.codAttribute === 12 || fa.codAttribute === 23);
            if (fieldFormatFrom) {
                item.formatFrom = fieldFormatFrom.desValue;
            }
            
            [item.from, item.to] = (item.desField || "").split(" -> ");
        })

        dataSource.sort((o1, o2) => {
            return o1.indPosition - o2.indPosition;
        })

        if (noAttrs) {
            disabled = true;
        }

        return (
            <Fragment>
                <div style={{ display: openModal ? "none" : "block" }}>
                    <Grid2 container spacing={1}>
                        <StyledIconButton
                            mini
                            aria-label="Move"
                            variant="contained"
                            className={"iconNoMargin"}
                            onClick={this.moveUp.bind(this)}
                            disabled={
                                disabled
                                || (ObjectUtils.equals({}, gridOptions.selectedRow) || !gridOptions.selectedRow)
                                || dataSource.length <= 1
                                || !selectedField
                            }>
                            <MdArrowDropUp size={24}/>
                        </StyledIconButton>
                        <StyledIconButton
                            mini
                            aria-label="Move"
                            variant="contained"
                            className={"iconNoMargin"}
                            onClick={this.moveDown.bind(this)}
                            disabled={
                                disabled
                                || (ObjectUtils.equals({}, gridOptions.selectedRow) || !gridOptions.selectedRow)
                                || dataSource.length <= 1
                                || !selectedField
                            }>
                            <MdArrowDropDown size={24}/>
                        </StyledIconButton>
                        <StyledIconButton
                            size="small"
                            name={"edit-field"}
                            variant="contained"
                            className={"iconNoMargin"}
                            onClick={this.editField.bind(this)}
                            disabled={
                                disabled
                                || (ObjectUtils.equals({}, gridOptions.selectedRow) || !gridOptions.selectedRow)
                                || !selectedField
                                || dataSource.length === 0
                            }>
                            <MdEdit size={24}/>
                        </StyledIconButton>
                        <StyledIconButton
                            size="small"
                            name={"delete-field"}
                            variant="contained"
                            className={"iconNoMargin"}
                            onClick={this.deleteField.bind(this)}
                            disabled={
                                disabled
                                || (ObjectUtils.equals({}, gridOptions.selectedRow) || !gridOptions.selectedRow)
                                || !selectedField
                                || dataSource.length === 0
                            }>
                            <MdDelete size={24}/>
                        </StyledIconButton>
                        <StyledIconButton
                            size="small"
                            name={"add-field"}
                            variant="outlined"
                            disabled={disabled}
                            onClick={this.addField.bind(this)}>
                            <MdAddCircle size={24}/> {Translate("add_field")}
                        </StyledIconButton>
                    </Grid2>
                    <ConectorGridTable dataSource={dataSource} gridOptions={gridOptions} />
                </div>

                <WizardStructureDetailsTabsFieldsModal
                    selectedNode={selectedNode || {}}
                    openModal={openModal}
                    wizardState={wizardState}
                    stepProperties={stepProperties}
                    stepStructure={stepStructure}
                    attributeGroups={attributeGroups}
                    selectedInputFields={selectedInputFields}
                    structureModel={structureModel}
                    update={this.modalCallback.bind(this)}
                    selectedField={
                        (
                            selectedField &&
                            selectedNode &&
                            selectedNode.node &&
                            selectedNode.node.fields
                        )
                            ? selectedNode.node.fields[selectedField.indPosition]
                            : {}
                    }
                    setChangedField={this.props.setChangedField}/>

                <ConectorDialogConfirmation dialogOptions={dialog} open={dialog.open} />
            </Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        wizardState: wizardStore.getWizardState(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWizardState: wizardState => dispatch(setWizardState(wizardState))
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WizardStructureDetailsTabsFields);