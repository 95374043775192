import request from '../../utils/request'

export default {
    RecoverPassword: (userEmail) => {
        return request.executeRequest(
            `${request.baseUrl}globals/recover-your-password`,
            {
                ...request.postOptions(),
                body: JSON.stringify({ userEmail })
            }
        );
    },
    ValidatePasswordChangeToken: (token) => {
        return request.executeRequest(
            `${request.baseUrl}globals/validate-password-token/${token}`,
            request.getOptions().headers,
            request.getOptions().method
        )
    },
    SetUserPassword: (token, newPassword) => {
        return request.executeRequest(
            `${request.baseUrl}globals/set-user-password`,
            {
                ...request.postOptions(),
                body: JSON.stringify({ 
                    token,
                    newPassword  
                })
            }
        );
    },
    ResetExpiredPassword: (token, newPassword, currentPassword) => {
        return request.executeRequest(
            `${request.baseUrl}globals/reset-expired-password`,
            {
                ...request.postOptions(),
                body: JSON.stringify({ 
                    token: token,
                    newPassword: newPassword,
                    currentPassword: currentPassword
                })
            }
        );
    }
}