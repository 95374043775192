const Field = field => {
    const value1 = field.fieldAttributes.find(attr => attr.description === 'from') || {desValue: ""};
    const value2 = field.fieldAttributes.find(attr => attr.description === 'to' || attr.description === 'soap_xml');

    if (value1.desValue != "") {
        field.desField = value1.desValue;
    }

    if (value2) {
        field.desField = `${value1.desValue} -> ${value2.desValue}`;
    }

    field.desAttributeGroup = field.attributeGroup.DesLabel;
    field.codAttributeGroup = field.attributeGroup.CodAttributeGroup;
    field.desSubaction      = field.attributeGroup.DesSubaction;

    return field;
}

export default (field) => {
    return Field(field);
}