import request from "../../../utils/request"

export default {
    getSoftwareVersions: softwareName => {
        return request.executeRequest(
            `${request.baseUrl}path_notes/get_last_version/${softwareName}`,
            request.getOptions()
        ).then(res => res.json())
    },
    getPathNotes: softwareName => {
        return request.executeRequest(
            `${request.baseUrl}path_notes/${softwareName}`,
            request.getOptions()
        ).then(res => res.json())
    },
    addOrEditVersion: VersionBodyObject => {
        return request.executeRequest(
            `${request.baseUrl}path_notes/new`,
            {
                ...request.postOptions(),
                body: JSON.stringify(VersionBodyObject)
            }
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText)
            }
            return res.json();
        })
    },
    deletePathNotes: id => {
        return request.executeRequest(
            `${request.baseUrl}path_notes/delete/${id}`,
            {
                ...request.deleteOptions()
            }
        ).then(res => res.json())
    },
    getVersionWorkerOfInstance: () => {
        return request.executeRequest(
            `${request.baseUrl}globals/instance-worker-version`, 
            request.getOptions()
        ).then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
    
            return response.json()
        })
    }
}