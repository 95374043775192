import React, { Component, Fragment } from 'react';
import { Grid2 } from '@mui/material'
import AttributeTypeComponentList from '../../../attribute-type-component-list'
import Session from '../../../../../utils/session-info'

class WizardStructureDetailsTabsExtras extends Component {
    constructor(props) {
        super(props);

        if(props.selectedNode == undefined) props.selectedNode = new Array
        if(props.selectedNode.node == undefined) props.selectedNode.node = new Array
        if(!props.selectedNode.node.detailExtras) props.selectedNode.node.detailExtras = [];

        this.state = {
            ...props
        }
    }

    getOptions = attr => {
        if (attr.options) {
            return attr.options.map(option => {
                return {
                    label: option.label,
                    value: option.codAttribute,
                    description: option.description,
                    master: option.CodMaster
                }
            })
        }
    }

    handleChangeSelect = e => {
        let state = { ...this.state };

        this.props.update(state);
    }

    handleChange = e => {
        let state = { ...this.state };
        let name = parseInt(e.target.name, 10);
        let desValue = e.target.value;

        if (e.type === "blur") {
            desValue = desValue.trim();
        }

        let extra = state.selectedNode.node.detailExtras.find(extra => {
            if (!extra.codStructureExtraValues) {
                return extra.codAttribute === name;
            } else {
                const codStrucExtra = state.detailsExtras.find(extra =>
                    extra.codAttribute === name
                ).codStructureExtra;
                return extra.codStructureExtra === codStrucExtra
            }
        })

        if (!extra) {
            const detailExtra = state.detailsExtras.find(extra =>
                extra.codAttribute === name
            );
            const extra = {
                codAttribute: name,
                value: desValue,
                codStructureExtra: detailExtra.codStructureExtra,
                codInstance: Session().codInstance,
                codStructure: state.stepStructure.codStructure,
                codStructureExtraValues: 0,
                desValue
            };

            state.selectedNode.node.detailExtras.push(extra);
        } else {
            extra.desValue = desValue;
            extra.value = desValue;
        }

        this.props.update(state);
    }

    handleChangeCheckbox = e => {
        let state = { ...this.state };
        let name = parseInt(e.target.name, 10);

        let extra = state.selectedNode.node.detailExtras.find(extra => {
            if (!extra.codStructureExtraValues) {
                return extra.codAttribute === name;
            } else {
                const codStrucExtra = state.detailsExtras.find(extra =>
                    extra.codAttribute === name
                ).codStructureExtra;
                return extra.codStructureExtra === codStrucExtra
            }
        })

        if (!extra) {
            extra = {
                codAttribute: name,
                value: 'true',
                codStructureExtra: state.detailsExtras.find(detExtra =>
                    detExtra.codAttribute === name
                ).codStructureExtra,
                codStructureExtraValues: 0,
                codStructure: state.stepStructure.codStructure
            }
            state.selectedNode.node.detailExtras.push(extra);
        } else extra.value = extra.value === 'true' ? 'false' : 'true'

        this.props.update(state);
    }

    getSelectValue = attr => {
        let state = { ...this.state };

        const extra = state.stepStructure.structureExtras.find(extra =>
            extra.codAttribute === attr.codAttribute
        )

        if (extra) {
            return extra.value;
        }
    }

    getValue = attr => {
        const { selectedNode, detailsExtras } = this.state;

        let detailExtra = selectedNode.node.detailExtras.find(extra => {
            if (!extra.codStructureExtraValues) {
                return extra.codAttribute === attr.codAttribute;
            } else {
                const codStrucExtra = detailsExtras.find(extra =>
                    extra.codAttribute === attr.codAttribute
                ).codStructureExtra;
                return extra.codStructureExtra === codStrucExtra
            }
        });

        if (detailExtra) {
            switch (attr.AttributeType.desAttributeType) {
                case 'boolean': return detailExtra.value === 'true' ? 'true' : 'false';
                default: return detailExtra.value
            }
        } else {
            return '';
        }
    }

    getChecked = attr => {
        const { selectedNode, detailsExtras, wizardState } = this.state;

        if(wizardState.event.connectionAction.codConnectionType == 2){
            return this.getValueFromRestPostDetail()
        }

        let detailExtra = selectedNode.node.detailExtras.find(extra => {
            if (!extra.codStructureExtraValues) {
                return extra.codAttribute === attr.codAttribute;
            } else {
                const codStrucExtra = detailsExtras.find(extra =>
                    extra.codAttribute === attr.codAttribute
                ).codStructureExtra;
                return extra.codStructureExtra === codStrucExtra
            }
        })

        if (detailExtra) {
            return detailExtra.value === 'true' ? true : false;
        }
    }

    getValueFromRestPostDetail = () => {
        const { selectedNode } = this.state;

        if(selectedNode.node.detailExtras != undefined && selectedNode.node.detailExtras.length > 0){
            let detailExtra = selectedNode.node.detailExtras[0]
            return detailExtra.value === "true" ? true : false;
        }
    }

    setValueFromRestPostDetail = e => {
        let state = { ...this.state };
        let name = parseInt(e.target.name, 10);

        let extra = state.selectedNode.node.detailExtras.find(extra => {
            if (!extra.codStructureExtraValues) return extra.codAttribute === name;
            else return extra
        })

        if (!extra) {
            extra = {
                codAttribute: name,
                value: 'true',
                codStructureExtra: state.detailsExtras.find(detExtra =>
                    detExtra.codAttribute === name
                ).codStructureExtra,
                codStructureExtraValues: 0,
                codStructure: state.stepStructure.codStructure
            }
            state.selectedNode.node.detailExtras.push(extra);
        } else extra.value = extra.value === 'true' ? 'false' : 'true'

        this.props.update(state);
    }

    render() {
        const { detailsExtrasAttributes = [], wizardState } = this.state;

        return (
            <Fragment>
                <Grid2 container spacing={1}>
                    {detailsExtrasAttributes
                        .map(attr => {
                            const TypeComponent = AttributeTypeComponentList(
                                attr.AttributeType.codAttributeType
                            )
                            return (
                                <Grid2 key={attr.codAttribute}>
                                    {
                                        React.createElement(TypeComponent, {
                                            required: attr.isRequired === 'true',
                                            options: this.getOptions(attr),
                                            label: attr.label,
                                            checked: this.getChecked(attr),
                                            value: (() => {
                                                if (attr.options) {
                                                    return this.getSelectValue(attr)
                                                } else {
                                                    return this.getValue(attr);
                                                }
                                            })(),
                                            name: String(attr.codAttribute),
                                            showTime: true,
                                            onChange: (() => {
                                                switch (attr.AttributeType.codAttributeType) {
                                                    case 5: 
                                                        if(wizardState.event.connectionAction.codConnectionType == 2){
                                                            return this.setValueFromRestPostDetail.bind(this);
                                                        } else {
                                                            return this.handleChangeCheckbox.bind(this);
                                                        }
                                                    case 7: return this.handleChangeSelect.bind(this);
                                                    default: return this.handleChange.bind(this)
                                                }
                                            })(),
                                            onBlur: (() => {
                                                if (attr.AttributeType.codAttributeType) {
                                                    return this.handleChange.bind(this);
                                                }
                                            })()
                                        })
                                    }
                                </Grid2>
                            )
                        })
                    }
                </Grid2>
            </Fragment>
        )
    }
}

export default WizardStructureDetailsTabsExtras