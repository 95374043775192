import styled from "styled-components";
import { List } from "@mui/material";

export const CustomGridContainer = styled.div`
    @media screen and (max-width:1280px) {
        padding-bottom: 20px;
    }

    @media screen and (min-width:1280px) {
        padding-right: 20px;
    }
`

export const CustomPanelContainer = styled.div`
    @media screen and (max-width:1280px) {
        .scroll-permission-lists { height: calc(100vh - 570px); }
        .changed-scroll-permission-lists { height: calc(100vh - 623px); }
    } 
    @media screen and (min-width:1280px) {
        .scroll-permission-lists { height: calc(100vh - 335px); }
        .changed-scroll-permission-lists { height: calc(100vh - 390px); }
    } 
`

export const Title = styled.h1`
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;  
    font-size: 1.5rem;
    color: #6F8296;
    text-align: left;
`

export const AdminPermission = styled.div`
    pointer-events: none;
    cursor: default;
`

export const PermissionLists = styled(List)`
    && {
        padding-top: 0px;
        padding-bottom: 0px;

        > li {
            padding-top: 5px;
            padding-bottom: 0px;
            padding-left: 10px;
            &:nth-child(2n+1) { background-color: #f4f4f4; }
        }

        li {
            position: relative;
            color: #6F8296;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            
            span.MuiButtonBase-root {
                padding: 5px;
                svg { font-size: 15px; }
            }

            ul { 
                padding: 10px 0 10px 40px; 
                list-style: none;
                > li {
                    ::before {
                        content: "";
                        position: absolute;
                        left: -27px;
                        width: 20px;
                        height: 15px;
                    }
                }
            }
        }
    }
`

export const GridToolBarDiv = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 50px;
`

export const TopDiv = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {  
        font-size: 1.5rem;
        color: #6F8296;
        text-align: left;
    }
`