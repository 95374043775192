import request from "../../utils/request";

export const globalService = {
    getAll
};

function getAll() {
    return request.executeRequest(`${request.baseUrl}globals`, request.getOptions()).then(response => {
        if (!response.ok) {
            if (response.status === 403) {
                localStorage.clear();
                localStorage.setItem("expiredAuth", true);

                window.location.reload();
            } else {
                return Promise.reject(response.statusText);
            }
        }

        return response.json();
    });
}