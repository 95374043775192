import request from '../../utils/request';

export default {
    GetTemplateStatusByDate: (dateOptions) => {
        return request.executeRequest(
            `${request.dashUrl}templatestatus/${dateOptions}`,
            request.getOptions()
        )
    },
    GetInstanceBlockById: (codInstance) => {
        return request.executeRequest(
            `${request.baseUrl}globals/getblockinstance/${codInstance}`,
            request.getOptions()
        ).then(res => res.json());
    }
}