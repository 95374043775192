import request from "../../utils/request"
import Session from "../../utils/session-info"

export default {
    DeleteScheduleArray: (schedules) => {
        return request.executeRequest(
            `${request.baseUrl}schedules/deleteSchedule`,
            {
                ...request.deleteOptions(),
                body: JSON.stringify(schedules)
            }
        );
    },
    GetSchedules: (codInstance = Session().codInstance) => {
        return request.executeRequest(
            `${request.baseUrl}schedules/instances/${codInstance}/schedules`,
            request.getOptions()
        )
            .then(res => res.json());
    },
    GetTypes: () => {
        return request.executeRequest(
            `${request.baseUrl}schedules/scheduleTypes`,
            request.getOptions()
        )
            .then(res => res.json());
    },
    GetScheduleById: (id) => {
        return request.executeRequest(
            `${request.baseUrl}schedules/get/${id}`,
            request.getOptions()
        )
            .then(res => res.json())
    },
    GetUserPermissionSchedule: () => {
        return request.executeRequest(
            `${request.baseUrl}schedules/userPermission`,
            request.getOptions()
        ).then((res) => {
            if (!res.ok) {
                return Promise.reject({ status: res.status, message: "notHavePermissionToAccess" });
            }
            return res.json();
        })
        .catch((error) => {
            return Promise.reject(error);
        });
    },

    PostSchedule: (schedule) => {
        return request.executeRequest(
            `${request.baseUrl}schedules/insert`,
            {
                ...request.postOptions(),
                body: JSON.stringify(schedule)
            }
        );
    },
    PutSchedule: (schedule) => {
        return request.executeRequest(
            `${request.baseUrl}schedules/edit`,
            {
                ...request.putOptions(),
                body: JSON.stringify(schedule)
            }
        );
    }
}