import styled from 'styled-components'
import Snackbar from '@mui/material/Snackbar'

const StyledSnackbar = styled(Snackbar)`
    && {
        font-weight: 700;
        color: #FFFFFF;
        svg { font-size: 20px; margin-right: 8px; }
        .info { background-color: #FFC34B; }
        .error { background-color: #CD5C5C; }
        .success { background-color: #52B4B7; }
        .verticalAlign { align-items: center; display: flex; }
    }
`

export default StyledSnackbar