import React, { Component } from "react"
import { StyledDialog } from "../../../../componentsUI/styledComponents/styledTransform"
import StyledButton from "../../../../componentsUI/styledComponents/styledButton"
import ConectorInputText from "../../../../componentsUI/inputText"
import ConectorSelect from "../../../../componentsUI/conectorSelect"
import Session from "../../../../utils/session-info"
import Translate from "../../../../i18n/translate"
import TransformUtil from "../transform-util"
import {
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";

class TransformField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            transformOptions: [{
                value: "case",
                label: Translate("case"),
                propertyKey: "cases"
            }, {
                value: "concat",
                label: Translate("concat"),
                propertyKey: "concats"
            }, {
                value: "special",
                label: Translate("field"),
                propertyKey: "specials"
            },{
                value: "fixed_value",
                label: Translate("fixed_value"),
                propertyKey: "fixedValues"
            }],
            structureOptions: [{
                value: false,
                label: Translate("structureOut")
            }, {
                value: true,
                label: Translate("structureIn")
            }]
        }
    }

    componentWillReceiveProps = (props) => {
        this.setState({
            ...props,
            parentFields: TransformUtil.getItemsByParentPath(props.detailsOut, props.parentPath),
            transformType: this.state.transformOptions[0],
            structureOption: this.state.structureOptions[0],
            fieldName: "",
            disableSave: true
        })
    }

    handleChangeTransformType = (transformType) => {
        this.setState({
            transformType: transformType
        });
    }

    handleChangeStructureOption = (structureOption) => {
        this.setState({
            structureOption : structureOption
        });
    }

    handleChangeFieldName = (event) => {
        let fieldName = event.target.value;
        fieldName = fieldName.replace(/\./g, "");

        let disableSave = (fieldName.trim() == "");
        if (!disableSave) {
            disableSave = this.hasFieldWithSameName(fieldName);
        }

        this.setState({
            fieldName: fieldName,
            disableSave: disableSave
        });
    }

    handleCloseModal = _ => {
        this.state.onAddField(null);
    }

    handleAddField = _ => {
        const {
            attributes,
            fieldName,
            transformType,
            structureOption
        } = this.state;
        const { codInstance } = Session();

        const newField = {
            title: fieldName,
            fieldAttributes: [{
                codAttribute: attributes.field_type,
                codInstance: codInstance,
                desValue: "string",
                indPosition: 0
            }, {
                codAttribute: attributes.to,
                codInstance: codInstance,
                desValue: fieldName,
                indPosition: 1
            }, {
                codAttribute: attributes.structure_in,
                codInstance: codInstance,
                desValue: structureOption.value.toString(),
                indPosition: 2
            }],
            fieldTransform: {
                ...transformType,
                [transformType.propertyKey]: [this.getInitialTransform()]
            },
            structureOption: structureOption
        };

        this.state.onAddField(newField);
    }

    getInitialTransform() {
        let {
            attributes,
            transformType,
            structureOption
        } = this.state;

        if (transformType.value == "case") {
            return {
                desField: "case",
                fieldTransformAttributes: [{
                    codAttribute: attributes.field_type,
                    desValue: "field",
                    desLabel: "field_type",
                    indPosition: 1
                }, {
                    codAttribute: attributes.from,
                    desValue: null,
                    desLabel: "from",
                    indPosition: 0
                },{
                    codAttribute: attributes.structure_in,
                    desValue: structureOption.value.toString(),
                    desLabel: "structure_in",
                    indPosition: 2
                }, {
                    codAttribute: attributes.field_name,
                    desValue: "",
                    desLabel: "field_name",
                    indPosition: 3
                }],
                children: [],
                expanded: true
            }
        } else if (transformType.value == "concat") {
            return {
                name: "",
                desField: "concat",
                fieldType: "text",
                textInput: "",
                isSubstring: false,
                initPosition: 0,
                endPosition: 0,
                indPosition: 0,
                isConcatAll: false,
                fieldTransformAttributes: [{
                    codAttribute: attributes.field_type,
                    desLabel: "field_type",
                    desValue: "text",
                    indPosition: 0
                }, {
                    codAttribute: attributes.from,
                    desLabel: "from",
                    desValue: "",
                    indPosition: 1
                }, {
                    codAttribute: attributes.structure_in,
                    desValue: structureOption.value.toString(),
                    desLabel: "structure_in",
                    indPosition: 2
                }, {
                    codAttribute: attributes.is_substring,
                    desLabel: "is_substring",
                    desValue: "false",
                    indPosition: 3
                }, {
                    codAttribute: attributes.ini_substring,
                    desLabel: "ini_substring",
                    desValue: "0",
                    indPosition: 4
                }, {
                    codAttribute: attributes.end_substring,
                    desLabel: "end_substring",
                    desValue: "0",
                    indPosition: 5
                }, {
                    codAttribute: attributes.is_concatall,
                    desLabel: "is_concatall",
                    desValue: "false",
                    indPosition: 6
                }, {
                    codAttribute: attributes.field_name,
                    desLabel: "field_name",
                    desValue: "",
                    indPosition: 7
                }]
            }
        } else if (transformType.value == "special") {
            return {
                name: "",
                desField: "special",
                fieldType: "text",
                textInput: "",
                isSubstring: false,
                initPosition: 0,
                endPosition: 0,
                indPosition: 0,
                isTrim:false,
                trimType:"",
                fieldTransformAttributes: [{
                    codAttribute: attributes.field_type,
                    desLabel: "field_type",
                    desValue: "text",
                    indPosition: 0
                }, {
                    codAttribute: attributes.from,
                    desLabel: "from",
                    desValue: "",
                    indPosition: 1
                }, {
                    codAttribute: attributes.is_substring,
                    desLabel: "is_substring",
                    desValue: "false",
                    indPosition: 2
                }, {
                    codAttribute: attributes.ini_substring,
                    desLabel: "ini_substring",
                    desValue: "0",
                    indPosition: 3
                }, {
                    codAttribute: attributes.end_substring,
                    desLabel: "end_substring",
                    desValue: "0",
                    indPosition: 4
                }, {
                    codAttribute: attributes.structure_in,
                    desValue: structureOption.value.toString(),
                    desLabel: "structure_in",
                    indPosition: 5
                },
                {
                    codAttribute: attributes.is_trim,
                    desValue: "false",
                    desLabel: "is_trim",
                    indPosition: 6
                }, {
                    codAttribute: attributes.trim_type,
                    desValue: "",
                    desLabel: "trim_type",
                    indPosition: 7
                },{
                    codAttribute: attributes.is_delimiter,
                    desValue: "",
                    desLabel: "is_delimiter",
                    indPosition: 8
                },{
                    codAttribute: attributes.delimiter,
                    desValue: "",
                    desLabel: "delimiter",
                    indPosition: 9
                },{
                    codAttribute: attributes.pos_delimiter,
                    desValue: "",
                    desLabel: "pos_delimiter",
                    indPosition: 10
                }]
            }
        } else {
            return {
                desField: "fixed_value",
                fieldTransformAttributes: [{
                    codAttribute: attributes.from,
                    desValue: "",
                    desLabel: "fixed_value",
                    indPosition: 0
                }, {
                    codAttribute: attributes.is_system,
                    desValue: "false",
                    desLabel: "is_system",
                    indPosition: 1
                }, {
                    codAttribute: attributes.format_from,
                    desValue: "",
                    desLabel: "format_from",
                    indPosition: 2
                },]
            }
        }
    }

    hasFieldWithSameName = (fieldName) => {
        const {
            parentFields,
            parentPath
        } = this.state;

        const fieldWithSameName = parentFields.find(field => field.title == fieldName && field.parentPath == parentPath);

        return !!fieldWithSameName;
    }

    render() {
        const {
            opened,
            transformType,
            transformOptions,
            fieldName,
            disableSave,
            structureOptions,
            structureOption
        } = this.state;

        return (
            <StyledDialog onBackdropClick={this.handleCloseModal} onEscapeKeyDown={this.handleCloseModal} open={opened}>
                <DialogTitle>{Translate("new_field")}</DialogTitle>
                <DialogContent>
                    <ConectorSelect label={"Estrutura"} placeholder="choose" options={structureOptions}
                        onChange={this.handleChangeStructureOption} value={structureOption} />
                    <ConectorSelect label={"transform"} placeholder="choose" options={transformOptions}
                        onChange={this.handleChangeTransformType} value={transformType} />

                    <ConectorInputText label={"name"} name="fieldName" value={fieldName} onChange={this.handleChangeFieldName} />
                </DialogContent>
                <DialogActions>
                    <StyledButton variant="contained" color="primary" onClick={this.handleCloseModal}>{Translate("cancel")}</StyledButton>
                    <StyledButton variant="outlined" disabled={disableSave} onClick={this.handleAddField}>{Translate("save")}</StyledButton>
                </DialogActions>
            </StyledDialog>
        )
    }

}

export default TransformField;