import userService from "../../src/store/login/service"
import Toast from "../components/toast/toast"
import Translate from "../i18n/translate"

const timeToLogOut = 300000 // 5 minutes
let timerAutomaticallyLogOut = null
let timerAlertMessageLogOut = null

document.addEventListener("visibilitychange", () => {
  if (document.visibilityState === 'visible')
    automaticallyLogOut()
  else
    turnOffAutoLogout()
})
window.addEventListener("mousemove", () => automaticallyLogOut())
window.addEventListener("keydown", () => automaticallyLogOut())

let turnOffAutoLogout = () => {
  clearTimeout(timerAutomaticallyLogOut)
  clearTimeout(timerAlertMessageLogOut)
}

let automaticallyLogOut = () => {
  turnOffAutoLogout()

  timerAlertMessageLogOut = setTimeout(() => {
    if(!window.location.href.endsWith('#/login')) Toast.info(Translate("inactivity_session"))
  }, timeToLogOut - 15000) // 15 seconds left

  timerAutomaticallyLogOut = setTimeout(() => {
    if(!window.location.href.endsWith('#/login')) userService.logout()
  }, timeToLogOut) 
}

export default automaticallyLogOut