import request from "../../utils/request"

export default {
    GetPrivilegesLevels: () => {
        return request.executeRequest(
            `${request.baseUrl}user_privileges/privilegesLevels`,
            request.getOptions()
        ).then(res => res.json())
    },
    GetUsersPrivileges: () => {
        return request.executeRequest(
            `${request.baseUrl}user_privileges/getUsersPrivileges`,
            request.getOptions()
        ).then(res => res.json())
    },
    GetUser: userEmail => {
        return request.executeRequest(
            `${request.baseUrl}user_privileges/getUser/${userEmail}`,
            request.getOptions()
        )
    },
    DeleteUserPrivileges: userPrivileges => {
        return request.executeRequest(
            `${request.baseUrl}user_privileges/deleteUserPrivileges`,
            {
                ...request.deleteOptions(),
                body: JSON.stringify({codUser: userPrivileges.codUser})
            }
        )
    },
    SaveUserPrivileges: userPrivilegesLevels => {
        return request.executeRequest(
            `${request.baseUrl}user_privileges/saveUserPrivileges`,
            {
                ...request.postOptions(),
                body: JSON.stringify(userPrivilegesLevels)
            }
        )
    }
}