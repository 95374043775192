import store from '../store'
import * as types from './action-types';
import * as appStateReducer from './reducer'

export const setBreadcrumb = (breadcrumb) => {
    return (dispatch) => {
        dispatch({ 
            type: types.SET_BREADCRUMB, breadcrumb 
        });
    };
}

export const setToast = (toast) => {
    return (dispatch) => {
        dispatch({ 
            type: types.SET_TOAST, toast 
        });
    };
}

export const setUserLanguage = (translation) => {
    return (dispatch) => {
        dispatch({ 
            type: types.SET_USER_LANGUAGE, 
            translation
        });
    };
}

export const clearSteps = () => {
    return (dispatch) => {
        dispatch({ 
            type: types.CLEAR_STEPS,
            steps: []
        });
    }
}

export const setSteps = (steps) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_STEPS,
            steps: [...steps]
        });
    }    
}

export const setStepStatus = (step, complete) => {
    var steps = appStateReducer.getSteps(store.getState());
    steps[step - 1].complete = complete;
    return (dispatch) => {
        dispatch({
            type: types.SET_STEPS,
            steps: [...steps]
        });
    }    
}

export const setUserPermissions = (userPermissions) => {
    return (dispatch) => {
        dispatch({
            userPermissions,
            type: types.SET_USER_PERMISSIONS
        });
    }
}