import styled from 'styled-components'

export const GridToolBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
`

export const TopDiv = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button { margin-right: 0 !important }
  
  p {  
    font-size: 1.5rem;
    color: #6F8296;
    text-align: left;
  }
`