import store from '../../store/store'
import { setToast } from '../../store/app-state/actions'

export default {
    success: (message, permanent = false) => {
        store.dispatch(
            setToast({
                type: "success",
                message,
                permanent: permanent
            })
        )
    },

    info: (message, permanent = false) => {
        store.dispatch(
            setToast({
                type: "info",
                message,
                permanent: permanent
            })
        )
    },

    error: (message, permanent = false) => {
        store.dispatch(
            setToast({
                type: "error",
                message,
                permanent: permanent
            })
        )
    },

    clearStore: _ => {
        store.dispatch(
            setToast({})
        )
    }
}