import React, { Component, Fragment } from 'react'
import { CardContent, Grid2 } from '@mui/material'
import MonitoramentoService from './monitoring-service'
import Translate from '../../i18n/translate'
import { connect } from 'react-redux'
import { setBreadcrumb } from '../../store/app-state/actions'
import Toast from '../../components/toast/toast'
import Session from "../../utils/session-info"
import { CardTitle, CardDescription, DashCard, StyledGrid } from "../../componentsUI/styledComponents/styledMonitoring"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import ConectorSelect from "../../componentsUI/conectorSelect"
import ConectorTable from "../../componentsUI/conectorTable"

class Monitoramento extends Component {
    constructor(props) {    
        super(props)
        props.setBreadcrumb([
            { label: 'Monitoramento' },
            { label: 'Dashboard' }
        ])
        this.state = {
            gridOptions: {
                multiSelect: false,
                hideBtnNew: true,
                hideBtnEdit: true,
                hideBtnDelete: true,
                hideBtnCopy: true,
                hideFilter: true,
                columns: [
                {
                    title: Translate("company"),
                    field: "DesCompany"
                }, 
                {
                    title: Translate("instance"),
                    field: "DesInstance"
                }, 
                {
                    title: Translate("template"),
                    field: "DesInstanceInterface"
                },
                {
                    title: Translate("last_execution_date"),
                    field: "DatLastExecution",
                    cellFilter: "ddMMyyyy-hh:mm"
                }]
            },
            interfacesStatus: {
                success: new Array,
                warning: new Array,
                error: new Array
            },
            timerOptions: [
            {
                id: 1,
                label: "3 Minutos"
            },
            {
                id: 2,
                label: "5 Minutos"
            },
            {
                id: 3,
                label: "10 Minutos"
            },
            {
                id: 4,
                label: "15 Minutos"
            },
            {
                id: 5,
                label: "30 Minutos"
            }],
            dateOptions: [
            {
                id: 0,
                label: "Sem Filtro"
            }, 
            {
                id: 1,
                label: "1 dia"
            }, 
            {
                id: 2,
                label: "7 dias"
            }, 
            {
                id: 3,
                label: "30 dias"
            }],
            dateOptionSelected: 0,
            timerOptionSelected: 1,
            instanceCompanyOptionSelected: 0,
            instancesByCompaniesOptions : [],
            instanceCompanyDataSource: null,
            intervalTimerInstance: null
        }
    }

    componentWillMount(){
        MonitoramentoService.GetInstanceBlockById(Session().codInstance)
        .then(res => {
            if(res.id !== 0){
                this.updateSession(true)
                Toast.error(Translate("instanceBlockedByAdministrative"), true)
            }
            else{
                this.updateSession(false)
            }
        })

        let { instanceCompanyOptionSelected, 
              dateOptionSelected } = this.state
        
        MonitoramentoService.GetTemplateStatusByDate(dateOptionSelected)
        .then(res => res.json())
        .then(returnedData => {
            this.separateStatusInterfaces(instanceCompanyOptionSelected, returnedData)
        }).catch(() => {})

        this.timerToUpdate()
    }

    updateSession(isBlock) {
        const session = Session();
        session.instanceBlock = isBlock;
        localStorage.setItem("user", JSON.stringify(session));
    }

    separateStatusInterfaces = (codInstance, dataSource) => {
        let { interfacesStatus } = this.state

        interfacesStatus.success = []
        interfacesStatus.warning = []
        interfacesStatus.error = []

        dataSource.forEach((instanceObject) => {
            instanceObject.InstanceInterface.forEach((interfaceObject) => {
                if(codInstance == 0 || codInstance == interfaceObject.CodInstance)
                    switch(interfaceObject.CodInterfaceStatus) {
                        case 1:
                            interfaceObject.DesCompany = instanceObject.DesCompany
                            interfaceObject.DesInstance = instanceObject.DesInstance
                            interfacesStatus.success.push(interfaceObject)
                        break;

                        case 2:
                            interfaceObject.DesCompany = instanceObject.DesCompany
                            interfaceObject.DesInstance = instanceObject.DesInstance
                            interfacesStatus.warning.push(interfaceObject)
                        break;

                        case 3:
                            interfaceObject.DesCompany = instanceObject.DesCompany
                            interfaceObject.DesInstance = instanceObject.DesInstance
                            interfacesStatus.error.push(interfaceObject)
                        break;
                    }
            })
        })

        this.createInstancesByCompaniesOptions(dataSource)

        this.setState({ interfacesStatus, 
                        instanceCompanyDataSource: dataSource })
    }

    createInstancesByCompaniesOptions = (dataSource) => {
        let Options = [{id: 0, label: "Sem Filtro"}]

        dataSource.forEach((instanceObject) => {
            Options.push({
                id: instanceObject.CodInstance, 
                label: `${instanceObject.DesCompany} - ${instanceObject.DesInstance}` 
            })
        })

        this.setState({ instancesByCompaniesOptions: Options })
    }

    changeOptionFilterDate = (optionSelect) => {
        let { instanceCompanyOptionSelected, timerOptionSelected } = this.state

        MonitoramentoService.GetTemplateStatusByDate(optionSelect.id)
        .then(res => res.json())
        .then(returnedData => {
            this.separateStatusInterfaces(instanceCompanyOptionSelected, returnedData)
        }).catch(() => Toast.error(Translate("errorMonitoringData")))

        this.timerToUpdate(timerOptionSelected, instanceCompanyOptionSelected, optionSelect.id)
    }

    changeOptionInstanceCompany = (optionSelect) => {
        let { instanceCompanyDataSource, timerOptionSelected } = this.state

        this.separateStatusInterfaces(optionSelect.id, instanceCompanyDataSource)
        this.timerToUpdate(timerOptionSelected, optionSelect.id)
    }

    timerToUpdate(optionSelect = this.state.timerOptionSelected, 
                  codInstance = this.state.instanceCompanyOptionSelected, 
                  dateOption = this.state.dateOptionSelected){

        let millisecondsTimer
        let { intervalTimerInstance } = this.state

        clearInterval(intervalTimerInstance)

        switch(optionSelect) {
            case 1:
                millisecondsTimer = 180000 // 3 minutes
                break;
            case 2:
                millisecondsTimer = 300000 // 5 minutes
                break;
            case 3:
                millisecondsTimer = 600000 // 10 minutes
                break;
            case 4:
                millisecondsTimer = 900000 // 15 minutes
                break;
            case 5:
                millisecondsTimer = 1800000 // 30 minutes
                break;
        }

        let intervalTimer = setInterval(() => {
            MonitoramentoService.GetTemplateStatusByDate(dateOption)
            .then(res => res.json())
            .then(returnedData => {
                this.separateStatusInterfaces(codInstance, returnedData)
            }).catch(() => Toast.error(Translate("errorMonitoringData")))
        }, millisecondsTimer)

        this.setState({ intervalTimerInstance: intervalTimer, 
                        timerOptionSelected: optionSelect,
                        instanceCompanyOptionSelected: codInstance,
                        dateOptionSelected: dateOption })
    }

    render() {
        const { interfacesStatus, 
                timerOptions, 
                dateOptions,
                instancesByCompaniesOptions,
                dateOptionSelected,
                timerOptionSelected,
                instanceCompanyOptionSelected,
                gridOptions
            } = this.state;
        


        return (
            <Fragment>
                <StyledPaper>
                    <Grid2 spacing={1} container alignItems="center">
                        <Grid2 size={4}>
                            <ConectorSelect
                                label="instance_by_company"
                                name="codInstanceInterface"
                                placeholder="choose"
                                options={instancesByCompaniesOptions}
                                value={instancesByCompaniesOptions.find(e => e.id === instanceCompanyOptionSelected) || null}
                                onChange={this.changeOptionInstanceCompany}
                                
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <ConectorSelect
                                label="timer"
                                name="timers"
                                options={timerOptions}
                                value={timerOptions.find(e => e.id === timerOptionSelected) || null}
                                onChange={(e) => this.timerToUpdate(e.id)}
                            /> 
                        </Grid2>
                        <Grid2 size={4}>
                            <ConectorSelect
                                label="filter_date"
                                name="timers"
                                options={dateOptions}
                                value={dateOptions.find(e => e.id === dateOptionSelected) || null}
                                onChange={this.changeOptionFilterDate}
                            /> 
                        </Grid2>
                    </Grid2>

                    <Grid2 spacing={1} container>
                        <Grid2 size={15}>
                            <Grid2>
                                <div className="card-row">
                                    <div className="card-item">
                                        <DashCard style={{color: `#96BBBB`}}>
                                            <CardContent>
                                                <CardTitle>{interfacesStatus.success.length}</CardTitle>
                                                <CardDescription>{Translate("successful_runs")}</CardDescription>
                                            </CardContent>
                                        </DashCard>
                                    </div>
                                    <div className="card-item">
                                        <DashCard style={{color: `#FCCA46`}}>
                                            <CardContent>
                                                <CardTitle>{interfacesStatus.warning.length}</CardTitle>
                                                <CardDescription>{Translate("running_reservations")}</CardDescription>
                                            </CardContent>
                                        </DashCard>
                                    </div>
                                    <div className="card-item">
                                        <DashCard style={{color: `#C95D63`}}>
                                            <CardContent>
                                                <CardTitle>{interfacesStatus.error.length}</CardTitle>
                                                <CardDescription>{Translate("running_error")}</CardDescription> 
                                            </CardContent>
                                        </DashCard>
                                    </div> 
                                </div> 
                            </Grid2>
                        </Grid2>
                    </Grid2>

                    <Grid2 size={8}>
                        <StyledGrid size={8} sx={{marginTop: "15px"}}>
                            <ConectorTable
                                gridTitle={Translate("listWarning")}
                                gridOptions={gridOptions}
                                dataSource={interfacesStatus.warning}>
                            </ConectorTable>
                        </StyledGrid>
                        <StyledGrid size={8} sx={{marginTop: "15px"}}>
                            <ConectorTable
                                gridTitle={Translate("listError")}
                                gridOptions={gridOptions}
                                dataSource={interfacesStatus.error}>
                            </ConectorTable>
                        </StyledGrid>
                    </Grid2>
                </StyledPaper>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
    }
}

export default connect(null, mapDispatchToProps)(Monitoramento);