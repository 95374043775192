import React, {
    Component,
    Fragment
} from "react"
import { Button } from "@mui/material"
import { connect } from "react-redux"
import { setBreadcrumb } from "../../store/app-state/actions"
import * as appStateReducer from "../../store/app-state/reducer"
import AttemptsService from "./attempts-old-service"
import Translate from "../../i18n/translate"
import { CSVLink } from 'react-csv'
import Loading from "../../componentsUI/loading"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import ConectorTable from "../../componentsUI/conectorTable"
import StyledButton from "../../componentsUI/styledComponents/styledButton"

class AttemptsOLD extends Component {
	constructor(props) {
		super(props);

		props.setBreadcrumb([{
            label: "administration"
        }, {
            label: "attempts"
        }]);

		this.state = {
			loading: true,
			gridOptions: {
				multiSelect: false,
				hideBtnNew: true,
				hideBtnEdit: true,
				hideBtnDelete: true,
				hideBtnCopy: true,
				columns: [{
                    title: "name",
                    field: "user",
                }, {
                    title: "status",
                    field: "status",
					hideFilter: true
                }, {
                    title: "dataAttempt",
                    field: "DatAttempt",
                    cellFilter: "ddMMyyyy-hh:mm"
                }]
			}
		}
	}

    componentWillMount() {
		AttemptsService
		.GetAllByAttempts()
		.then((dataSource) => {
			if(dataSource != null){
				dataSource.map(item => {
					delete item.codUser
					if(item.status == false){
						item.status = Translate("FALSE")
					} else if(item.status == true){
						item.status = Translate("TRUE")
					}
				})
				this.setState({
					dataSource: dataSource,
					loading: false
				});
			}
        });
	}

	render() {
		const {
			loading,
			gridOptions,
            dataSource,
		} = this.state;

		if (loading) { return <Loading /> }

		return (
			<Fragment>
				<InternalPageTitle title={Translate("attempts")} />
                <StyledPaper>
					<CSVLink data={dataSource} filename={"attempts.csv"} style={{ textDecoration: 'none' }}>
						<StyledButton variant="outlined">{Translate("downloadReport")}</StyledButton>
					</CSVLink>
					<ConectorTable gridOptions={gridOptions} dataSource={dataSource} />
                </StyledPaper>
			</Fragment>
		)
    }
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AttemptsOLD);