import React, { Component } from "react"
import { Checkbox, FormControlLabel } from "@mui/material"
import Translate from "../../../../i18n/translate"
import Session from "../../../../utils/session-info"
import TransformUtil from "../transform-util"
import Typography from '@mui/material/Typography'
import ConectorSelect from "../../../../componentsUI/conectorSelect"
import ConectorInputText from "../../../../componentsUI/inputText"
import StyledPaper from "../../../../componentsUI/styledComponents/styledPaper"

class TransformProperty extends Component {
    constructor(props) {
        super(props);

        const intOption = { value: "int", label: Translate("int") };
        const dateOption = { value: "date", label: Translate("date") };
        const timeOption = { value: "time", label: Translate("time") };
        const dateTimeOption = { value: "datetime", label: Translate("datetime") };
        const decimalOption = { value: "decimal", label: Translate("decimal") };
        const stringOption = { value: "string", label: Translate("string") };
        const booleanOption = { value: "boolean", label: Translate("boolean") };
        const base64Option = { value: "base64", label: Translate("base64") };

        this.state = {
            ...props,
            typesWithFormat: ["decimal", "datetime", "date"],
            converters: {
                string: [intOption, dateOption, decimalOption],
                int: [stringOption, dateOption],
                date: [stringOption, intOption],
                datetime: [stringOption, intOption],
                decimal: [stringOption],
                boolean: [stringOption]
            },
            transformTypes: [stringOption, intOption, decimalOption, dateTimeOption,
                booleanOption, base64Option, dateOption, timeOption]
        };
    }

    componentWillReceiveProps = (props) => {
        this.setState({
            ...props
        });
    }

    handleChangeFieldName = (event) => {
        const {
            field,
            attributes
        } = this.state;
        let fieldName = event.target.value;

        if (event.type === "blur") {
            fieldName = fieldName.trim();
    
            TransformUtil.getAttributeByCode(field.fieldAttributes, attributes.to).desValue = fieldName;
            field.title = fieldName;
    
            this.setState({
                field
            }, this.state.updateEvent);
        } else {
            fieldName = fieldName.replace(/\./g, "");
    
            TransformUtil.getAttributeByCode(field.fieldAttributes, attributes.to).desValue = fieldName;
            field.title = fieldName;
    
            this.setState({ field: field });
            this.state.setChangedField("CHANGED", field);
        }
    }

    handleChangeFieldType = (event) => {
        const {
            field,
            attributes
        } = this.state;
        const fieldType = event.value;

        TransformUtil.getAttributeByCode(field.fieldAttributes, attributes.field_type).desValue = fieldType;

        this.state.updateEvent();
        this.state.setChangedField("CHANGED", field);
    }

    handleChangeFieldFormat = (event) => {
        const {
            field,
            attributes
        } = this.state;
        const { codInstance } = Session();
        const fieldFormat = event.target.value;

        let formatAttribute = TransformUtil.getAttributeByCode(field.fieldAttributes, attributes.format_to);

        if (!formatAttribute) {
            formatAttribute = {
                codAttribute: attributes.format_to,
                codInstance: codInstance,
                desLabel: "format_to"
            };

            field.fieldAttributes.push(formatAttribute);
        }

        formatAttribute.desValue = fieldFormat;

        this.state.updateEvent();
        this.state.setChangedField("CHANGED", field);
    }

    handleChangeStructureArray = (event) => {
        const {
            field
        } = this.state;

        let extraArray = TransformUtil.getExtraByCode(field.structureExtras, 3);
        if (!extraArray) {
            extraArray = {
                codInstance: Session().codInstance,
                codStructureExtra: 3
            };
            field['structureExtras'].push(extraArray);
        }

        extraArray.value = event.target.checked.toString();

        this.state.updateEvent(this);
    }

    render() {
        const {
            field
        } = this.state;

        return (
            <StyledPaper>
                <Typography variant="h6">{Translate("field_attributes")}</Typography>
                <Typography variant="subtitle1" gutterBottom>{Translate("change_field_props")}</Typography>

                {field.children ? this.renderDetailProperty() : this.renderFieldProperty()}
            </StyledPaper >
        )
    }

    renderDetailProperty = () => {
        const {
            field
        } = this.state;

        const isArray = (TransformUtil.getExtraValueByCode(field.structureExtras, 3) == "true" || false);

        return (
            <FormControlLabel label="Array"
                control={
                    <Checkbox checked={isArray} name="isArray" onChange={this.handleChangeStructureArray.bind(this)} />
                } />
        )
    }

    renderFieldProperty = () => {
        const {
            converters,
            typesWithFormat,
            field,
            typeOrigin,
            attributes
        } = this.state;

        const fieldName = TransformUtil.getAttributeValueByCode(field.fieldAttributes, attributes.to);
        const fieldType = TransformUtil.getAttributeValueByCode(field.fieldAttributes, attributes.field_type);
        const fieldFormat = TransformUtil.getAttributeValueByCode(field.fieldAttributes, attributes.format_to) || "";

        let typeOptions;

        if (typeOrigin != null) {
            typeOptions = [{
                value: typeOrigin,
                label: Translate(typeOrigin)
            }];
        } else {
            typeOptions = this.state.transformTypes;
        }

        return (
            <div>
                <ConectorInputText label={"field_name"} name="name" value={fieldName}
                    onChange={this.handleChangeFieldName.bind(this)} onBlur={this.handleChangeFieldName.bind(this)} />

                <ConectorSelect label={"field_type"} placeholder={"choose"}
                    options={typeOptions} onChange={this.handleChangeFieldType.bind(this)}
                    value={typeOptions.find(converter => converter.value == fieldType)} />

                {
                    typesWithFormat.indexOf(fieldType) >= 0 ? (
                        <ConectorInputText label={"format_to"} name="format" value={fieldFormat}
                            onChange={this.handleChangeFieldFormat.bind(this)} />
                    ) : ""
                }
            </div>
        )
    }

}

export default TransformProperty;