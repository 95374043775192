import WizardConnectionAction from './wizard-connection-action/wizard-connection-action'
import GenericStepConnections from './generic-steps/generic-step-connections'
import GenericStepStructure from './generic-steps/generic-step-structure'
import TransformStructure from './transform/transform-structure'
import WizardSQL from './wizard-sql/wizard-sql'
import FilterStep from './filter/filter-step'

const Specifics = {
    'SQL': {
        connection: GenericStepConnections,
        structure_in: WizardSQL,
        structure_out: GenericStepStructure
    },
    46: {
        connection: GenericStepConnections,
        structure_out_transform: TransformStructure
    },
    48: {
        connection: GenericStepConnections,
        structure_out_filter: FilterStep
    }
}

const Generics = {
    connection: GenericStepConnections,
    structure_in: GenericStepStructure,
    structure_out: GenericStepStructure
}

export default (codConnectionAction, currentStep, desEventProperty) => {

    if (!currentStep) {
        return WizardConnectionAction;
    }

    //https://stackoverflow.com/a/9055603
    switch (true) {
        case ((codConnectionAction >= 16 && codConnectionAction <= 35) || (codConnectionAction >= 53 && codConnectionAction <= 57) || codConnectionAction == 83):
            return Specifics['SQL'][desEventProperty];
        case codConnectionAction === 46:
            return Specifics[codConnectionAction][desEventProperty];
        case codConnectionAction === 48:
            return Specifics[codConnectionAction][desEventProperty];
        default:
            return Generics[desEventProperty]
    }
};