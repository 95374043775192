import React, { Component } from "react"
import {
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid2
} from "@mui/material"
import AttributeTypeComponentList from "../attribute-type-component-list"
import Session from "../../../utils/session-info"
import Translate from "../../../i18n/translate"
import {StyledDialogTitle} from "../../../componentsUI/styledComponents/styledDialog"
import StyledButton from "../../../componentsUI/styledComponents/styledButton"
import {StyledDialogZIndex} from "../../../componentsUI/styledComponents/styledWizardSQL"

class ParametersModal extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props }
    }

    getOptions = attr => {

        const {
            detailsFields,
            toAttrOptions
        } = this.state;

        if (attr.codAttribute === 1) {
            var fields = [];
            detailsFields.forEach(detailField => {
                fields = [...fields, ...detailField.fieldAttributes]
            });
            const attrTypes = fields
                .filter(field => field.codAttribute === 1)
                .map(field => field.desValue);

            return attr.options
                .filter(attrOption => attrTypes.includes(attrOption.description))
                .map(attrOption => {
                    return {
                        label: attrOption.label,
                        value: attrOption.codAttribute,
                        description: attrOption.description,
                        master: attrOption.CodMaster,
                    }
                });
        } else {
            return toAttrOptions;
        }
    }

    componentWillReceiveProps(props) {
        this.setState({ ...props }, this.setToOptions)
    }

    setToOptions = _ => {
        var toAttrs = [],
            { selectedParameter, detailsFields } = this.state;

        if (!selectedParameter || !selectedParameter.fieldAttributes) {
            return;
        }

        const selectedFieldType = selectedParameter.fieldAttributes.find(fieldAttr =>
            fieldAttr.codAttribute === 1
        );

        var selectedTypeValue;
        if (selectedFieldType) {
            selectedTypeValue = (selectedFieldType.desValue || '').replace('?', '');
        }

        detailsFields.forEach(detail => {
            const typeAttr = detail.fieldAttributes.find(attribute => attribute.codAttribute == 1);
            if (typeAttr && selectedTypeValue === (typeAttr.desValue || '').replace('?', '')){
        } {
                //const toAttr = detail.fieldAttributes.slice(-1).pop();
                const toAttr = detail.fieldAttributes.find(attr => attr.codAttribute === 11);
                toAttrs.push(toAttr);
            }
        });

        this.setState({
            toAttrOptions: toAttrs.map(option => ({
                label: option.desValue,
                value: option.codAttribute,
                description: option.desValue,
                master: option.codField
            }))
        })
    }

    getValue = attr => {
        const { selectedParameter, toAttrOptions } = this.state;

        var field;
        if (attr.options) {
            field = selectedParameter.fieldAttributes.find(fieldAttr =>
                fieldAttr.codAttribute === 1
            )

            if (field) {
                const attrOption = attr.options.find(attrOption =>
                    attrOption.description === field.desValue
                )
                return {
                    label: attrOption.label,
                    value: attrOption.codAttribute,
                    description: attrOption.description,
                    master: attrOption.CodMaster,
                }
            }

        } else {

            if (attr.description === "is_list"){
                field = selectedParameter.fieldAttributes.find(fieldAttr =>
                    fieldAttr.codAttribute === attr.codAttribute
                )
                var attrOption = toAttrOptions.find(attrOption =>
                    attrOption.description === selectedParameter.desField
                )
                if(attrOption && field){
                    return {
                        label: attr.label,
                        value: field.codAttribute,
                        description: attr.description,
                        codMaster: attrOption.master
                    }
                }
            }
            field = selectedParameter.fieldAttributes.find(fieldAttr =>
                fieldAttr.codAttribute === attr.codAttribute
            );

            if (field) {
                return toAttrOptions.find(option => option.description === field.desValue);
            }
        }

        return "";

    }

    handleChangeType = e => {
        let {
            selectedParameter,
            attributes
        } = this.state;
        const codAttribute = parseInt(e.master, 10);

        if (selectedParameter.attributeType
            && selectedParameter.attributeType.value === e.value) {
            return;
        }

        selectedParameter.fieldAttributes = [{
            codAttribute,
            desValue: e.description,
            desLabel: String(e.value),
            codInstance: Session().codInstance,
            indPosition: attributes.find(attr =>
                attr.codAttribute === e.master
            ).indPosition
        }];
        selectedParameter.attributeType = e;

        this.setState({ selectedParameter }, this.setToOptions);
    }

    handleChangeTo = e => {

        var { selectedParameter, attributes } = this.state

        var fromAttr = attributes.find(attr => attr.description.indexOf("from") !== -1)

        let attr = selectedParameter.fieldAttributes.find(
            fieldAttr => fieldAttr.codAttribute === fromAttr.codAttribute
        )

        if (!attr) {
            selectedParameter.fieldAttributes.push({
                codAttribute: fromAttr.codAttribute,
                codMaster: e.master,
                desValue: e.description,
                indPosition: 1,
                codInstance: Session().codInstance
            })
        } else {
            attr.desValue = e.description;
            attr.codMaster = e.master;
        }

        this.setState({ selectedParameter });
    }

    handleChecked = e => {
        var { selectedParameter, attributes , isActive } = this.state
        var wellersom
        var fromAttr = attributes.find(attr => attr.description.indexOf("is_list") !== -1)
        if(fromAttr && e.description == "is_list"){
            let attr = selectedParameter.fieldAttributes.find(
                fieldAttr => fieldAttr.codAttribute === fromAttr.codAttribute
            )
            if(attr){
                isActive = attr.desValue == "true" ? true : false
                return isActive
            } else {
                return false
            }

        }
    }

    handleChangeList = e =>{
        var { selectedParameter, attributes , isActive } = this.state
        var fromAttr = attributes.find(attr => attr.description.indexOf("is_list") !== -1)
        if(fromAttr){

            let attr = selectedParameter.fieldAttributes.find(
                fieldAttr => fieldAttr.codAttribute === fromAttr.codAttribute
            )
            isActive = this.state.isActive
            var valueChecke =  e.target.checked
            valueChecke = isActive != valueChecke ? valueChecke : isActive;
            
            if (!attr) {
    
                selectedParameter.fieldAttributes.push({
                    codAttribute: fromAttr.codAttribute,
                    codMaster: 1,
                    codInstance: Session().codInstance,
                    desValue: valueChecke.toString(),
                    intPosition: 1, 
                    desLabel: fromAttr.description
                });
            } else {
                attr.desValue = valueChecke.toString();
                attr.codMaster = selectedParameter.codField;
            }
            this.setState({ selectedParameter });
        }
    }


    onClose = _ => {
        const {
            dialogOptions,
            selectedParameter
        } = this.state;

        selectedParameter
            .fieldAttributes
            .forEach(fieldAttr => {
                switch (fieldAttr.codAttribute) {
                    case 1:
                        selectedParameter.desAttrType = (fieldAttr.desValue  || '').replace('?', '');
                        break;
                    case 26:
                        selectedParameter.desField = fieldAttr.desValue;
                        break;
                    default:
                        break;
                }
            });

        dialogOptions.handleClose(selectedParameter);
    }

    handleTypeComponent(e) {
        var TypeComponent

        switch (e.codAttributeType) {
            case 5:
                TypeComponent = AttributeTypeComponentList(5)
                break;
            default:
                TypeComponent = AttributeTypeComponentList(7)
                break;
        }

        return TypeComponent
    }

    

    handleChangeTypeAttribute = e => {

        switch (e.codAttributeType) {
            case 5: return this.handleChangeList.bind(this);
            case 7: return this.handleChangeType.bind(this);
            case 1: return this.handleChangeTo.bind(this);
        }

    }

    render() {

        const {
            dialogOptions,
            attributes,
            selectedParameter,
            isList
        } = this.state;

        if (!dialogOptions.open) {
            return null;
        }

        return (
            <StyledDialogZIndex
                open={dialogOptions.open}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <StyledDialogTitle className={dialogOptions.type}
                    id="dialog-title">
                    {Translate(dialogOptions.title)}
                </StyledDialogTitle>
                <DialogContent>
                    <DialogContentText style={{ textWeight: 700, paddingBottom: "15px" }}
                        id="dialog-description">
                        {Translate(dialogOptions.message)}
                    </DialogContentText>
                    <Grid2 container spacing={1}>
                        { 
                            attributes.map((attr, idx) => {
                                const TypeComponent = this.handleTypeComponent(attr)
                                return (
                                    <Grid2 size={16}>
                                        {
                                            React.createElement(TypeComponent, {
                                                required: attr.isRequired === "true",
                                                options: this.getOptions(attr),
                                                label: attr.label,
                                                disabled: !attr.options
                                                    && !selectedParameter.fieldAttributes.length,
                                                value: this.getValue(attr),
                                                checked: this.handleChecked(attr),
                                                name: String(attr.codAttribute),
                                                showTime: true,
                                                onChange: this.handleChangeTypeAttribute(attr)
                                            })
                                        }
                                    </Grid2>
                                )
                            })
                        }
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={dialogOptions.handleDismiss}
                        autoFocus
                        color="primary">
                        {Translate("cancel")}
                    </StyledButton>
                    <StyledButton
                        variant="outlined"
                        onClick={this.onClose}>
                        {Translate("save")}
                    </StyledButton>
                </DialogActions>
            </StyledDialogZIndex>
        );
    }
}

ParametersModal.defaultProps = {
    attributes: [],
    detailsFields: []
}

export default ParametersModal;

