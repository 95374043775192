import React, {
	Component,
	Fragment
} from "react";
import { connect } from "react-redux";
import CompaniesService from "./companies-service";
import { setBreadcrumb } from "../../store/app-state/actions";
import * as appStateReducer from "../../store/app-state/reducer";
import Loading from "../../componentsUI/loading"
import ConectorTable from "../../componentsUI/conectorTable"
import Toast from "../../components/toast/toast"
import Translate from "../../i18n/translate"

class Company extends Component {

	constructor(props) {
		super(props);

		props.setBreadcrumb([{
			label: "registrations"
		}, {
			label: "companies"
		}]);

		this.state = {
			dataSource: [],
			loading: true,
			gridOptions: {
				multiSelect: false,
				hideBtnDelete: true,
				hideBtnCopy: true,
				sortField: "description",
				columns: [{
					title: "name",
					field: "description"
				}, {
					title: "hash",
					field: "hash",
					copy: true
				}]
			},
			CompanyServiceRequestCompleted: false
		}
	}

	componentWillMount() {
		this.getData();
	}

	async componentDidMount() {
		await this.getData();
		await this.updateButtonVisibility();
	}

	async updateButtonVisibility() {
		CompaniesService.GetUserPermissionCompany()
			.then(res => {

					const { gridOptions } = this.state;
					const updatedGridOptions = {
						...gridOptions,
						hideBtnNew: !res.hasPermission,
						hideBtnEdit: !res.hasPermission,
					};
	
					this.setState({
						gridOptions: updatedGridOptions,
						CompanyServiceRequestCompleted: true,
					});
				
			})
			.catch(error => {
				Toast.error(Translate(error.message));
				setTimeout(() => {
					window.location.href = '/';
				}, 2000);
			});
	}	

getData() {
	CompaniesService.GetCompanies().then((companies) => {
		this.setState({
			dataSource: companies,
			loading: false
		});
	});
}

gridNewItem = _ => {
	window.location.replace('#/companies/add')
}

gridEditItem(row = "add") {
	if (row instanceof Object) {
		row = row.id
	}

	window.location.replace(`#/companies/${row}`)
}

render() {
	const {
		loading,
		dataSource,
		gridOptions,
		CompanyServiceRequestCompleted
	} = this.state

	if (!CompanyServiceRequestCompleted) {
		return <Loading />
	}

	return (
		<Fragment>
			<ConectorTable
				gridTitle={"companies_list"}
				gridBtn={"new_company"}
				gridNewItem={this.gridNewItem}
				gridEditItem={this.gridEditItem}
				gridOptions={gridOptions}
				dataSource={dataSource}>
			</ConectorTable>
		</Fragment>
	)
}

}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Company);