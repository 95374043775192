import request from '../../../../utils/request'
import Session from '../../../../utils/session-info';

export default {
    GetLogsResume: (codInterface, objectFilter, errorFilter, successFilter, warningFilter) => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/logsByInterface/${Session().codInstance}/${codInterface}/${objectFilter.filterStart}/${objectFilter.filterEnd}/${errorFilter}/${successFilter}/${warningFilter}`,
            request.getOptions()
        ).then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
    },
}
