import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-outlined': {
    backgroundColor: '#4C637B',
    fontWeight: 'bold',
    color: 'white',
    borderRadius: `15px`,
    border: '3px solid #4C637B',
    '&:hover': { 
      borderColor: '#0266B1',
      backgroundColor: '#0266B1'
    }
  },

  '&.MuiButton-contained': {
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    color: "#6F8296",
    borderRadius: `15px`,
    border: '3px solid #C1C1C1',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#C1C1C1 '
    }
  },

  '&.Mui-disabled': {
    opacity : '0.3',
    pointerEvents: 'auto'
  },

  '&.Mui-disabled:hover': { 
    cursor: 'not-allowed',
    borderColor: '#4C637B',
    backgroundColor: '#4C637B'

  }, 
  '&.hidden': { display: 'none' }
}))

export default StyledButton;
