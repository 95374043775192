import request from '../../../utils/request'

export default {
    GetAllByInterfaceHistory: (codInterface) => {
        return request.executeRequest(
            `${request.baseUrl}interface_history/byInterface/${codInterface}`,
            request.getOptions()
        ).then(res => res.json())
    }
}
