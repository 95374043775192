import React, { Component } from "react"
import { connect } from "react-redux"
import { setSelectedConnectionAction, setWizardState } from "../../../store/wizard/wizard-store-actions"
import { setSteps } from "../../../store/app-state/actions"
import * as wizardStore from "../../../store/wizard/wizard-store-reducer"
import StyledPaper from "../../../componentsUI/styledComponents/styledPaper"
import ConectorSelect from "../../../componentsUI/conectorSelect"
import { Grid2 } from "@mui/material";

class RestConfig extends Component {
    constructor(props) {
        super(props)

        props.setWizardState({
            disabledStep: typeof props.wizardState.event.codConnectionAction !== "number"
        })

        this.state = {
            ...props.wizardState,
            connectionActions: [...props.connectionActions],
            connectionActionsOptions: []
        }
    }

    componentWillMount() {
        var {
            event,
            connectionActions
        } = this.state;

        connectionActions = connectionActions.filter(connAction =>
            connAction.codConnectionType === event.codConnectionType
        );

        const connectionActionsOptions = connectionActions
            .sort((a, b) => a.indPosition < b.indPosition)
            .map(action => {
                return {
                    value: action.id,
                    label: action.description
                }
            }
            )

        this.setState({
            connectionActions,
            connectionActionsOptions
        })
    }

    handleChange = selectedObj => {
        let { event, connectionActions } = this.state;

        event.codConnectionAction = selectedObj.value;
        event.connectionAction = connectionActions.find(action =>
            action.id === selectedObj.value
        )

        const eventProperties = this.props.eventProperties.filter(prop =>
            prop.codConnectionAction === selectedObj.value
        ).sort((o1, o2) => o1.codProperty - o2.codProperty);

        this.props.setSteps(
            eventProperties.map((eventProp, idx) => ({
                label: eventProp.desLabel
            }))
        );

        this.props.setWizardState({
            codConnectionAction: event.codConnectionAction,
            eventProperties,
            event: event,
            disabledStep: false
        })
    }

    componentWillReceiveProps(props) {
        this.setState({
            ...props.wizardState
        })
    }

    render() {
        const {
            connectionActionsOptions,
            event
        } = this.state

        return (
            <StyledPaper className={"bg_darken_04"}>
                <Grid2
                    sx={{minHeight: 'calc(100vh - 376px)'}}
                    container 
                    spacing={1} 
                    alignItems="flex-start" 
                    alignContent="flex-start">
                    <Grid2>
                        <ConectorSelect
                            label="action"
                            help="select_action"
                            name="codInterfaceMode"
                            placeholder="choose"
                            onChange={this.handleChange.bind(this)}
                            options={connectionActionsOptions}
                            value={connectionActionsOptions.find(option =>
                                option.value === event.codConnectionAction
                            )}/>
                    </Grid2>
                </Grid2>
            </StyledPaper>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedConnectionAction: codConnectionAction => dispatch(
            setSelectedConnectionAction(codConnectionAction)
        ),
        setWizardState: newWizardState => dispatch(
            setWizardState(newWizardState)
        ),
        setSteps: steps => dispatch(setSteps(steps)),
    }
}

const mapStateToProps = store => {
    return {
        wizardState: wizardStore.getWizardState(store),
        eventProperties: wizardStore.getEventProperties(store),
        connectionActions: wizardStore.getConnectionActions(store)
    }
}

RestConfig = connect(mapStateToProps, mapDispatchToProps)(RestConfig)
export default RestConfig