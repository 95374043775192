// strings should be unique across reducers so namespace them with the reducer name

export const FETCH_INTERFACES = 'FETCH_INTERFACES';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const SET_INTERFACE = 'SET_INTERFACE';
export const ADD_EVENT = 'ADD_EVENT';
export const SET_EVENTS = 'SET_EVENTS';
export const SET_NEW_INTERFACE = 'SET_NEW_INTERFACE';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
export const SET_INTERFACE_EVENTS = 'SET_INTERFACE_EVENTS';
export const SET_INPUTS = 'SET_INPUTS';