import styled from "styled-components"
import StyledButton from "../../componentsUI/styledComponents/styledButton"

export const StyledContainer = styled.div`
    && {
        margin-top: 15px;
    }

`

export const StyledButtonContainer = styled.div`
    && {
        text-align: center;
        margin-top: 10px;
    }
`

export const StyledGridButton = styled(StyledButton)`
    && { 
        min-width: 50px;
        margin: 0 20px 5px 0;

        &:last-child {
            margin-right: 0;
        }

        @media screen and (max-width:500px) {
            min-width: calc(50% - 13px);
            &:last-child { min-width: calc(100% - 15px); }
        }
    }
`

export const StyledSeparator = styled.div`
    && {
        width: 100%;
        height: 1px;
        background-color: #B8C6D6;
    }
`

export const StyledContainerTableAndRadio = styled.div`
    && {text-align: center;}
`

export const StyledRadioGroup = styled.div`
    && {
        display: flex;
    }
`

export const StyledGridTable = styled.div`
    && {
        width: 100%;
        margin-top: 5px;
    }
`