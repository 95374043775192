import React, {
    Component,
    Fragment
} from "react";
import { connect } from "react-redux"
import ConnectionsService from "./connections-service"
import Session from "../../utils/session-info"
import Toast from "../../components/toast/toast"
import Translate from "../../i18n/translate"
import { setBreadcrumb } from "../../store/app-state/actions"
import * as appStateReducer from "../../store/app-state/reducer"
import ConnectionsCopyModal from "./connections-copy-modal/connections-copy-modal"
import { StyledDialogZIndex } from "../../componentsUI/styledComponents/styledConnectionsCopyModal"
import Loading from "../../componentsUI/loading"
import ConectorTable from "../../componentsUI/conectorTable"
import ConectorDialogConfirmation from "../../componentsUI/dialogComponentConfirmation"
import { StyledDialogTitle } from "../../componentsUI/styledComponents/styledDialog"

class Connections extends Component {
    constructor(props) {
        super(props)

        props.setBreadcrumb([{
            label: "integrations"
        }, {
            label: "connections"
        }]);

        this.state = {
            open: props.open,
            connectionTypes: props.connectionTypes,
            connectionModes: props.connectionModes,
            gridOptions: props.gridOptions,
            instanceConnections: props.instanceConnections,
            connectionServiceRequestCompleted: false
        }
    }

    componentWillMount = _ => {
        this.getConnectionTypes();
    }

    async componentDidMount() {
        await this.getDataConnections();        
        await this.updateButtonVisibility();
    }

	async updateButtonVisibility() {
        ConnectionsService.GetUserPermissionConnection()
            .then(res => {

                    const { gridOptions } = this.state;
                    const updatedGridOptions = {
                        ...gridOptions,
                        hideBtnNew: !res,
                        hideBtnEdit: !res,
                        hideBtnDelete: !res,
                        hideBtnCopy: !res
                    };

                    this.setState({
                        gridOptions: updatedGridOptions,
                        connectionServiceRequestCompleted: true,
                    });

            })
            .catch(error => {
                Toast.error(Translate(error.message));
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
            });
    }

    getConnectionTypes() {
        ConnectionsService.GetConnectionTypes().then(connectionTypes => {
            var connectionModes = [];
            connectionTypes
                .filter(connectionType => connectionType.children)
                .forEach(connectionType => {
                    connectionModes.push(...connectionType.children);
                });

            this.setState({ connectionTypes, connectionModes }, this.getDataConnections);
        });
    }

    getDataConnections() {
        var {
            connectionModes,
            connectionTypes
        } = this.state;

        ConnectionsService.GetConnections().then(
            ({ instance_connections }) => {
                this.setState({
                    instanceConnections: instance_connections.map(connection => {
                        var connectionType = connectionTypes.find(type =>
                            type.codConnectionType === connection.codConnectionType
                        ) || {};

                        var connectionMode = connectionModes.find(mode =>
                            mode.codConnectionMode === connection.codConnectionMode
                        ) || {};

                        return {
                            description: connection.description,
                            isActive: connection.isActive,
                            connectionTypeDesc: connectionType.label || "",
                            connectionModeDesc: connectionMode.label || "",
                            id: connection.id
                        }
                    }),
                    isReady: true
                });
            }
        )
    }

    gridNewItem = _ => window.location.replace('#/connections/add')

    gridEditItem = row => {
        if (!row) {
            return
        }

        let id;
        if (row instanceof Array) {
            id = row[0].id;
        } else {
            id = row.id;
        }

        window.location.replace(`#/connections/${id}`)
    }

    deleteConnectionRequest = rows => {
        var connectionsID = rows.map(row => row.id);

        ConnectionsService
        .DeleteConnection(connectionsID)
        .then(resMessage => {
            switch (resMessage) {
                case "allConnectionsNotDeleted":
                    Toast.error(Translate(resMessage))
                    break;
                case "someConnectionsNotDeleted":
                    Toast.info(Translate(resMessage))
                    break;
                case "allConnectionsDeletedSuccessfully":
                    Toast.success(Translate(resMessage))
                    break;
                case "connectionDeletedSuccessfully":
                    Toast.success(Translate(resMessage))
                    break;
                case "connectionNotDeleted":
                    Toast.error(Translate(resMessage))
                    break;
                case "systemError":
                    Toast.error(Translate(resMessage))
                    break;
            }
        })
        .then(() => this.getDataConnections())
    }

    gridDeleteItem = rows => {
        if (!rows) return

        this.setState({
            dialog: {
                type: "danger",
                open: true,
                message: "remove_connection",
                title: "exclusion_confirmation",
                onClose: () => {
                    this.deleteConnectionRequest(rows)
                    this.setState({dialog: { open: false }})
                },
                onDismiss: () => this.setState({dialog: { open: false }})
            }
        })
    }

    gridCopyItem = row => {
        if (!row) return
        else this.setState({ modalConnectionID: row[0].id })
    }

    cancelCopyConnectionModal = () => this.setState({modalConnectionID: undefined})

    saveCopyConnectionModal = (connectionOfModal, nameOfDestinyInstance) => {
        ConnectionsService.PostConnection(connectionOfModal)
        .then(response => {
            if (response.ok) {
                if(connectionOfModal.codInstance == Session().codInstance) {
                    Toast.success("connectionCopiedSuccessfully")
                }
                else Toast.success(`${Translate("connectionCopiedToInstance")} ${nameOfDestinyInstance}`)
            }
            else Toast.error("error_saving_record")

            this.setState({modalConnectionID: undefined}, this.getDataConnections())
        })
    }

    renderConnectionModal = () => {
        let { modalConnectionID } = this.state

        return (
            <StyledDialogZIndex fullWidth={true} maxWidth={"sm"} open={modalConnectionID != undefined}>
                <StyledDialogTitle id="confirmation-dialog-title" className="confirmation">
                    {Translate("copyOfConnection")}
                </StyledDialogTitle>
                <ConnectionsCopyModal
                    connectionID={modalConnectionID}
                    cancelCopyConnection={this.cancelCopyConnectionModal.bind(this)}
                    saveCopyConnection={this.saveCopyConnectionModal.bind(this)}/>
            </StyledDialogZIndex>
        )
    }

    render() {
        const {
            dialog,
            gridOptions,
            instanceConnections,
            connectionServiceRequestCompleted,
            modalConnectionID
        } = this.state

        if (!connectionServiceRequestCompleted) return <Loading />

        return (
            <Fragment>
                <ConectorTable
                    gridTitle={Translate("connection_list")}
                    gridBtn={Translate("new_connection")}
                    gridNewItem={this.gridNewItem}
                    gridEditItem={this.gridEditItem}
                    gridDeleteItem={this.gridDeleteItem.bind(this)}
                    gridCopyItem={this.gridCopyItem.bind(this)}
                    gridOptions={gridOptions}
                    dataSource={instanceConnections}>
                </ConectorTable>
                {modalConnectionID != undefined ? this.renderConnectionModal() : ""}
                <ConectorDialogConfirmation dialogOptions={dialog} />
            </Fragment>
        )
    }
}

Connections.defaultProps = {
    dialog: {},
    open: false,
    connectionTypes: [],
    connectionModes: [],
    instanceConnections: [],
    gridOptions: {
        multiSelect: true,
        sortField: "description",
        columns: [{
            title: "description",
            field: "description"
        }, {
            title: "type",
            field: "connectionTypeDesc"
        }, {
            title: "connection_mode",
            field: "connectionModeDesc"
        }, {
            title: "active",
            field: "isActive",
            cellFilter: "boolean",
            hideFilter: true
        }]
    }

}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Connections);