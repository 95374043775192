import React, { Component } from "react";
import Translate from "../../i18n/translate";
import StyledButton from "../../componentsUI/styledComponents/styledButton";
import {
    DialogContent,
    Grid2,
    DialogActions,
    DialogContentText
} from "@mui/material";
import {
    StyledDialog,
    StyledDialogTitle
} from "../../componentsUI/styledComponents/styledDialog";
import StyledInputLabel from "../../componentsUI/styledComponents/styledInputLabel";
import Toast from '../../components/toast/toast'
import TagsInput from 'react-tagsinput'
import './../../themes/react-tagsinput.css'
import workerService from './worker-service'
class ModalLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            request:{
                emailNotification :  localStorage.getItem('email'),
                codInstance :props.codInstance 
            }

        }
        this.requestLog = this.requestLog.bind(this);
    }
    generateTagsInputValue(){
        const { request } = this.state

        if(request.emailNotification === '' || request.emailNotification === undefined) return new Array

        return request.emailNotification.split(";")
    }
    setInputInstanceEmails(emailArray) {
        const { request } = this.state;
        let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let enteredEmail = emailArray[emailArray.length - 1];
        let updatedEmailNotification = '';
    
        if (emailArray.length === 0) {
            request.emailNotification = '';
        } else {
            if (emailRegex.test(enteredEmail)) {
                updatedEmailNotification = emailArray.join(';');
                request.emailNotification = updatedEmailNotification;
            } else {
                Toast.error('invalid_addresss');
            }
        }
        this.setState({ request });  // Atualiza o estado corretamente
    }
    requestLog(){
        const { request,props } = this.state
        workerService.RequestLog(request).then(_ => {
            Toast.success('Log solicitado com sucesso!')
            this.props.onDismiss()
        }).catch(_ => {
            Toast.error('Erro ao solicitar o log!')
        })
    }

    render() {
        const { emailNotification } = this.state
        return (
            <StyledDialog
                open={true}
                disableEscapeKeyDown={true}
                disableBackdropClock={true}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <StyledDialogTitle className="info">
                    {Translate("requestLog")}
                </StyledDialogTitle>
                <DialogContent>         
                <StyledInputLabel >{Translate("requestMsg")}</StyledInputLabel>           
                    <Grid2 container={true} spacing={24}>
                        <Grid2 item={true} xs={24} sm={24} md={24} lg={12} xl={12}>
                            <TagsInput value={this.generateTagsInputValue()} inputProps={{placeholder: undefined}} onChange={emailArray => this.setInputInstanceEmails(emailArray)}/>
                            <p class="tagsInputLabel">{Translate('enterEmails')}</p>
                        </Grid2>
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                         onClick={this.requestLog}
                         disabled={emailNotification == ''}
                    >
                        {Translate("request")}
                    </StyledButton>
                    <StyledButton
                        color="primary"
                        variant="contained"
                        onClick={this.props.onDismiss}
                    >
                        {Translate("close")}
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        );
    }
}

export default ModalLog;
