import React, {Component, Fragment} from "react";
import { connect } from "react-redux";
import * as wizardStore from "../../../../store/wizard/wizard-store-reducer";
import Translate from "../../../../i18n/translate"
import StyledButton from "../../../../componentsUI/styledComponents/styledButton"
import { StyledDialog, StyledDialogTitle } from "../../../../componentsUI/styledComponents/styledDialog"
import { StyledCardContent, StyledCard } from "../../../../componentsUI/styledComponents/styledConnectionActionModal"
import {
    DialogActions,
    DialogContent,
    Grid2,
    CardActionArea
} from "@mui/material";

class ConnectionActionsModal extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props }
    }

    componentWillReceiveProps = props => {
        this.setState({ ...props });
    }

    renderCards(connectionActions) {
        return connectionActions.map((e, idx) =>
            <Grid2 size={3} key={idx}>
                <StyledCard>
                    <CardActionArea>
                        <StyledCardContent onClick={_ => { this.props.onClose(e.id) }}>
                            <span>{e.description}</span>
                        </StyledCardContent>
                        </CardActionArea>
                </StyledCard>
            </Grid2>
        )
    }

    render() {
        var { open, connectionActions, event } = this.state;

        if (!open) {
            return <Fragment></Fragment>;
        }

        connectionActions = [...connectionActions].filter(connAction =>
            connAction.codConnectionType === event.codConnectionType
        );

        if (connectionActions.length === 1) {
            this.props.onClose(connectionActions[0].id);
            return <Fragment></Fragment>;
        }

        return (
            <StyledDialog maxWidth="md"
                fullWidth={true}
                open={open}
            >
                <StyledDialogTitle className="confirmation">
                    {Translate("set_event_type")}
                </StyledDialogTitle>
                <DialogContent style={{borderBottom: "1px solid #e4e1e1"}}>
                    <Grid2 container spacing={1}>
                        {this.renderCards(connectionActions)}
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={this.props.onDismiss}>
                        {Translate("cancel")}
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        );
    }
}

const mapStateToProps = store => {
    return {
        connectionActions: wizardStore.getConnectionActions(store)
    };
};

ConnectionActionsModal = connect(mapStateToProps)(ConnectionActionsModal);

export default ConnectionActionsModal;