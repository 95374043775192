import request from '../../../utils/request'

export default {
    PostCount: object => {
        return request.executeRequest(
            `${request.baseUrl}maintenance/countStart`,
            {
                ...request.postOptions(),
                body: JSON.stringify(object)
            }
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText)
            }
            return res.json();
        })
    },
    PostExecute: object => {
        return request.executeRequest(
            `${request.baseUrl}maintenance/executeStart`,
            {
                ...request.postOptions(),
                body: JSON.stringify(object)
            }
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText)
            }
            return res.json();
        })
    },
    GetAllByMaintananceHistory: (name) => {
        return request.executeRequest(
            `${request.baseUrl}maintenance_history/byNameMaintenance/${name}`,
            request.getOptions()
        ).then(res => res.json())
    },
    PostCountExtend: object => {
        return request.executeRequest(
            `${request.baseUrl}maintenance/countExtend`,
            {
                ...request.postOptions(),
                body: JSON.stringify(object)
            }
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText)
            }
            return res.json();
        })
    },
    PostExecuteExtend: object => {
        return request.executeRequest(
            `${request.baseUrl}maintenance/executeExtend`,
            {
                ...request.postOptions(),
                body: JSON.stringify(object)
            }
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText)
            }
            return res.json();
        })
    },

}
