import request from "../../utils/request";
import Session from "../../utils/session-info";

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete,
    postAuthenticationChange
};

export default userService

function login(user, password, userIP) {
    return request.executeRequest(`${request.baseUrl}globals/user_authenticate`, {
        ...request.postOptions(),
        body: JSON.stringify({ user, password, userIP })
    }).then(res => {
        if (res.ok) return res.json();
        
        return Promise.reject(res);
    });
}

function logout() {
    return request.executeRequest(`${request.baseUrl}globals/logout`, { 
        ...request.postOptions()
    }).then(res => {
        if (res.ok){return res.json()}
    }).finally(() => {
        localStorage.clear()
        window.location.reload(true)
    })
}

function getAll() {
    return request.executeRequest('/users', request.getOptions()).then(handleResponse);
}

function getById(id) {
    return request.executeRequest('/users/', request.getOptions()).then(handleResponse);
}

function register(user) {
    return request.executeRequest('/users/register', {
        ...request.postOptions,
        body: JSON.stringify(user)
    }).then(handleResponse);
}

function update(user) {
    return request.executeRequest('/users/' + user.id, {
        ...request.putOptions(),
        body: JSON.stringify(user)
    }).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return request.executeRequest(`/users/${id}`, request.deleteOptions()).then(handleResponse);
}

function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }

    return response.json();
}

function postAuthenticationChange(codCompany, codInstance) {
    return request.executeRequest(`${request.baseUrl}globals/changeAuth`, {
        ...request.postOptions(),
        body: JSON.stringify({
            "CodCompany": parseInt(codCompany, 10),
            "CodInstance": parseInt(codInstance, 10)
        })
    }).then(response => {
        if (!response.ok) {
            return Promise.reject(response.statusText);
        }

        return response.json();
    }).then((auth) => {
        return auth;
    });
}

