import styled from 'styled-components'
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)`
    && {
        width: 100%;

        .MuiOutlinedInput-root:hover:not(.MuiOutlinedInput-disabled):not(.MuiOutlinedInput-focused):not(.MuiOutlinedInput-error) .MuiOutlinedInput-notchedOutline { 
            border-color: #B8C6D6;
        }

        .Mui-disabled { background-color: #EEEEEE; }
        
        .Mui-disabled:hover {
            cursor: not-allowed
        }
        
        fieldset { 
            border-color: #B8C6D6;
            border-width: 1px;
            .MuiOutlinedInput-notchedOutline { 
                border-color: #B8C6D6;
                border-width: 1px; 
            }
        }

        .MuiFormControl-error {
            fieldset { border-color: #CD5C5C; }
        }

        svg, span.material-icons { 
            color: #B8C6D6;
            font-size: 20px; 

            &.sentiment_dissatisfied {
                font-size: 22px;
                color: #CD5C5C;
            }

            &.sentiment_satisfied {
                font-size: 22px;
                color: #FFC34B;
            }

            &.sentiment_very_satisfied {
                font-size: 22px;
                color: #52B4B7;
            }
        }
        
        input { 
            padding: 9.5px 14px;
            color: #6F8296;
            background-color: #FFFFFF;
        }

        input:-webkit-autofill, 
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            box-shadow: 0 0 0px 1000px #EEEEEE inset !important;
            -webkit-text-fill-color: #009CDF !important;
            transition: background-color 5000s ease-in-out 0s;
        }
        
        p {
            &.MuiFormHelperText-root {
                background-color: transparent;
                font-weight: normal;
                color: #B8C6D6;
                text-align: left;
                font-size: 0.8rem;
                margin-bottom: 5px;
                margin-left: 0;
                .MuiFormHelperText-error { 
                    color: #CD5C5C;
                }
            }
        }

        .MuiInputAdornment-positionStart { 
            margin-right: 0;
            &.noBtn ~ .MuiOutlinedInput-inputAdornedStart {
                padding: 11.5px 14px;
            }
        }

        .MuiOutlinedInput-adornedEnd { padding-right: 1px; }
        .MuiInputAdornment-positionEnd { margin-left: 0; }
        
        .MuiInputAdornment-root {
            .separator {
                background-color: #B8C6D6;
                width: 1px;
                height: 40px;
                box-sizing: border-box;
            }

            .divButton {
                min-height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: default;
            }
            
            button {
                border-radius: 0;   
                padding: 0;
                min-height: 40px;
                min-width: 60px;
                svg { color: #B8C6D6; }
                &:hover { background-color: #EEEEEE; }
            }
        }

        &.error {
            fieldset { border-color: #CD5C5C; }
            p { &.MuiFormHelperText-root { color: #CD5C5C; }}
        }
        
        &.input-condition-success {
            fieldset { border-color: #52B4B7; }
            p { &.MuiFormHelperText-root { color: #52B4B7; }}
        }
        
        &.input-condition-warning {
            fieldset { border-color: #FFC34B; }
            p { &.MuiFormHelperText-root { color: #FFC34B; }}
        }

        &.hidden { display: none; }
    }
`

export default StyledTextField