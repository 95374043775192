export default {

    changeUserScenario: ({ company, instance, userAccessProfile, token, desName, isActive }) => {
        let user = JSON.parse(localStorage.getItem("user"));
        user.Token = token;
        user.desName = desName;
        user.desCompany = company.description;
        user.codCompany = company.id;
        user.codInstance = instance.id;
        user.desInstance = instance.description;
        user.isActive = isActive;
        user.userAccessProfile = userAccessProfile;

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("RestoreSession", true);
        localStorage.setItem("CodCompanySelected", company.id);
        localStorage.setItem("DescCompanySelected", company.descripion);
        localStorage.setItem("CodInstanceSelected", instance.id);
        localStorage.setItem("DescInstanceSelected", instance.description);

        window.location.reload();
    }

}
