export default {
    equals: (
        obj1 = {}, 
        obj2 = {}
    ) => {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    deepCopy: (obj = {}) => {
        return JSON.parse(JSON.stringify(obj));
    }
}