import * as types from './wizard-store-action-types';
import wizardStoreService from './wizard-store-service'

export const setSelectedConnectionAction = codInterface => {
    return async dispatch => {
        dispatch({
            type: types.WIZARD_SET_SELECTED_CONNECTION_ACTION,
            codInterface
        });
    };
}

export const setDeletedFields = (deletedFields = []) => {
    return async dispatch => {
        dispatch({
            type: types.WIZARD_SET_DELETED_FIELDS,
            deletedFields
        });
    }
}

export const setWizardEventTypes = (eventTypes) => {
    return async dispatch => {
        dispatch({
            type: types.WIZARD_SET_EVENT_TYPES,
            eventTypes
        })
    }
}

export const clearWizardStore = _ => {
    return async dispatch => {
        dispatch({
            type: types.WIZARD_CLEAR_WIZARD_STORE
        });
    };
}

export const defineAttributes = _ => {
    return async dispatch => {
        wizardStoreService
            .getAllAttributes()
            .then(res => {
                dispatch({
                    type: types.WIZARD_DEFINE_ATTRIBUTES,
                    attributes: res
                })
            })
    }
}

//isso pode ser o valor default da store. remover action.
export const defineConnectionActions = _ => {
    return async dispatch => {
        wizardStoreService
            .getAllConnectionActions()
            .then(res => {
                dispatch({
                    type: types.WIZARD_DEFINE_CONNECTION_ACTIONS,
                    connectionActions: res
                })
            })
    }
}

//isso pode ser o valor default da store. remover action.
export const defineEventProperties = _ => {
    return async dispatch => {
        wizardStoreService
            .getAllEventProperties()
            .then(res => {
                dispatch({
                    type: types.WIZARD_DEFINE_EVENT_PROPERTIES,
                    eventProperties: res
                })
            })
    }
}

export const setWizardState = newStateProps => {
    return async dispatch => {
        dispatch({
            type: types.WIZARD_SET_WIZARD_STATE,
            newStateProps
        })
    }
}

export const setWizardSelectedStructure = selectedStructure => {
    return async dispatch => {
        dispatch({
            type: types.WIZARD_SET_SELECTED_STRUCTURE,
            selectedStructure
        })
    }
}

export const setWizardStructureArray = structureArray => {
    return async dispatch => {
        dispatch({
            type: types.WIZARD_SET_STRUCTURE_ARRAY,
            structureArray
        })
    }
}

export const setStepAuxiliary = isStepAuxiliary => {
    return async dispatch => {
        dispatch({
            type: types.WIZARD_SET_STEP_AUXILIARY,
            isStepAuxiliary
        })
    }
}

export const setStepStructure = stepStructure => {
    return async dispatch => {
        dispatch({
            type: types.WIZARD_SET_STEP_STRUCTURE,
            stepStructure
        })
    }
}

export const setIsStructureChangeTab = isStructureChangeTab => {
    return async dispatch => {
        dispatch({
            type: types.WIZARD_SET_STRUCTURE_CHANGE_TAB,
            isStructureChangeTab
        })
    }
}

export const setTreeData = treeData => {
    return async dispatch => {
        dispatch({
            type: types.WIZARD_SET_TREE_DATA,
            treeData
        })
    }
}