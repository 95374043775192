import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'

const StyledIconButton = styled(IconButton)`
  && {
    margin-right: 10px;
    padding: 7px;
    color: #009CDF;

    &:hover {
      background-color: #B8C6D6;
      color: #009CDF;
    }
    
    .hidden {color: red;}
    &.default { color: #6F8296; }
    .save { color: #009CDF; }
    .cancel { color: #4C637B; }
  }`

export default StyledIconButton