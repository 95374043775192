import { styled } from '@mui/material/styles'
import { Avatar, Tooltip, Box } from '@mui/material'

export const PanelTitle = styled('div')({
    fontSize: '20px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    paddingBottom: '10px',
    color: '#6f8296',
})

export const TextDescription = styled('div')({
    fontSize: '16px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    paddingBottom: '10px',
    color: '#6f8296',
})

export const StyledVersion = styled('label')({
    width: '100%',
    position: 'absolute',
    left: 0,
    bottom: '10px',
    color: '#b8c6d6',
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    cursor: 'pointer',
})

export const StyledVersionText = styled('label')({
    fontSize: '20px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    paddingBottom: '10px',
    color: '#6f8296',
    cursor: 'pointer',
})

export const PulsatingImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
}))

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: '40px',
  height: '40px',
  backgroundColor: 'rgba(52, 52, 52, 0.0)',
  animation: 'pulse 2s infinite',
  boxShadow: theme.shadows[0],
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.3)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}))

export const ImageWithTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#b8c6d6',
    color: 'black',
    fontSize: '14px',
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#b8c6d6',
  },
}))