import styled from 'styled-components'
import { Popper } from "@mui/material"

export const StyledPopper = styled(Popper)`
    && {
        top: 1px !important;
        right: 0;

        .MuiListItemIcon-root {
            font-size: 0.875rem;
            color: #6F8296;
        }
    }
`

export const ColoredBold = styled.strong`
    && {
        color: #fe7f2d !important;
    }
`