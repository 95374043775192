import request from '../../../utils/request'
import Session from '../../../utils/session-info';

export default {
    GetSummaryLogsByInstance: () => {
        return request.executeRequest(
            `${request.baseUrl}summary_logs/${Session().codInstance}`,
            request.getOptions()
        ).then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
    },
}
