import styled from "styled-components"
import {Table, TableRow} from "@mui/material"

export const ExpandableTableRow = styled(TableRow)`
    word-break: break-word;

    && {
        &.hidden {
            display: none;

        }

        td {
            padding: 0 40px;

            border-bottom: 1px solid #E0E0E0;
        }

        animation: slidein 0.25s;
        @keyframes slidein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
    }
`

export const StyledGridContainer = styled.div`
    && {
        width: 100%;
        overflow: auto;
        &.scrollWidth { 
            width: calc(100%);
            hr { width: 100%; }

            &.scrollHeight {
                width: calc(100% - 7px);
                hr { width: 100%; }
                table { width: calc(100% - 7px); margin-right: 7px; }
            }
            :before {
                content: "";
                width: 1px;
                /* height: 100%; */
                right: 32px;
                z-index: 9999;
                position: absolute;
                background-color: #B8C6D6;
            }
        }

        &.scrollHeight {
            hr { width: calc(100% - 7px); }
            table { width: calc(100% - 7px); margin-right: 7px; }
        }

        ::-webkit-scrollbar-corner { background-color: #eee; }
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #eee;
            cursor: pointer;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
`

export const StyledTextFragment = styled.div`
    && {
              
        > p  {
            margin-top: 4px;
            margin-bottom: 15px;
            font-family: "Roboto", "Helvetica", "Arial", "sans-serif";  
            word-break: break-word;
            white-space: normal;
            
        }

        > pre {
            font-family: "Roboto", "Helvetica", "Arial", "sans-serif"; 
        }
    }
`

export const StyledTableFooter = styled(Table)`
    && { 
        width: calc(100%);
        background: white;
        tr { border: 1px solid #B8C6D6; }

        &&.full-size-container {
            width: 100%;
            margin-right: 0;
        }
    }
`

export const StyledTable = styled(Table)`
    && {

        tbody > tr.contentRow > td:not(:last-child):not(.checkbox) {
            padding: 10px 24px;
        }

        tbody > tr.contentRow > td:not(.checkbox) {
            padding-top: 10px;
            padding-bottom: 10px
        }

        tbody td.pointer { cursor: pointer; }

        tr {
            height: auto;
            border-right: 1px solid #B8C6D6;
            border-left: 1px solid #B8C6D6;

            th {
                color: #4c637b;
                padding: 10px 24px;
                background-color: #B8C6D6;
                text-transform: uppercase;
                span { color: #4c637b; }
            }
            
            td {
                position: relative;
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis;
                background-color: #fff;
                line-height: 0;
                color: #6F8296;

                .MuiTableCell-paddingCheckbox { 
                    padding: 0 12px;
                    svg { color: #6F8296; } 
                }

                &.notFound {
                    text-align: center;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    font-weight: bold;
                }
            }

            &:last-child { td { border-bottom: 0; }}
            tbody tr { cursor: pointer; }
        }

        thead > tr > th {
            top: 0;
            z-index: 2;
            position: sticky;
        }

        thead > tr > th:first-child { width: 50px; }
        thead > tr > th:last-child { padding-right: 0px; }

        &&.full-size-container {
            width: 100%;
            margin-right: 0;
        }

        margin: auto;
        margin-right: 13px;
        width: calc(100%);
        border-collapse: collapse;
    }
`

export const CustomListItem = styled.li`
    && {
        padding: 5px 0;
        display: inline;
        text-align: justify;
        line-height: 1;
        position: static;
        

        div:last-child {
            margin-bottom: 10px
            button {
                padding: 0;
           }
    }
    }
`