import request from '../../utils/request'
import Session from '../../utils/session-info'

const getAllConnectionActions = _ => {
    return request.executeRequest(
        `${request.baseUrl}interfaces/connectionaction/`,
        request.getOptions()
    ).then(response => {return response.json()})
}

const getAllEventProperties = _ => {
    return request.executeRequest(
        `${request.baseUrl}interfaces/eventproperties`,
        request.getOptions()
    ).then(res => {
        return res.json()
    })
}

const getAllAttributes = _ => {
    return request.executeRequest(
        `${request.baseUrl}interfaces/attributes`,
        request.getOptions()
    ).then(res => {
        return res.json()
    })
}

export default {
    getAllConnectionActions,
    getAllEventProperties,
    getAllAttributes
};