import request from "../../utils/request";

export const detailService = {
    getAll : getAll,
    getStructure : getStructure
};

function getAll(codStructure) {
    return request.executeRequest(`${request.baseUrl}interfaces/structuredetails/${codStructure}`, request.getOptions()).then(response => {
        if (!response.ok) {
            return Promise.reject(response.status);
        }

        return response.json();
    });
}

function getStructure(codStructure) {
    return request.executeRequest(`${request.baseUrl}interfaces/structuredetails/${codStructure}/fields`).then(response => response.json());
}