import React, { Component, Fragment } from 'react'
import { Grid2 } from '@mui/material'
import LoginService from '../login-service'
import Toast from '../../../components/toast/toast'
import { 
    StyledButtonLogin, 
    ResetTitle, 
    ResetSubtitle, 
    SubTitleStyled, 
    ResetPasswordFormStyled, 
    InputTextStyled 
} from "../../../componentsUI/styledComponents/styledLogin"
import Loading from "../../../componentsUI/loading"
import ConectorInputText from "../../../componentsUI/inputText"
import ConectorInputPassword from "../../../componentsUI/inputPassword"

class PasswordResetForm extends Component {
    constructor(props) {
        super(props);

        let passwordChangeToken
        let tokenInLocalStorage = false

        if (localStorage.hasOwnProperty("tokenResetExpiredPassword")){
            tokenInLocalStorage = true
            passwordChangeToken = localStorage.getItem("tokenResetExpiredPassword")
        } else passwordChangeToken = props.passwordChangeToken

        this.state = { 
            passwordChangeToken: passwordChangeToken,
            tokenInLocalStorage: tokenInLocalStorage,
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            validating: true
        }
    }

    componentWillMount() {
        const { passwordChangeToken } = this.state;

        LoginService.ValidatePasswordChangeToken(passwordChangeToken)
        .then(res => {
            if(res.status == 403) Toast.error('tokenNotValid')
            else return res.json()
        })
        .then(({userEmail, userName}) => {
                if (userEmail && userName) {
                    this.setState({
                        userEmail,
                        userName,
                        validating: false
                    });
                }
        })
    }

    onChange = ({target: { name, value }}) => this.setState({[name]: value})

    forgotPassword(){
        const { 
            userName, 
            userEmail,
            newPassword,
            newPasswordConfirmation,
            passwordChangeToken
        } = this.state;
        
        return (
            <Grid2 spaing={2} container sx={ResetPasswordFormStyled}>
                <Grid2 size={16} sx={SubTitleStyled}>
                    <ResetTitle>Olá, {userName}</ResetTitle>
                    <ResetSubtitle>Use os campos abaixo para redefinir sua senha.</ResetSubtitle>
                </Grid2>
                <Grid2 size={16} sx={InputTextStyled}>
                    <ConectorInputText
                        label={"Endereço de e-mail"}
                        value={userEmail}
                        disabled={true}/>
                </Grid2>
                <Grid2 size={16}>
                    <ConectorInputPassword 
                        label={"Senha"}
                        name="newPassword"
                        help="Informe nesse campo sua nova senha"
                        value={newPassword}
                        onChange={this.onChange}
                        endIcon={true}
                        showPasswordStrength={true}/>
                </Grid2>
                <Grid2 size={16}>
                    <ConectorInputPassword 
                        label={"Confirmação de Senha"}
                        name="newPasswordConfirmation"
                        help="Confirme nesse campo sua nova senha"
                        onChange={this.onChange}
                        endIcon={true}
                        value={newPasswordConfirmation}/>
                </Grid2>
                <Grid2 size={16}>
                    <StyledButtonLogin 
                        fullWidth 
                        type="submit" 
                        variant="outlined"
                        disabled={(newPassword == "" || newPasswordConfirmation == "") || (newPassword != newPasswordConfirmation) ? true : false}
                        onClick={() => this.props.changeUserPassword(passwordChangeToken, newPassword)}>
                            {newPassword != newPasswordConfirmation ? 'Senhas não coincidem' : 'Definir senha'}
                    </StyledButtonLogin>
                </Grid2>
            </Grid2>
        )
    }

    expiredPassword(){
        const { 
            userName, 
            userEmail,
            newPassword,
            currentPassword,
            newPasswordConfirmation,
            passwordChangeToken
        } = this.state;

        return (
            <Grid2 spaing={2} container sx={ResetPasswordFormStyled}>
                <Grid2 size={16} sx={SubTitleStyled}>
                    <ResetTitle>Olá, {userName}</ResetTitle>
                    <ResetSubtitle>Sua senha expirou e será necessário criar uma nova, use os campos abaixo para redefinir sua senha.</ResetSubtitle>
                </Grid2>
                <Grid2 size={16}>
                    <ConectorInputText 
                        label={"Endereço de e-mail"}
                        value={userEmail}
                        disabled={true}/>
                </Grid2>
                <Grid2 size={16}>
                    <ConectorInputPassword 
                        label={"Senha atual"}
                        name="currentPassword"
                        help="Informe nesse campo sua senha atual"
                        value={currentPassword}
                        onChange={this.onChange}
                        endIcon={true}
                        showPasswordStrength={true}/>
                </Grid2>
                <Grid2 size={16}>
                    <ConectorInputPassword 
                        label={"Senha"}
                        name="newPassword"
                        help="Informe nesse campo sua nova senha"
                        value={newPassword}
                        onChange={this.onChange}
                        endIcon={true}
                        showPasswordStrength={true}
                    />
                </Grid2>
                <Grid2 size={16}>
                    <ConectorInputPassword 
                        label={"Confirmação de Senha"}
                        name="newPasswordConfirmation"
                        help="Confirme nesse campo sua nova senha"
                        onChange={this.onChange}
                        endIcon={true}
                        value={newPasswordConfirmation}/>
                </Grid2>
                <Grid2 size={16}>
                    <StyledButtonLogin 
                        fullWidth
                        type="submit"
                        variant="outlined"
                        disabled={(newPassword == "" || newPasswordConfirmation == "" || currentPassword == "") || (newPassword != newPasswordConfirmation) ? true : false}
                        onClick={() => this.props.changeUserPassword(passwordChangeToken, newPassword, currentPassword)}>
                            {newPassword != newPasswordConfirmation ? 'Senhas não coincidem' : 'Definir senha'}
                    </StyledButtonLogin>
                </Grid2>
            </Grid2>
        )
    }

    firstLogin(){
        const { 
            userName, 
            userEmail,
            newPassword,
            newPasswordConfirmation,
            passwordChangeToken
        } = this.state;

        return (
            <Grid2 spaing={2} container sx={ResetPasswordFormStyled}>
                <Grid2 size={16} sx={SubTitleStyled}>
                    <ResetTitle>Olá, {userName}</ResetTitle>
                    <ResetSubtitle>Solicitamos que você crie uma nova senha para o seu primeiro login.</ResetSubtitle>
                </Grid2>
                <Grid2 size={16}>
                    <ConectorInputText 
                        label={"Endereço de e-mail"}
                        value={userEmail}
                        disabled={true}
                    />
                </Grid2>
                <Grid2 size={16}>
                    <ConectorInputPassword 
                        label={"Senha"}
                        name="newPassword"
                        help="Informe nesse campo sua nova senha"
                        value={newPassword}
                        onChange={this.onChange}
                        endIcon={true}
                        showPasswordStrength={true}
                    />
                </Grid2>
                <Grid2 size={16}>
                    <ConectorInputPassword 
                        label={"Confirmação de Senha"}
                        name="newPasswordConfirmation"
                        help="Confirme nesse campo sua nova senha"
                        onChange={this.onChange}
                        endIcon={true}
                        value={newPasswordConfirmation}
                    />
                </Grid2>
                <Grid2 size={16}>
                    <StyledButtonLogin 
                        fullWidth
                        type="submit"
                        variant="outlined"
                        disabled={(newPassword == "" || newPasswordConfirmation == "") || (newPassword != newPasswordConfirmation) ? true : false}
                        onClick={() => this.props.changeUserPassword(passwordChangeToken, newPassword)}
                    >{newPassword != newPasswordConfirmation ? 'Senhas não coincidem' : 'Definir senha'}</StyledButtonLogin>
                </Grid2>
            </Grid2>
        )
    }

    render() { 
        const { validating, tokenInLocalStorage } = this.state;

        if (validating) return <Loading/>
        
        if(tokenInLocalStorage) {
            if (localStorage.hasOwnProperty("firstLogin")) return <Fragment>{this.firstLogin()}</Fragment>
            else return <Fragment>{this.expiredPassword()}</Fragment>
        }
        else return <Fragment>{this.forgotPassword()}</Fragment>
    }
}

export default PasswordResetForm;