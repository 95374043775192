import React, { Component } from "react"
import { connect } from 'react-redux'
import './app.css'
import ConectorRoutes from '../../routes/routes'
import i18next from "i18next"
import XHR from 'i18next-xhr-backend'
import Header from '../header/header'
import ToastComponent from '../toast/toast-component'
import ToastPermanent from '../toast/toast-permanent-component'
import { getToast } from '../../store/app-state/reducer'
import { getBreadcrumb } from '../../store/app-state/reducer'
import { getUserLanguage } from '../../store/app-state/reducer'
import * as loginSelector from '../../store/login/reducer'
import * as infosSelectors from '../../store/user-info/reducer'
import { setUserLanguage } from '../../store/app-state/actions'
import { getAllGlobals } from '../../store/globals/actions'
import Session from "../../utils/session-info";
import '@nosferatu500/react-sortable-tree/style.css';
import ErrorBoundary from "../error/ErrorBoundary"
import { BodyDiv, RoutesDiv } from "../../componentsUI/styledComponents/styledApp"
import SideBar from "../side-bar/side-bar"
import Breadcrumb from "../../componentsUI/breadcrumb"

class App extends Component {
  i18next = i18next

  constructor(props) {
    super(props)

    this.state = {
      appState: {
        openDrawer: true,
        userLoggedIn: {},
        drawerTemp: window.innerWidth >= 960 ? "permanent" : "temporary",
      }
    }
    this.handleResize = this.handleResize.bind(this)
  }

  refresh = (newAppState) => this.setState({ ...this.state, appState: { ...newAppState } })

  loadingInfos = _ => {
    if (window.innerWidth <= 960) {
      this.setState({
        appState: {
          openDrawer: false,
          drawerTemp: "temporary"
        }
      })
    }

    if (Session().hasOwnProperty("codUser") || localStorage.getItem("RestoreSession") == "true") {
      this.props.getAllGlobals();
    }
  }

  componentWillMount() {
    this.validateUrlHash();
    this.defineTranslation();

    if (Session().hasOwnProperty("codUser")) {
      this.loadingInfos()
    }
  }

  validateUrlHash() {
    if (window.location.pathname.length > 1) {
      window.location.pathname = '/';
    }
  }

  componentDidMount = _ => {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount = _ => {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize = _ => {
    this.setState({
      ...this.state,
      appState: {
        ...this.state.appState,
        drawerTemp: (window.innerWidth >= 960 ? "permanent" : "temporary")
      }
    })
  }

  defineTranslation() {
    let userLanguage = Session().language || navigator.language;

    this.props.setUserLanguage({ userLanguage });

    let dialect;
    switch (userLanguage) {
      case "en-US":
        dialect = "en";
        break;
      case "pt-BR":
        dialect = "br";
        break;
    }

    this.i18next
      .use(XHR)
      .init({
        dialect,
        backend: {
          loadPath: `/static/lang/${userLanguage}.json`,
        }
      }, _ => { this.dispatchUserLanguage(userLanguage) })
  }

  dispatchUserLanguage(userLanguage) {
    let session = Session();

    if (!session.hasOwnProperty("codUser")) {
      session.language = userLanguage;
      localStorage.setItem("user", JSON.stringify(session));
    }

    this.props.setUserLanguage({ userLanguage, i18next: this.i18next });
    this.setState({ ...this.state });
  }

  loginScreen() {
    const urlAfterHash = window.location.hash.split('#/').pop();
    const path = urlAfterHash.split('/');

    return path[0] === 'login' && path.length <= 2;
  }

  render() {
    const userLoggedIn = Session().hasOwnProperty("codUser");

    const appState = {
      ...this.state.appState,
      userLoggedIn,
      refresh: this.refresh,
      breadcrumb: this.props.breadcrumb,
      toast: this.props.toast
    }

    if (!userLoggedIn && !this.loginScreen()) {
      window.location.replace('#/login')
    }

    if (this.props.userLanguage != Session().language) {
      this.defineTranslation();
    }

    return (
      <BodyDiv>
        <ErrorBoundary>
          <Header appState={appState} user={this.props.user} />
          <Breadcrumb hiddenSidebar={!appState.openDrawer} appState={appState}/>
          {userLoggedIn ? <SideBar appState={appState}/> : ''}
          <RoutesDiv 
            id="pageContent" 
            loggedIn={userLoggedIn}
            hiddenSidebar={!appState.openDrawer}>
            <ConectorRoutes />
          </RoutesDiv>
          {appState.toast.permanent ? (<ToastPermanent options={appState.toast}/>) : (<ToastComponent options={appState.toast}/>)}
        </ErrorBoundary>
      </BodyDiv>
    )
  }
}

const mapStateToProps = (state) => {
  const [user, isLoginPending, isLoginSuccess] = loginSelector.getUserInfo(state);
  const [instanceSelect] = infosSelectors.getInstanceSelected(state);
  const [companySelect] = infosSelectors.getCompanySelected(state);
  const breadcrumb = getBreadcrumb(state);
  const toast = getToast(state);
  const userLanguage = getUserLanguage(state);
  return { breadcrumb, user, isLoginPending, isLoginSuccess, instanceSelect, companySelect, toast, userLanguage }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserLanguage: (userLanguage) => { dispatch(setUserLanguage(userLanguage)) },
    getAllGlobals: () => { dispatch(getAllGlobals()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)