import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setBreadcrumb } from '../../store/app-state/actions'
import Session from '../../utils/session-info'
import './home.css'
import Monitoramento from '../monitoring/monitoring'

class Home extends Component {
    constructor(props) {
        super(props)
        props.setBreadcrumb([
            { label: 'Dashboard de Monitoramento' }
        ]);
        this.state = {}
    }

    getUserName() {
        const session = Session();
        if (session) {
            return `Bem-vindo ao Conector, ${session.desName}`;
        }
    }

    render() { 
        return <Monitoramento/>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

Home = connect(null, mapDispatchToProps)(Home)
export default Home