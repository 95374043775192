import { Grid2 } from "@mui/material";
import styled from "styled-components";

export const StyledGrid = styled(Grid2)`
    && {
        position: relative;

        > div:nth-child(3) {
            width: calc(100% - 24px);
            height: calc(100% - 24px);
            top: 12px;
            position: absolute;
            z-index: 3;
            background-color: #FFF;
        }
    }
`;