import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 165px);
`

export const ContainerSmall = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const LoadingDefault = styled(CircularProgress)`
  && { 
    color: #009CDF;
  }
`