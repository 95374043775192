// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

//import _ from 'lodash';
import * as types from './action-types';
//import Immutable from 'seamless-immutable';

const initialState = {
    globals: [],
    isFetching: false,
    isFetched: false
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
      
    case types.FETCH_GLOBALS:
        return Object.assign({},state,{
            globals: action.globals,
            isFetched: true,
        });
    default:
      return state;
  }
}

// selectors

export function getGlobals(state) {
    const attributes = state.globals.globals.attributes;
    return [attributes];
}
export function getEvents(state) {
    const events = state.globals.globals.events;
    return [events];
}
export function getModes(state) {
    const connectionModes = state.globals.globals.connectionModes;
    return [connectionModes];
}
export function getTypes(state) {
    const connectionTypes = state.globals.globals.connectionTypes;
    return [connectionTypes];
}
export function getDesInterface(state) {
    const desInterface = state.globals.globals.desInterface;
    return [desInterface];
}