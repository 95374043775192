import React, {Component,Fragment} from "react"
import { MdAttachFile } from "react-icons/md"
import Translate from "../i18n/translate"
import {StyledDiv} from "./styledComponents/styledInputFile"
import {
	IconButton,
	InputAdornment
} from "@mui/material"
import StyledTextField from "./styledComponents/styledTextField"
import StyledInputLabel from "./styledComponents/styledInputLabel"

class InputFile extends Component {
	constructor(props) {
		super(props)

		this.state = { 
			...props
		}
	}

	componentWillReceiveProps(props) {
		this.setState({...props})
	}

	handleChange = e => {
		if (this.state.onChange) {
			this.state.onChange(e)
		}
	}

	handleBlur = e => {
		if (this.state.onBlur) {
			this.state.onBlur(e)
		}
	}

	render() {
		const props = { ...this.state }

		if (props.invisible) {
			return <Fragment></Fragment>
		}

		var labelClass = props.classNameLabel;
		if (props.required) {
			labelClass += " required";
		}

		return (
			<StyledDiv className={props.classNameForm}>
				<StyledInputLabel className={labelClass}>{Translate(props.label)}</StyledInputLabel>
				<StyledTextField id={props.id} helperText={props.help} name={props.name} onChange={this.handleChange.bind(this)} onBlur={this.handleBlur.bind(this)}
					autoComplete="false" required={props.required} type="file" variant="outlined" disabled={!!props.disabled ? props.disabled : false}
					value={props.value} className={props.classNameInput} inputProps={{accept: props.accept, id: props.id}} InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton>
									<label htmlFor={props.id}>
										<MdAttachFile />
									</label>
								</IconButton>
							</InputAdornment>
						)
					}} />
			</StyledDiv>
		)
	}

}

export default InputFile;