import request from '../../utils/request'

export default {
    GetAllInstance: () => {
        return request.executeRequest(
            `${request.baseUrl}instances/get-all`,
            request.getOptions()
        ).then(res => res.json())
    },UpdateInstanceBlock: (Id,Block,Name,IdName) => {
        return request.executeRequest(
            `${request.baseUrl}instances/updateInstance/${Id}/${Block}/${Name}/${IdName}}`,
            request.putOptions()
        ).then(res => res.json())
    },UpdateCompanyBlock: (Id,Block,Name,IdName) => {
        return request.executeRequest(
            `${request.baseUrl}instances/updateCompany/${Id}/${Block}/${Name}/${IdName}}`,
            request.putOptions()
        ).then(res => res.json())
    }
}