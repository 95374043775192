import request from '../../utils/request';
import Session from '../../utils/session-info'

export default {
    GetAttributesByCodConnectionMode: codConnectionMode => {
        return request.executeRequest(
            `${request.baseUrl}connections/connectionmodeattributes/getattributesbycod/${codConnectionMode}`,
            request.getOptions()
        ).then(res => res.json());
    },
    PostConnection: connection => {
        return request.executeRequest(
            `${request.baseUrl}connections`,
            {
                ...request.postOptions(),
                body: JSON.stringify(connection)
            }
        )
    },
    PutConnection: connection => {
        return request.executeRequest(
            request.baseUrl + 'connections',
            {
                ...request.putOptions(),
                body: JSON.stringify(connection)
            }
        );
    },
    GetConnectionTypes: () => {
        return request.executeRequest(
            `${request.baseUrl}globals/connectiontypes/getconnectiontypes/`,
            request.getOptions()
        )
            .then(res => res.json());
    },
    DeleteConnection: connectionIds => {
        return request.executeRequest(
            `${request.baseUrl}connections/removeconnection`,
            {
                ...request.deleteOptions(),
                body: JSON.stringify(connectionIds)
            }
        ).then(res => res.json());
    },
    GetConnections: () => {
        return request.executeRequest(
            `${request.baseUrl}connections/instances/${Session().codInstance}/connections`,
            request.getOptions()
        ).then(res => res.json());
    },
    GetConnection: id => {
        return request.executeRequest(
            `${request.baseUrl}connections/get/${id}`,
            request.getOptions()
        ).then(res => res.json());
    },
    GetUserPermissionConnection: () => {
        return request.executeRequest(
            `${request.baseUrl}connections/userPermission`,
            request.getOptions()
        ).then((res) => {
            if (!res.ok) {
                return false;
            }
            return true;
        }).catch((error) => {
            console.error(error);
        });
    },
    GetAttributeTypes: () => {
        return request.executeRequest(
            `${request.baseUrl}globals/getattributetypes`,
            request.getOptions()
        ).then(res => res.json())
    },
    GetConnectionsByInstanceAndConnectionMode: (codInstance, codConnectionMode) => {
        return request.executeRequest(
            `${request.baseUrl}connections/${codInstance}/${codConnectionMode}`,
            request.getOptions()
        ).then(res => res.json());
    },
    GetInstancesWithConnectionPermission: () => {
        return request.executeRequest(
            `${request.baseUrl}connections/get/instances/connectionPermission`,
            request.getOptions()            
        ).then(res => res.json());
    }
}