import React, {
	Component,
	Fragment
} from "react"
import { connect } from "react-redux"
import UserService from "./user-service"
import Translate from "../../i18n/translate"
import { setBreadcrumb } from "../../store/app-state/actions"
import validator from 'validator'
import Toast from "../../components/toast/toast"
import { Grid2 } from "@mui/material"
import ConectorSelect from "../../componentsUI/conectorSelect"
import ConectorInputText from "../../componentsUI/inputText"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import ConectorInputPassword from "../../componentsUI/inputPassword"
import CancelSaveButtons from "../../componentsUI/cancelSaveButtons"

class UserDetails extends Component {
	constructor(props) {
		super(props);

		props.setBreadcrumb([{
			label: "registrations"
		}, {
			label: "users",
			url: "#/users"
		}, {
			label: "add"
		}]);

		this.state = {
			user: {
				codUser: 0,
				desName: "Administrador",
				desEmail: "",
				desPassword: "",
				language: "pt-BR"
			},
			validations: [],
			languages: [{
				value: "en-US",
				label: "Inglês"
			}, {
				value: "pt-BR",
				label: "Português"
			}]
		};
	}

	handleChange = event => {
		var {
			name,
			value
		} = event.target;

		this.setUserValue(name, value);
	}

	handleChangeSelect = ({name, value}) => {
		this.setUserValue(name, value);
	}

	setUserValue(property, value) {
		var {
			user,
			validations
		} = this.state;

		user[property] = value;

        this.setState({
            user,
            validations: validations.filter(validation => validation !== property)
        });
	}

	goBack() {
		window.location.replace('#/users')
	}

	validate = (password) => {
		var allowedToSave = false
  
		if (validator.isStrongPassword(password, {
		  minLength: 8, minLowercase: 1,
		  minUppercase: 1, minNumbers: 1, minSymbols: 1
		})) {
		  allowedToSave = true
		}

		return allowedToSave
	  }

	save() {
		var {
			user,
			validations
		} = this.state;

		if (!user.desName) {
			validations.push("desName")
		}

		var validadorEmail = /\S+@\S+\.\S+/;
		if (!validadorEmail.test(user.desEmail)) {
			Toast.error("invalid_address")
			validations.push("desEmail")
		}

		if (!user.desPassword) {
			validations.push("desPassword")
		}

		if(!this.validate(user.desPassword)){
			Toast.error("password_not_strong")
			validations.push("desPassword")
		}
		
		if (validations.length) {
			this.setState({ validations });
			return;
		}
		UserService.Save(user).then((response) => {
			if(this.validate(user.desPassword)){
				if (response.ok) {
					Toast.success("record_success_saved");
	
					setTimeout(this.goBack)
				} else if(response.status == 409){
					Toast.error("email_registered");
				} 
				else {
					Toast.error("error_saving_record");
				}
			} else {
				Toast.error("password_not_strong");
			}
		});
	}

	render() {
		const {
			user,
			languages,
			validations
		} = this.state;

		return (
			<Fragment>
				<InternalPageTitle title={"new_user"} />
				<StyledPaper>
					<Grid2 container spacing={1}>
						<Grid2 size={4}>
							<ConectorInputText label={"description"} help={"enter_description"} name={"desName"}
								value={user.desName} onChange={this.handleChange.bind(this)}
								required={true} errorCondition={Boolean(validations.includes("desName"))} />
						</Grid2>
						<Grid2 size={4}>
							<ConectorInputText label={"email"} help={"enter_email"} name={"desEmail"}
								value={user.desEmail} onChange={this.handleChange.bind(this)}
								required={true} errorCondition={Boolean(validations.includes("desEmail"))} />
						</Grid2>
						<Grid2 size={4}>
							<ConectorInputPassword label={Translate("password")} help={"password_must_include"} name={"desPassword"}
								value={user.desPassword} onChange={this.handleChange.bind(this)} 
								required={true} errorCondition={Boolean(validations.includes("desPassword"))} />
						</Grid2>
						<Grid2 size={4}>
							<ConectorSelect label={Translate("language")} name={"language"} options={languages}
                                onChange={this.handleChangeSelect.bind(this)}
                                value={languages.find(language => language.value == user.language)} />
						</Grid2>
					</Grid2>
					<Grid2 container justifyContent="flex-end">
                        <CancelSaveButtons onCancel={this.goBack} onSave={this.save.bind(this)} />
                    </Grid2>
				</StyledPaper>
			</Fragment>
		)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(null, mapDispatchToProps)(UserDetails)