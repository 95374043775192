import request from '../../utils/request'
import Session from '../../utils/session-info';
import UserService from "../../routes/users/user-service";

export default {
    GetAllByAttempts: async () => {
        if (await UserService.FindPrivilege(Session().codUser,1)) {
            return request.executeRequest(
                `${request.baseUrl}attempts/attempts_old`,
                request.getOptions()
            ).then(res => res.json())
        }
    }
}
