import React, {
    Component,
    Fragment
} from "react"
import { connect } from "react-redux"
import Translate from '../../i18n/translate'
import Toast from "../../components/toast/toast";
import { setBreadcrumb } from "../../store/app-state/actions"
import * as appStateReducer from "../../store/app-state/reducer"
import { CSVLink } from "react-csv"
import InstancesService from "./instances-service"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import Loading from "../../componentsUI/loading"
import ConectorTable from "../../componentsUI/conectorTable"
import StyledButton from "../../componentsUI/styledComponents/styledButton"

class Instance extends Component {
	constructor(props) {
        super(props);

		props.setBreadcrumb([{
			label: "registrations"
		}, {
			label: "instances"
		}]);

		this.state = {
			getInstancesServiceRequestLoading: true,
			gridOptions: {
				hideBtnDelete: true,
                hideBtnCopy: true,
				multiSelect: false,
				sortField: "description",
				columns: [{
                    title: "description",
                    field: "description"
                }, {
					title: "Id",
					field: "id"
				},{
                    title: "quantity_threads",
                    field: "indMaxThreads",
                    size: 100
                }, {
                    title: "hash",
                    field: "hash",
                    copy: true
                }, {
                    title: "token",
                    field: "instanceToken",
                    copy: true
                }]
			},
			InstancesServiceRequestCompleted: false
		}
	}

	componentDidMount() {
		this.getData();
	}

	async componentDidMount() {
        await this.getData();
        await this.updateButtonVisibility();
    }

	async updateButtonVisibility() {
        try {
            const permissions = await InstancesService.GetUserPermissionsInstance();
    
            const { gridOptions } = this.state;
            const updatedGridOptions = {
                ...gridOptions,
                hideBtnNew: !permissions.addPermission,
                hideBtnEdit: !permissions.editPermission,
            };
    
            this.setState({
                gridOptions: updatedGridOptions,
                InstancesServiceRequestCompleted: true,
            });
        } catch (error) {
            Toast.error(Translate("notHavePermissionToAccess"));
            setTimeout(() => {
                window.location.href = '/';
            }, 2000);
        }
    }

	getData() {
		InstancesService.GetInstances()
        .then(instances => {if(instances != undefined) return instances})
        .then(instances => this.setState({dataSource: instances, getInstancesServiceRequestLoading: false}))
	}

	gridNewOrEditItem(row = "add") {
		if (row instanceof Object) {
			row = row.id;
		}

		window.location.replace(`#/instances/${row}`)
	}

	render() {
		const {
            getInstancesServiceRequestLoading,
            dataSource,
            gridOptions 
        } = this.state

        if(getInstancesServiceRequestLoading) return <Loading />
        else {
            const csvData = dataSource.map(item => ({
                Description: item.description,
                ID: item.id,
                IndMaxThreads: item.indMaxThreads,
                Hash: item.hash,
                InstanceToken: item.instanceToken,
              }));
    
            return (
                <Fragment>
                    <InternalPageTitle title={Translate("instance_list")} />
                    <StyledPaper>
                            <CSVLink data={csvData} filename={"instanceList.csv"} style={{ textDecoration: 'none' }}>
                                <StyledButton
                                variant="outlined"
                                style={gridOptions.hideBtnNew ? {marginTop:'35px'} : {top:'35px'}}>
                                    {Translate("download_list")}
                                </StyledButton>
                            </CSVLink>
                        <ConectorTable
                            gridBtn={"new_instance"}
                            gridNewItem={this.gridNewOrEditItem}
                            gridEditItem={this.gridNewOrEditItem} 
                            gridOptions={gridOptions} 
                            dataSource={dataSource}/>
                    </StyledPaper>
                </Fragment>
            )
        }
	}
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Instance)