import React, { Component } from "react"
import { Scrollbars } from "@opensumi/react-custom-scrollbars-2"
import { connect } from "react-redux"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { MdDelete, MdEdit, MdFileCopy } from "react-icons/md"
import * as wizardStore from "../../../../store/wizard/wizard-store-reducer"
import * as interfaceSelector from "../../../../store/interfaces/reducer"
import { setInterfaceEvents } from "../../../../store/interfaces/actions"
import * as globalSelector from "../../../../store/globals/reducer"
import InterfacesEventsService from "../interfaces-events-service"
import {
    TimelineTitle,
    TimelineDiv,
    TimelineCard,
    TimelineIcon
} from "../../../../componentsUI/styledComponents/styledEventsTimeline" 
import Translate from "../../../../i18n/translate"
import {Grid2, IconButton} from "@mui/material"

class EventCard extends Component {
    constructor(props) {
        super(props);

        const {
            events,
            connectionActions,
            connectionModes,
            interfaceEvents,
            banksList,
            bankAccounts
        } = this.props;

        this.state = {
            events,
            interfaceEvents,
            connectionActions,
            connectionModes,
            banksList,
            bankAccounts
        };

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd({ source, destination }) {
        let eventsOrder = {};
        let sourceIndex = source.index;
        let destIndex = (destination || {}).index;

        if (!destination || destIndex === sourceIndex) {
            return;
        }

        let { interfaceEvents } = this.props;

        interfaceEvents[sourceIndex].indPosition = (destIndex + 1);
        eventsOrder[interfaceEvents[sourceIndex].codInterfaceEvent] = (destIndex + 1);

        if (sourceIndex < destIndex) {
            for (let d = destIndex; d > sourceIndex; d--) {
                interfaceEvents[d].indPosition = d;
                eventsOrder[interfaceEvents[d].codInterfaceEvent] = d;
            }
        } else {
            for (let s = sourceIndex; s > destIndex; s--) {
                interfaceEvents[s - 1].indPosition = (s + 1);
                eventsOrder[interfaceEvents[s - 1].codInterfaceEvent] = (s + 1);
            }
        }

        interfaceEvents = interfaceEvents.sort((item1, item2) => item1.indPosition - item2.indPosition);

        this.props.setInterfaceEvents(interfaceEvents);
        InterfacesEventsService.UpdateEventsOrder(eventsOrder);
    }

    componentWillReceiveProps = ({
        connectionActions = [],
        interfaceEvents = [],
        events = [],
        connectionModes = [],
        banksList,
        bankAccounts,
        codInterface
    }) => {
        this.setState({
            interfaceEvents,
            events,
            codInterface,
            connectionModes,
            connectionActions,
            banksList,
            bankAccounts
        });
    }

    generateEventNameTitle(event){
        const { bankAccounts, banksList } = this.state
        let eventName = undefined

        switch (event.codEvent) {
            case 1: eventName = "connection"
            break;

            case 6:
                let bankStructures = event.structures[0].bankStructures

                if(bankStructures != undefined){
                    let idBankAccountFromStructure = bankStructures.idBankAccount
                    let codBankFromAccount = bankAccounts.filter(account => account.id_account == idBankAccountFromStructure)[0].cod_bank
                    eventName = `${Translate('bank')} ${banksList.filter(bank => bank.cod_bank == codBankFromAccount)[0].trade_name}`
                } else return
            break;

            default: eventName = "modifier"
            break;
        }

        return `${Translate(eventName)}: `
    }

    generateBankAccountStringLabel(idAccount){
        const { bankAccounts } = this.state;
        let inputAccountValue = ""
        const bankAccount = bankAccounts.filter(account => account.id_account == idAccount)[0]

        if(bankAccount == undefined) return ""

        if(bankAccount.account_digit == undefined || bankAccount.account_digit == '')
            inputAccountValue = `C/C ${bankAccount.account}`
        else inputAccountValue = `C/C ${bankAccount.account} - ${bankAccount.account_digit}`

        if(bankAccount.bank_branch_digit == undefined || bankAccount.bank_branch_digit == '')
            inputAccountValue += ` AG ${bankAccount.bank_branch}`
        else inputAccountValue += ` AG ${bankAccount.bank_branch} - ${bankAccount.bank_branch_digit}`

        return inputAccountValue
    }

    generateLabel(event){
        const {
            connectionModes,
            connectionActions,
        } = this.state;

        const structure = event.structures[0];

        const generateBankAccountStringLabel = IdBankAccount => {
            return this.generateBankAccountStringLabel(IdBankAccount);
        }

        if (event.codEvent === 6) {
            const bankStructures = event.structures[0].bankStructures
            const IdBankAccount = bankStructures ? bankStructures.idBankAccount : undefined
            const bankAccountLabel = generateBankAccountStringLabel(IdBankAccount)
                                        
            return bankAccountLabel
        }

        if (
            !event.structures ||
            !event.structures.length ||
            !connectionActions.length ||
            !connectionModes.length ||
            event.codEvent == 6
        ) return ""

        if (!structure.codConnectionAction) return `${structure.label}`

        const connectionAction = connectionActions.find(
            connAction => connAction.id === structure.codConnectionAction
        ).description;

        if (!structure.codConnectionMode) return `${connectionAction}`
        else {
            const connectionMode = connectionModes.find(
                connMode => connMode.codConnectionMode === structure.codConnectionMode
            ).label;
            
            return `${connectionMode} - ${connectionAction}`
        }
    }

    renderEventCards() {
        const { interfaceEvents } = this.state;
    
        if (!interfaceEvents.length) return null;
    
        const generateStructureLabel = ({ structures }, property) => {
            let label;
    
            if (structures && structures.length) {
                structures.forEach(({ structureDetails }) => {
                    if (structureDetails && structureDetails.length) {
                        structureDetails.forEach(detail => {
                            if (detail[property]) {
                                label = detail[property];
                            }
                        });
                    }
                });
            }
    
            return label;
        }
    
        return interfaceEvents.map((event, index) => (
            <Draggable
                key={event.indPosition}
                draggableId={event.indPosition.toString()}
                index={index}>
                {(provided) => (
                    <TimelineIcon>
                        <TimelineCard
                            className="success"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>

                            <Grid2 container size={12}>    
                                <Grid2 size={5} justifyContent="flex-end"><h3>{event.desEventName}</h3></Grid2>
                                <Grid2 size={6} justifyContent="flex-start">
                                    <Grid2 size={12}>
                                        <h6>{this.generateEventNameTitle(event)}{this.generateLabel(event)}</h6>
                                    </Grid2>
                                    {(() => {
                                        const inputLabel = generateStructureLabel(event, "input");
                                            
                                        return inputLabel ? (
                                            <Grid2 size={10}>
                                                <h6>{Translate("input")}: {inputLabel}</h6>
                                            </Grid2>
                                        ) : null;
                                    })()}
                                    {(() => {
                                        const outputLabel = generateStructureLabel(event, "output")

                                        return outputLabel ? (
                                            <Grid2 size={10}>
                                                <h6>{Translate("output")}: {outputLabel}</h6>
                                            </Grid2>
                                        ) : null;
                                    })()}
                                    {event.responsibleEmail ? (
                                        <Grid2 size={10}>
                                            <h6>{Translate("responsible")}: {event.responsibleEmail}</h6>
                                        </Grid2>
                                    ) : null}
                                </Grid2>
                            </Grid2>
                            <Grid2>
                                <Grid2>
                                    <IconButton onClick={() => { this.props.onClickEdit(event) }}>
                                        <MdEdit size={18}/>
                                    </IconButton>
                                    <IconButton onClick={() => { this.props.onClickDelete(event) }}>
                                        <MdDelete size={18}/>
                                    </IconButton>
                                    <IconButton onClick={() => { this.props.onClickCopy(event) }}>
                                        <MdFileCopy size={18}/>
                                    </IconButton>
                                </Grid2>
                            </Grid2>
                        </TimelineCard>
                    </TimelineIcon>
                )}
            </Draggable>
        ));
    }
    
    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div 
                            {...provided.droppableProps} 
                            ref={provided.innerRef}>
                            <TimelineTitle>
                                {Translate("beginning")}
                            </TimelineTitle>
                            <TimelineDiv>
                                <Scrollbars>
                                    {this.renderEventCards()}
                                    {provided.placeholder}
                                </Scrollbars>
                            </TimelineDiv>
                            <TimelineTitle>{Translate("end")}</TimelineTitle>                 
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}

const mapStateToProps = state => {
    const [interfaceEvents] = interfaceSelector.getEvents(state),
        [connectionModes] = globalSelector.getModes(state),
        [events] = globalSelector.getEvents(state),
        connectionActions = wizardStore.getConnectionActions(state);
    return {
        interfaceEvents,
        events,
        connectionModes,
        connectionActions
    };
}

const dispatchToProps = dispatch => {
    return {
        setInterfaceEvents: interfaceEvents => { dispatch(setInterfaceEvents(interfaceEvents)) }
    }
}

EventCard = connect(mapStateToProps, dispatchToProps)(EventCard);
export default EventCard;
