import * as types from './action-types';

const initialState = {
    isFetching: false,
    isFetched: false,
    details: [],
    detailsdeletadas: [],
    detailsInput: [],
    detailsIN: [],
    detailsOUT: []
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.FETCH_DETAILS_IN:
            return Object.assign({}, state, {
                detailsIN: action.details,
                isFetched: true,
            });
        default:
            return state;
    }
}

export function getDetailInInfo(state) {
    const details = state.details.detailsIN;
    return details;
}

export function getDetailOutInfo(state) {
    const details = state.details.detailsOUT;
    return details;
}