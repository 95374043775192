import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Scrollbars } from "@opensumi/react-custom-scrollbars-2";

import request from "../../utils/request";
import Session from "../../utils/session-info";
import ObjectUtils from "../../utils/object-utils";
import Translate from "../../i18n/translate";
import Toast from "../../components/toast/toast";
import ProfileService from "./profiles-service";
import ProfileToolbar from "./profiles-grid-toolbar";
import { setBreadcrumb } from "../../store/app-state/actions";
import * as appStateReducer from "../../store/app-state/reducer";
import UserService from "../../routes/users/user-service";
import { Grid2, Radio, Checkbox } from "@mui/material";
import Loading from "../../componentsUI/loading"
import ConectorInputText from "../../componentsUI/inputText"
import ConectorTable from "../../componentsUI/conectorTable"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import ConectorDialogConfirmation from "../../componentsUI/dialogComponentConfirmation"
import { CustomGridContainer, CustomPanelContainer, Title, AdminPermission, PermissionLists } from "../../componentsUI/styledComponents/styledProfiles"

class Profile extends Component {
    constructor(props) {
        super(props);

        props.setBreadcrumb([{
            label: "registrations"
        }, {
            label: "profilessecurity"
        }]);

        this.state = {
            ...props,
            isReady: false,
            profilesGridOptions: {
                sortable: true,
                multiSelect: false,
                hideBtnEdit: true,
                hideBtnCopy: true,
                height: 724,
                columns: [{
                    title: Translate("description"),
                    field: "description"
                }]
            },
            profileServiceRequestCompleted: false,
            allowedUser: false
        }
    }

    componentWillMount = _ => {
        this.createGrid(true);
        this.createFunctionsTree();
    }

    async componentDidMount() {
        await this.updateButtonVisibility();
    }

	async updateButtonVisibility() {
        ProfileService.GetUserPermissionProfile()  
            .then(res => {
                const { gridOptions } = this.state;
                const updatedGridOptions = {
                    ...gridOptions,
                    hideBtnDelete: !res
                };

                this.setState({
                    allowedUser: res,
                    gridOptions: updatedGridOptions,
                    profileServiceRequestCompleted: true,
                });
            })
            .catch(error => {
                Toast.error(Translate(error.message));
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
            });
    }

    async createFunctionsTree() {
        try {
            const funcoes = await ProfileService.GetFunctions();
            const allFunctions = JSON.parse(JSON.stringify(funcoes));
    
            let systemFunctions = funcoes
                .filter(item => item.codSuper === 0 && item.indPosition > 0)
                .map(item => {
                    return {
                        codFunction: item.codFunction,
                        desc: Translate(item.desFunction),
                        codSuper: item.codSuper,
                        indPosition: item.indPosition
                    };
                });
    
            const accessAdm = await UserService.FindPrivilege(Session().codUser, 1);
            if (!accessAdm) {
                systemFunctions = systemFunctions.filter(
                    item => item.codFunction !== 22 && item.codFunction !== 21
                );
            }
    
            funcoes
                .filter(item => item.codSuper !== 0 && item.indPosition > 0)
                .map(item => {
                    let child = {
                        codSuper: item.codSuper,
                        codfunc: item.codFunction,
                        desc: Translate(item.desFunction),
                        indPosition: item.indPosition
                    };
    
                    systemFunctions.forEach(menuItem => {
                        if (menuItem.codFunction === child.codSuper) {
                            if (menuItem.children) {
                                menuItem.children.push(child);
                            } else {
                                menuItem.children = [child];
                            }
                        }
                    });
                });
    
            this.setState({ systemFunctions, allFunctions });
        } catch (error) {
            console.error("Erro ao criar a árvore de funções:", error);
        }
    }

    createGrid = firstRender => {
        ProfileService.GetProfiles().then(profilesDataSource => {
            this.setState({
                profilesDataSource,
                isReady: true
            }, _ => {
                this.updateSelectedProfile(firstRender ? this.state.profilesDataSource[0] : {});
            })
        });
    }

    deleteItem = row => {
        if (!row) {
            return;
        }

        this.setState({
            dialogOptions: {
                type: "danger",
                open: true,
                message: Translate("you_want_remove") + ` ${row.description}?`,
                title: Translate("exclusion_confirmation"),
                onClose: _ => {
                    request.executeRequest(request.baseUrl + "profilessecurity/deleteProfileAndPermisssions?codProfile=" + row.codProfile, {
                        ...request.deleteOptions()
                    }).then(onSuccess.bind(this));

                    function onSuccess(res) {
                        var self = this;

                        res.json().then(function (data) {
                            var msgFromDB = data ? data.msg : null;

                            if (res.ok) {
                                self.setState({
                                    dialogOptions: {
                                        open: false
                                    }
                                });

                                Toast.success(msgFromDB || Translate("records_success_removed"));

                                self.createGrid();
                            } else {
                                self.setState({
                                    dialogOptions: {
                                        open: false
                                    }
                                });

                                Toast.error(msgFromDB || Translate("error_remove_records"));

                                self.createGrid();
                            }
                        });
                    };
                }
            }
        });
    }

    updateSelectedProfile = newSelectedProfile => {
        let { profilesGridOptions } = this.state;

        if (!newSelectedProfile.description) {
            newSelectedProfile.description = Translate("new_profile");
        }

        let originalSelectedProfile = { ...newSelectedProfile };
        let selectedProfile = { ...newSelectedProfile };
        let dialogOptions = { open: false };

        profilesGridOptions.selectedRow = { ...newSelectedProfile };

        ProfileService.GetProfileFunctions(newSelectedProfile.codProfile).then((functions => {
            const profileFunctions = functions.map(func => func.codFunction);

            let { allFunctions } = this.state;
            const missingFunctions = allFunctions.filter(systemFunction =>
                !profileFunctions.includes(systemFunction.codFunction)
            );

            missingFunctions.forEach(missingFunction => {
                functions.push({
                    codAccessLevel: 3,
                    codFunction: missingFunction.codFunction,
                    codInstance: newSelectedProfile.codInstance,
                    CodProfile: newSelectedProfile.codProfile || ""
                })
            })

            selectedProfile.functions = ObjectUtils.deepCopy(functions);
            originalSelectedProfile.functions = ObjectUtils.deepCopy(functions);

            this.setState({
                originalSelectedProfile,
                selectedProfile,
                dialogOptions,
                profilesGridOptions
            });
        }).bind(this))
    }

    differentProfileSelected(newSelectedProfile) {
        return this.state.selectedProfile && (newSelectedProfile.codProfile !== this.state.selectedProfile.codProfile);
    }

    getSelectUpdate = newSelectedProfile => {
        if (this.changedProfile()) {
            this.setState({
                dialogOptions: {
                    type: "danger",
                    open: true,
                    message: Translate("selec_new_profile"),
                    title: Translate("attention") + "!",
                    onClose: _ => { this.updateSelectedProfile(newSelectedProfile) },
                    onDismiss: (function () {
                        this.setState({
                            dialogOptions: {
                                open: false
                            }
                        })
                    }).bind(this)
                }
            });
        } else {
            this.updateSelectedProfile(newSelectedProfile);
        }
    }

    handleChange = e => {
        let { selectedProfile } = this.state;
        selectedProfile[e.target.name] = e.target.value;

        this.setState({ selectedProfile });
    }

    changedProfile = _ => {
        const { selectedProfile, originalSelectedProfile } = this.state;

        return !ObjectUtils.equals(selectedProfile, originalSelectedProfile);
    }

    cancelChanges = _ => {
        if (this.changedProfile()) {
            this.setState({
                dialogOptions: {
                    type: "danger",
                    open: true,
                    message: Translate("cancel_changes"),
                    title: Translate("attention"),
                    onClose: _ => {
                        this.setState({
                            selectedProfile: ObjectUtils.deepCopy(this.state.originalSelectedProfile),
                            dialogOptions: {
                                open: false
                            }
                        })
                    }
                }
            });
        }
    }

    save = _ => {
        const {
            originalSelectedProfile,
            selectedProfile
        } = this.state

        if (!this.changedProfile(originalSelectedProfile, selectedProfile)) {
            return;
        }

        const codInstance = Session().codInstance;
        selectedProfile.codCompany = Session().codCompany;
        selectedProfile.codInstance = codInstance;

        if (selectedProfile.codProfile) {
            ProfileService.PutProfile(selectedProfile).then(_ => {
                selectedProfile.functions.forEach(func => {
                    func.CodProfile = selectedProfile.codProfile
                    func.CodInstance = codInstance;
                });

                ProfileService.SavePermissions(selectedProfile.codProfile, selectedProfile.functions).then(_ => {
                    Toast.success(Translate("profile_success_updated"));

                    this.setState({
                        originalSelectedProfile: ObjectUtils.deepCopy(selectedProfile)
                    });
                });
            }).catch(_ => {
                Toast.error(Translate("error_saving_profile"));
            });
        } else {
            ProfileService.PostProfile(selectedProfile).then((res => {
                Toast.success(Translate("record_success_saved"));

                selectedProfile.functions.forEach(func => {
                    func.CodProfile = res
                    func.CodInstance = codInstance;
                });

                ProfileService.SavePermissions(res, selectedProfile.functions).then(this.createGrid);
            }).bind(this)).catch(_ => {
                Toast.error(Translate("error_saving_profile"))
            });
        }
    }

    handleCheckboxChange = e => {
        let { selectedProfile, systemFunctions } = { ...this.state };
        const selectedFunctionCod = parseInt(e.target.name, 10);

        let selectedFunction = { ...systemFunctions.find(f => f.codFunction === selectedFunctionCod) };

        //nível root
        if (selectedFunction.codSuper === 0) {
            if (selectedFunction.children) {
                let funcChildren = selectedFunction.children.map(f => f.codfunc);

                //selecionado
                if (selectedProfile.functions.find(selectedFunc =>
                    selectedFunc.codFunction === selectedFunctionCod
                    && selectedFunc.codAccessLevel !== 3)
                ) {
                    selectedProfile.functions.forEach(selectedFunc => {
                        if (funcChildren.includes(selectedFunc.codFunction)
                            || selectedFunc.codFunction === selectedFunctionCod) {
                            selectedFunc.codAccessLevel = 3;
                        }
                    });
                } else {
                    //não selecionado
                    let parentLevel = selectedProfile.functions.find(profileFunction => profileFunction.codFunction === selectedFunctionCod);

                    if (parentLevel) {
                        parentLevel.codAccessLevel = 1;
                    } else {
                        selectedProfile.functions.push({
                            CodProfile: selectedProfile.codProfile || 0,
                            codAccessLevel: 1,
                            codFunction: selectedFunctionCod,
                            codInstance: selectedProfile.codInstance
                        });
                    }

                    if (funcChildren.length) {
                        funcChildren.forEach(childCod => {
                            const child = selectedProfile.functions.find(profileFunction => childCod === profileFunction.codFunction);

                            if (child) {
                                child.codAccessLevel = 1;
                            } else {
                                selectedProfile.functions.push({
                                    CodProfile: selectedProfile.codProfile || 0,
                                    codAccessLevel: 1,
                                    codFunction: childCod,
                                    codInstance: selectedProfile.codInstance
                                });
                            }
                        })
                    }
                }
            } else {
                let selectedFunc = selectedProfile.functions.find(profileFunc => profileFunc.codFunction === selectedFunctionCod);

                let newAccessLevel;
                if (!selectedFunc || selectedFunc.codAccessLevel === 3) {
                    newAccessLevel = 1;
                } else {
                    newAccessLevel = 3;
                }

                if (selectedFunc) {
                    selectedFunc.codAccessLevel = newAccessLevel;
                } else {
                    selectedProfile.functions.push({
                        CodProfile: selectedProfile.codProfile || 0,
                        codAccessLevel: newAccessLevel,
                        codFunction: selectedFunctionCod,
                        codInstance: selectedProfile.codInstance
                    });
                }
            }
        } else {
            //se tiver siblings, mudar apenas nível, else nível e pai
            let codSiblings, codParent;

            for (let index = 0; index < systemFunctions.length; index++) {
                const parentLevel = systemFunctions[index];

                if (parentLevel.children) {
                    let selectedFunc = parentLevel.children.find(child => child.codfunc === selectedFunctionCod);
                    if (selectedFunc) {
                        codParent = parentLevel.codFunction;
                        codSiblings = parentLevel.children
                            .filter(sibling => sibling.codfunc !== selectedFunctionCod)
                            .map(sibling => sibling.codfunc);
                        break;
                    }
                }
            }

            let selectedFunc = selectedProfile.functions.find(profileFunc =>
                profileFunc.codFunction === selectedFunctionCod
            )

            let selectedFuncParent = selectedProfile.functions.find(profileFunc =>
                profileFunc.codFunction === codParent
            )

            let newAccessLevel;
            if (!selectedFunc || selectedFunc.codAccessLevel === 3) {
                newAccessLevel = 1;
            } else {
                newAccessLevel = 3;
            }

            //tem sibling
            if (codSiblings.length > 0) {
                //verificar se existe sibling selecionado;
                if (selectedProfile.functions.find(selectedFunc => codSiblings.includes(selectedFunc.codFunction) && selectedFunc.codAccessLevel !== 3)) {
                    //possui - verificar se existe
                    if (selectedFunc) {
                        selectedFunc.codAccessLevel = newAccessLevel;
                    } else {
                        selectedProfile.functions.push({
                            CodProfile: selectedProfile.codProfile || 0,
                            codAccessLevel: newAccessLevel,
                            codFunction: selectedFunctionCod,
                            codInstance: selectedProfile.codInstance
                        });
                    }
                } else {
                    //não possui
                    if (selectedFunc) {
                        selectedFunc.codAccessLevel = newAccessLevel;
                        selectedFuncParent.codAccessLevel = newAccessLevel;
                    } else {
                        //adicionar pai e filho
                        selectedProfile.functions.push({
                            CodProfile: selectedProfile.codProfile || 0,
                            codAccessLevel: newAccessLevel,
                            codFunction: selectedFunctionCod,
                            codInstance: selectedProfile.codInstance
                        });
                        selectedProfile.functions.push({
                            CodProfile: selectedProfile.codProfile || 0,
                            codAccessLevel: newAccessLevel,
                            codFunction: codParent,
                            codInstance: selectedProfile.codInstance
                        });
                    }
                }
            } else {
                // não possui 
                if (selectedFunc) {
                    selectedFunc.codAccessLevel = newAccessLevel;
                    selectedFuncParent.codAccessLevel = newAccessLevel;
                } else {
                    selectedProfile.functions.push({
                        CodProfile: selectedProfile.codProfile || 0,
                        codAccessLevel: newAccessLevel,
                        codFunction: selectedFunctionCod,
                        codInstance: selectedProfile.codInstance
                    });
                    selectedProfile.functions.push({
                        CodProfile: selectedProfile.codProfile || 0,
                        codAccessLevel: newAccessLevel,
                        codFunction: codParent,
                        codInstance: selectedProfile.codInstance
                    });
                }
            }
        }

        this.setState({
            selectedProfile,
            dialogOptions: {
                open: false
            }
        });
    }

    handleRadioChange = e => {
        let {
            selectedProfile,
            systemFunctions
        } = { ...this.state };

        const newFn = {
            permissionCod: parseInt(e.target.name),
            newAccessLevel: parseInt(e.target.value)
        }

        let codFunctionParent;

        for (let index = 0; index < systemFunctions.length; index++) {
            const parentLevel = systemFunctions[index];

            if (parentLevel.children) {
                let selectedFunc = parentLevel.children.find(child => child.codfunc === newFn.permissionCod);

                if (selectedFunc) {
                    codFunctionParent = parentLevel.codFunction;
                    break;
                }
            }
        }

        let currentFunction = selectedProfile.functions.find(fn =>
            fn.codFunction === newFn.permissionCod
        );

        let currentParentFunction = selectedProfile.functions.find(fn =>
            fn.codFunction === codFunctionParent
        );

        if (currentFunction) {
            currentFunction.codAccessLevel = newFn.newAccessLevel;
            currentParentFunction.codAccessLevel = newFn.newAccessLevel;
        } else {
            selectedProfile.functions.push({
                CodProfile: selectedProfile.codProfile || 0,
                codAccessLevel: newFn.newAccessLevel,
                codFunction: newFn.permissionCod,
                codInstance: selectedProfile.codInstance
            });
            selectedProfile.functions.push({
                CodProfile: selectedProfile.codProfile || 0,
                codAccessLevel: newFn.newAccessLevel,
                codFunction: codFunctionParent,
                codInstance: selectedProfile.codInstance
            });
        }

        this.setState({
            selectedProfile,
            dialogOptions: {
                open: false
            }
        });
    }

    renderFunctions = (functionsList, selectedProfileFunctions = []) => {
        const {
            permission,
            selectedProfile,
            allowedUser
        } = this.state;

        if (selectedProfile.isPrincipal || !allowedUser) {
            return (
                <AdminPermission>
                    <PermissionLists >
                        {this.renderFunctionsTree(functionsList, selectedProfileFunctions)}
                    </PermissionLists>
                </AdminPermission>
            )
        } else {
            return (
                <PermissionLists>
                    {this.renderFunctionsTree(functionsList, selectedProfileFunctions)}
                </PermissionLists>
            )
        }
    }

    renderFunctionsTree = (functionsList, selectedProfileFunctions = []) => {
        functionsList.forEach(menuItem => {
            const funcaoPerfil = selectedProfileFunctions.find(funcao => funcao.codFunction === menuItem.codFunction);

            if (funcaoPerfil) {
                menuItem.accessLevel = funcaoPerfil.codAccessLevel;
            }

            if (menuItem.children) {
                menuItem.children.forEach(child => {
                    const funcaoChildPerfil = selectedProfileFunctions.find(funcao => funcao.codFunction === child.codfunc)

                    if (funcaoChildPerfil) {
                        child.accessLevel = funcaoChildPerfil.codAccessLevel;
                    }
                });
            }
        })

        return functionsList.sort(this.handleSortItem).map(topItem => {
            return (
                <Fragment key={topItem.desc}>
                    <li key={topItem.desc}>
                        <span>
                            <Checkbox color="default" checked={topItem.accessLevel && topItem.accessLevel !== 3}
                                onChange={this.handleCheckboxChange.bind(this)} value={JSON.stringify(topItem.accessLevel && topItem.accessLevel !== 3)}
                                name={String(topItem.codFunction)} />
                            {topItem.desc}
                        </span>

                        <ul>
                            {
                                topItem.children ? 
                                    topItem.children.sort(this.handleSortItem).map(child => {
                                        return (
                                            <li key={`${child.desc}-${child.codfunc}`}>
                                                <Checkbox color="default" checked={child.accessLevel && child.accessLevel !== 3}
                                                    name={String(child.codfunc)} value={JSON.stringify(child.accessLevel && child.accessLevel !== 3)}
                                                    onChange={this.handleCheckboxChange.bind(this)} />
                                                {child.desc}

                                                <ul>
                                                    {
                                                        ProfileService.accessLevels.map(accessLevel => {
                                                        // Se child.codfunc for igual a 8 (interfaces) ou 13 (instancias) , todas as opções devem ser exibidas
                                                        if (child.codfunc === 8 || child.codfunc === 13) {
                                                            return (
                                                            <li key={`${child.desc}-${accessLevel.desAccessLevel}`}>
                                                                <Radio color="default" checked={ child.accessLevel === accessLevel.codAccessLevel }
                                                                    value={accessLevel.codAccessLevel} name={String(child.codfunc)}
                                                                    onChange={this.handleRadioChange.bind(this)} /> 
                                                                {Translate(accessLevel.desAccessLevel)}
                                                            </li>
                                                            );
                                                        } else {
                                                            // Para outros valores de child.codfunc, exibimos apenas as opções "Gerenciamento" e "Visualização"
                                                            if (accessLevel.codAccessLevel === 1 || accessLevel.codAccessLevel === 2) {
                                                            return (
                                                                <li key={`${child.desc}-${accessLevel.desAccessLevel}`}>
                                                                <Radio 
                                                                    color="default" 
                                                                    checked={child.accessLevel === accessLevel.codAccessLevel}
                                                                    value={accessLevel.codAccessLevel} 
                                                                    name={String(child.codfunc)}
                                                                    onChange={this.handleRadioChange.bind(this)} 
                                                                /> 
                                                                {Translate(accessLevel.desAccessLevel)}
                                                                </li>
                                                            );
                                                            }
                                                        }
                                                        })
                                                    }
                                                    </ul>
                                            </li>
                                        )
                                    }) : <Fragment></Fragment>
                            }
                        </ul>
                    </li>
                </Fragment>
            )
        })
    }

	handleSortItem = (item1, item2) => {
		if (item1.indPosition > item2.indPosition) {
			return 1;
		} else if (item1.indPosition < item2.indPosition) {
			return -1;
		}

		return 0;
    }

    copyProfile = _ => {
        const { selectedProfile } = this.state;
        this.setState({
            dialogOptions: {
                type: "danger",
                open: true,
                message: Translate("copy_of_profile") + ` ${selectedProfile.description}?`,
                title: Translate("copy_confirmation"),
                onClose: _ => {
                    let newProfile = { ...selectedProfile };
                    newProfile.isPrincipal = false;
                    newProfile.description = `${newProfile.description} - ` + Translate("copy_item")
                    delete newProfile.codProfile;
                    const codCompany = Session().codCompany;
                    const codInstance = Session().codInstance;
                    newProfile.codCompany = codCompany;
                    newProfile.codInstance = codInstance;

                    ProfileService
                        .PostProfile(newProfile)
                        .then((res => {
                            Toast.success(Translate("profile_success_copied"));
                            newProfile.functions.forEach(func => {
                                func.CodProfile = res
                                func.CodInstance = codInstance;
                            });
                            ProfileService
                                .SavePermissions(
                                    res,
                                    newProfile.functions
                                ).then(res => {
                                    this.createGrid();
                                });
                        }).bind(this))
                        .catch(_ => {
                            Toast.error(Translate("error_copying_profile"))
                        })
                }
            }
        });
    }

    createNewProfile = _ => {
        if (this.changedProfile()) {
            this.setState({
                dialogOptions: {
                    type: "danger",
                    open: true,
                    message: Translate("cancel_changes"),
                    title: Translate("attention"),
                    onClose: _ => {
                        this.setState({
                            ...this.state,
                            profilesGridOptions: {
                                ...this.state.profilesGridOptions,
                                selectedRow: {}
                            },
                            dialogOptions: {
                                open: false
                            }
                        }, _ => {
                            this.updateSelectedProfile({});
                        })
                    }
                }
            });
        } else {
            this.setState({
                ...this.state,
                profilesGridOptions: {
                    ...this.state.profilesGridOptions,
                    selectedRow: {}
                }
            }, _ => {
                this.updateSelectedProfile({});
            });
        }
    }

    render() {
        const {
            dialogOptions,
            isReady,
            profilesDataSource,
            profilesGridOptions,
            systemFunctions,
            selectedProfile,
            permission,
            profileServiceRequestCompleted,
            allowedUser
        } = this.state;

        if (!profileServiceRequestCompleted) {
            return <Loading />
        }

        return (
            <Fragment>
                <ProfileToolbar 
                    lg={12} 
                    newProfile={this.createNewProfile} 
                    copyProfile={selectedProfile ? this.copyProfile : null}
                    selectedRow={profilesGridOptions.selectedRow} 
                    hideButtons={!allowedUser}/>
                <Grid2 container spacing={1}>
                    <Grid2 size={5}>
                        <CustomGridContainer>
                            <ConectorTable 
                                dataSource={profilesDataSource} 
                                hideTopDiv={true} 
                                gridDeleteItem={this.deleteItem}
                                gridOptions={profilesGridOptions} 
                                connectSelectedRows={this.getSelectUpdate.bind(this)} />
                        </CustomGridContainer>
                    </Grid2>
                    <Grid2 size={7}>
                        <CustomPanelContainer>
                            <StyledPaper>
                                {
                                    selectedProfile ? (
                                        <Fragment>
                                            <Grid2 item lg={6} sm={12}>
                                                <ConectorInputText label={Translate("description")} value={selectedProfile.description}
                                                    name={"description"} placeholder={Translate("description")} onChange={this.handleChange}
                                                    disabled={!allowedUser} help={Translate("enter_description")} />
                                            </Grid2>
                                            <Grid2 className={this.changedProfile() ? "changed-scroll-permission-lists" : "scroll-permission-lists"} item>
                                                <Scrollbars>
                                                    {
                                                        systemFunctions && systemFunctions.length ? (
                                                            this.renderFunctions(ObjectUtils.deepCopy(systemFunctions), selectedProfile.functions)
                                                        ) : (
                                                            <PermissionLists>
                                                                <Fragment></Fragment>
                                                            </PermissionLists>
                                                        )
                                                    }
                                                </Scrollbars>
                                            </Grid2>

                                            <Grid2 container justify="flex-end" style={
                                                !this.changedProfile() || !allowedUser ? {display: "none" } : {marginTop: "20px"}
                                            }>
                                                <Grid2 container spacing={1}>
                                                    <StyledButton variant="contained" onClick={this.cancelChanges} color="primary">
                                                        {Translate("cancel")}
                                                    </StyledButton>
                                                    <StyledButton className="no-margin" variant="outlined" onClick={this.save}>
                                                        {Translate("save")}
                                                    </StyledButton>
                                                </Grid2>
                                            </Grid2>
                                        </Fragment>
                                    ) : <Title>{Translate("select_profile")}</Title>
                                }
                            </StyledPaper>
                        </CustomPanelContainer>
                    </Grid2>
                </Grid2>

                <ConectorDialogConfirmation dialogOptions={dialogOptions} />
            </Fragment>
        );
    }

}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)