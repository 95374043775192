import request from '../../utils/request'

export default {
    GetUserPermissions: () => {
        return request.executeRequest(
            `${request.baseUrl}globals/accesspermission`,
            { ...request.getOptions() }
        ).then(res => res.json())
    },
    GetUserPrivileges: () => {
        return request.executeRequest(
            `${request.baseUrl}globals/userprivileges`,
            { ...request.getOptions() }
        ).then(res => res.json())
    }
} 