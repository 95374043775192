// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

//import _ from 'lodash';
import * as types from './action-types';
//import Immutable from 'seamless-immutable';

const initialState = {
  user: {},
  username: '',
  password: '',
  showPassword: false,
  isLoginSuccess: false,
  isLoginPending: true,
  loginError: '',
  auths: []
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.SET_LOGIN_PENDING:
      return Object.assign({}, state, {
        isLoginSuccess: false,
        isLoginPending: true,
      });
    case types.SET_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        user: action.user,
        isLoginSuccess: true,
      });
    case types.SET_LOGIN_ERROR:
      return Object.assign({}, state, {
        isLoginSuccess: false,
        loginError: action.message,
      });
    
    default:
      return state;
  }
}

export function getUserInfo(state) {
  const user = state.login.user;
  const isLoginSuccess = state.login.isLoginSuccess;
  const isLoginPending = state.login.isLoginPending;
  return [user, isLoginPending, isLoginSuccess];
}