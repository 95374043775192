import React, { Component, Fragment } from 'react'
import ConnectionsService from '../connections-service'
import Translate from '../../../i18n/translate'
import { Grid2 } from '@mui/material'
import Loading from "../../../componentsUI/loading"
import ConectorSelect from "../../../componentsUI/conectorSelect"
import ConectorCheckbox from "../../../componentsUI/checkBox"
import ConectorInputText from "../../../componentsUI/inputText"

class Step1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            connectionTypes: [],
            connectionModes: [],
            connection: {
                isActive: false
            },
            validations: [],
            ...props
        }
    }

    stepDisabled() {
        const { connection } = this.state;

        if (!connection.description || !connection.description.length) {
            return true;
        }

        if (!connection.codConnectionMode) {
            return true;
        }

        return false;
    }

    componentWillMount() {
        this.getTypes()
        this.state.stepDisabled = this.stepDisabled();
        this.props.refresh(this.state)
    }

    componentWillReceiveProps(props) {
        this.setState({ ...props });
    }

    getTypes() {
        ConnectionsService
            .GetConnectionTypes()
            .then(
                (connectionTypes) => {
                    var { connection } = this.state,
                        connectionModes = [];

                    const modifiers = [
                        10,
                        11,
                        12
                    ];

                    connectionTypes = connectionTypes.filter(connectionType =>
                        !modifiers.includes(connectionType.codConnectionType)
                    );

                    connectionTypes
                        .filter(connectionType => connectionType.children)
                        .forEach(connectionType => {
                            connectionModes.push(...connectionType.children)
                        })

                    if (connection.codConnectionMode) {
                        connection.codConnectionType = connectionModes.find(mode =>
                            mode.codConnectionMode === connection.codConnectionMode
                        ).codConnectionType || {};
                    };

                    this.setState({
                        isReady: true,
                        connectionModes,
                        connectionTypes,
                        connection,
                    });
                }
            );
    }

    handleChange = e => {
        let state = { ...this.state };
        if (e.target.name === 'isActive') {
            state.connection.isActive = !state.connection.isActive;
        } else {
            state.connection[e.target.name] = e.target.value;
        }

        state.stepDisabled = this.stepDisabled();
        this.props.refresh(state);
    }

    handleChangeSelectType = (connectionTypeValue) => {
        let state = { ...this.state }

        if (state.connection.codConnectionType === connectionTypeValue.value) {
            return;
        }

        state.connection.codConnectionType = connectionTypeValue.value
        delete state.connection.codConnectionMode;
        delete state.connection.attributes;

        state.connectionModes = [];
        state.connectionTypes
            .filter(connectionType => connectionType.children)
            .forEach(connectionType => {
                if (connectionType.codConnectionType == connectionTypeValue.value) {
                    state.connectionModes.push(...connectionType.children)
                }
            });
        
        state.stepDisabled = this.stepDisabled();
        this.props.refresh(state)
    }

    handleChangeSelectMode = (connectionModeValue) => {
        let state = { ...this.state }
        state.connection.codConnectionMode = connectionModeValue.value
        delete state.connection.attributes;

        state.stepDisabled = this.stepDisabled();
        this.props.refresh(state);
    }

    makeOptionsList = (arr, prop) => {
        return arr.map(item => ({
            value: item[prop],
            label: item.label
        }));
    }

    render() {
        const { 
            isReady,
            connection,
            connectionTypes,
            connectionModes,
            validations
        } = this.state;

        const optionsTypes = this.makeOptionsList(connectionTypes, 'codConnectionType');
        const optionsModes = this.makeOptionsList(connectionModes.filter(conMode =>
            conMode.codConnectionType === connection.codConnectionType
        ), 'codConnectionMode');

        if (!isReady) {
            return (<Loading />)
        }

        return (
            <Fragment>
                <Grid2 container spacing={2}>
                    <Grid2 size={16}>
                        <ConectorInputText
                            label={'description'}
                            help={'enter_description'}
                            required={true}
                            name={'description'}
                            value={connection.description}
                            onChange={this.handleChange}
                            errorCondition={
                                validations.find(validation =>
                                    validation.field === Translate('description')
                                )
                            }
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={2}>
                    <Grid2 size={4}>
                        <ConectorSelect
                            label={'connection_type'}
                            help={'enter_type'}
                            placeholder={'connection_type'}
                            required={true}
                            name={'connection_type'}
                            onChange={this.handleChangeSelectType}
                            value={optionsTypes.find(option => option.value === connection.codConnectionType) || null}
                            options={optionsTypes}
                            className={
                                validations.find(validation =>
                                    validation.field === Translate('connection_type')
                                ) ? 'error' : ''
                            }
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <ConectorSelect
                            label={'connection_mode'}
                            help={'select_mode'}
                            placeholder={'connection_mode'}
                            name={'connection_mode'}
                            required={true}
                            disabled={!connection.codConnectionType || !optionsModes.length}
                            onChange={this.handleChangeSelectMode}
                            value={optionsModes.find(option =>
                                option.value === connection.codConnectionMode
                            ) || null}
                            options={optionsModes}
                            className={
                                validations.find(
                                    validation => validation.field === Translate('connection_mode')
                                ) ? 'error' : ''
                            }
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={2}>
                    <Grid2 size={4}>
                        <ConectorCheckbox
                            name="isActive"
                            color="default"
                            value={connection.isActive}
                            onChange={this.handleChange}
                            checked={connection.isActive}
                            label={Translate('active_connection')}
                        />
                    </Grid2>
                </Grid2>
            </Fragment>
        )
    }
}

export default Step1