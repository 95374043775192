import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { CSVLink } from 'react-csv';
import { setBreadcrumb } from "../../store/app-state/actions";
import Session from "../../utils/session-info"
import * as appStateReducer from "../../store/app-state/reducer";
import Translate from '../../i18n/translate'
import permissionsPanelService from "./permissions-panel-service";
import { Grid2 } from "@mui/material";
import ConectorTable from "../../componentsUI/conectorTable"
import ConectorSelect from "../../componentsUI/conectorSelect"
import Loading from "../../componentsUI/loading"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import Toast from "../../components/toast/toast"
import UserService from "../users/user-service"

class PermissionsPanel extends Component {
    constructor(props) {
        super(props);

        props.setBreadcrumb([{
			label: "administration"
		}, {
			label: "permissions_panel"
		}]);

        this.state = {
            loading: true,
            selectedCompanyID: Session().codCompany,
            companiesOptions: [],
            dataSourceAccess: [],
            gridOptions: {
				multiSelect: false,
                hideBtnNew: true,
                hideBtnEdit: true,
                hideBtnDelete: true,
                hideBtnCopy: true,
                sortField: "company",
				columns: [{
					title: "company",
					field: "des_company"
				},
                {
					title: "instance",
					field: "des_instance"
				},
                {
					title: "profile",
					field: "des_profile"
				},
                {
					title: "name",
					field: "des_name"
				},
                {
					title: "email",
					field: "des_email"
				},
                {
					title: "active",
					field: "is_active",
                    cellFilter: "boolean"
				}]
			}
        }
    }

    componentDidMount = () => {
		UserService.FindPrivilege(Session().codUser, 1)
        .then(response => {
            if(response == false) throw new Error("notHavePermissionToAccess")
        })
        .catch(error => {
            Toast.error(Translate(error.message));
            setTimeout(() => {
                window.location.href = '/'
            }, 2000)
        })
    }

    componentWillMount = () => {
        const { selectedCompanyID } = this.state

        permissionsPanelService
        .GetAllSystemCompanies()
        .then((dataSource) => {
            dataSource.unshift({ "id": 0, "label": "all" })
            this.setState({companiesOptions: dataSource})
        })

		this.getAccesses(selectedCompanyID);
	}

    getAccesses = codCompany => {
		permissionsPanelService
        .GetAccesses(codCompany)
        .then((dataSource) => {
            this.setState({
                dataSourceAccess: dataSource,
                loading: false
            });
        });
	}

    changeCompanyFilter = selectedObj => {
        this.setState({selectedCompanyID: selectedObj.id})
        this.getAccesses(selectedObj.id);
    }

    render = () => {
        const {
			loading,
            companiesOptions,
            selectedCompanyID,
            dataSourceAccess,
            gridOptions
        } = this.state

        if (loading) { return <Loading /> }

        return (<Fragment>
                    <InternalPageTitle title={Translate("permissions_panel")} />

                    <StyledPaper>
                        <Grid2 container spacing={2}>
                            <Grid2 size={16}>
                                <ConectorSelect
                                    label={'company'}
                                    help={'company_select'}
                                    onChange={this.changeCompanyFilter}
                                    options={companiesOptions}
                                    value={companiesOptions.find(company => company.id === selectedCompanyID) || null}/>
                            </Grid2>

                            <Grid2 size={4}>
                                <CSVLink data={dataSourceAccess} filename={"permissions.csv"}>
                                    <StyledButton variant="outlined">{Translate("downloadReport")}</StyledButton>
                                </CSVLink>
                            </Grid2>
                        </Grid2>

                        <Grid2 spacing={2}>
                            <ConectorTable gridOptions={gridOptions} dataSource={dataSourceAccess}/>
                        </Grid2>
                    </StyledPaper>
                </Fragment>)
    }
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsPanel);