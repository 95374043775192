import React, {
    Component,
    Fragment
} from "react";
import { connect } from 'react-redux'
import Loading from "../../componentsUI/loading"
import ConectorTable from "../../componentsUI/conectorTable"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper";
import InternalPageTitle from "../../componentsUI/internalPageTitle";
import StyledButton from "../../componentsUI/styledComponents/styledButton";
import Translate from '../../i18n/translate'
import { Tooltip } from '@mui/material'
import ModalLog from './modalLog'
import { setBreadcrumb } from '../../store/app-state/actions'
import * as workerReducer  from '../../store/worker/worker-store-reducer'
import { defineWorkerProperties } from '../../store/worker/worker-store-actions'


class Worker extends Component {
    constructor(props){
        super(props);

        props.setBreadcrumb([{
            label: "dataworker"
        }, {
            label: "worker"
        }]);
        this.state = {
            openModalLog:false,
            loading: true,
            gridOptions: {
                multiSelect: false,
                sortField: "datinstall",
                sortType: "desc",
                columns: [{
                    title: "token",
                    field: "instancetoken"
                }, {
                    title: "osPlatform",
                    field: "desosplatform"
                }, {
                    title: "osVersion",
                    field: "desosversion"
                }, {
                    title: "hostName",
                    field: "deshostname"
                }, {
                    title: "domainName",
                    field: "desdomainname"
                }, {
                    title: "totalMemory",
                    field: "destotalmemory"
                }, {
                    title: "language",
                    field: "language"
                }, {
                    title: "active",
                    field: "isactive",
					cellFilter: "boolean"
                }, {
                    title: "datInstall",
                    field: "datinstall",
                    cellFilter: "ddMMyyyy-hh:mm"
                }, {
                    title: "datUninstall",
                    field: "datuninstall",
                    cellFilter: "ddMMyyyy-hh:mm"
                }, {
                    title: "workVersion",
                    field: "desversionworker"
                }, {
                    title: "systemType",
                    field: "dessystemtype"
                }]
            }
        }
    }

    componentWillReceiveProps(props) {
        let { gridOptions } = this.state;
        gridOptions.hideBtnNew = true;
		gridOptions.hideBtnEdit = true;
		gridOptions.hideBtnDelete = true;
        gridOptions.hideBtnCopy = true;

		this.setState({ gridOptions });
    }

    componentDidMount = () => {
        this.props.defineWorkerProperties()
	}
    handleOpenModal = () => {
        this.setState({ openModalLog: true });
    }

    handleDismissModal = () => {
        this.setState({ openModalLog: false });
    }

    render() {
		const { gridOptions,openModalLog } = this.state
        const { workerProperties } = this.props

		if (!workerProperties) {
			return <Loading />
		}      
        const versionActive = workerProperties.find(item => item.isactive === true);
        var btnDisabled = true;
            if(versionActive)
                btnDisabled = (versionActive.desversionworker >= "1.6.17.0" && !versionActive.stopped) ? false : true;

		return (
			<Fragment>
                <InternalPageTitle title={Translate("worker_list")} />
                <StyledPaper>
                    <Tooltip title={btnDisabled ?"Versão não suportada ou Worker parado. É necessário utilizar uma versão maior ou igual 1.6.17.0.":"Solicitar Log"}>
                        <span>
                            <StyledButton
                            variant="contained"
                            onClick={this.handleOpenModal}
                            disabled={btnDisabled}
                                >
                                {Translate("requestLog")}
                            </StyledButton>
                        </span>
                    </Tooltip>
                    <ConectorTable
                        gridOptions={gridOptions}
                        dataSource={workerProperties}>
                    </ConectorTable>
                </StyledPaper>
                {
                    (() => {
                        if (openModalLog) {
                             return <ModalLog codInstance={versionActive.codinstance} onDismiss={this.handleDismissModal.bind(this)} />
                        }
                    })()
                }
			</Fragment>
		)
    }

}

const mapStateToProps = store => {
	return {
        workerProperties: workerReducer.getWorkerProperties(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
        defineWorkerProperties: () => dispatch(defineWorkerProperties()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Worker);