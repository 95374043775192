import React, { Component, Fragment } from "react"
import './customer-management.css'
import { connect } from "react-redux"
import { setBreadcrumb } from "../../store/app-state/actions"
import { MdExpandMore, MdExpandLess } from "react-icons/md"
import './customer-management.css'
import Session from "../../utils/session-info"
import CustomerManagementService from "./customer-management-service"
import { parse, format } from 'date-fns'
import Toast from "../../components/toast/toast"
import UserService from "../../routes/users/user-service"
import { FormGroup, FormControlLabel, Switch, Grid2, Tooltip } from '@mui/material'
import { StyledDivider, StyledGridContainer, StyledTable } from "../../componentsUI/styledComponents/styledCustomerManagement"
import Loading from "../../componentsUI/loading"
import ConectorInputText from "../../componentsUI/inputText"
import Translate from "../../i18n/translate"
import ConectorDialogConfirmation from "../../componentsUI/dialogComponentConfirmation"

class CustomerManagement extends Component {
	constructor(props) {
		super(props);

		props.setBreadcrumb([
			{
				label: "registrations",
			},
			{
				label: "customer_management",
			},
		]);

		this.state = {
			checked: false,
			id: 0,
			initialData: {},
			initialDataOld: {},
			loading: true,
			requiresMathValidation: false,
		};
	}

	componentDidMount = async () => {
		const hasPrivilege = await UserService.FindPrivilege(Session().codUser, 2);
		this.setState({ hasPrivilege });
	}

	componentWillMount() {
		UserService.FindPrivilege(Session().codUser, 2)
			.then(response => {
				if (response == false) throw new Error("notHavePermissionToAccess")
			})
			.catch(error => {
				Toast.error(Translate(error.message));
				setTimeout(() => {
					window.location.href = '/';
				}, 2000);
			})

		CustomerManagementService.GetAllInstance().then((instance) => {
			const initialData = [];

			instance.forEach((item) => {
				const { codCompany, des_company, is_bloqueado, expanded, ...instanceData } = item;

				const existingCompany = initialData.find((company) => company.codCompany === codCompany);

				if (!existingCompany) {
					const newCompany = {
						codCompany,
						des_company,
						is_bloqueado,
						expanded: false,
						instances: [instanceData],
					};

					initialData.push(newCompany);
				} else {
					existingCompany.instances.push(instanceData);
				}
			});

			initialData.forEach((company) => {
				const allInstancesBlocked = company.instances.every((instance) => instance.isbloqueado === true);
				if (allInstancesBlocked) {
					company.is_bloqueado = true;
				}

				company.instances.sort((a, b) => (a.description || '').localeCompare(b.description || ''));
			});

			initialData.sort((a, b) => a.des_company.localeCompare(b.des_company));

			this.setState({
				initialData,
				initialDataOld: initialData,
				loading: false,
			});
		});
	}


	handleToggle = (CompanyId) => {
		this.setState((prevState) => ({
			initialData: prevState.initialData.map((company) =>
				company.codCompany === CompanyId ? { ...company, expanded: !company.expanded } : company
			),
		}));
	};

	onConfirmeHandleChangeSwitch = _ => _ => {
		const { checked, id } = this.state;
		const updatedData = this.state.initialData.map(company => {
			if (company.codCompany === id) {
				const InstancesUpdated = company.instances.map(instance => {
					if (checked) {
						return { ...instance, isbloqueado: checked, datbloqueio: new Date().toLocaleString(), datativacao: null, desname: Session().desName };
					} else {
						return { ...instance, isbloqueado: checked, datativacao: new Date().toLocaleString(), datbloqueio: null, desname: Session().desName };
					}
				});

				return {
					...company,
					is_bloqueado: checked,
					instances: InstancesUpdated,
				};
			}

			return company;
		});

		CustomerManagementService.UpdateCompanyBlock(id, checked, Session().desName, Session().codUser).then();

		this.setState({
			initialData: updatedData,
			confirmationModalOptions: { open: false },
		});
	};

	onConfirmeHandleChangeSwitchInstance = _ => _ => {
		const { checked, id } = this.state;
		const updatedData = this.state.initialData.map(company => {
			const InstancesUpdated = company.instances.map(instance => {
				if (instance.id === id) {
					return { ...instance, isbloqueado: checked, datbloqueio: checked ? new Date().toLocaleString() : '', datativacao: checked ? '' : new Date().toLocaleString(), desname: Session().desName };
				}
				return instance;
			});

			const isAllIsntanceBlocked = InstancesUpdated.every(instance => instance.isbloqueado);

			if (isAllIsntanceBlocked !== company.is_bloqueado) {
				return { ...company, is_bloqueado: isAllIsntanceBlocked, instances: InstancesUpdated, dataBloqueio: isAllIsntanceBlocked ? new Date().toLocaleString() : '', dataAtivacao: isAllIsntanceBlocked ? '' : new Date().toLocaleString(), pessoaBloqueou: Session().desName };
			}

			return { ...company, instances: InstancesUpdated };
		});

		CustomerManagementService.UpdateInstanceBlock(id, checked, Session().desName, Session().codUser).then();

		this.setState({
			initialData: updatedData,
			confirmationModalOptions: { open: false },
		});
	};

	handleChangeSwitch = (idCompany, desCompany) => event => {
		const { checked, id, hasPrivilege } = this.state;
		if (hasPrivilege) {
			this.setState({
				id: idCompany,
				checked: event.target.checked,
				confirmationModalOptions: {
					type: "danger",
					title: "company_lock_confirmation",
					message: `lock_select_company ${desCompany} do_you_want_to_continue`,
					open: true,
					requiresMathValidation: true,
					onDismiss: this.onDismissModal,
					onClose: this.onConfirmeHandleChangeSwitch(this, id, checked),
				},
			});
		} else {
			Toast.error("Você não tem permissão para acessar esse recurso");
		}
	}

	handleChangeSwitchInstance = (idInstance,desInstance,desCompany) => event => {
		const { checked, id, hasPrivilege } = this.state;
		if (hasPrivilege) {
			this.setState({
				id: idInstance,
				checked: event.target.checked,
				confirmationModalOptions: {
					type: "danger",
					title: "instance_lock_confirmation",
					message: `lock_select_instance ${desInstance} of_the_company ${desCompany} do_you_want_to_continue`,
					requiresMathValidation: true,
					open: true,
					onDismiss: this.onDismissModalInstance,
					onClose: this.onConfirmeHandleChangeSwitchInstance(this, id, checked),
				},
			});
		} else {
			Toast.error("Você não tem permissão para acessar esse recurso");
		}
	}

	onDismissModal = () => {
		this.setState({
			confirmationModalOptions: {
				open: false
			}
		});
	}

	onDismissModalInstance = () => {
		this.setState({
			confirmationModalOptions: {
				open: false
			}
		});
	}

	filterCompany = (searchInput) => {
		const { initialDataOld } = this.state
		var filteredInterfacesList = initialDataOld.filter(DataElement => (
			DataElement.des_company.toLowerCase().includes(searchInput.toLowerCase())
		))

		this.setState({
			initialData: filteredInterfacesList,
		})
	}

	formatDate(dateString) {
		if (!dateString) {
			return '';
		}

		let parsedDate;
		let formatString;

		if (dateString.match(/^\d{2}\/\d{2}\/\d{4}, \d{2}:\d{2}:\d{2}$/)) {
			parsedDate = parse(dateString, 'dd/MM/yyyy, HH:mm:ss', new Date());
			formatString = 'dd/MM/yyyy';
		} else if (dateString.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/)) {
			parsedDate = parse(dateString, "yyyy-MM-dd'T'HH:mm:ss'Z'", new Date());
			formatString = 'dd/MM/yyyy';
		}

		return format(parsedDate, formatString);
	}

	render() {
		const { loading, initialData, confirmationModalOptions } = this.state;

		if (loading) {
			return <Loading />;
		}

		return (
			<Fragment>
				<Grid2 size={4}>
					<ConectorInputText
						label="searchCompany"
						placeholder="toTypeSearchCompany"
						help="companyDescriptionFilter"
						onChange={(initialData) => {
							this.filterCompany(initialData.target.value)
						}}
					/>
				</Grid2>
				<StyledGridContainer>
					<StyledTable>
						<thead>
							<tr>
								<th>Descrição</th>
								<th>ID</th>
								<th>Data de Bloqueio</th>
								<th>Data de Ativação</th>
								<th>Usuario</th>
								<th>Bloqueado</th>
							</tr>
						</thead>
						<tbody>
							{initialData.map((company) => (
								<React.Fragment key={company.codCompany}>
									<tr>
										<td
											className="icon"
											onClick={() => this.handleToggle(company.codCompany)}
										>
											{company.expanded ? <MdExpandLess size={24} className="Expand" /> : <MdExpandMore size={24} className="Expand" />}
											{company.des_company}
										</td>
										<td onClick={() => this.handleToggle(company.codCompany)} className="cell" >{company.codCompany}</td>
										<td onClick={() => this.handleToggle(company.codCompany)} className="cell" >{company.dat_bloqueio}</td>
										<td onClick={() => this.handleToggle(company.codCompany)} className="cell" >{company.dat_ativacao}</td>
										<td onClick={() => this.handleToggle(company.codCompany)} className="cell" >{company.des_name}</td>
										<td className="switch-cell">
											<FormGroup>
												<Tooltip title={company.is_bloqueado ? 'Bloqueado' : 'Desbloqueado'}>
													<FormControlLabel
														control={
															<div onClick={this.handleChangeSwitch(company.codCompany, company.des_company)}> {
																company.is_bloqueado ?
																	<Switch
																		color="primary"
																		checked={true}
																	/> :
																	<Switch
																		color="primary"
																		checked={false}
																	/>
															}
															</div>
														}
														className="tooltip"
													/>
												</Tooltip>
											</FormGroup>
											<span className="slider"></span>
										</td>
									</tr>
									{company.expanded &&
										company.instances.map((instance) => (
											<tr key={instance.id}>
												<td className="instance-cell">{instance.description}</td>
												<td>{instance.id}</td>
												<td>{this.formatDate(instance.datbloqueio)}</td>
												<td>{this.formatDate(instance.datativacao)}</td>
												<td>{instance.desname}</td>
												<td className="instance-switch-cell">
													<FormGroup key={instance.id}>
														<Tooltip title={instance.isbloqueado ? 'Bloqueado' : 'Desbloqueado'}>
															<FormControlLabel
																control={
																	<div onClick={this.handleChangeSwitchInstance(instance.id,instance.description,company.des_company)}> {
																		instance.isbloqueado ?
																			<Switch
																				color="primary"
																				checked={true}
																			/> :
																			<Switch
																				color="primary"
																				checked={false}
																			/>
																	}
																	</div>
																}
																className="tooltip"
															/>
														</Tooltip>
													</FormGroup>
													<span className="slider"></span>
												</td>
											</tr>
										))}
								</React.Fragment>
							))}
						</tbody>
					</StyledTable>
					<StyledDivider />
				</StyledGridContainer>
				<ConectorDialogConfirmation dialogOptions={confirmationModalOptions} />
			</Fragment>
		)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
	};
}

export default connect(null, mapDispatchToProps)(CustomerManagement)