import styled from 'styled-components'
import { Card, Grid2 } from '@mui/material'

export const CardTitle = styled.span`
font-size: 11rem;
font-weight: bold;
margin-bottom: 10px;
`

export const CardDescription = styled.span`
&& {
font-size: 18px;
bottom: 15px;
position: absolute;
}
`

export const DashCard = styled(Card)`
&& {
    height: 200px;
    > div {
        position: relative;
        justify-content: center;
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        text-align: center;
        border-left: 6px solid;
        height: 200px;
    }
}
`

export const StyledGrid = styled(Grid2)`
&& {
    fontSize: 10%;
    font-weight: bold;
    position: relative;
    height: 100%;
    > div:nth-child(3) {
        width: calc(100% - 24px);
        height: calc(100% - 24px);
        top: 12px;
        position: absolute;
        z-index: 3;
        background-color: #FFF;
    }
}
`;