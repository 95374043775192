
import Translate from "../i18n/translate"
import StackTraceModal from "../componentsUI/stackTraceModal"
import ReactJson from "react-json-view-18"
import xmlFormat from 'xml-formatter'
import React, {Component, Fragment} from "react"
import {
    TableFooter,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    IconButton
} from "@mui/material"
import {
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
    MdZoomIn
} from "react-icons/md"
import {
    ExpandableTableRow, 
    StyledGridContainer, 
    StyledTextFragment, 
    StyledTableFooter, 
    StyledTable, 
    CustomListItem
} from "./styledComponents/styledInterfaceLogsTable"
import ConnectorGridFilters from "../utils/grid-logs-filters"

class InterfaceLogsTable extends Component {
    constructor(props) {
        super(props);

        var {
            gridOptions,
            dataSource
        } = props;

        gridOptions = {
            ...gridOptions,
            sortType: "asc"
        }

        this.state = {
            gridOptions,
            dataSource
        };
    }

    componentWillReceiveProps = ({ gridOptions, dataSource }) => {
        this.setState({
            gridOptions: {
                ...this.state.gridOptions,
                ...gridOptions
            },
            dataSource
        });
    }

    renderHeader(gridOptions) {
        let cellIndex = 0;

        const renderHeaderCells = () => {
            let cellsArr = [];

            cellsArr.push(
                <TableCell key={"icon"}>
                    <MdKeyboardArrowDown size={24} style={{ opacity: 0 }} />
                </TableCell>
            );

            gridOptions.columns.forEach(coluna => {
                cellIndex++;

                cellsArr.push(
                    <TableCell key={cellIndex}>
                        {coluna.title}
                    </TableCell>
                )
            });

            return cellsArr;
        }

        return (
            <TableHead>
                <TableRow key={0}>
                    {renderHeaderCells()}
                </TableRow>
            </TableHead>
        )
    }

    expandRow = idx => {
        var { dataSource } = this.state,
            row = dataSource.logs[idx];

        if (!row.detailsCount) {
            return;
        }

        row.expanded = !Boolean(row.expanded);
        this.setState({ dataSource });
    }

    getValue = (obj, path) => {
        var objPath = obj;

        if (!path) {
            return;
        }

        if (path instanceof Object) {
            path = path.field;
        }

        path.split(".").forEach(function (p) {
            if (objPath) {
                objPath = objPath[p];
            }
        });

        return objPath;
    }

    renderFooter(gridOptions, dataSource) {
        const { filterError, filterSuccess, filterWarning } = gridOptions;

        return (
            <TableFooter>
                <TableRow>
                    <TablePagination
                        page={gridOptions.currPage}
                        rowsPerPage={gridOptions.pageSize}
                        onPageChange={(e, currPage) => {
                            gridOptions.findPage(currPage, filterError, filterSuccess, filterWarning);
                        }}
                        labelRowsPerPage={Translate("items_per_page")}
                        labelDisplayedRows={() => {
                            let labelTemplate = `${Translate("displaying_from")} [recordIndex] ${Translate("up_to")} [pageSize], ${Translate("from", true)} [total] ${Translate("records")}`;

                            if (gridOptions.currPage === 0) {
                                if (dataSource.logs && dataSource.logs.length) {
                                    labelTemplate = labelTemplate.replace("[recordIndex]", "1");
                                } else {
                                    labelTemplate = labelTemplate.replace("[recordIndex]", "0");
                                }
                            } else {
                                labelTemplate = labelTemplate.replace("[recordIndex]", ((gridOptions.currPage * gridOptions.pageSize) + 1));
                            }

                            if ((gridOptions.currPage + 1) * gridOptions.pageSize > dataSource.total) {
                                labelTemplate = labelTemplate.replace("[pageSize]", dataSource.total);
                            } else {
                                labelTemplate = labelTemplate.replace("[pageSize]", ((gridOptions.currPage + 1) * gridOptions.pageSize));
                            }

                            labelTemplate = labelTemplate.replace("[total]", dataSource.total);

                            return labelTemplate;
                        }}
                        rowsPerPageOptions={[]}
                        count={dataSource.total || 0}
                    ></TablePagination>
                </TableRow>
            </TableFooter>
        )
    }

    renderContent(gridOptions, dataSource) {
        if (dataSource.total == 0) {
            return (
                <TableBody>
                    <TableRow className={this.state.gridOptions.sortable ? "contentRow" : ""} key={"notFound"} >
                        <TableCell className={"notFound"} colSpan={4}>
                            {gridOptions.noDataMessage}
                        </TableCell>
                    </TableRow>
                </TableBody>
            )
        }

        if (gridOptions.filter && gridOptions.filter.field) {
            dataSource.logs = dataSource.logs.filter(obj => {
                let value = gridOptions.filter.field.indexOf(".") !== -1
                    ? this.getValue(obj, gridOptions.filter.field)
                    : obj[gridOptions.filter.field]

                if (typeof (value) === "string") {
                    return value.toUpperCase().indexOf(gridOptions.filter.value.toUpperCase()) !== -1
                } else {
                    return value.toString().indexOf(gridOptions.filter.value) !== -1
                }
            })
        }

        let rowIndex = 0

        const criarRow = (row) => {
            rowIndex++
            let cellIndex = 0,
                cellsArr = [];

            cellsArr.push(
                <TableCell sx={{padding: '0px'}} align="center" key={"icon"}>
                    {row.expanded ? <MdKeyboardArrowUp size={15}/> : <MdKeyboardArrowDown size={15}/>}
                </TableCell>
            );

            gridOptions.columns.forEach(coluna => {
                let value = coluna.field.indexOf(".") !== -1
                    ? this.getValue(row, coluna.field)
                    : row[coluna.field]

                if (coluna.cellFilter) {
                    value = ConnectorGridFilters(value, coluna.cellFilter)
                }

                cellIndex++

                cellsArr.push(
                    <TableCell
                        title={value}
                        key={rowIndex + "+" + cellIndex}
                        className={!row.detailsCount ? "" : "pointer"}
                        sx={{
                            textAlign: coluna.textAlign ? coluna.textAlign : "left",
                            minWidth: coluna.size ? coluna.size + "px" : "50px",
                            maxWidth: coluna.size ? coluna.size + "px" : "auto",
                            paddingLeft: "24px"
                        }}>
                        {value}
                    </TableCell>
                )
            })

            row.idx = rowIndex - 1;
            ds.push(
                <TableRow
                    className={this.state.gridOptions.sortable ? "contentRow" : ""}
                    onClick={() => { this.expandRow(row.idx) }}
                    key={rowIndex}
                    hover={true}>
                    {cellsArr}
                </TableRow>
            );
            if(row.expanded){
            ds.push(
                <ExpandableTableRow key={`${rowIndex}-exp`} className={!Boolean(row.expanded) ? "hidden" : "contentRow"}>
                    <td colspan="4" style={{padding: '0px'}}>
                    <ul style={{ 
                        padding: '0', 
                        margin: '10px', 
                        fontSize: '15px'}}>
                            {row.logInterfaceDetails.map(detail => (
                                <CustomListItem>
                                    <strong>{detail.logType}</strong> - <strong>{detail.dateTime}</strong>
                                    <br/>
                                    <StyledTextFragment>{this.generateLogText(detail.message)}</StyledTextFragment>
                                    <div style={detail.stackTrace.length ? {} : { display: "none" }}>
                                        <strong>{Translate("trace")}: </strong>
                                        {detail.stackTrace.length > 150 ? `${detail.stackTrace.slice(0, 150)}...` : detail.stackTrace}
                                        <Tooltip title={Translate("view_full")}>
                                            <IconButton onClick={_ => { this.displayLog(detail.stackTrace) }}>
                                                <MdZoomIn/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </CustomListItem>
                            ))}
                        </ul>
                    </td>
                </ExpandableTableRow>
            );}
        }

        var ds = [];

        if (dataSource.logs) {
            dataSource.logs.map(criarRow.bind(this));
        }

        return (<TableBody style={{fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}>{ds}</TableBody>)
    }

    getSplitedMessage(message) {
        return message
            .split(/(.{50000})/)
            .filter(m => m)
            .map(fragment => {
                return <p>{fragment}</p>
            });
    }

    displayLog = trace => {
        this.setState({
            stackTraceToExpand: trace,
            stackTraceModalOpen: true
        });
    }

    onCloseStackTraceModal = _ => {
        this.setState({ stackTraceModalOpen: false });
    }

    generateLogText(message) {
            try {
                let JSONlog = JSON.parse(message);
                return <p><ReactJson src={JSONlog} collapsed={true} /></p>
            }
            catch (e) {
                try {
                    return <pre>{xmlFormat(message, {
                        indentation: '      ', 
                        filter: (node) => node.type !== 'Comment', 
                        collapseContent: true, 
                        lineSeparator: '\n'
                    })}</pre>
                }
                catch (e) {
                    return this.getSplitedMessage(message)
                }
            }
    }

    render() {
        const {
            dataSource,
            gridOptions,
            stackTraceModalOpen,
            stackTraceToExpand
        } = this.state;

        return (
            <Fragment>
                <StyledGridContainer>
                    <StyledTable>
                        {this.renderHeader(gridOptions)}
                        {this.renderContent(this.state.gridOptions, { ...dataSource })}
                    </StyledTable>
                </StyledGridContainer>
                <StyledTableFooter>
                    {this.renderFooter(this.state.gridOptions, this.state.dataSource)}
                </StyledTableFooter>
                <StackTraceModal 
                    open={stackTraceModalOpen || false}
                    stackTraceToExpand={stackTraceToExpand}
                    onClose={this.onCloseStackTraceModal}
                />
            </Fragment>
        );
    }
}

InterfaceLogsTable.defaultProps = {
    dataSource: {
        logs: [],
        total: 0
    },
    stackTraceModalOpen: false
}

export default InterfaceLogsTable