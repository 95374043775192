import React, { Component } from 'react'
import { components } from 'react-select'
import Translate from '../../i18n/translate'
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import { Typography, Grid2 } from "@mui/material"
import { GridToolBarDiv, TopDiv } from "../../componentsUI/styledComponents/styledProfiles"
import {
    MdExpandMore,
    MdFileCopy, 
    MdSearch,
    MdAddCircle
} from "react-icons/md"

class ProfileToolbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...props
        }
    }

    componentWillReceiveProps(props) {
        this.setState({...props});
    }

    onChangeIconSelect = (value) => {
        const DropdownIndicator = (props) => {
            if (value) {
                return components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <MdSearch />
                    </components.DropdownIndicator>
                )
            } else {
                return components.DropdownIndicator && (
                    <components.DropdownIndicator {...props}>
                        <MdExpandMore />
                    </components.DropdownIndicator>
                )
            }
        }

        return { DropdownIndicator }
    }

    onChangeSelect = (target) => {
        let state = Object.assign(this.state);
        state.selectValue = target

        if (target === null) {
            state.gridOptions.filter.field = "";
            state.gridOptions.filter.value = "";
        } else if (state.gridOptions.filter.field !== target.value) {
            state.gridOptions.filter.field = target.value;
            state.gridOptions.filter.value = "";
            state.gridOptions.filter.label = target.label;
        }

        this.setState(state, () => {
            this.state.gridOptions.refresh(this.state);
        });
    }

    onBlurInput = () => {
        this.state.gridOptions.refresh(this.state);
    }

    render() {
        const {
            newProfile,
            copyProfile,
            hideButtons
        } = this.state;

        return (
            <GridToolBarDiv>
                <TopDiv>
                    <Typography>{Translate('profile_list')}</Typography>
                    <Grid2 container spacing={1} style={hideButtons ? { display: 'none' } : {}}>
                        <StyledButton
                            variant="outlined"
                            onClick={copyProfile}
                            disabled={!copyProfile}>
                            <Grid2 container spacing={0.5}>
                                <Grid2 container><MdFileCopy size={24}/></Grid2>
                                <Grid2 container>{Translate('copy_profile')}</Grid2>
                            </Grid2>
                        </StyledButton>
                        <StyledButton
                            variant="outlined"
                            onClick={newProfile}>
                            <Grid2 container spacing={0.5}>
                                <Grid2 container><MdAddCircle size={24}/></Grid2>
                                <Grid2 container>{Translate('add_profile')}</Grid2>
                            </Grid2>
                        </StyledButton>
                    </Grid2>
                </TopDiv>
            </GridToolBarDiv>
        )
    }

}

export default ProfileToolbar