import React from 'react';
import Translate from '../../i18n/translate'
import request from '../../utils/request'
import {
  StyledErrorImage,
  StyledErrorMessage,
  StyledErrorSupportMessage,
  StyledErrorScreen
} from "../../componentsUI/styledComponents/styledErrorBoundary"
import StyledButton from "../../componentsUI/styledComponents/styledButton"

class ErrorBoundary extends React.Component {
  state = { 
    hasError: false,
    loggedOutOfSession: false,
    problemDescription: 'somethingWentWrong'
  };

  componentDidCatch = (error, errorInfo) => {
    request.executeRequest(
      `${request.baseUrl}globals/accesspermission/interfaces`,
      request.getOptions()
    )
    .then(res => {
      if(res.status == 498) {
        localStorage.clear()
        localStorage.setItem("loggedOutByInvalidToken", true)
        
        this.setState({problemDescription: 'sessionHasBeenTerminated', loggedOutOfSession: true})
      }
    })
    .finally(() => this.setState({hasError: true}))
  }

  redirect = () => window.location.href = '/'

  render = () => {
    const {hasError, problemDescription, loggedOutOfSession} = this.state

    if(loggedOutOfSession){
      return(
        <StyledErrorScreen>
          <StyledErrorImage src="/static/images/logout-image.jpg" alt="Erro" />
          <StyledErrorMessage>
            <h1>{Translate(problemDescription)}</h1>
          </StyledErrorMessage>
          <StyledButton 
            variant='outlined' 
            onClick={this.redirect}>
              {Translate('clickToLogin')}
          </StyledButton>
        </StyledErrorScreen>
      )
    }

    if (hasError) {
      return(
        <StyledErrorScreen>
          <StyledErrorImage src="/static/images/error-page.jpg" alt="Erro" />
          <StyledErrorMessage>
            <h1>{Translate(problemDescription)}</h1>
            <p>{Translate('refreshYourPage')}</p>
          </StyledErrorMessage>
          <StyledButton
            variant='outlined' 
            onClick={this.redirect}>
              {Translate('update')}
          </StyledButton>
          <StyledErrorSupportMessage>
            {Translate('ifPreferContactOur')}<a href="https://gesplan.movidesk.com/" target="_blank" rel="noopener noreferrer">{Translate('support')}</a>
          </StyledErrorSupportMessage>
        </StyledErrorScreen>
      )
    }
    return this.props.children;
  }
}

export default ErrorBoundary