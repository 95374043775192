import request from "../../utils/request";

export const interfaceService = {
    getAllByInstance,
    getAllByInterface,
    insertInterface,
    editInterface,
    deleteinterfaceevent
};

function getAllByInstance(codInstance) {
    return request.executeRequest(`${request.baseUrl}interfaces/instances/${codInstance}/interfaces`, request.getOptions()).then(response => {
        if (!response.ok) {
            return Promise.reject(response.statusText);
        }

        return response.json();
    });
}

function getAllByInterface(codInterface) {
    return request.executeRequest(`${request.baseUrl}interfaces/${codInterface}/events`, request.getOptions()).then(response => {
        if (!response.ok) {
            return Promise.reject(response.statusText);
        }

        return response.json();
    })
}

function insertInterface(insert) {
    return request.executeRequest(`${request.baseUrl}interfaces/insert`, {
        ...request.postOptions(),
        body: JSON.stringify(insert)
    }).then(response => {
        if (!response.ok) {
            return Promise.reject(response.statusText);
        }

        return response.json();
    }).then((json) => {
        return json;
    });
}

function editInterface(edit) {
    return request.executeRequest(`${request.baseUrl}interfaces/edit`, {
        ...request.putOptions(),
        body: JSON.stringify(edit)
    }).then(response => {
        if (!response.ok) {
            return Promise.reject(response.statusText);
        }

        return response.json();
    }).then((json) => {
        return json;
    });
}

function deleteinterfaceevent(codInstanteceInterface) {
    return request.executeRequest(`${request.baseUrl}interfaces/instance-interface-event/${codInstanteceInterface}`, request.deleteOptions()).then(response => {
        if (!response.ok) {
            return Promise.reject(response.statusText);
        }

        return response.json();
    }).then((json) => {
        return json;
    });
}
