const SidebarNavigationPaths = {
    "registrations": [
        { path: "companies", codTypePrivilege: null },
        { path: "instances", codTypePrivilege: null },
        { path: "users", codTypePrivilege: 1 },
        { path: "profilessecurity", codTypePrivilege: null },
        { path: "accessrelease", codTypePrivilege: null }
    ],
    "integrations": [
        { path: "schedules", codTypePrivilege: null },
        { path: "connections", codTypePrivilege: null },
        { path: "interfaces", codTypePrivilege: null },
        { path: "worker_panel", codTypePrivilege: null },
        { path: "bank_accounts", codTypePrivilege: null }
    ],
    "administration": [
        { path: "attempts", codTypePrivilege: 1 },
        { path: "summary_logs", codTypePrivilege: 1 },
        { path: "template_history", codTypePrivilege: 1 },
        { path: "permissions_panel", codTypePrivilege: 1 },
        { path: "customer_management", codTypePrivilege: 2 },
        { path: "user_privileges", codTypePrivilege: 1 }
    ],
    "maintenance": [
        { path: "start_interfaces", codTypePrivilege: 4 },
        { path: "extend_interfaces", codTypePrivilege: 3 }
    ]
}

export default SidebarNavigationPaths