import request from '../../utils/request'
import Session from "../../utils/session-info"

export default {
    GetBanks: () => {
        return request.executeRequest(
            `${request.baseUrl}bank_accounts/banks`,
            request.getOptions()).then(res => res.json())
    },
    GetBankAccounts: (codBank = "AllBankAccounts") => {
        return request.executeRequest(
            `${request.baseUrl}bank_accounts/get_accounts/${codBank}`, request.getOptions())           
            .then((res) => {
                if(res.status == 401) return res = new Array
                else return res.json()
            })
    },
    GetBankAccount: (idAccount) => {
        return request.executeRequest(
            `${request.baseUrl}bank_accounts/get_account/${idAccount}`,
            request.getOptions()).then(res => res.json())
    },
    RegisterAccount: (bankAccount) => {
        return request.executeRequest(
            `${request.baseUrl}bank_accounts/insert`,
            {
                ...request.postOptions(),
                body: JSON.stringify({cod_instance: Session().codInstance, ...bankAccount})
            }
        )
    },
    EditAccount: (bankAccount) => {
        return request.executeRequest(
            `${request.baseUrl}bank_accounts/edit`,
            {
                ...request.putOptions(),
                body: JSON.stringify({cod_instance: Session().codInstance, ...bankAccount})
            })
    },
    DeleteAccount: (id_account, cod_bank) => {
        return request.executeRequest(
            `${request.baseUrl}bank_accounts/delete`,
            {
                ...request.deleteOptions(),
                body: JSON.stringify({id_account: id_account, cod_bank: cod_bank})
            })
    },
    GetUserPermissionAccessLevelBankAccounts: () => {
        return request.executeRequest(
            `${request.baseUrl}bank_accounts/userPermissionAccessLevel`,
            request.getOptions())
    }
}