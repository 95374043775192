import React from 'react'
import { Container, LoadingDefault } from "../componentsUI/styledComponents/styledLoading"

const Loading = () => {
  return (
    <Container>
      <LoadingDefault />
    </Container>
  )
}

export default Loading