export default { 
    //https://stackoverflow.com/a/47666869
    shallowEquals: (arr1, arr2) => {
        if(!(arr1 != null && arr2 != null && arr1.length == arr2.length)) {
            return false;
        }

        arr1 = [].concat(arr1);
        arr2 = [].concat(arr2);
    
        return arr1.every(function(element) {
            return arr2.some(function(e, i) {
                return e === element && (arr2[i] = undefined, true);
            });
        });
    }
}