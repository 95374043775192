import Translate from '../i18n/translate'

export default (codAttribute) => {
     switch (codAttribute) {
            case 37:
            case 162:
                return  Translate('in_seconds')
            default:
                return '';
        }
}