import React, { Component, Fragment } from "react"
import Translate from "../../../i18n/translate"
import ConnectionsService from "../connections-service"
import Session from "../../../utils/session-info"
import { DialogContent, DialogActions, Grid2 } from "@mui/material"
import { StyledLeftButton, StyledLoading, titleStyles } from "../../../componentsUI/styledComponents/styledConnectionsCopyModal"
import ConectorSelect from "../../../componentsUI/conectorSelect"
import StyledButton from "../../../componentsUI/styledComponents/styledButton"
import Loading from "../../../componentsUI/loading"
import ConectorInputText from "../../../componentsUI/inputText"
import ConectorInputPassword from "../../../componentsUI/inputPassword"

class ConnectionsCopyModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            connectionID: props.connectionID,
            getConnectionRequestCompleted: false,
            getInstancesWithConnectionPermissionRequestCompleted: false,
            getAttributesByCodConnectionModeRequestCompleted: false,
            instancesOptions: undefined,
            setConnectionParameters: false,
            attributes: undefined,
            connection: { description: "" }
        }
    }

    componentWillMount = () => {
        this.getInstancesWithConnectionPermission()
        this.getConnection()
    }

    getInstancesWithConnectionPermission = () => {
        ConnectionsService.GetInstancesWithConnectionPermission()
        .then(instancesWithConnectionPermission => {
            return instancesWithConnectionPermission.map(instance => {
                return {
                    value: instance.CodInstance,
                    label: `${instance.DesCompany} => ${instance.DesInstance} ${instance.CodInstance}`,
                    desInstance: instance.DesInstance
                }
            })
        })
        .then(instancesOptions => {
            this.setState({instancesOptions, getInstancesWithConnectionPermissionRequestCompleted: true})
        })
    }

    getConnection = () => {
        const { connectionID } = this.state

        ConnectionsService.GetConnection(connectionID)
        .then(connection => {
            delete connection.id
            delete connection.dateModification
            delete connection.dateCreation

            connection.codInstance = Session().codInstance
            connection.description = ""
            
            return connection
        })
        .then(connection => {
            this.setState({connection, getConnectionRequestCompleted: true}, this.getAttributesByCodConnectionMode(connection.codConnectionMode))
        })
    }

    getAttributesByCodConnectionMode = codConnectionMode => {
        ConnectionsService.GetAttributesByCodConnectionMode(codConnectionMode)
        .then(attributes => {
            this.setState({attributes, getAttributesByCodConnectionModeRequestCompleted: true})
        })
    }

    connectionTitleAndDestinyInstance = () => {
        const {
            connection,
            instancesOptions
        } = this.state

        return (
            <Fragment>
                <DialogContent>
                    <Grid2 container spacing={1}>
                        <Grid2 size={16}>
                            <ConectorInputText
                                value={connection.description}
                                onChange={inputObject => this.setState({ connection: { ...this.state.connection, description: inputObject.target.value } })}
                                label="connectionTitle"
                                placeholder="enterTitleCopyConnection"
                                help="enterTitleCopyConnection"/>
                        </Grid2>
                        <Grid2 size={16}>
                            <ConectorSelect
                                label="dest_instance"
                                help="select_target_instance"
                                onChange={selectedOption => this.setState({ connection: { ...this.state.connection, codInstance: selectedOption.value } })}
                                options={instancesOptions}
                                value={instancesOptions.find(option => option.value === connection.codInstance)}/>
                        </Grid2>
                    </Grid2>
                </DialogContent>
            </Fragment>
        )
    }

    changeValueOfInputConnectionParameter = inputObject => {
        const { connection } = this.state
        const inputCodAttribute = parseInt(inputObject.target.name, 10)
        const inputTextValue = inputObject.target.value

        connection.attributes.find(attr => attr.codAttribute == inputCodAttribute).value = inputTextValue

        this.setState({connection})
    }

    getAttributeValue = codAttribute => {
        const { connection } = this.state
        const attribute = connection.attributes.find(attr => attr.codAttribute === parseInt(codAttribute, 10))

        if (attribute && attribute.value) return attribute.value
        else return ''
    }

    connectionParameters = () => {
        const { attributes } = this.state

        const connectionInputParameters = attributes.map(attribute => {
            if(attribute.codAttributeType == 10){
                return (
                    <Grid2 size={4}>
                        <ConectorInputPassword
                            name={attribute.codAttribute}
                            label={Translate(attribute.label)}
                            required={attribute.isRequired === 'true'}
                            value={this.getAttributeValue(attribute.codAttribute)}
                            onChange={this.changeValueOfInputConnectionParameter.bind(this)}/>
                    </Grid2>)
            } else {
                return (
                    <Grid2 size={4}>
                        <ConectorInputText
                            name={String(attribute.codAttribute)}
                            label={Translate(attribute.label)}
                            required={attribute.isRequired === 'true'}
                            value={this.getAttributeValue(attribute.codAttribute)}
                            onChange={this.changeValueOfInputConnectionParameter.bind(this)}/>
                    </Grid2>)
            }
        })

        return(
            <Fragment>
                <DialogContent>
                    {<div style={titleStyles}>{Translate("connectionParams")}</div>}
                    <Grid2 container spacing={2}>
                        {connectionInputParameters}
                    </Grid2>
                </DialogContent>
            </Fragment>
        )
    }

    connectionComponents = () => {
        const { setConnectionParameters } = this.state

        if(setConnectionParameters) return this.connectionParameters()
        else return this.connectionTitleAndDestinyInstance()
    }

    buttons = () => {
        const { setConnectionParameters } = this.state

        return(
            <DialogActions>
                <StyledLeftButton 
                    variant="contained" 
                    color="primary"
                    style={{ display: setConnectionParameters == false ? "none" : "block" }}
                    onClick={() => this.setState({setConnectionParameters: false})}>
                    {Translate("back")}
                </StyledLeftButton>
                <StyledButton 
                    variant="contained" 
                    color="primary"
                    onClick={this.props.cancelCopyConnection.bind(this)}>
                    {Translate("cancel")}
                </StyledButton>
                {this.saveOrForwardButton()}
            </DialogActions>
        )
    }

    saveOrForwardButton = () => {
        const { connection, setConnectionParameters } = this.state

        const goForwardButton = (
            <StyledButton 
                variant="outlined"
                disabled={connection.description == ""}
                onClick={() => this.setState({setConnectionParameters: true})}>
                {Translate("go_forward")}
            </StyledButton >
        )

        const saveButton = (
            <StyledButton 
                variant="outlined"
                disabled={connection.description == ""}
                onClick={this.saveConnection.bind(this)}>
                {Translate("save")}
            </StyledButton >
        )

        if(setConnectionParameters == true) return saveButton
        else return goForwardButton
    }

    saveConnection = () => {
        const { connection, instancesOptions } = this.state

        const nameOfDestinyInstance = instancesOptions.find(option => {
            return option.value === connection.codInstance
        }).desInstance

        this.props.saveCopyConnection(connection, nameOfDestinyInstance)
    }

    render = () => {
        const { 
            getInstancesWithConnectionPermissionRequestCompleted, 
            getConnectionRequestCompleted, 
            getAttributesByCodConnectionModeRequestCompleted 
        } = this.state

        return (
            <div>
                {getInstancesWithConnectionPermissionRequestCompleted && 
                getConnectionRequestCompleted && 
                getAttributesByCodConnectionModeRequestCompleted ? 
                this.connectionComponents() : <StyledLoading><Loading/></StyledLoading>}
                {this.buttons()}
            </div>
        )
    }
}

export default ConnectionsCopyModal