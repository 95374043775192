import React, {Component, Fragment} from "react"
import ScheduleService from "../../../../routes/schedules/schedules-service"
import { Grid2 } from "@mui/material"
import ConectorDatePicker from "../../../../componentsUI/datePicker"
import ConectorInputText from "../../../../componentsUI/inputText"
import ConectorCheckbox from "../../../../componentsUI/checkBox"
import ConectorSelect from "../../../../componentsUI/conectorSelect"

class ScheduleStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isChecked: false,
            schedule: null,
            schedules: [],
            indContinuousVal: 180,
            ...props
        };
    }

    componentWillMount() {
        this.setState({
            desSchedule: "",
            datSchedule: new Date().toISOString(),
            isActive: true,
            ...this.state
        }, this.updateParent);

        const {
            scheduleTypes
        } = this.state;

        if (scheduleTypes.length == 0) {
            this.getScheduleTypes();
        }

        this.getSchedules();
    }

    getScheduleTypes() {
        ScheduleService.GetTypes().then(
            ((scheduleTypes) => {
                this.setState({
                    scheduleTypes: scheduleTypes.map(
                        ({ des_label, id }) => ({
                            value: id,
                            label: des_label
                        })
                    ),
                    codScheduleType: scheduleTypes[0].id
                });
            }).bind(this)
        )
    }

    getSchedules() {
        const {
            targetInstance,
            schedule
        } = this.state;

        ScheduleService.GetSchedules(targetInstance).then(schedules => {
            const hasSchedules = schedules.length > 0;

            this.setState({
                schedules: schedules.map(({ codSchedule, desSchedule }) => {
                    return {
                        value: codSchedule,
                        label: desSchedule
                    }
                }),
                schedule: hasSchedules && schedule == null ? schedules[0].codSchedule : schedule
            });
        })
    }

    updateParent() {
        this.props.updateParent({
            ...this.state,
            stepDisabled: this.stepDisabled()
        });
    }

    stepDisabled() {
        const {
            isChecked,
            desSchedule,
            codScheduleType
        } = this.state;

        if (!isChecked && (!codScheduleType || !desSchedule || desSchedule.trim() === "")) {
            return true;
        }

        return false;
    }

    handleChange({ target: { name, value } }) {
        this.setState({
            [name]: value
        }, this.updateParent);
    }

    handleChangeSelect({ name, value }) {
        let {
            indContinuousVal
        } = this.state;

        if (name == "codScheduleType") {
            if (value === 1) {
                indContinuousVal = 180;
            }else if (value === 7) {
                indContinuousVal = 60;
            } else {
                indContinuousVal = 0;
            }
        }

        this.setState({
            [name]: value,
            indContinuousVal
        }, this.updateParent);
    }

    handleChangeCheckbox() {
        let {
            isChecked
        } = this.state;

        this.setState({
            isChecked: !isChecked
        }, this.updateParent);
    }

    render() {
        const {
            schedule,
            schedules,
            scheduleTypes,
            desSchedule,
            datSchedule,
            codScheduleType,
            isChecked
        } = this.state;

        return (
            <Fragment>
                {
                    schedules.length > 0 ? (
                        <Grid2 container spacing={1}>
                            <Grid2 size={10}>
                                <ConectorCheckbox label={"existing_schedule"} name="isChecked" color="default"
                                    value={isChecked} onChange={this.handleChangeCheckbox.bind(this)} checked={isChecked}/>
                            </Grid2>
                        </Grid2>
                    ) : null
                }
                {
                    isChecked ? (
                        <Grid2 container spacing={1}>
                            <Grid2 size={15}>
                                <ConectorSelect
                                    label={"schedule"}
                                    name="schedule"
                                    placeholder={" "}
                                    onChange={this.handleChangeSelect.bind(this)}
                                    value={schedules.find(option => option.value === schedule)}
                                    options={schedules}
                                    required={true}/>
                            </Grid2>
                        </Grid2>
                    ) : (
                            <Fragment>
                                <Grid2 container spacing={1}>
                                    <Grid2 size={15}>
                                        <ConectorInputText
                                            label={"description"}
                                            help={"enter_description"}
                                            name={"desSchedule"}
                                            value={desSchedule}
                                            onChange={this.handleChange.bind(this)}
                                            required={true}/>
                                    </Grid2>
                                </Grid2>
                                <Grid2 container spacing={1}>
                                    <Grid2 size={15}>
                                        <ConectorDatePicker
                                            value={datSchedule}
                                            onChange={this.handleChange.bind(this)}
                                            showTime={true}
                                            name="datSchedule"
                                            help="enter_date"
                                            label="date"/>
                                    </Grid2>
                                    <Grid2 size={15}>
                                        <ConectorSelect
                                            label={"type"}
                                            help={"enter_type"}
                                            name="codScheduleType"
                                            placeholder={" "}
                                            onChange={this.handleChangeSelect.bind(this)}
                                            value={scheduleTypes.find(option => option.value === codScheduleType)}
                                            options={scheduleTypes}
                                            required={true}/>
                                    </Grid2>
                                </Grid2>
                            </Fragment >
                        )
                }
            </Fragment>
        )
    }
}

ScheduleStep.defaultProps = {
    scheduleTypes: []
};

export default ScheduleStep;