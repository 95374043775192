import styled from 'styled-components'
import Paper from '@mui/material/Paper'

const StyledPaper = styled(Paper)`
  && {
    padding: 20px;
    background-color: #FFFFFF;
    border: 1px solid #B8C6D6;
    box-shadow: 0 0;
    margin-bottom: 10px;
    justify-content: center;
    &.bg_darken_04 {
      background-color: #F2F2F2;
    } 
  }
`

export default StyledPaper