import React, { Component, Fragment } from 'react';
import StyledSelect from './styledComponents/styledSelect';
import StyledInputLabel from './styledComponents/styledInputLabel';
import StyledFormHelpText from './styledComponents/styledFormHelpText';
import { StyledFormControl } from "./styledComponents/styledFormControl";
import Translate from '../i18n/translate';
import TooltipValue from './conector-tooltip-selector';

class ConectorSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ ...props });
  }

  handleChange = e => {
    if (this.state.onChange) {
      let changeParam = {
        ...e,
        name: this.state.name
      };

      this.state.onChange(changeParam);
    }
  };

  render() {
    var {
      required,
      className,
      options,
      invisible,
      label,
      name,
      placeholder,
      disabled,
      value,
      help,
      errorCondition,
      width, // Adiciona a prop width
      showTooltip
    } = this.state;

    invisible = Boolean(invisible);
    errorCondition = Boolean(errorCondition);
    required = Boolean(required);

    if (invisible) {
      return <Fragment></Fragment>;
    }

    if (options.length) {
      options.forEach(option => option.label = Translate(option.label));
    }

    if (errorCondition) {
      className = `${className || ''} error`;
    }

    if (required) {
      className = `${className || ''} required`;
    }

    return (
      <Fragment>
        <StyledFormControl width={width}>
          <StyledInputLabel className={className}>{Translate(label)}</StyledInputLabel>
          <StyledSelect
            select
            required={required}
            id={`conector-input-select-${name}`}
            classNamePrefix="select-default"
            name={name}
            placeholder={Translate(placeholder)}
            onChange={this.handleChange.bind(this)}
            isDisabled={disabled}
            options={options}
            value={value}
            className={className}
            components={{ Option: (optionProps) => <TooltipValue {...optionProps} showTooltip={showTooltip} /> }}
          >
          </StyledSelect>
          <StyledFormHelpText style={!help ? { display: 'none' } : {}}>
            {Translate(help)}
          </StyledFormHelpText>
        </StyledFormControl>
      </Fragment>
    );
  }
}

export default ConectorSelect;
