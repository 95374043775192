import React, { Component } from "react"
import Translate from "../../../i18n/translate"
import Session from "../../../utils/session-info"
import UserService from "../../../routes/users/user-service"
import VersionService from "./version-service"
import { DialogContent, DialogActions, Tooltip, Grid2 } from "@mui/material"
import { MdEdit, MdDelete, MdCloudDownload } from "react-icons/md"
import ConectorDialogConfirmation from "../../../componentsUI/dialogComponentConfirmation"
import StyledInputLabel from "../../../componentsUI/styledComponents/styledInputLabel"
import InputText from "../../../componentsUI/inputText"
import StyledIconButton from "../../../componentsUI/styledComponents/styledIconButton"
import StyledButton from "../../../componentsUI/styledComponents/styledButton"
import { StyledDialog, StyledDialogTitle } from "../../../componentsUI/styledComponents/styledDialog"
import { TextDescription, PanelTitle } from "../../../componentsUI/styledComponents/styledVersionModal"

class VersionModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasPrivilege: false,
            isEditing: null,
            infos: new Array,
            newNotes: new Array,
            url: "",
            newTitle: ""
        }
    }

    componentWillMount = () => {
        const { softwareSystemName } = this.props

        Promise.all([
            VersionService.getPathNotes(softwareSystemName),
            UserService.FindPrivilege(Session().codUser, 5)
        ]).then(([Infos, hasPrivilege]) => {
            this.setState({ infos: Infos, hasPrivilege })
        })
    }

    deleteVersionConfirm = id => {
        const { softwareSystemName } = this.props

        this.setState({ 
            confirmationModalOptions: { open: false },
            isEditing: null,
            newTitle: "",
            newNotes: []
        })

        Promise.all([
            VersionService.deletePathNotes(id),
            VersionService.getPathNotes(softwareSystemName)
        ]).then(([, Infos]) => {
            this.setState({ infos: Infos })
        })
    }

    getPathNotes = () => {
        const { softwareSystemName } = this.props

        VersionService.getPathNotes(softwareSystemName)
        .then(pathNotes => {
            this.setState({infos: pathNotes})
        })
    }

    handleSave = index => {
        const { newTitle, newNotes, infos, url } = this.state
        const { softwareSystemName } = this.props

        const updatedInfos = [...infos];
        const filteredNotes = newNotes.filter(note => note.trim() !== "")
    
        if (newTitle.trim() === "" || filteredNotes.length === 0) {
            return
        }

        updatedInfos[index] = {
            ...updatedInfos[index],
            id_path: updatedInfos[index].id_path || 0,
            version: newTitle,
            system: softwareSystemName,
            url: url,
            notes: filteredNotes.map((note, i) => ({
                id_note: i < (updatedInfos[index].notes ? updatedInfos[index].notes.length : 0) ? updatedInfos[index].notes[i].id_note : 0,
                id_path: updatedInfos[index].id_path || 0,
                note: note
            }))
        }
        
        Promise.all([VersionService.addOrEditVersion(updatedInfos[index])])
        .then(([, Infos]) => {
            this.setState({
                isEditing: null,
                url: "",
                newTitle: "",
                newNotes: []
            }, () => this.getPathNotes())
        })
        .catch(error => console.error("Erro ao salvar as alterações:", error))
    }

    handleEdit = index => {
        const { infos } = this.state

        this.setState({
            isEditing: index,
            newTitle: infos[index].version,
            url: infos[index].url,
            newNotes: infos[index].notes.map(note => note.note)
        })
    }

    handleChangeTitle = event => {
        this.setState({ newTitle: event.target.value })
    }

    handleChangeUrl = event => {
        this.setState({ url: event.target.value })
    }

    handleChangeNote = (event, index) => {
        const newNotes = [...this.state.newNotes]
        newNotes[index] = event.target.value

        this.setState({ newNotes })
    }

    handleAddNote = () => {
        this.setState((prevState) => ({
            newNotes: [...prevState.newNotes, ""]
        }))
    }

    handleDeleteNote = index => {
        const newNotes = [...this.state.newNotes]
        newNotes.splice(index, 1)

        this.setState({ newNotes })
    }

    dismissModal() {
		this.setState({ confirmationModalOptions: { open: false } })
	}

    handleDeleteVersionModal = id => {
        this.setState({
            confirmationModalOptions: {
                type: "danger",
                message: "remove_records",
                title: "deleteConfirmation",
                open: true,
                onDismiss:() => this.dismissModal,
                onClose: () => this.deleteVersionConfirm(id) 
            }
        })
    }

    getTitle = systemName => {
        switch (systemName) {
            case "Portal":
                return "historic_version_portal"
            case "Worker":
                return "historic_version_worker"
            case "MicroBack":
                return "historic_version_microback"
            case "MicroFront":
                return "historic_version_microfront"
            case "MicroDash":
                return "historic_version_microdash"
            default:
                break;
        }
    }

    handleAddVersion = () => {
        const { infos } = this.state
    
        const newVersion = {
            id_path: 0, 
            version: "",
            url: "",
            notes: [{ id_note: 0, note: "" }] 
        }
    
        this.setState({
            infos: [newVersion, ...infos],
            isEditing: 0,
            url: "",
            newTitle: "",
            newNotes: [""]
        })
    }

    renderVersion = () => {
        const { infos, isEditing, newTitle, newNotes, url, hasPrivilege } = this.state

        return infos.map((i, index) => (
            <div key={index.toString()}>
                {isEditing === index ? (
                    <div> 
                        <DialogContent>
                            <InputText
                                label={"Versão"}
                                name={"version"}
                                type={"text"}
                                value={newTitle}
                                onChange={this.handleChangeTitle}/>
                            <InputText
                                label={"Documentação (URL)"}
                                name={"url"}
                                type={"text"}
                                value={url}
                                onChange={this.handleChangeUrl}/>
                            <StyledInputLabel>
                                {Translate("Notes")}
                            </StyledInputLabel>
                            {newNotes.map((note, i) => (
                                <Grid2 container spacing={12} key={i}>
                                    <Grid2 item sm={11}>
                                        <InputText
                                            name={"note" + i}
                                            type={"text"}
                                            value={note}
                                            onChange={(event) => this.handleChangeNote(event, i)}/>
                                    </Grid2>
                                    <Grid2 item sm={1}>
                                        <Tooltip title={Translate("remove")} placement="top">
                                            <span>
                                                <StyledIconButton onClick={() => this.handleDeleteNote(i)}>
                                                    <MdDelete className={"default"} />
                                                </StyledIconButton>
                                            </span>
                                        </Tooltip>
                                    </Grid2>
                                </Grid2>
                            ))}
                            <br></br>
                            <Grid2 container spacing={1}>
                                <StyledButton variant="contained" onClick={this.handleAddNote}>
                                    {Translate("addNote")}
                                </StyledButton>
                                <StyledButton variant="outlined" onClick={() => this.handleSave(index)}>
                                    {Translate("save")}
                                </StyledButton>
                            </Grid2>
                        </DialogContent>
                    </div>
                ) : (
                    <div>
                        <Grid2 container>
                            <PanelTitle>{'v' + i.version}</PanelTitle>
                            <Grid2>
                                {i.url != null && (
                                    <Tooltip title={"Documentação"}>
                                        <StyledIconButton onClick={() => window.open(i.url, '_blank')}>
                                            <MdCloudDownload fontSize="small"/>
                                        </StyledIconButton>
                                    </Tooltip>
                                )}
                                {hasPrivilege && (
                                    <>
                                        <Tooltip title={Translate("edit")}>
                                            <StyledIconButton
                                                disabled={isEditing != null}
                                                onClick={() => this.handleEdit(index)}>
                                                <MdEdit fontSize="small"/>
                                            </StyledIconButton>
                                        </Tooltip>
                                        <Tooltip title={Translate("remove")}>
                                            <span>
                                                <StyledIconButton
                                                    disabled={isEditing != null}
                                                    onClick={() => this.handleDeleteVersionModal(i.id_path)}>
                                                    <MdDelete fontSize="small"/>
                                                </StyledIconButton>
                                            </span>
                                        </Tooltip>
                                    </>
                                )}
                            </Grid2>
                        </Grid2>
                        <TextDescription>
                            {i.notes.map((note, i) => (
                                <li key={i}>{note.note}</li>
                            ))}
                        </TextDescription>
                    </div>
                )}
            </div>
        ))
    }

    render() {
        const { isEditing, confirmationModalOptions, hasPrivilege } = this.state
        const { softwareSystemName } = this.props

        return (
            <StyledDialog
                open={true}
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                fullWidth={true}
                maxWidth={"sm"}>
                <StyledDialogTitle className="info">
                    <Grid2 container justifyContent="space-between">
                        <Grid2>
                            {Translate(this.getTitle(softwareSystemName))}
                        </Grid2>
                        {hasPrivilege &&
                            <Grid2>
                                <StyledButton variant="outlined" onClick={this.handleAddVersion} disabled={isEditing != null}>
                                    {Translate("add")}
                                </StyledButton>
                            </Grid2>
                        }
                    </Grid2>
                </StyledDialogTitle>
                <DialogContent>
                    <Grid2 container spacing={24}>
                        <Grid2 item xs={24} sm={24} md={24} lg={12} xl={12}>
                            {this.renderVersion()}
                        </Grid2>
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={this.props.onDismiss}>
                        {Translate("close")}
                    </StyledButton>
                </DialogActions>
                <ConectorDialogConfirmation dialogOptions={confirmationModalOptions}/>
            </StyledDialog>
        )
    }
}

export default VersionModal;
