import * as types from './worker-store-action-types'
import WorkService from './worker-store-service'
import sessionInfo from '../../utils/session-info'

export const defineWorkerProperties = () => {
    return async dispatch => {
        WorkService
            .getAllByWorker(sessionInfo().codInstance)
            .then(res => {
                dispatch({
                    type: types.WORKER_DEFINE_PROPERTIES,
                    workerProperties: res
                })
            })
    }
}

