import styled from "styled-components"
import {
    Grid2,
    ListItem,
    Typography,
    Card,
    List
} from "@mui/material";

export const StyledTypography = styled(Typography)`
    && { 
        padding-bottom: 20px;
        color: #6F8296;
    }
`

export const StyledGrid = styled(Grid2)`
    && { 
        width: calc(100% + 12px);

        div.MuiGrid-item:first-child {
            max-width: 100%;
            flex-basis: 100%;
        }
    }
`

export const DivSearch = styled.div`
    && {
        margin: 20px 20px 30px 20px;
        display: flex;
        align-items: center;
        > div { flex-basis: 80%; }
    }
`

export const StyledList = styled(List)`
    && {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 1px solid #B8C6D6;
    }
`

export const StyledCard = styled(Card)`
  && {
    margin: 20px;
    box-shadow: 0 0 0 0;
    border: 1px solid #B8C6D6;
    width: 560px;
    height: 300px;
  }
`

export const StyledListItem = styled(ListItem)`
    && {
        width: 560px;
        border-top: 1px solid #B8C6D6;
        background-color: #F5F5F5;
        color: #6F8296;
        
        &:first-child { border-top: 0px; }
        &:hover { background-color: #B8C6D6; }

        .MuiListItemText-root span {
            padding-top: 7px;
            padding-bottom: 3px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.8rem;
            color: #6F8296;
        }
        
        &.selected {
            background-color: #009cdf;
            .MuiListItemIcon-root svg,
            .MuiListItemText-root span {
                color: #fff;
            }
        }
    }
`

export const FooterDiv = styled.div`
    width: 100%;
    height: 55px;
    box-sizing: border-box;
    padding: 0 10px 10px 10px;

    button {
      float: right;
      margin-right: 10px;
    }

    @media screen and (max-width:390px) {
      button {
        min-width: 115px !important;
      }
    }

    @media screen and (max-width:320px) {
      button {
        min-width: calc(100% - 20px) !important;
        margin-bottom: 10px;
      }
    }
`