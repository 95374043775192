import styled from 'styled-components'
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"

export const StyledPaperRest = styled(StyledPaper)`
    && { 
        border-width: 0;
        padding: 0;
    }
`

export const StyledDiv = styled.div`
    && { 
        position: relative;
    }
`