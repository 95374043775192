import { styled } from '@mui/system';
import { Table, Divider } from '@mui/material';

export const StyledDivider = styled(Divider)(({ theme }) => ({
  bottom: 0,
  position: 'sticky',
  backgroundColor: '#B8C6D6',
}));

export const StyledGridContainer = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'auto',

  '&.scrollWidth': {
    hr: { width: '100%' },

    '&.scrollHeight': {
      hr: { width: '100%' },
    },

    '&:before': {
      content: '""',
      width: '1px',
      right: '32px',
      zIndex: 9999,
      position: 'absolute',
      backgroundColor: '#B8C6D6',
    },
  },

  '&.scrollHeight': {
    hr: { width: 'calc(100% - 7px)' },
  },

  '::-webkit-scrollbar-corner': { backgroundColor: '#eee' },
  '::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
    backgroundColor: '#eee',
    cursor: 'pointer',
  },

  '::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
  },
}));

export const StyledTableFooter = styled(Table)(({ theme }) => ({
  background: 'white',
  tr: { border: '1px solid #B8C6D6' },
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  tbody: {
    '> tr.contentRow > td:not(:last-child):not(.checkbox)': {
      padding: '10px 24px'
    },
    '> tr.contentRow > td:not(.checkbox)': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    'td.pointer': { cursor: 'pointer' },
  },

  tr: {
    height: 'auto',
    borderRight: '1px solid #B8C6D6',
    borderLeft: '1px solid #B8C6D6',
    '&.Mui-selected td': {
      backgroundColor: 'rgba(224, 224, 224, 1)',
      cursor: 'default',
    },
    th: {
      color: '#4c637b',
      span: { color: '#4c637b' },
      cursor: 'pointer',
      padding: '10px 24px',
      backgroundColor: '#B8C6D6',
      textTransform: 'uppercase',
      '&:hover': { backgroundColor: '#b1bfce' },
      '&.bgActive': { fontWeight: 'bold', fontSize: '0.8rem' },
      '&.MuiTableCell-paddingCheckbox': {
        width: '50px',
        padding: '0 12px',
        svg: { color: '#6F8296' },
      },
    },
    td: {
      position: 'relative',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      backgroundColor: '#fff',
      color: '#6F8296',
      lineHeight: '5px',
      padding: 10,
      '&.MuiTableCell-paddingCheckbox': {
        padding: '0 12px',
        svg: { color: '#6F8296' },
      },
      '.iconCopy': {
        position: 'absolute',
        marginLeft: '-30px',
        top: 'calc(50% - 13px)',
        cursor: 'pointer',
        '&:hover': { color: '#009CDF' },
      },
      '&.notFound': {
        textAlign: 'center',
        paddingTop: '20px',
        paddingBottom: '20px',
        fontWeight: 'bold',
      },
    },
    '&:last-child td': { borderBottom: 0 },
    'tbody tr': { cursor: 'pointer' },
  },

  thead: {
    '> tr > th': {
      top: 0,
      zIndex: 2,
      position: 'sticky'
    },
    '> tr > th:last-child': {
      paddingRight: '0px'
    },
  },

  '&.full-size-container': {
    width: '100%',
    marginRight: 0
  },

  margin: 'auto',
  borderCollapse: 'collapse',
}));
