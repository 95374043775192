import * as types from './worker-store-action-types';
// import request from '../../utils/request'

const initialState = {
    workerProperties: []
};

export default function reduce(store = initialState, action = {}) {
    switch (action.type) {
        case types.WORKER_DEFINE_PROPERTIES:
            return {
                ...store,
                workerProperties: action.workerProperties
            }
        default:
            return store;
    }
}

export function getWorkerProperties(store) {
    return store.worker.workerProperties
}