import Typography from '@mui/material/Typography'
import styled from "styled-components"

export const TitlePageDefault = styled(Typography)`
	&& {
		width: 100%;
		text-align: left;
		font-size: 1.5rem;
		display: inline-block;
		color: #6F8296;

		&:not(.without-margin) {
			padding: 3px 0;
			margin-bottom: 20px;
		}
	}
`;