import request from '../../../utils/request'

export default {
    GetAllByTemplateHistory: (codInstance) => {
        return request.executeRequest(
            `${request.baseUrl}instance_history/byCodInstance/${codInstance}`,
            request.getOptions()
        ).then(res => res.json())
    }
}
