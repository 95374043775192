import styled from 'styled-components'
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import StyledButton from "../../componentsUI/styledComponents/styledButton"

export const  StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px);
`

export const  StyledPaperLogin = styled(StyledPaper)`
    && { padding: 20px 0 0 0; }
`

export const  StyledButtonLogin = styled(StyledButton)`
    && {
        padding: 10px;
        font-size: 0.8rem;
        
        &.forgotPassword, &.register {
            padding: 16px 8px;
            font-size: 0.6rem;
        } 

        &.forgotPassword {
            border-radius: 0 0 0 4px;
            border: 1px solid #B8C6D6;
            background-color: #B8C6D6;
            color: #6F8296;
            &:hover { 
                border: 1px solid #d7dfe9;
                background-color: #d7dfe9; 
            }
        }

        &.register {
            border-radius: 0 0 4px 0;
            border: 1px solid #6F8296;
            background-color: #6F8296;
            &:hover { 
                border: 1px solid #97a5b3;
                background-color: #97a5b3; 
            }
        }
    }
`

export const StyledForm = styled.div`
    width: 400px;
    .MuiAvatar-root { margin: 0 auto; }
    
    .marginForm {
        margin: 24px;
        width: calc(100% - 48px);
        .MuiGrid-item { margin: 10px 0; }
    }

    h1 {
        text-align: center;
        font-size: 1.5rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        color: #6F8296;
    }
`

export const ResetTitle = styled.div`
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 22px;
    color: #6F8296;
`

export const ResetSubtitle = styled.div`
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #B8C6D6;
`
export const ResetPasswordFormStyled = {
    paddingTop: '0px',
    paddingRight: '15px',
    paddingLeft: '15px',
    paddingBottom: '10px'
}

export const SubTitleStyled = {
    textAlign: 'center', 
    paddingBottom: '5px'
}

export const InputTextStyled = {
    paddingBottom: '10px',
    margin: '5px'
}

export const GoBackButtonStyled = {
    paddingTop: '10px'
}