import React, { Component, Fragment } from 'react'
import Session from "../../utils/session-info"
import SettingButton from './settings-button'
import ScenarioButton from './scenarios-button'
import { Toolbar, Avatar } from '@mui/material'
import Translate from "../../i18n/translate"
import StyledHeaderAppBar from "../../componentsUI/styledComponents/styledHeaderAppBar"
import StyledTitlePage from "../../componentsUI/styledComponents/styledHeaderTitlePage"

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            ...this.state,
            appState: {...props.appState}
        });
    }

    handleDrawerOpen = _ => {
        let state = {...this.state}
        state.appState.openDrawer = !state.appState.openDrawer
        this.state.appState.refresh(state.appState)
    }

    userLoggedIn = () => { return Session().hasOwnProperty("codUser") }

    renderTitlePage = () => {
        const { appState } = this.state
        const headerTitlePage = appState.breadcrumb[1] === undefined ? '' : appState.breadcrumb[1].label 

        return (
            <Fragment>
                <Avatar 
                    alt="ConectorWebAPI" 
                    src="/static/images/conector-web-img.png" 
                    sx={{marginRight: "10px", cursor: "pointer"}}
                    onClick={() => window.location.replace("#/")}/>
                <StyledTitlePage>{Translate(headerTitlePage)}</StyledTitlePage>
            </Fragment>
        )
    }

    render() { 
        const { appState } = this.state

        if (!appState.userLoggedIn) return (<Fragment></Fragment>)

        return (
            <Fragment>
                <StyledHeaderAppBar>
                    <Toolbar style={{ borderBottom: "1px solid #B8C6D6" }}>
                        {this.renderTitlePage()}
                        <div className={'groupButtons'}>
                            <ScenarioButton appState={appState}/>
                            <SettingButton />
                        </div>
                    </Toolbar>
                </StyledHeaderAppBar> 
            </Fragment>

        )
    }
}

export default Header