import React, {
    Component,
    Fragment
} from "react"
import { connect } from "react-redux"
import {
    insertNode,
    getNodeAtPath,
    changeNodeAtPath,
    removeNodeAtPath
} from "@nosferatu500/react-sortable-tree"
import {
    MdDelete,
    MdAddCircle,
    MdEdit,
    MdCheckCircle,
    MdCancel
} from "react-icons/md"
import * as inputSelector from "../../../../store/interfaces/reducer"
import * as wizardStore from "../../../../store/wizard/wizard-store-reducer"
import Uuid from "../../../../utils/uuid-utils"
import Translate from "../../../../i18n/translate"
import Toast from "../../../../components/toast/toast"
import { StyledSortableTree } from "../../../../componentsUI/styledComponents/styledSortableTree"
import StyledButton from "../../../../componentsUI/styledComponents/styledButton"
import ConectorInputText from "../../../../componentsUI/inputText"
import ConectorSelect from "../../../../componentsUI/conectorSelect"
import { Grid2 } from "@mui/material"
import { InlineIconButton } from "../../../../componentsUI/styledComponents/styledWizardStructure"
import getDetailTreeHeightInPixels from "../../../../utils/getDetailTreeHeightInPixels"

class WizardStructureDetailsTree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props
        };
    }

    componentWillReceiveProps = props => {
        this.setState({ ...props }, () => {
            let { treeData } = this.state;

            if (treeData && treeData.length) {
                if (treeData[0].firstSelectAfterInputChange) {
                    setTimeout(() => {
                        delete treeData[0].firstSelectAfterInputChange;
                        document.getElementsByClassName("rst__rowContents")[0].click()
                        this.setState({ treeData })
                    })
                }
            }
        });
    }

    onChangeTree = treeData => {
        this.props.update({ ...this.state, treeData })
    }

    editLevelTitle = item => {
        item.node.isEditing = !Boolean(item.node.isEditing)
        item.node.tempTitle = item.node.title;
        this.props.update({
            selectedNode: this.state.selectedNode,
            selectedNodeId: this.state.selectedNodeId,
            treeData: changeNodeAtPath({
                treeData: this.state.treeData,
                path: item.path,
                newNode: item.node,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex;
                }
            })
        })
    }

    actOnNewTitle = (item, persistTitle) => {
        if (persistTitle) {
            if(item.parentNode != null){
                if(item.parentNode.children.some(child => child.title === item.node.tempTitle.trim()) && item.node.title != item.node.tempTitle.trim()){
                    Toast.error(Translate("FieldAlreadyExists"))
                    return;
                }
            }
            item.node.title = item.node.tempTitle.trim();

            this.state.setChangedDetail("CHANGED", item.node);
        }

        item.node.isEditing = false;
        delete item.node.tempTitle;

        this.props.update({
            selectedNode: this.state.selectedNode,
            selectedNodeId: this.state.selectedNodeId,
            treeData: changeNodeAtPath({
                treeData: this.state.treeData,
                path: item.path,
                newNode: item.node,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex;
                }
            })
        });
    }

    getLevelButtons = item => {
        if (item.node.isEditing) {
            return [(
                <InlineIconButton onClick={() => this.actOnNewTitle(item, true)}>
                    <MdCheckCircle size={20} />
                </InlineIconButton>
            ),
            (
                <InlineIconButton onClick={() => this.actOnNewTitle(item)}>
                    <MdCancel size={20} />
                </InlineIconButton>
            )];
        } else {
            return [(
                <InlineIconButton onClick={() => this.editLevelTitle(item)}>
                    <MdEdit size={20} />
                </InlineIconButton>
            ),
            (
                <InlineIconButton onClick={() => this.addLevel(item)}>
                    <MdAddCircle size={20} />
                </InlineIconButton>
            ),
            (
                <InlineIconButton onClick={() => this.removeLevel(item)}>
                    <MdDelete size={20} />
                </InlineIconButton>
            )];
        }

    }

    addLevel = node => {
        const newNode = {
            title: `Sub detail - ${node.node.children.length + 1}`,
            children: []
        }

        this.props.update({
            treeData: insertNode({
                treeData: this.state.treeData,
                newNode,
                minimumTreeIndex: node.treeIndex + 1,
                depth: node.path[node.path.length - 1] + 1 || 1,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex
                },
                expandParent: true
            }).treeData
        });

    }

    removeLevel = node => {
        let isSelected = node.node.uuid === this.state.selectedNodeId

        if (!isSelected && node.node.children) {
            isSelected = !!(node.node.children.find(child => {
                return child.uuid === this.state.selectedNodeId
            }))
        }

        this.state.setChangedDetail("DELETED", node.node);

        this.props.update({
            selectedNode: isSelected ? null : this.state.selectedNode,
            selectedNodeId: isSelected ? null : this.state.selectedNodeId,
            treeData: removeNodeAtPath({
                treeData: this.state.treeData,
                path: node.path,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex
                }
            })
        })

        this.setDeletedExtraInWizardStateEvent(node.node)
    }

    setDeletedExtraInWizardStateEvent = structureDetail => {
        const { wizardState } = this.state

        if(structureDetail.structureExtras != undefined && structureDetail.structureExtras.length > 0){
            structureDetail.structureExtras.map(extra => {
                wizardState.event.deletedExtras.push(extra.codStructureExtraValues)
            })
        }

        if(structureDetail.children != undefined && structureDetail.children.length > 0){
            structureDetail.children.map(childrenStructureDetail => {
                if(childrenStructureDetail.structureExtras != undefined && childrenStructureDetail.structureExtras.length > 0){
                    childrenStructureDetail.structureExtras.map(extra => {
                        wizardState.event.deletedExtras.push(extra.codStructureExtraValues)
                    })
                }
            })
        }
    }
    
    addDetail = _ => {
        this.props.update({
            treeData: this.state.treeData.concat([{
                title: `Detail da Estrutura - ${this.state.treeData.length + 1}`,
                children: []
            }])
        })
    }

    getNode = nodePath => {
        return getNodeAtPath({
            treeData: this.state.treeData,
            path: nodePath,
            getNodeKey: ({ treeIndex }) => {
                return treeIndex
            }
        })
    }

    selectRow = event => {

        let tagsThatDontSelect = ["path", "input", "svg", "button"];
        let element = event.target;

        if (tagsThatDontSelect.includes(
            element.tagName.toLowerCase()) || element.className === "rst__toolbarButton"
        ) {
            return;
        }

        while (!element.id) {
            element = element.parentElement;
        }

        const selectedNodePath = element["attributes"]["nodepath"]["value"]
            .split("-")
            .map(e => parseInt(e));

        let item = this.getNode(selectedNodePath);

        item.node.uuid = element.id;

        this.props.update({
            selectedNode: item,
            selectedNodeId: element.id,
            treeData: changeNodeAtPath({
                treeData: this.state.treeData,
                path: selectedNodePath,
                newNode: item.node,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex;
                }
            })
        });
    }

    updateTempTitle = (event, item) => {

        item.node.tempTitle = event.target.value;

        this.props.update({
            selectedNode: this.state.selectedNode,
            selectedNodeId: this.state.selectedNodeId,
            treeData: changeNodeAtPath({
                treeData: this.state.treeData,
                path: item.path,
                newNode: item.node,
                getNodeKey: ({ treeIndex }) => {
                    return treeIndex;
                }
            })
        });
    }

    nodeProps = item => {
        const { selectedNodeId, structureIn } = this.state;
        const id = item.node.uuid || Uuid.generateUuid();

        var title;
        if (item.node.isEditing) {
            title = (
                <input
                    value={item.node.tempTitle}
                    onChange={e => { this.updateTempTitle(e, item) }}
                />
            );
        }

        //se title é undefined, vai usar item.node.title
        return {
            buttons: this.getLevelButtons(item, structureIn),
            onClick: this.selectRow.bind(this),
            nodepath: item.path.join("-"),
            className: id === selectedNodeId ? "selected" : " ",
            title,
            id
        };
    }

    handleChangeInputSelect = e => {
        var { structureInInput, stepInputs } = this.state;

        if (structureInInput && e.codStructureDetail === structureInInput.idStructureDetail) {
            return;
        }

        structureInInput = stepInputs.find(option => e.codStructureDetail === option.idStructureDetail);

        this.props.update({ structureInInput });
    }

    handleChangeInputText = e => {
        let { stepStructure } = this.state
        stepStructure.desOutputDetails = e.target.value
        
        if(stepStructure.structureDetails && stepStructure.structureDetails.length)
        stepStructure.structureDetails[0].output = e.target.value 

        this.setState({stepStructure})
        this.props.renderEvent()
    }

    render() {
        const {
            treeData,
            structureIn,
            structureInInput,
            structureInputOptions,
            stepStructure
        } = this.state

        return (
            <Fragment> 
                <Grid2 size={15}>
                    {
                        structureIn ?
                            (
                                <ConectorSelect
                                    name={"model-select"}
                                    label={"input_model"}
                                    placeholder={"choose"}
                                    options={structureInputOptions}
                                    onChange={this.handleChangeInputSelect.bind(this)}
                                    value={
                                        structureInInput
                                            ? structureInputOptions.find(option =>
                                                option.codStructureDetail === structureInInput.idStructureDetail
                                            ) : null
                                    }/>
                            )
                            :
                            (
                                <Grid2 size={12}>
                                    <ConectorInputText
                                        name="detail-name"
                                        label="output_model"
                                        placeholder="enter_name"
                                        required={false}
                                        value={stepStructure.desOutputDetails}
                                        onChange={this.handleChangeInputText.bind(this)}/>
                                </Grid2>
                            )
                    }
                    <Grid2 size={12}>
                        <StyledButton
                            style={{marginTop: `10px`, marginBottom: `10px`}}
                            variant="outlined"
                            size="small"
                            name={"add-detail"}
                            onClick={this.addDetail.bind(this)}>
                            <MdAddCircle size={24}/>{Translate("add_detail")}
                        </StyledButton>
                    </Grid2>
                    <Grid2 size={12}>
                        <Grid2 size={12} style={{height: `${getDetailTreeHeightInPixels(treeData)}px`}}>
                            <StyledSortableTree
                                style={{height: `${getDetailTreeHeightInPixels(treeData)}px`}}
                                generateNodeProps={this.nodeProps}
                                treeData={treeData}
                                onChange={this.onChangeTree.bind(this)}
                                isVirtualized={false}/>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Fragment>
        )
    }
}

const mapStateToProps = store => {
    const [inputs] = inputSelector.getEvents(store);

    return {
        inputs,
        wizardState: wizardStore.getWizardState(store),
        connectionActions: wizardStore.getConnectionActions(store)
    };
};

export default connect(mapStateToProps)(WizardStructureDetailsTree);