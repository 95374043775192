import * as types from './action-types';

const initialObj = {
    breadcrumb     : [],
    toast          : {},
    translation    : {},
    steps          : [],
    userPermissions: [],
    userLoggedIn   : false
};

export default function reduce(
    storeState = initialObj, 
    action = { breadcrumb: [] }
) {
    switch (action.type) {
        case types.SET_USER_PERMISSIONS:
            return {
                ...storeState,
                userPermissions: action.userPermissions
            }
        case types.SET_BREADCRUMB:
            if (action.breadcrumb.length) {
                action.breadcrumb.unshift({label: "Home", url: "/"});
            } 
            return {
                ...storeState,
                breadcrumb: action.breadcrumb
            }
        case types.SET_TOAST:
            return {
                ...storeState,
                toast: action.toast
            }
        case types.SET_USER_LANGUAGE:
            return {
                ...storeState,
                translation: action.translation
            }
        case types.SET_STEPS:
            return {
                ...storeState,
                steps: action.steps
            }
        case types.CLEAR_STEPS:
            storeState.steps = [];
            return {
                ...storeState
            }
        default: 
            return {
                ...storeState
            }
    }
}

export function getUserPermissions(store) {
    return store.appState.userPermissions;
}

export function getBreadcrumb(store) {
    return store.appState.breadcrumb;
}

export function getToast(store) {
    return store.appState.toast;
}

export function getUserLanguage(store) {
    return store.appState.translation.userLanguage;
}

export function getI18next(store) {
    return store.appState.translation.i18next;
}

export function getSteps(store) {
    return store.appState.steps;
}

export function getUserPermissionByView(store) {
    let permissions = store.appState.userPermissions;
    const baseCurrentUrl = window.location.hash.split("/")[1];

    return permissions.find(({functions}) => functions.function === baseCurrentUrl) || {};
}