import React, { Component, Fragment } from 'react';
import { StyledButtonLogin, ResetPasswordFormStyled, GoBackButtonStyled } from "../../../componentsUI/styledComponents/styledLogin"
import ConectorInputText from "../../../componentsUI/inputText"
import { Grid2 } from '@mui/material'

class ForgotYourPasswordForm extends Component {
    onChange = ({target: {name, value}}) => {
        this.props.updateValues(name, value);
    }

    render() { 
        const { user } = this.props;

        return ( 
            <Fragment>
                <Grid2 spaing={2} container sx={ResetPasswordFormStyled}>
                    <Grid2 size={16}>
                        <ConectorInputText 
                            label={"Login"}
                            name={"user"}
                            startIcon={"person"}
                            value={user}
                            required={true}
                            onChange={this.onChange}
                            help={'Informe o endereço de e-mail do seu usuário, e receba em alguns instantes um link de recuperação de senha'}
                        />
                    </Grid2>
                    <Grid2 size={16}>
                        <StyledButtonLogin 
                            fullWidth 
                            type="submit" 
                            variant="outlined"
                            onClick={this.props.sendEmail}>                            
                            Enviar e-mail de recuperação
                        </StyledButtonLogin>
                    </Grid2>
                    <Grid2 size={16} sx={GoBackButtonStyled}>
                        <StyledButtonLogin 
                            fullWidth
                            onClick={this.props.setForgotPassword}
                            variant="contained" 
                            className={"goBack"}>
                            Voltar
                        </StyledButtonLogin>
                    </Grid2>
                </Grid2>
            </Fragment>
        );
    }
}

export default ForgotYourPasswordForm;