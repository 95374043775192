import React, { Component, Fragment } from 'react'
import StyledFormHelpText from "./styledComponents/styledFormHelpText"
import { Checkbox } from '@mui/material'
import Translate from "../i18n/translate"
import { StyleFormControlLabel } from "../componentsUI/styledComponents/styledFormControl"

class ConectorCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      ...props
    }
  }

  componentWillReceiveProps(props) {
    this.setState({...props})
  }

  handleChange = e => {
    if (this.state.onChange) {
      this.state.onChange(e)
    }
  }

  render() {
    const {
      invisible,
      name,
      checked,
      value,
      color,
      label,
      help
    } = this.state;

    if (invisible) {
      return (<Fragment></Fragment>);
    }

    return (
      <Fragment>
        <StyleFormControlLabel
          control={
            <Checkbox
              id={`conector-input-checkbox-${name}`}
              name={name}
              checked={checked}
              onChange={this.handleChange.bind(this)}
              value={Boolean(value) ? value.toString() : ''}
              color={color}
            />
          }
          labelPlacement='end'
          label={Translate(label)}
        />
        {
          help 
            ? <StyledFormHelpText>{Translate(help)}</StyledFormHelpText>
            : null
        }
      </Fragment>
    )
  }
}

export default ConectorCheckbox;