import { authHeader } from "./auth-header";
import { hostIp } from "./host-ip";
import { dashIp } from "./dash-ip";

export default {
    baseUrl: `${hostIp}/api/`,
    dashUrl: `${dashIp}/api/`,
    getOptions: () => {
        return {
            headers: authHeader(),
            method: "GET"
        }
    },
    postOptions: () => {
        return {
            headers: authHeader(),
            method: "POST"
        }
    },
    putOptions: () => {
        return {
            headers: authHeader(),
            method: "PUT"
        }
    },
    deleteOptions: () => {
        return {
            headers: authHeader(),
            method: "DELETE"
        }
    },
    multipartOptions: () => {
        let user = JSON.parse(localStorage.getItem("user"));

        return {
            headers: {
                "Authorization": "Bearer " + user.Token
            },
            method: "POST"
        }
    },
    executeRequest: (url, options) => {
        return fetch(url, options)
        .then(response => {
            if(response.status === 498){
                localStorage.clear()
                localStorage.setItem("loggedOutByInvalidToken", true)
                window.location.replace('#/login')
            } else return Promise.resolve(response);
        })
    }
}