import request from '../../../utils/request';

export default {
    UpdateEventsOrder: newOrder => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/instance-interface-event/update-event-order`,
            {
                ...request.postOptions(),
                body: JSON.stringify(newOrder)
            }
        ).then(response => {
            return response;
        })
    },
    GetEventList: (codInstance) => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/events/${codInstance}`,
            request.getOptions()
        ).then(res => res.json())
    },
    DeleteEvent: codEvent => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/instance-interface-event/${codEvent}`,
            request.deleteOptions()
        ).then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
    },
    Copy: (id, name) => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/instance-interface-event/copy`,
            {
                ...request.postOptions(),
                body: JSON.stringify({
                    idEvent: id,
                    newEventName: name
                })
            }
        ).then(response => {
            return response;
        })
    }
}