import styled from "styled-components"
import { Card, CardContent } from "@mui/material";

export const StyledCardContent = styled(CardContent)`
    && {
        display: flex;
        justify-content: center;
        background-color: #F4F4F4;
        align-items: center;
        color: #827272;
        height: 80px;
        word-wrap: break-word;
        font-size: 20px;
        font-weight: 600;
    }
`

export const StyledCard = styled(Card)`
    && {
        animation: fadein 0.8s;
        @keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
        color: #827272;
        line-height: 300px;
        word-wrap: break-word;
        border: 2px solid black;
        text-align: center;
    }
`