import { styled } from '@mui/system';

export const StyledBreadcrumb = styled('div')(({ hiddenSidebar }) => ({
  backgroundColor: '#F5F5F5',
  borderBottom: '1px solid #B8C6D6',
  position: 'fixed',
  zIndex: 99,
  paddingTop: '68px',
  paddingBottom: '5px',
  paddingRight: '100vw',
  paddingLeft: hiddenSidebar ? '80px' : '210px',
  transition: 'padding-left 0.5s',
  '& p': {
    display: 'inline',
    fontSize: '0.7rem',
    color: '#B8C6D6',
    '&.pointer': {
      cursor: 'pointer',
      '&:hover': {
        color: '#009CDF',
      },
    },
    '&:last-child': {
      color: '#6F8296',
    },
    '&.divider': {
      padding: '0 10px',
    }
  }
}))