import styled from 'styled-components';
import { FormControl, FormControlLabel } from '@mui/material';

export const StyledFormControl = styled(FormControl)`
  && { 
    width: 100%;

    .error {
      border-color: #CD5C5C;
    }
  }
`

export const StyleFormControlLabel = styled(FormControlLabel)`
  && { 
    height: 100%; 
    span { 
      color: #6F8296; 
    }
    .MuiIconButton-root {
      padding: 0 12px;
    }
  }
`

export const StyledFormControlDatePicker = styled(FormControl)`
    && {
        width: 80%;

        .MuiFormHelperText-root {
          height: 30px;
        }

        .react-datepicker__input-container {
            display: grid;

            input {
                border: 1px solid #B8C6D6;
                color: #6F8296;
                border-radius: 5px;
                padding: 11.5px 14px;
                font-size: inherit;
                
                &.react-datepicker-ignore-onclickoutside {
                    outline: none;
                    border: 2px solid #3f51b5;
                    padding: 10.5px 13px;
                }
            }
        }
        .react-datepicker-popper{
            z-index: 10;
        }
    }
` 