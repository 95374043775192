import React, { Component } from "react";
import { connect } from "react-redux";
import {
    addNodeUnderParent,
    changeNodeAtPath,
} from "@nosferatu500/react-sortable-tree";
import {
    MdEdit,
    MdCancel,
    MdAddCircle,
    MdCheckCircle,
    MdDelete,
    MdForward
} from "react-icons/md"
import Toast from "../../../components/toast/toast"
import TransformUtil from "./transform-util"
import TransformCase from "./tabs/transform-case"
import TransformField from "./modals/transform-field"
import TransformConcat from "./tabs/transform-concat"
import TransformFieldSpecial from "./tabs/transform-field"
import TransformProperty from "./tabs/transform-property"
import TransformFixedValue from "./tabs/transform-fixed-value"
import { detailService } from "../../../store/details/service"
import * as inputSelector from "../../../store/interfaces/reducer"
import * as wizardStore from "../../../store/wizard/wizard-store-reducer"
import { setWizardState } from "../../../store/wizard/wizard-store-actions"
import Translate from "../../../i18n/translate"
import Session from "../../../utils/session-info"
import ObjectUtils from "../../../utils/object-utils"
import { StyledSortableTreeTransform } from "../../../componentsUI/styledComponents/styledSortableTree"
import StyledButton from "../../../componentsUI/styledComponents/styledButton"
import StyledPaper from "../../../componentsUI/styledComponents/styledPaper"
import Loading from "../../../componentsUI/loading"
import ConectorSelect from "../../../componentsUI/conectorSelect"
import ConectorInputText from "../../../componentsUI/inputText"
import { StyledTabs, StyledFloatingTabsMenu, StyledIconButton } from "../../../componentsUI/styledComponents/styledTransform"
import { Tab, Grid2, Tooltip } from "@mui/material"
import getDetailTreeHeightInPixels from "../../../utils/getDetailTreeHeightInPixels"

class TransformStructure extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            detailInSelected: {
                value: 0,
                label: ""
            },
            detailsIn: [],
            detailsInLoading: props.wizardState.isEdit,
            detailsOut: [],
            detailsOutLoading: props.wizardState.isEdit,
            fieldInSelected: null,
            fieldSelected: null,
            fieldModal: {
                opened: false,
                treePath: null,
                parentPath: null
            },
            flagHasTransformed: false,
            fixedPropertyContent: false,
            selectedTab: 0,
            newDetail: null,
            flagDetailFields: true,
            editingIdDetailInput: null
        };
    }

    componentWillMount = () => {
        let wizardState = { ...this.state.wizardState };
        let event = wizardState.event;

        this.props.setWizardState({ disabledStep: true });

        if (wizardState.isEdit) {
            let structureDetails = event.structures[0].structureDetails;
            let structureDetail = structureDetails[0];

            this.setState({
                detailInSelected: {
                    value: structureDetail.idDetailInput,
                    label: structureDetail.input
                },
                flagHasTransformed: structureDetails.filter(structureDetail => structureDetail.structureExtras.find(extra => extra.codStructureExtra === 74)).length > 0
            });

            this.loadStructureDetails(structureDetail.idDetailInput, "detailsIn", 
                (error) => {
                    if (error == 500) {
                        const entradas = this.getInputLookupList();
                        const idDetailInput = entradas.find(entrada => entrada.label === structureDetail.input);
                        if(idDetailInput){
                            this.setState({editingIdDetailInput: idDetailInput.value})
                            this.loadStructureDetails(idDetailInput.value, "detailsIn", )
                        }
                        else{
                            Toast.error("Erro ao carregar os detalhes da entrada. Verifique o nome ou recrie o evento.")
                            console.error("Erro ao carregar detalhes de entrada:", error);
                        }
                    }
            
                    this.loadStructureDetails(structureDetail.codStructure, "detailsOut", this.updateEvent);
                }
            );
        } else {
            const stepProperties = wizardState.eventProperties[wizardState.currentStep - 1];

            event.structures = [{
                desName: "",
                codInstance: event.codInstance,
                codEventProperty: stepProperties.codEventProperty,
                codConnectionType: event.codConnectionType,
                codConnectionAction: event.codConnectionAction,
                structureDetails: [],
                isMain: true,
                indPosition: 1,
                codMaster: 0
            }];

            this.setState({ wizardState: wizardState });
        }

        window.document.addEventListener("scroll", this.handleViewScroll, false);
    }

    componentWillUnmount = () => {
        window.document.removeEventListener("scroll", this.handleViewScroll, false);
    }

    handleViewScroll = () => {
        const { fixedPropertyContent } = this.state;

        if (document.documentElement.scrollTop > 360 && !fixedPropertyContent) {
            this.setState({
                fixedPropertyContent: true
            });
        } else if (document.documentElement.scrollTop < 360 && fixedPropertyContent) {
            this.setState({
                fixedPropertyContent: false
            });
        }
    }

    handleChangeInputLookup = detailInSelected => {
        this.setState({
            detailInSelected: detailInSelected,
            fieldInSelected: null,
            fieldSelected: null,
            detailsIn: [],
            detailsInLoading: true,
            detailsOut: [],
            flagHasTransformed: false
        });

        this.loadStructureDetails(detailInSelected.value, "detailsIn");
    }

    loadStructureDetails = async (codStructure, stateProp, afterLoad) => {
        try {
            const structureDetails = await detailService.getAll(codStructure);
            this.setState({
                [stateProp]: this.getDetailsToEdit(structureDetails),
                [stateProp + "Loading"]: false
            }, afterLoad);
        } catch (error) {
            if (afterLoad) {
                afterLoad(error);
            }
        }
    };

    getDetailsToEdit = (detailSources, parentDetail) => {
        let detailDests = [];

        detailSources.map(detailSource => {
            let path = ((parentDetail ? (parentDetail.path + ".") : "") + detailSource.title);
            let transformPath = ((parentDetail ? (parentDetail.transformPath + ".") : "") + detailSource.title);
            let parentPath = (parentDetail ? parentDetail.path : detailSource.title);

            const originPath = TransformUtil.getExtraValueByCode(detailSource.structureExtras, 74);
            if (originPath) {
                parentPath = originPath.substring(0, originPath.lastIndexOf("."));
                path = originPath;
            }

            let detailDest = {
                ...detailSource,
                expanded: true,
                path,
                parentPath,
                transformPath
            };

            if (detailSource.fields || detailSource.children) {
                detailDest.children = [];
            }

            if (detailSource.fields) {
                detailDest.children = this.getFieldsToEdit(detailDest, detailSource.fields);
                delete detailDest.fields;
            }

            if (detailSource.children) {
                const childrens = this.getDetailsToEdit(detailSource.children, detailDest);
                detailDest.children = detailDest.children.concat(childrens);
            }

            detailDests.push(detailDest);
        });

        return detailDests;
    }

    getFieldsToEdit = (parentDetail, fieldSources) => {
        let fieldDests = [];
        const { attributes } = this.state;

        fieldSources.map(fieldSource => {
            const originPath = TransformUtil.getAttributeValueByCode(fieldSource.fieldAttributes, attributes.origin);

            //CG-431 limpa o valor "Formato Para" do transform quando o tipo do campo é texto (string)
            const formatTo = TransformUtil.getAttributeValueByCode(fieldSource.fieldAttributes, attributes.format_to);
            const fieldType = TransformUtil.getAttributeValueByCode(fieldSource.fieldAttributes, attributes.field_type);
            if(fieldType == "string" && formatTo != null){
                const attribute = fieldSource.fieldAttributes.find(({ codAttribute }) => codAttribute === attributes.format_to);
                if (attribute) {
                    attribute.desValue = null;
                }
            }
            let title = TransformUtil.getAttributeValueByCode(fieldSource.fieldAttributes, attributes.to);
            let parentPath = parentDetail.path;
            let path = (parentPath + "." + title);
            let transformPath = (parentDetail.transformPath + "." + title);

            if (originPath) {
                parentPath = originPath.substring(0, originPath.lastIndexOf("."));
                path = originPath;
            }

            let field = {
                ...fieldSource,
                title,
                path,
                parentPath,
                transformPath
            };

            if (field.hasOwnProperty("fieldTransforms")) {
                field.fieldTransform = {
                    value: field.desSubaction,
                    label: Translate(field.desSubaction)
                };

                try {
                    field.structureOption = {
                        value: fieldSource.fieldAttributes.find(({codAttribute}) => codAttribute == attributes.structure_in).desValue
                    };
                } catch(error){}

                switch (field.desSubaction) {
                    case "case":
                        const attributeCase = fieldSource.fieldAttributes.find(({ codAttribute }) => codAttribute === attributes.structure_in);
                            if (attributeCase && attributeCase.desValue !== null && attributeCase.desValue !== undefined) {
                                field.structureOption = {
                                    value: JSON.parse(attributeCase.desValue)
                            };
                        }
                        field.fieldTransform.cases = this.getCasesTransformToEdit(field.fieldTransforms);
                        break;
                    case "concat":
                        const attributeConcat = fieldSource.fieldAttributes.find(({ codAttribute }) => codAttribute === attributes.structure_in);
                            if (attributeConcat && attributeConcat.desValue !== null && attributeConcat.desValue !== undefined) {
                                field.structureOption = {
                                    value: JSON.parse(attributeConcat.desValue)
                            };
                        }
                        field.fieldTransform.concats = this.getConcatsTransformToEdit(field.fieldTransforms);
                        break;
                    case "special":
                        field.fieldTransform.specials = this.getSpecialsTransformToEdit(field.fieldTransforms);
                        break;
                    default:
                        field.fieldTransform.fixedValues = field.fieldTransforms;
                        break;
                }

                delete field.fieldTransforms;
            }

            fieldDests.push(field);
        });
        return fieldDests;
    }

    getCasesTransformToEdit = (caseSources, codMaster = 0) => {
        let caseDests = [];

        caseSources = caseSources.sort((case1, case2) => case1.indPosition - case2.indPosition);

        for (let c = 0; c < caseSources.length; c++) {
            const caseSource = caseSources[c];
            if (caseSource.codMaster != codMaster && caseSource.indPosition > 1) {
                continue;
            }

            let caseDest = {
                ...caseSource,
                expanded: true
            };

            if (caseSource.desField != "then") {
                caseDest.children = this.getCasesTransformToEdit(caseSources.slice(c + 1), caseSource.indPosition);

                c += caseDest.children.length;
            }

            caseDests.push(caseDest);
        }

        return caseDests;
    }

    getConcatsTransformToEdit = (concatSources) => {
        const {
            attributes,
            detailsIn
        } = this.state;

        return concatSources.map(concatSource => {
            let fieldType = TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.field_type);
            let originalPath = TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.from);

            return {
                ...concatSource,
                name: (fieldType == "text" ? originalPath : (TransformUtil.getItemByPath(detailsIn, originalPath) || {}).title),
                fieldType: fieldType,
                textInput: (fieldType == "text" ? originalPath : ""),
                fieldPath: (fieldType == "text" ? "" : originalPath),
                isSubstring: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.is_substring),
                initPosition: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.ini_substring),
                endPosition: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.end_substring),
                isConcatAll: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.is_concatall),
                isTrim: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.is_trim),
                trimType: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.trim_type),
                isDelimiter: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.is_delimiter),
                delimiter: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.delimiter),
                pos_delimiter: TransformUtil.getAttributeValueByCode(concatSource.fieldTransformAttributes, attributes.pos_delimiter),
            };
        });
    }

    getSpecialsTransformToEdit = (specialSources) => {
        const {
            attributes,
            detailsIn
        } = this.state;

        return specialSources.map(specialSource => {
            let fieldType = TransformUtil.getAttributeValueByCode(specialSource.fieldTransformAttributes, attributes.field_type);
            let originalPath = TransformUtil.getAttributeValueByCode(specialSource.fieldTransformAttributes, attributes.from);

            return {
                ...specialSource,
                name: (fieldType == "text" ? originalPath : (TransformUtil.getItemByPath(detailsIn, originalPath) || {}).title),
                fieldType: fieldType,
                textInput: (fieldType == "text" ? originalPath : ""),
                fieldPath: (fieldType == "text" ? "" : originalPath),
                isSubstring: TransformUtil.getAttributeValueByCode(specialSource.fieldTransformAttributes, attributes.is_substring),
                initPosition: TransformUtil.getAttributeValueByCode(specialSource.fieldTransformAttributes, attributes.ini_substring),
                endPosition: TransformUtil.getAttributeValueByCode(specialSource.fieldTransformAttributes, attributes.end_substring),
                isTrim: TransformUtil.getAttributeValueByCode(specialSource.fieldTransformAttributes, attributes.is_trim),
                trimType: TransformUtil.getAttributeValueByCode(specialSource.fieldTransformAttributes, attributes.trim_type),
                isDelimiter: TransformUtil.getAttributeValueByCode(specialSource.fieldTransformAttributes, attributes.is_delimiter),
                delimiter: TransformUtil.getAttributeValueByCode(specialSource.fieldTransformAttributes, attributes.delimiter),
                pos_delimiter: TransformUtil.getAttributeValueByCode(specialSource.fieldTransformAttributes, attributes.pos_delimiter),
            };
        });
    }

    updateEvent = () => {
        let wizardState = { ...this.state.wizardState };

        const { detailsOut } = this.state;
        const rootStructure = wizardState.event.structures[0];
        const structureDetails = this.getDetailsToSave(detailsOut);

        rootStructure.structureDetails = structureDetails;
        rootStructure.desName = (structureDetails.length > 0 ? detailsOut[0].output : "");

        this.setState({ wizardState: wizardState }, this.validateStep());
    }

    getDetailsToSave = (detailSources, indPosition = 1, codMaster = 0) => {
        let detailDests = [];

        detailSources.map(detailSource => {
            const childSources = detailSource.children;

            let detailDest = {
                ...detailSource,
                codStructureDetail: indPosition,
                indPosition: indPosition++,
                codMaster: codMaster
            };

            delete detailDest.children;

            if (childSources.length > 0) {
                const detailChildSources = childSources.filter(detailChildSource => detailChildSource.hasOwnProperty("children"));

                if (detailChildSources.length > 0) {
                    const detailChildDests = this.getDetailsToSave(detailChildSources, indPosition, detailDest.indPosition);

                    indPosition += detailChildDests.length;
                    detailDests = detailDests.concat(detailChildDests);
                }

                const fieldSources = childSources.filter(fieldSource => !fieldSource.hasOwnProperty("children"));
                if (fieldSources.length > 0) {
                    detailDest.fields = this.getFieldsToSave(fieldSources);
                }

                detailDests.push(detailDest);
            }
            //detailDests.push(detailDest);

            else {
                this.setState({
                    confirmationModalOptions: {
                        type: "danger",
                        title: "user_update",
                        message: "activate_user",
                        open: true,
                        //onDismiss: this.onDismissModal(),
                        //onClose: this.onConfirmActivateUser.bind(this, UserService),
                    }
                })
            }
        })

        return detailDests;
    }

    getFieldsToSave = (fieldSources) => {
        return fieldSources.map((fieldSource, index) => {
            let fieldDest = {
                ...fieldSource,
                indPosition: index
            };

            let transform = fieldDest.fieldTransform;

            if (transform) {
                fieldDest.desSubaction = transform.value;

                switch (transform.value) {
                    case "case":
                        fieldDest.fieldTransforms = this.getCasesTransformToSave(transform.cases);
                        break;
                    case "concat":
                        fieldDest.fieldTransforms = transform.concats;
                        break;
                    case "special":
                        fieldDest.fieldTransforms = transform.specials;
                        break;
                    default:
                        fieldDest.fieldTransforms = transform.fixedValues;
                        break;
                }
            }

            return fieldDest;
        });
    }

    getCasesTransformToSave = (caseSources, indPosition = 1, codMaster = 0) => {
        let caseDests = [];

        caseSources.map(caseSource => {
            caseDests.push({
                ...caseSource,
                indPosition: indPosition++,
                codMaster: codMaster
            });

            if (caseSource.children) {
                let caseSourceChildrens = this.getCasesTransformToSave(caseSource.children, indPosition, (indPosition - 1));
                indPosition += caseSourceChildrens.length;

                caseDests = caseDests.concat(caseSourceChildrens);
            }
        });

        return caseDests;
    }

    validateStep = () => {
        const {
            event,
            isEdit
        } = this.state.wizardState;

        const outputStructure = event.structures[0];

        const {
            desName,
            codStructure
        } = outputStructure;

        const {
            flagDetailFields,
            newDetail
        } = this.state;
        let disableStep = true

        if (this.state.detailsOut[0] && this.state.detailsOut[0].children) {
        const subStructures = this.state.detailsOut[0].children

        if (desName.trim() != "" && !this.hasOutputWithSameName(isEdit ? codStructure : 0, desName))
            disableStep = this.hasFieldWithError(outputStructure.structureDetails);

        if(outputStructure.structureDetails.length > 0)
            subStructures.forEach((element) => {
                if(element.hasOwnProperty('children'))
                    if(element.children.length == 0)
                        disableStep = true
            })
        }
        this.props.setWizardState({
            disabledStep: disableStep
        });
    }

    hasFieldWithError = (structureDetails) => {
        let hasError = false;

        structureDetails.map(structureDetail => {
            const fields = structureDetail.fields || [];

            fields.forEach((fieldFrom, indexFrom) => {
                const titleFrom = fieldFrom.title;

                if (titleFrom.length == 0) {
                    hasError = true;
                } else if (!hasError) {
                    fields.forEach((fieldTo, indexTo) => {
                        const titleTo = fieldTo.title;

                        if (titleTo.length == 0 || (titleFrom == titleTo && indexFrom != indexTo)) {
                            hasError = true;
                            return;
                        }
                    });

                    if (!hasError) {
                        hasError = this.hasTransformWithError(fieldFrom);
                    }
                }

                if (hasError) {
                    return;
                }
            });

            if (hasError) {
                return;
            }
        });

        return hasError;
    }

    hasTransformWithError = (field) => {
        const {
            fieldTransform,
            fieldTransforms
        } = field;

        if (!fieldTransform) {
            return false;
        }

        switch (fieldTransform.value) {
            case "case":
                return this.hasCaseWithError(fieldTransforms);
            case "concat":
                return fieldTransforms.length == 0;
            case "special":
                return fieldTransforms.length == 0;
            default:
                return false;
        }
    }

    hasCaseWithError = (cases) => {
        let hasError = false;

        if (cases.length == 0) {
            hasError = true;
        } else {
            const casesTransform = cases.filter(caseTransform => caseTransform.desField == "case");

            casesTransform.forEach(caseTransform => {
                const whensTransform = cases.filter(whenTransform => {
                    return whenTransform.desField == "when" && whenTransform.codMaster == caseTransform.indPosition;
                });

                if (whensTransform.length == 0) {
                    hasError = true;
                    return;
                } else {
                    whensTransform.forEach(whenTransform => {
                        const thensTransform = cases.filter(thenTransform => {
                            return thenTransform.desField == "then" && thenTransform.codMaster == whenTransform.indPosition
                        });

                        if (thensTransform.length == 0) {
                            const casesTranform = cases.filter(caseTransform => {
                                return caseTransform.desField == "case" && caseTransform.codMaster == whenTransform.indPosition
                            });

                            if (casesTranform.length == 0) {
                                hasError = true;
                                return;
                            }
                        }
                    });
                }

                if (hasError) {
                    return;
                }
            });

            if (!hasError) {
                const { attributes } = this.state;

                cases.forEach(caseTransform => {
                    const {
                        desField,
                        fieldTransformAttributes
                    } = caseTransform;

                    const codAttribute = attributes[desField == "case" ? "from" : "to"];
                    const typeAttribute = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, attributes.fielt_type);
                    const valueAttribute = TransformUtil.getAttributeValueByCode(fieldTransformAttributes, codAttribute);

                    if (typeAttribute == "field" && (!valueAttribute || valueAttribute == "")) {
                        hasError = true;
                        return;
                    }
                });
            }
        }

        return hasError;
    }

    hasOutputWithSameName = (codStructure, name) => {
        const { inputs } = this.state;

        return inputs.find(input => input.output == name && input.codStructure != codStructure) != undefined;
    }

    handleOpenFieldModal = (event, detail, path) => {        
        event.stopPropagation();

        let fieldModal = { ...this.state };
        const { newDetail } = this.state;
        fieldModal.opened = true;
        fieldModal.treePath = path;
        fieldModal.parentPath = detail.path;
        
        if(newDetail != null){
            if(newDetail.path == fieldModal.parentPath){
                this.setState({ 
                    flagDetailFields: true
                 });
            }
        }

        this.setState({ 
            fieldModal: fieldModal,
         });
    }

    getOutputStructuresFromEvent = interfaceEvents => {
        let outputStructures = new Array

        interfaceEvents.filter(event => event.structures)
            .forEach(event => {
                event.structures.forEach(structure => {
                    if (structure.structureDetails) {
                        const outputStructure = structure.structureDetails.find(detail => {
                            return detail.output
                        });

                        if (outputStructure) outputStructures.push(outputStructure);
                    }
                })
            })

        return outputStructures
    }

    getInputLookupList = () => {
        const { events, wizardState } = this.state;
        const eventThatWillBeCreated = wizardState.event;

        let pastEvents = events.filter(event => event.indPosition < eventThatWillBeCreated.indPosition)
        let outputStructuresFromPastEvents = this.getOutputStructuresFromEvent(pastEvents)

        return outputStructuresFromPastEvents.map(input => {
            return {
                label: input.output,
                value: input.codStructure
            }
        })
    }

    renderFloatingTabsMenuComponent = (fieldSelected, selectedTab) => {
        if(fieldSelected != null){
            const fieldTransform = fieldSelected.fieldTransform

            return (
                <StyledFloatingTabsMenu>
                    <StyledTabs
                        value={selectedTab} 
                        onChange={this.handleChangeTab.bind(this)}>
                        <Tab key={0} label={Translate("properties")} />
                        {fieldTransform ? <Tab key={1} label={fieldTransform.label} /> : ""}
                    </StyledTabs>
                    {this.renderTabsContainer()}
                </StyledFloatingTabsMenu>
            )
        } else {
            return ""
        }
    }

    render() {
        const {
            fieldSelected,
            selectedTab,
            detailsInLoading,
            detailsOutLoading
        } = this.state;

        return (
            <StyledPaper className={"bg_darken_04"}>
                <Grid2 spacing={1} container>
                    <Grid2 size={fieldSelected == null ? 6 : 4} >
                        {this.renderInputModelComponent()}
                        {detailsInLoading ? this.renderLoading() : this.renderInputTreeComponent()}
                    </Grid2>
                    <Grid2 size={fieldSelected == null ? 6 : 4}>
                        {this.renderOutputModelComponent()}
                        {detailsOutLoading ? this.renderLoading() : this.renderOutputTreeComponent()}
                    </Grid2>
                    <Grid2 size={4}>
                        {this.renderFloatingTabsMenuComponent(fieldSelected, selectedTab)}
                    </Grid2>
                </Grid2>
            </StyledPaper>
        )
    }

    renderTabsComponent = () => {
        const {
            fieldSelected,
            selectedTab,
            fixedPropertyContent
        } = this.state;

        let component;

        if (fieldSelected != null) {
            const fieldTransform = fieldSelected.fieldTransform;

            component = (
                <StyledContainer className={fixedPropertyContent ? "content-fixed" : ""}>
                    <StyledTabs value={selectedTab} onChange={this.handleChangeTab.bind(this)}>
                        <Tab key={0} label={Translate("properties")} />
                        {fieldTransform ? <Tab key={1} label={fieldTransform.label}/> : ""}
                    </StyledTabs>
                    {this.renderTabsContainer()}
                </StyledContainer>
            );
        } else {
            component = <StyledPaper />;
        }

        return component;
    }

    renderTabsContainer = () => {
        const {
            attributes,
            selectedTab,
            fieldSelected,
            detailsIn
        } = this.state;
        const fieldTransform = fieldSelected.fieldTransform;

        let wizardState = { ...this.state.wizardState };
        let event = wizardState.event;
        const structureDetailsIn = event.structures[0].structureDetails;

        if (fieldTransform && selectedTab == 1) {
            switch (fieldTransform.value) {
                case "case":
                    return <TransformCase attributes={attributes} updateEvent={this.updateEvent.bind(this)} fields={this.getFieldsForTransform()}
                        fieldTransform={fieldTransform} onRemove={this.prepareFieldTransformToRemove} onRemoveCaseConcat={this.prepareFieldTransformCaseToRemove}/>
                case "concat":
                    return <TransformConcat attributes={attributes} updateEvent={this.updateEvent.bind(this)} fields={this.getFieldsForTransform()}
                        fieldTransform={fieldTransform} onRemove={this.prepareFieldTransformToRemove} />
                case "special":
                    return <TransformFieldSpecial attributes={attributes} updateEvent={this.updateEvent.bind(this)} fields={this.getFieldsForTransform()}
                        fieldTransform={fieldTransform} onRemove={this.prepareFieldTransformToRemove} />
                default:
                    return <TransformFixedValue attributes={attributes} fieldTransform={fieldTransform} updateEvent={this.updateEvent.bind(this)} />
            }
        }

        const typeOrigin = this.getOriginFieldType();

        return (
            <TransformProperty setChangedField={this.setChangedField.bind(this)} updateEvent={this.updateEvent.bind(this)} field={fieldSelected} typeOrigin={typeOrigin} attributes={attributes} />
        )
    }

    getFieldsForTransform = () => {
        const {
            detailsIn,
            detailsOut,
            fieldSelected
        } = this.state;

        let parentDetail
        let parentDetailIndex = 0

        if (fieldSelected.parentPath.includes(".")){
            parentDetail = TransformUtil.getItemByPath(detailsOut, fieldSelected.parentPath);
        } else{
            parentDetail = TransformUtil.getItemByPath(detailsIn, fieldSelected.parentPath);
        }
        
        let isArray = false;
        
        if(parentDetail && parentDetail.structureExtras){
           isArray = (TransformUtil.getExtraValueByCode(parentDetail.structureExtras, 3) == "true");
        }

        let masterNode = TransformUtil.getItemByPath(detailsOut, detailsOut[0].path);
        let node = TransformUtil.getItemByPath(detailsOut, fieldSelected.path);

        if(fieldSelected){
            if(fieldSelected.structureOption){
                if(fieldSelected.structureOption.value){
                    masterNode = TransformUtil.getItemByPath(detailsIn, detailsIn[0].path);
                }
            }
        }

        if (!isArray) {
            do {
                if (fieldSelected.parentPath.includes(".")){
                    parentDetail = TransformUtil.getItemByPath(detailsOut, parentDetail.parentPath);
                } else {
                    parentDetail = TransformUtil.getItemByPath(detailsIn, parentDetail.parentPath);
                }
                if (parentDetail) {
                    isArray = (TransformUtil.getExtraValueByCode(parentDetail.structureExtras, 3) == "true") ? true : false
                    parentDetailIndex = parentDetail.parentPath.indexOf(".")
                }
            } while (!isArray && parentDetailIndex > 0);
        }

        function getDetailFields(detail, node) {
            let fields = [];

            detail.children.map(child => {
                if (child.hasOwnProperty("children")) 
                    fields = fields.concat(getDetailFields(child));
                else if(child.path != fieldSelected.path || fieldSelected.desSubaction == "case" || fieldSelected.desSubaction == "concat" || fieldSelected.desSubaction == "special")
                    fields.push(child);
            });

            return fields;
        }

        return getDetailFields(masterNode, node);
    }

    getOriginFieldType = () => {
        const {
            detailsIn,
            attributes,
            fieldSelected
        } = this.state;

        const fieldOrigin = TransformUtil.getItemByPath(detailsIn, fieldSelected.path);
        if (fieldOrigin) {
            return TransformUtil.getAttributeValueByCode(fieldOrigin.fieldAttributes, attributes.field_type);
        }

        return null;
    }

    handleChangeTab = (_, selectedTab) => {
        if (this.state.selectedTab == selectedTab) {
            return;
        }

        this.setState({ selectedTab: selectedTab });
    }

    renderLoading = () => {
        return (
            <StyledPaper style={{ marginTop: "15px" }}>
                <Loading />
            </StyledPaper>
        )
    }

    renderInputModelComponent = () => {
        const { detailInSelected } = this.state;
        const { isEdit } = this.props.wizardState;

        return (
            <ConectorSelect label={"input_model"} help={"select_input_model"} placeholder={"choose"} options={this.getInputLookupList()}
                onChange={this.handleChangeInputLookup} value={detailInSelected} disabled={isEdit} />
        )
    }

    renderOutputModelComponent = () => {
        const { detailsOut, editingIdDetailInput } = this.state;
        const disabled = (detailsOut.length == 0);

        let output = "";
        if (!disabled) {
            output = detailsOut[0].output;
            if(editingIdDetailInput != null){
                detailsOut[0].idDetailInput = editingIdDetailInput ;
            }
        }

        return (
            <ConectorInputText name="outputName" label={"output_model"} disabled={disabled} value={output}
                onChange={this.handleChangeOutputName.bind(this)} onBlur={this.updateEvent.bind(this)} />
        )
    }

    handleChangeOutputName = (event) => {
        const { detailsOut } = this.state;

        this.applyOutputName(detailsOut, event.target.value);

        this.setState({ detailsOut: detailsOut });
    }

    applyOutputName = (detailsOut, output) => {
        detailsOut.map(detailOut => {
            if (detailOut.hasOwnProperty("children")) {
                detailOut.output = output;

                this.applyOutputName(detailOut.children, output);
            }
        });
    }

    handleChangeDetailName = (event, item, path) => {
        item.tempTitle = event.target.value;

        this.doChangeDetailsNode(this.state.detailsOut, path, item, false);
    }

    handleEditingDetailName = (event, item, path, editing, title) => {
        event.stopPropagation();

        let canUpdateEvent = false;

        item.editing = editing;

        if (editing) {
            item.tempTitle = title;
        } else {
            if (title) {
                canUpdateEvent = true;
                item.title = title.trim();

                this.setChangedDetail("CHANGED", item);
            }

            delete item.tempTitle;
        }

        this.doChangeDetailsNode(this.state.detailsOut, path, item, canUpdateEvent);
    }
    
    handleNewDetail = (event, item, path, editing, title) => {
        event.stopPropagation();

        let canUpdateEvent = false;

        item.editing = editing;

        if (editing) {
            item.tempTitle = title;
        } else {
            if (title) {
                if (event.structure.children.length > 0) {
                    canUpdateEvent = true;
                    this.setChangedDetail("CHANGED", item);
                }
            }

            delete item.tempTitle;
        }

        this.doChangeDetailsNode(this.state.detailsOut, path, item, canUpdateEvent);
    }

    handleRemoveTreeItem = (event, item, path) => {
        event.stopPropagation();

        const {
            fieldSelected,
            flagHasTransformed,
            detailsOut,
            wizardState
        } = this.state;

        if (wizardState.isEdit) {
            this.prepateItemToRemove(item);
        }

        if (fieldSelected != null && fieldSelected.path.indexOf(item.path) >= 0) {
            this.setState({
                fieldInSelected: null,
                fieldSelected: null,
                flagHasTransformed: (detailsOut.length == 0 ? false : flagHasTransformed)
            });
        }

        if (item.children) {
            this.setChangedField("DELETED", item);
        } else {
            this.setChangedDetail("DELETED", item);
        }

        this.doChangeDetailsNode(detailsOut, path, null, true);
    }

    setChangedDetail(action, sourceDetail) {
        let { wizardState } = this.state;

        if (sourceDetail.idStructureDetail) {
            let changedDetail = wizardState.event.changedDetails[sourceDetail.transformPath];
            if (!changedDetail) {
                changedDetail = {};
                wizardState.event.changedDetails[sourceDetail.transformPath] = changedDetail;
            }

            changedDetail.action = action;
            changedDetail.changes = {
                title: sourceDetail.title,
                codStructureDetail: sourceDetail.codStructureDetail,
                codMaster: sourceDetail.codMaster,
                indPosition: sourceDetail.indPosition
            };
        }
    }

    setChangedField(action, sourceField) {
        let { wizardState } = this.state;

        if (sourceField.codField) {
            let changedField = wizardState.event.changedFields[sourceField.transformPath];
            if (!changedField) {
                changedField = {};
                wizardState.event.changedFields[sourceField.transformPath] = changedField;
            }

            changedField.action = action;
            changedField.changes = {
                desField: sourceField.desField,
                indPosition: sourceField.indPosition,
                fieldAttributes: sourceField.fieldAttributes.map(attribute => {
                    return {
                        codAttribute: attribute.codAttribute,
                        desValue: attribute.desValue,
                        desLabel: attribute.desLabel,
                        indPosition: attribute.indPosition
                    }
                })
            };
        }
    }

    prepateItemToRemove = (items) => {
        let { wizardState } = this.state;

        if (!items.length) {
            items = [items];
        }

        this.addItemToRemove(items);

        this.setState({ wizardState: wizardState });
    }

    addItemToRemove = (items) => {
        let { event } = this.state.wizardState;

        items.map(item => {
            if (item.idStructureDetail) {
                event.deletedExtras = event.deletedExtras.concat(item.structureExtras.map(structureExtra => { return structureExtra.codStructureExtraValues }));
                event.deletedDetails.push(item.idStructureDetail);
            } else if (item.codField) {
                event.deletedFields.push(item.codField);
            }

            if (item.children) {
                this.addItemToRemove(item.children);
            }
        });
    }

    prepareFieldTransformCaseToRemove = (fieldTransforms) => {
        let { wizardState } = this.state;

        if (!fieldTransforms.length) {
            fieldTransforms = [fieldTransforms];
        }

        this.addFieldTransformCaseToRemove(fieldTransforms);

        this.setState({ wizardState: wizardState });
    }

    addFieldTransformCaseToRemove = (fieldTransforms) => {
        let { event } = this.state.wizardState;
        fieldTransforms.map(fieldTransform => {
            if (fieldTransform.codFieldTransform) {
                if(!event.deletedFieldTransformsCase)
                    event.deletedFieldTransformsCase = [];

                event.deletedFieldTransformsCase.push(fieldTransform.codFieldTransform);
            }

            if (fieldTransform.children) {
                this.addFieldTransformCaseToRemove(fieldTransform.children);
            }
        });
    }

    prepareFieldTransformToRemove = (fieldTransforms) => {
        let { wizardState } = this.state;

        if (!fieldTransforms.length) {
            fieldTransforms = [fieldTransforms];
        }

        this.addFieldTransformToRemove(fieldTransforms);

        this.setState({ wizardState: wizardState });
    }

    addFieldTransformToRemove = (fieldTransforms) => {
        let { event } = this.state.wizardState;

        fieldTransforms.map(fieldTransform => {
            if (fieldTransform.codFieldTransform) {
                event.deletedFieldTransforms.push(fieldTransform.codFieldTransform);
            }

            if (fieldTransform.children) {
                this.addFieldTransformToRemove(fieldTransform.children);
            }
        });
    }

    doChangeDetailsNode = (treeData, path, node, canUpdateEvent) => {
        var newTreeData = changeNodeAtPath({
            treeData: treeData,
            path: path,
            getNodeKey,
            newNode: node
        });

        this.setState({
            detailsOut: newTreeData
        }, canUpdateEvent ? this.updateEvent : null);
    }



    renderInputTreeComponent = () => {
        let { detailsIn } = this.state;

        return (
            <div style={{ marginTop: "15px", height: `${getDetailTreeHeightInPixels(detailsIn)}px` }}>
                <StyledSortableTreeTransform 
                    isVirtualized={false} 
                    treeData={detailsIn}
                    canDrag={false}
                    onChange={(detailsIn) => this.setState({ detailsIn })}
                    generateNodeProps={({ node }) => this.getInputTreeComponentProps(node)} />
            </div>
        )
    }

    getInputTreeComponentProps = (item) => {
        let buttons = [];
        let className = "";
        let onClick = null;

        const {
            detailsOut,
            fieldInSelected,
            flagHasTransformed
        } = this.state;

        if (item.hasOwnProperty("children")) {
            if (detailsOut.length == 0 || !flagHasTransformed) {
                buttons.push(
                    <Tooltip title={Translate("add")}>
                        <StyledIconButton onClick={() => this.handleAddStructureDetail(item)}>
                            <MdForward size={20} />
                        </StyledIconButton>
                    </Tooltip>
                );
            }

            const itemParentOutput = TransformUtil.getItemByPath(detailsOut, item.parentPath);
            if (itemParentOutput != null) {
                onClick = () => this.handleSelectInputItem(item);
            }
        } else {
            const itemOutput = TransformUtil.getItemByPath(detailsOut, item.path);
            const itemParentOutput = TransformUtil.getItemByPath(detailsOut, item.parentPath);

            if (itemOutput == null) {
                if (itemParentOutput != null) {
                    buttons.push(
                        <Tooltip title={Translate("add")}>
                            <StyledIconButton onClick={() => this.handleAddField(item)}>
                                <MdForward size={20} />
                            </StyledIconButton>
                        </Tooltip>
                    );
                }
            } else {
                onClick = () => this.handleSelectInputItem(item);
            }
        }

        if (fieldInSelected == item.path) {
            className = "selected";
        }

        return {
            onClick: onClick,
            buttons: buttons,
            className: className
        }
    }

    handleAddStructureDetail = (item) => {
        let { detailsOut } = this.state;

        let structureDetail = ObjectUtils.deepCopy(item);

        this.doCleanStructureDetailReference(structureDetail);

        if (detailsOut.length != 0) {
            detailsOut[0].children.push(structureDetail);
        } else {
            detailsOut.push(structureDetail);
        }

        this.setState({
            detailsOut: detailsOut,
            flagHasTransformed: true
        }, this.updateEvent);
    }

    handleAddField = (item) => {
        let { detailsOut } = this.state;

        let field = ObjectUtils.deepCopy(item);

        this.doCleanFieldReference(field);

        const parent = TransformUtil.getItemByPath(detailsOut, item.parentPath);

        parent.children.push(field);

        this.setState({
            detailsOut: detailsOut
        }, this.updateEvent);
        this.updateEvent;
    }

    doCleanStructureDetailReference = (structureDetail) => {
        const { detailsIn } = this.state;
        const { codInstance } = Session();

        structureDetail.idDetailInput = detailsIn[0].codStructure;
        structureDetail.input = detailsIn[0].output;
        structureDetail.output = "";

        delete structureDetail.idStructureDetail;
        delete structureDetail.codStructure;

        if (structureDetail.structureExtras) {
            structureDetail.structureExtras.map(structureExtra => {
                delete structureExtra.codStructure;
                delete structureExtra.codStructureDetail;
                delete structureExtra.codStructureExtraValues;
            });
        } else {
            structureDetail.structureExtras = [];
        }

        structureDetail.structureExtras.push({
            codInstance: codInstance,
            codStructureExtra: 74,
            value: structureDetail.path
        });

        structureDetail.children.map(child => {
            if (child.children) {
                this.doCleanStructureDetailReference(child);
            } else {
                this.doCleanFieldReference(child);
            }
        });
    }

    doCleanFieldReference = (field) => {
        const { attributes } = this.state;
        const { codInstance } = Session();

        field.fieldAttributes.map(attribute => {
            if (attribute.codAttribute == attributes.format_from) {
                attribute.codAttribute = attributes.format_to;
            }

            delete attribute.codField;
        });

        if (field.fieldTransform) {
            // Case
            if (field.fieldTransform.cases) {
                this.processTransform(field.fieldTransform.cases);
            }
            // Fixed
            if (field.fieldTransform.fixedValues) {
                this.processTransform(field.fieldTransform.fixedValues);
            }
            // Special
            if (field.fieldTransform.specials) {
                this.processTransform(field.fieldTransform.specials);
            }
            // Concat
            if (field.fieldTransform.concats) {
                this.processTransform(field.fieldTransform.concats);
            }
        }
        field.fieldAttributes.push({
            codAttribute: attributes.origin,
            codInstance: codInstance,
            desValue: field.path,
            desLabel: "origin"
        });

        delete field.id;
        delete field.codField;
    }
    // Função para deletar codigos para nao duplicar
    deleteFieldAttributes = (item) => {
        delete item.codField;
        delete item.codFieldTransform;
        item.fieldTransformAttributes?.forEach(attr => {
            delete attr.codFieldTransform;
        });
        if (item.children) {
            item.children.forEach(this.deleteFieldAttributes);
        }
    };
    // Função para processar as transformações
    processTransform = (transform) => {
        transform.forEach(this.deleteFieldAttributes);
    };
    
    renderOutputTreeComponent = () => {
        const {
            attributes,
            fieldModal,
            detailsOut
        } = this.state;

        return (
            <div style={{marginTop: "35px", height: `${getDetailTreeHeightInPixels(detailsOut)}px`}}>
                {
                    detailsOut.length == 0 ? (
                        <div style={{ marginBottom: "15px" }}>
                            <StyledButton 
                                variant="outlined" 
                                size="small" 
                                name={"add-detail"}
                                onClick={() => this.buildRootDetail()}>
                                <MdAddCircle size={20}/> {Translate("add_detail")}
                            </StyledButton>
                        </div>) : ""
                }

                <StyledSortableTreeTransform 
                    treeData={detailsOut} 
                    canDrag={false} 
                    isVirtualized={false}
                    generateNodeProps={({ node, path }) => this.getOutputTreeComponentProps(node, path)}
                    onChange={detailsOut => {
                        this.setState({ detailsOut });
                        this.updateEvent();
                    }}/>

                <TransformField 
                    attributes={attributes} 
                    opened={fieldModal.opened} 
                    detailsOut={detailsOut}
                    parentPath={fieldModal.parentPath} onAddField={this.handleAddNewField} />
            </div>
        )
    }

    handleAddNewField = (newField) => {
        let {
            fieldModal,
            detailsOut,
            fieldSelected
        } = this.state;

        if (newField != null) {
            newField.path = fieldModal.parentPath + "." + newField.title;
            newField.parentPath = fieldModal.parentPath;

            detailsOut = addNodeUnderParent({
                treeData: detailsOut,
                parentKey: fieldModal.treePath[fieldModal.treePath.length - 1],
                getNodeKey,
                newNode: newField
            }).treeData;
        }

        this.setState({
            detailsOut: detailsOut,
            fieldModal: {
                opened: false,
                treePath: null,
                parentPath: null
            },
            fieldInSelected: "",
            fieldSelected: (newField != null ? newField : fieldSelected)
        }, newField != null ? this.updateEvent : null);
    }

    buildRootDetail = () => {
        const { detailInSelected } = this.state;
        const rootDetail = [{
            title: "root",
            children: [],
            output: "",
            expanded: true,
            path: "root",
            idDetailInput: detailInSelected.value,
            input: detailInSelected.label,
        }];

        this.setState({
            detailsOut: rootDetail,
            flagHasTransformed: false
        });
    }

    addNewDetail = (event, node, path) => {
        event.stopPropagation();

        let {
            detailsOut
        } = this.state;

        let newNode = {
            parentPath: node.path,
            title: "Sub detail",
            children: [],
            path: (node.path + ".Sub detail"),
            idDetailInput: node.idDetailInput,
            input: node.input,
            output: node.output
        }

        detailsOut = addNodeUnderParent({
            treeData: detailsOut,
            parentKey: path[path.length - 1],
            getNodeKey,
            newNode: newNode
        }).treeData;

        this.setState({
            detailsOut: detailsOut,
            flagDetailFields: false,
            newDetail: newNode
        }, this.updateEvent);
    }

    handleSelectInputItem = (item) => {
        const { detailsOut } = this.state;

        this.setState({
            fieldInSelected: item.path,
            fieldSelected: TransformUtil.getItemByPath(detailsOut, item.path),
            selectedTab: 0
        });
    }

    getOutputTreeComponentProps = (item, path) => {
        const { fieldSelected } = this.state;

        let title;
        let buttons = [];
        let onClick;
        let className = "";

        if (item.editing) {
            title = (
                <input
                    value={item.tempTitle}
                    onChange={event => this.handleChangeDetailName(event, item, path)}
                    onClick={(event) => event.stopPropagation()}
                />
            );

            buttons.push(
                <Tooltip title={Translate("save")}>
                    <StyledIconButton onClick={(event) => this.handleEditingDetailName(event, item, path, false, item.tempTitle)}>
                        <MdCheckCircle size={20} />
                    </StyledIconButton>
                </Tooltip>
            );

            buttons.push(
                <Tooltip title={Translate("cancel")}>
                    <StyledIconButton onClick={(event) => this.handleEditingDetailName(event, item, path, false, null)}>
                        <MdCancel size={20} />
                    </StyledIconButton>
                </Tooltip>
            );
        } else {
            if (item.children) {
                buttons.push(
                    <Tooltip title={Translate("add_detail")}>
                        <StyledIconButton onClick={(event) => this.addNewDetail(event, item, path)}>
                            <MdAddCircle size={20} />
                        </StyledIconButton>
                    </Tooltip >
                );

                buttons.push(
                    <Tooltip title={Translate("edit")}>
                        <StyledIconButton onClick={(event) => this.handleEditingDetailName(event, item, path, true, item.title)}>
                            <MdEdit size={20} />
                        </StyledIconButton>
                    </Tooltip>
                );

                buttons.push(
                    <Tooltip title={Translate("add")}>
                        <StyledIconButton onClick={(event) => this.handleOpenFieldModal(event, item, path)}>
                            <MdAddCircle size={20} />
                        </StyledIconButton>
                    </Tooltip>
                );
            } else if (item.desSubaction !== "fields") {
                if (!item.desSubaction) {
                    item.desSubaction = item.fieldTransform.value;
                }

                className = "content-field-" + item.desSubaction.replace("_", "-");

                title = <div>
                    <div className="content-transform-label">{Translate(item.desSubaction)}</div>
                    {item.title}
                </div>
            }

            buttons.push(
                <Tooltip title={Translate("remove")}>
                    <StyledIconButton onClick={(event) => this.handleRemoveTreeItem(event, item, path)}>
                        <MdDelete size={20} />
                    </StyledIconButton>
                </Tooltip>
            );
        }

        onClick = () => this.handleSelectInputItem(item);

        if (fieldSelected != null && fieldSelected.path == item.path) {
            className += " selected";
        }

        return {
            title: title,
            buttons: buttons,
            onClick: onClick,
            className: className
        };
    }
}

const mapStateToProps = state => {
    const [inputs] = inputSelector.getInputs(state);
    const [events] = inputSelector.getEvents(state);

    return {
        inputs,
        events,
        wizardState: wizardStore.getWizardState(state),
        attributes: wizardStore.getAttributes(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWizardState: (wizardState) => { dispatch(setWizardState(wizardState)) }
    };
};

const getNodeKey = ({ treeIndex }) => treeIndex;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransformStructure);