import React, { Component, Fragment } from 'react'
import Translate from '../../i18n/translate'
import Session from "../../utils/session-info";
import { userService } from "../../../src/store/login/service"
import {
    Paper,
    MenuItem,
    MenuList,
    ListItemIcon,
    ClickAwayListener,
    IconButton,
    Grow
} from "@mui/material"
import { MdPerson, MdPowerSettingsNew } from "react-icons/md"
import { StyledPopper } from "../../componentsUI/styledComponents/styledHeader"
import { VscAccount } from "react-icons/vsc"

class SettingButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }

    handleToggle = () => {
        this.setState(state => ({ open: !state.open }))
    }

    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return
        }

        this.setState({ open: false })
    }

    handleMyAccount = () => {
        window.location.replace('#/myaccount')
        this.setState({ open: false })
    }

    render() {
        const { open } = this.state;
    
        return (
            <Fragment>
                <IconButton
                    ref={node => { this.anchorEl = node }} 
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                    color="inherit">
                        <VscAccount size={40}/>
                </IconButton>
                <StyledPopper open={open} anchorEl={this.anchorEl} transition disablePortal>
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps} id="menu-list-grow">
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList>
                                        <MenuItem onClick={this.handleMyAccount}>
                                            <ListItemIcon>
                                                <MdPerson size={24} />
                                            </ListItemIcon>
                                            {Translate('my_account')}
                                        </MenuItem>
                                        <MenuItem onClick={() => userService.logout()}>
                                            <ListItemIcon>
                                                <MdPowerSettingsNew size={24} />
                                            </ListItemIcon>
                                            {Translate('exit')}
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </StyledPopper>
            </Fragment>
        );
    }
}

export default SettingButton