import React, {
    Component,
    Fragment
} from "react"
import { connect } from "react-redux"
import Translate from "../../i18n/translate"
import { setBreadcrumb } from "../../store/app-state/actions"
import UserPrivilegesService from "./user-privileges-service"
import Toast from "../../components/toast/toast"
import AddEditUserPrivilegesModal from "./add-edit-user-privileges-modal"
import Loading from "../../componentsUI/loading"
import ConectorTable from "../../componentsUI/conectorTable"
import ConectorDialogConfirmation from "../../componentsUI/dialogComponentConfirmation"
import { StyledDialogZIndex } from "../../componentsUI/styledComponents/styledConnectionsCopyModal"
import { StyledDialogTitle } from "../../componentsUI/styledComponents/styledDialog"
import UserService from "../users/user-service"
import Session from "../../utils/session-info"

class UserPrivileges extends Component {
    constructor(props) {
        super(props)

        props.setBreadcrumb([{
            label: "administration"
        }, {
            label: "user_privileges"
        }])

        this.state = {
            privilegesLevels: new Array,
            usersPrivileges: new Array,
            loading: true,
            dialog: { open: false },
            modal: { open: false, title: undefined },
            userSelected: undefined,
            gridOptions: {
                multiSelect: false,
                hideBtnNew: false,
                hideBtnEdit: false,
                hideBtnDelete: false,
                hideBtnCopy: true,
                hideFilter: false,
                sortField: "datechange",
                sortType: "desc",
                columns: [
                    {
                        title: Translate("name"),
                        field: "desName"
                    },
                    {
                        title: Translate("user_email"),
                        field: "desEmail"
                    }]
            }
        }
    }

    componentDidMount = () => {
		UserService.FindPrivilege(Session().codUser, 1)
        .then(response => {
            if(response == false) throw new Error("notHavePermissionToAccess")
        })
        .then(() => this.getUsersPrivileges())
        .catch(error => {
            Toast.error(Translate(error.message));
            setTimeout(() => {
                window.location.href = '/'
            }, 2000)
        })
    }

    getUsersPrivileges = () => {
        Promise.all([
            UserPrivilegesService.GetPrivilegesLevels()
            .then(privilegesLevels => {
                return privilegesLevels
            }),
            UserPrivilegesService.GetUsersPrivileges()
            .then(usersPrivileges => {
                return usersPrivileges
            })
        ]).then(([privilegesLevels, usersPrivileges]) => {
            this.setState({privilegesLevels, usersPrivileges, loading: false})
        })
    }

    openDeleteDialog = userPrivileges => {
        this.setState({
            dialog: {
                type: "danger",
                open: true,
                message: "remove_records",
                title: "exclusion_confirmation",
                onClose: () => this.deleteUserPrivileges(userPrivileges),
                onDismiss: () => this.closeDialogAndModal()
            }
        })
    }

    deleteUserPrivileges = userPrivileges => {
        UserPrivilegesService.DeleteUserPrivileges(userPrivileges)
        .then(response => {
            switch (response.status) {
                case 200:
                    this.setState({loading: true}, () => Toast.success("records_success_removed"))
                    break;
                case 409:
                    throw new Error("userCannotDeleteHimself")
                    break;
                default:
                    throw new Error("recordNotBeDeleted")
                    break;
            }
        })
        .then(() => this.closeDialogAndModal(true))
        .catch(error => {
            Toast.error(error.message)
            this.closeDialogAndModal(true)
        })
    }

    closeDialogAndModal = (savedDataChanged = false) => {
        this.setState(
            { 
                loading: savedDataChanged,
                userSelected: undefined, 
                dialog: { open: false },
                modal: { open: false, title: undefined, type: undefined }
            }, () => this.getUsersPrivileges()
        )
    }

    renderUserPrivilegesModal = () => {
        const { modal, userSelected, privilegesLevels } = this.state

        return (
            <StyledDialogZIndex fullWidth={true} maxWidth={"sm"} open={modal.open}>
                <StyledDialogTitle id="confirmation-dialog-title" className="confirmation">
                    {modal.title}
                </StyledDialogTitle>
                <AddEditUserPrivilegesModal
                    privilegesLevels={privilegesLevels}
                    userSelected={userSelected}
                    closeDialogAndModal={this.closeDialogAndModal.bind(this)}/>
            </StyledDialogZIndex>
        )
    }

    renderUsersTable = () => {
        const { gridOptions, usersPrivileges } = this.state

        return (
            <ConectorTable
                gridTitle={"user_privileges"}
                gridBtn={"AddUser"}
                gridNewItem={() => {
                    this.setState({ modal: { open: true, title: Translate("PrivilegeAllocation") } })
                }}
                gridEditItem={user => {
                    this.setState({ userSelected: user, modal: { open: true, title: user.desName } })
                }}
                gridDeleteItem={this.openDeleteDialog.bind(this)}
                gridOptions={gridOptions}
                dataSource={usersPrivileges}/>
        )
    }

    render = () => {
        const { loading, dialog } = this.state

        if (loading) return <Loading/>

        return (
            <Fragment>
                {this.renderUsersTable()}
                {this.renderUserPrivilegesModal()}
                <ConectorDialogConfirmation dialogOptions={dialog}/>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(null, mapDispatchToProps)(UserPrivileges)