export const WIZARD_SET_SELECTED_CONNECTION_ACTION = 'WIZARD_SET_SELECTED_CONNECTION_ACTION';
export const WIZARD_SET_WIZARD_STATE = 'WIZARD_SET_WIZARD_STATE'
export const WIZARD_DEFINE_EVENT_PROPERTIES = 'WIZARD_DEFINE_EVENT_PROPERTIES'
export const WIZARD_DEFINE_CONNECTION_ACTIONS = 'WIZARD_DEFINE_CONNECTION_ACTIONS'
export const WIZARD_CLEAR_WIZARD_STORE = 'WIZARD_CLEAR_WIZARD_STORE'
export const WIZARD_SET_DELETED_FIELDS = 'WIZARD_SET_DELETED_FIELDS'
export const WIZARD_DEFINE_ATTRIBUTES = 'WIZARD_DEFINE_ATTRIBUTES'
export const WIZARD_SET_EVENT_TYPES = 'WIZARD_SET_EVENT_TYPES'
export const WIZARD_SET_SELECTED_STRUCTURE = 'WIZARD_SET_SELECTED_STRUCTURE'
export const WIZARD_SET_STRUCTURE_ARRAY = 'WIZARD_SET_STRUCTURE_ARRAY'
export const WIZARD_SET_STEP_AUXILIARY = 'WIZARD_SET_STEP_AUXILIARY'
export const WIZARD_SET_STEP_STRUCTURE = 'WIZARD_SET_STEP_STRUCTURE'
export const WIZARD_SET_STRUCTURE_CHANGE_TAB = 'WIZARD_SET_STRUCTURE_CHANGE_TAB'
export const WIZARD_SET_TREE_DATA = 'WIZARD_SET_TREE_DATA'
