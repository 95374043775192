import React, { Component, Fragment } from 'react'
import Toast from './toast'
import Translate from '../../i18n/translate'
import StyledSnackbar from '../../componentsUI/styledComponents/styledToast'
import {
    MdInfo,
    MdError,
    MdCheckCircle
} from "react-icons/md"

const iconList = {
    success : MdCheckCircle,
    error : MdError,
    info : MdInfo,
}

class ToastComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props.options
        }
    }

    componentWillReceiveProps(props) {
        if (props.options 
            && props.options.type 
            && props.options.message) {
            
            this.setState({
                ...props.options
            }, _ => {
                setTimeout(
                    function() {
                        Toast.clearStore();
                        this.setState({
                            type: null,
                            open: false,
                            message: null,
                            timeout: null
                        });
                    }.bind(this), 
                props.options.timeout || 5000)
            })
        }
    }

    render() { 
        const { type, message, timeout } = this.state
        const open = type && message ? true : false
        const Icon = iconList[type]

        if (!open) {
            return (<Fragment></Fragment>)
        }

        return (
            <StyledSnackbar
                anchorOrigin={{
                    vertical  : 'top',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={timeout || 30000000}
                ContentProps={{ className: type }}
                message={
                    <span className={'verticalAlign'}>
                        <Icon /> {Translate(message)}
                    </span>
                }
            />
        )
    }
}

export default ToastComponent