import React, { Component, Fragment } from 'react'
import { Icon, IconButton, InputAdornment, Tooltip } from '@mui/material'
import Translate from '../i18n/translate'
import StyledTextField from "./styledComponents/styledTextField"
import StyledInputLabel from "./styledComponents/styledInputLabel"

class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  componentWillReceiveProps(props) {
    this.setState({...props});
  }

  handleChange = e => {
    if (this.state.onChange) {
      this.state.onChange(e);
    }
  }

  handleBlur = e => {
    if (this.state.onBlur) {
      this.state.onBlur(e);
    }
  }

  handleKeyPress = e => {
    if (this.state.onKeyPress) {
      this.state.onKeyPress(e);
    }
  }

  handleStartActionButton = e => {
    if (this.state.starActionButton) {
      this.state.starActionButton(e)
    }
  }

  handleEndActionButton = e => {
    if (this.state.endActionButton) {
      this.state.endActionButton(e)
    }
  }

  handleMouseDownStartActionButton = event => {
    event.preventDefault()
  }

  handleMouseDownEndActionButton = event => {
    event.preventDefault()
  }

  render() {
    var {
      required,
      className,
      label,
      name,
      help,
      multiline,
      rows,
      errorCondition,
      placeholder,
      disabled,
      value,
      startIcon,
      endIcon,
      invisible,
      startTooltip,
      endTooltip,
      startActionButton,
      endActionButton
    } = this.state;

    if (invisible) {
      return (<Fragment></Fragment>);
    }

    required       = Boolean(required);
    errorCondition = Boolean(errorCondition);
    disabled       = Boolean(disabled);

    if (required) {
      className = `${className || ''} required`;
    }

    if (errorCondition) {
      className = `${className || ''} error`;
    }
    
    return (
      <div>
        <StyledInputLabel className={className}>
          {Translate(label)}
        </StyledInputLabel>
        <StyledTextField
          id={`conector-input-text-${name}`}
          helperText={Translate(help)}
          name={name}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          onKeyPress={this.handleKeyPress.bind(this)}
          autoComplete='false'
          multiline={multiline}
          rows={rows}
          error={errorCondition}
          required={required}
          type='text'
          placeholder={Translate(placeholder)}
          variant='outlined'
          disabled={disabled}
          value={value}
          className={className}
          InputProps={{
            startAdornment: (
              startIcon 
                ? (
                  <InputAdornment position="start" className={!!startActionButton ? '' : 'noBtn'}>
                    {
                      startActionButton 
                        ? (
                            <Fragment>
                              <Tooltip title={Translate(startTooltip)} placement="top">
                                <IconButton onClick={this.handleStartActionButton.bind(this)} onMouseDown={this.handleMouseDownStartActionButton}>
                                  <Icon>{startIcon}</Icon>
                                </IconButton>
                              </Tooltip>
                              <span className={'separator'}></span>
                            </Fragment>
                          )
                        : (
                            <div className={'divButton'}>
                              <Icon>{startIcon}</Icon>
                            </div>
                          )
                    }
                  </InputAdornment>
                )
                : null
            ),
            endAdornment: (
              endIcon 
                ? (
                    <InputAdornment position="end">
                      {
                        endActionButton 
                          ? (
                            <Fragment>
                              <span className={'separator'}></span>
                              <Tooltip title={Translate(endTooltip)} placement="top">
                                <IconButton onClick={this.handleEndActionButton.bind(this)} 
                                  onMouseDown={this.handleMouseDownEndActionButton}>
                                  <Icon>{endIcon}</Icon>
                                </IconButton>
                              </Tooltip>
                            </Fragment>
                          )
                        :
                          (
                            <div className={'divButton'}>
                              <Icon>{endIcon}</Icon>
                            </div>
                          )
                      }
                    </InputAdornment>
                  )
                : null
            )
          }}
        />
      </div>
    );
  }
}

export default InputText