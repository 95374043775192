import { Grid2 } from "@mui/material"
import Translate from "../i18n/translate"
import StyledButton from "../componentsUI/styledComponents/styledButton"

const CancelSaveButtons = ({ onCancel, onSave, disableSaveButton }) => (
    <Grid2 container spacing={1}>
      <Grid2 item>
        <StyledButton
          variant="contained"
          onClick={onCancel}>
          {Translate("cancel")}
        </StyledButton>
      </Grid2>
      <Grid2 item>
        <StyledButton
          variant="outlined"
          onClick={onSave}
          disabled={disableSaveButton}>
          {Translate("save")}
        </StyledButton>
      </Grid2>
    </Grid2>
)

export default CancelSaveButtons