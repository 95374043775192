import styled from 'styled-components'
import FormHelperText from '@mui/material/FormHelperText'

const StyledFormHelpText = styled(FormHelperText)`
    && {
        color: #B8C6D6;
        font-size: 0.8rem;
        margin-bottom: 0;
    }
`

export default StyledFormHelpText