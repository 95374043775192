import React from 'react';
import { components } from 'react-select';
import { Tooltip} from '@mui/material'

const TooltipValue = ({ showTooltip, ...props }) => (
    <components.Option {...props}>
    <Tooltip title={showTooltip ? props.data.value.replace(/\./g, ' > ') : ''} placement="top" sx={{ fontSize: '2.25rem' }}>
            <div>
                {props.children}
            </div>
        </Tooltip>
    </components.Option>
);

export default TooltipValue;