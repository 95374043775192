import request from '../../utils/request'

export default {
    GetAccesses: (codCompany) => {
        return request.executeRequest(
            `${request.baseUrl}permissions_panel/${codCompany}`,
            request.getOptions()
        ).then(res => res.json())
    },
    GetAllSystemCompanies: () => {
        return request.executeRequest(
            `${request.baseUrl}companies/get-all-system-companies`,
            request.getOptions()
        ).then(res => res.json())
    },
    GetUserPermissions: (desFunction) => {
        return request.executeRequest(
            `${request.baseUrl}globals/accesspermission/${desFunction}`,
            request.getOptions()
        )
        .then(res => {
            if(res.status == 498){
                localStorage.clear()
                localStorage.setItem("loggedOutByInvalidToken", true)
                window.location.replace('#/login')
            }

            return res
        })
        .catch((error) => {
            console.error(error);
        });
    }
}