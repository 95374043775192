import React, { Component } from "react"
import Translate from "../i18n/translate"
import {DialogActions, DialogContent, DialogContentText} from "@mui/material";
import {StyledDialog, StyledDialogTitle} from "../componentsUI/styledComponents/styledDialog"
import StyledButton from "../componentsUI/styledComponents/styledButton"
import ConectorInputText from "../componentsUI/inputText"

class DialogComponent extends Component {
    render() {
        return (
            <StyledDialog maxWidth="lg"
                fullWidth={true}
                open={this.props.open}
            >
                <StyledDialogTitle className="confirmation">
                    {Translate("stack_trace_detailing")}
                </StyledDialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <ConectorInputText
                            value={this.props.stackTraceToExpand}
                            disabled={false}
                            rows={25}
                            multiline={true}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={this.props.onClose}>
                        {Translate("close")}
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        )
    }
}

export default DialogComponent