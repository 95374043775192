import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Session from '../../utils/session-info'
import Translate from '../../i18n/translate'
import Toast from '../../components/toast/toast'
import { setBreadcrumb } from '../../store/app-state/actions'
import InstancesService from './instances-service'
import TagsInput from 'react-tagsinput'
import './../../themes/react-tagsinput.css'
import UserService from "../../routes/users/user-service";
import InstanceHistory from "./instance-history/instance-history";
import { Grid2 } from '@mui/material'
import CancelSaveButtons from "../../componentsUI/cancelSaveButtons"
import Loading from "../../componentsUI/loading"
import InternalPageTitle from "../../componentsUI/internalPageTitle"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import ConectorInputNumber from "../../componentsUI/inputNumber"
import ConectorInputText from "../../componentsUI/inputText"

class InstanceDetails extends Component {
    constructor(props) {
        super(props)

        let instanceID = window.location.hash.substring(12)

        if (typeof (parseInt(instanceID)) !== 'number' && instanceID !== 'add') {
            window.location.replace('#/instances')
        }

        props.setBreadcrumb([
            { label: 'registrations' },
            { label: 'instances', url: '#/instances' },
            {
                label: instanceID !== 'add'
                    ? "edit_instance"
                    : "add_instance"
            }
        ]);

        this.state = {
            dialogOpenInstanceHistory: false,
            instanceID: instanceID,
            validations: [],
            instance: {},
            inputEmails: [],
            loading: true,
            disabledInputs:false
        }
    }

    async componentWillMount() {
        const hasPrivilege = await UserService.FindPrivilege(Session().codUser, 1);
        this.setState({ hasPrivilege });

        const { instanceID } = this.state;
        if (instanceID === "add") {
            this.setState({ loading: false });
        } else {
            this.getInstance(instanceID);
        }

        const resEdit = await InstancesService.GetUserPermissionEditInstance();
        this.setState({
            disabledInputs: resEdit,
        });
    }

    getInstance(codInstance) {
        InstancesService
            .GetInstance(codInstance)
            .then(instance => {
                if (!instance.id) {
                    Toast.error('Instância não encontrada');
                    this.goBack();
                } else {
                    this.setState({ instance, loading: false });
                }
            })
    }

    goBack() {
        window.location.replace('#/instances')
    }

    save() {
        this.setState({
            validations: []
        }, _ => {
            var instance = { ...this.state.instance },
                validations = [];

            if (!instance.description) {
                validations.push('description');
            }

            if (!instance.indMaxThreads) {
                validations.push('indMaxThreads');
            }

            if (validations.length) {
                this.setState({ validations });
                return;
            }

            if (!instance.id) {
                instance.codCompany = Session().codCompany;

                InstancesService
                    .PostInstance(instance)
                    .then(onCallback.bind(this));
            } else {
                InstancesService
                    .PutInstance(instance)
                    .then(onCallback.bind(this));
            }

            function onCallback(res, action) {
                if (res && res.ok) {
                    Toast.success('record_success_saved');
                        localStorage.setItem('instance', JSON.stringify(instance));
                        localStorage.setItem('action', action);
                    window.location.replace('#/instances');

                } else {
                    if(res === "Token does not have access to this instance") Toast.error("userWithoutPermissionInstance")
                    else Toast.error('error_saving_record')
                }
            }
        })
    }

    setInputInstanceEmails(emailArray){
        const { instance } = this.state

        let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let enteredEmail = emailArray[emailArray.length - 1]

        if(emailArray.length == 0){
            instance.emailNotification = ''
            this.setState({instance})
        } else {
            if(emailRegex.test(enteredEmail)){
                instance.emailNotification = emailArray.join(';')
                this.setState({instance})
            } else Toast.error('invalid_addresss')
        }


    }

    generateTagsInputValue(){
        const { instance } = this.state

        if(instance.emailNotification === '' || instance.emailNotification === undefined) return new Array

        return instance.emailNotification.split(";")
    }

    onCopy(value) {
        const el = document.createElement('textarea')
        el.value = value
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
    }

    handleChange({ target: { name, value } }) {
        var { instance, validations } = this.state;

        if (name === 'indMaxThreads') {
            instance.indMaxThreads = parseInt(value, 10) || null;
        } else {
            instance[name] = value;
        }

        validations = validations.filter(v => v !== name);

        this.setState({ instance, validations });
    }

    copyHash() {
        this.onCopy(this.state.instance.hash)
    }

    copyInstanceToken() {
        this.onCopy(this.state.instance.instanceToken)
    }

    handleOpenInstanceHistory = () => {
        this.setState({ dialogOpenInstanceHistory: true });
    }

    handleDismissInstanceHistory = () => {
        this.setState({dialogOpenInstanceHistory: false});
    }

    render() {
        const {
            instance,
            validations,
            loading,
            disabledInputs,
            hasPrivilege,
            dialogOpenInstanceHistory
        } = this.state

        if (loading) {
            return (<Loading />)
        }

        return (
            <Fragment>
                <InternalPageTitle title={ instance.id ? 'edit_instance' : 'new_instance'} />

                <StyledPaper>
                    <Grid2 container spacing={2}>
                        <Grid2 size={4}>
                            <ConectorInputText
                                label={'description'}
                                help={'enter_description'}
                                name={'description'}
                                required={true}
                                disabled={disabledInputs}
                                value={instance.description}
                                onChange={this.handleChange.bind(this)}
                                errorCondition={validations.find(v =>
                                    v === 'description'
                                )}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <ConectorInputNumber
                                label={'quantity_threads'}
                                required={true}
                                disabled={disabledInputs}
                                help={'max_number_threads'}
                                name={'indMaxThreads'}
                                value={instance.indMaxThreads}
                                onChange={this.handleChange.bind(this)}
                                errorCondition={validations.find(v =>
                                    v === 'indMaxThreads'
                                )}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <ConectorInputText
                                label={'token'}
                                name={'token'}
                                type={'text'}
                                help={'Token da Instância'}
                                disabled={true}
                                value={instance.instanceToken}
                                endIcon={'file_copy'}
                                endTooltip={'copy'}
                                endActionButton={this.copyInstanceToken.bind(this)}
                            />
                        </Grid2>
                        <Grid2 size={16}>
                            <ConectorInputText
                                label={'hash'}
                                name={"hash"}
                                type={'text'}
                                disabled={true}
                                help={'Hash da Instância'}
                                value={instance.hash}
                                endIcon={'file_copy'}
                                endTooltip={'copy'}
                                endActionButton={this.copyHash.bind(this)}
                            />
                        </Grid2>
                        <Grid2 size={16}>
                            <TagsInput value={this.generateTagsInputValue()} inputProps={{placeholder: undefined}} onChange={emailArray => this.setInputInstanceEmails(emailArray)}/>
                            <p class="tagsInputLabel">{Translate('enterEmails')}</p>
                        </Grid2>
                    </Grid2>

                    <Grid2 container spacing={2} justify="flex-end">
                        <Grid2 size={4}>
                            {hasPrivilege &&
                                <Fragment>
                                    <StyledButton
                                        variant="contained"
                                        onClick={this.handleOpenInstanceHistory}
                                        color="primary"
                                    >
                                        {Translate("instance_history")}
                                    </StyledButton>
                                </Fragment>
                            }
                        </Grid2>
                    </Grid2>

                    <Grid2 container justifyContent="flex-end">
                        <CancelSaveButtons onCancel={this.goBack.bind(this)} onSave={this.save.bind(this)} />
                    </Grid2>
                </StyledPaper>
                    {
                        (() => {
                            if (dialogOpenInstanceHistory) {
                                return <InstanceHistory id={instance.id} onDismiss={this.handleDismissInstanceHistory.bind(this)} />
                            }
                        })()
                    }
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
    }
}

export default connect(null, mapDispatchToProps)(InstanceDetails)

