import styled from 'styled-components'
import {Modal} from '@mui/material'

const StyledModal = styled(Modal)`
    && {
        .contentModal {
            position: absolute;
            top: 100px;
            left: calc(50vw - 300px);
            width: 100vw;
            max-width: 600px;
            min-height: 325px;
            padding: 0;
            overflow-y: auto;
            background-color: #FFFFFF;
            box-shadow: 0 0;

            .titleModal {
                background-color: #4C637B;
                font-size: 1.2rem;
                color: #B8C6D6;
                padding: 15px 20px;
            }

            @media screen and (max-width:680px) {
                width: calc(100vw - 40px);
                margin: 0 20px;
                left: 0;
            }
        }
    }
`

export default StyledModal