import styled from "styled-components"
import {
    Tabs,
    Grid2,
    IconButton
} from "@mui/material"

export const StyledTabs = styled(Tabs)`
    && {
        position: absolute;
        width: 100%;
        top: -47px;
        
        button {
            color: #6F8296;
            font-size: 0.75rem;
            background-color: #f4f4f4;
            border-top: 1px solid #B8C6D6;
            border-left: 1px solid #B8C6D6;
            border-right: 1px solid #B8C6D6;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            opacity: 0.3;

            &:hover, &.Mui-selected {
                background-color: #f4f4f4;
                border-top: 1px solid #B8C6D6;
                border-left: 1px solid #B8C6D6;
                border-right: 1px solid #B8C6D6;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                opacity: 1;
            }
        }

        .MuiTabs-indicator {
            height: 0;
        }
    }
`

export const StyledGrid = styled(Grid2)`
    && {
        padding: 20px;
        margin-top: 48px;
        border: 1px solid #B8C6D6;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        min-height: calc(100vh - 495px);
        width: 100%;
    }
`

export const StyledButtonGrid = styled(Grid2)`
    && { 
        padding: 0;
        margin-top: 45px
    }
`

export const InlineIconButton = styled(IconButton)`
    && {padding: 6px 6px 6px 3px;}
`