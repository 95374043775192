import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { setWizardState, clearWizardStore } from "../../store/wizard/wizard-store-actions"
import Toast from "../../components/toast/toast"
import * as interfaceSelector from "../../store/interfaces/reducer"
import * as wizardStore from "../../store/wizard/wizard-store-reducer"
import Session from "../../utils/session-info"
import { setBreadcrumb, setSteps } from "../../store/app-state/actions"
import Translate from "../../i18n/translate"
import StepsList from "./steps-list"
import { Grid2 } from "@mui/material"
import { EventName, EventType } from "../../componentsUI/eventTitle"
import ConectorStepper from "../../componentsUI/stepper"
import StyledPaper from "../../componentsUI/styledComponents/styledPaper"
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import ConectorDialogConfirmation from "../../componentsUI/dialogComponentConfirmation"

class Wizard extends Component {
    constructor(props) {
        super(props);

        props.setBreadcrumb([{
            label: "registrations"
        }, {
            label: "interfaces",
            url: "#/interfaces"
        }, {
            label: "config_event"
        }]);

        const eventProperties = props.eventProperties;
        const event = props.wizardState.event;

        //validação para acesso direto pela url
        if (!event || !eventProperties.length) {
            window.location.replace('#/interfaces')
        }

        this.state = {
            isLoading: true,
            dialogOptions: {
                open: false
            },
            wizardState: props.wizardState,
            connectionActions: props.connectionActions,
            event,
            eventProperties,
            eventExtras: {}
        };
    }

    componentWillReceiveProps = props => {
        const {
            wizardState,
            connectionActions
        } = props;

        this.setState({
            connectionActions,
            wizardState: { ...wizardState },
            isLoading: wizardState.currentStep && !Boolean(wizardState.eventProperties.length)
        });
    }

    componentWillMount = () => {
        let { event } = this.state;

        const handleF5 = e => {
            if (e.keyCode === 116) {
                e.preventDefault();
                this.setState({
                    dialogOptions: {
                        open: true,
                        type: "danger",
                        title: "refresh_page",
                        message: "unsaved_changes_deleted",
                        onClose: () => {
                            this.setState({ open: false }, _ => {
                                window.location.assign("#/interfaces");
                            });
                        },
                        onDismiss: () => {
                            this.setState({
                                dialogOptions: { open: false }
                            });
                        }
                    }
                });
            }
        }
        document.onkeydown = handleF5.bind(this);

        let isLoading = true,
            firstStep = 1;

        event.connectionAction = this.props.connectionActions.find(connAction =>
            connAction.id === event.codConnectionAction
        )

        const eventProperties = this.props.eventProperties.filter(prop => {
            if (prop.codConnectionAction === event.codConnectionAction) {
                const step = StepsList(prop.codConnectionAction, prop.codProperty, prop.desEventProperty);

                return step != null;
            }

            return false;
        }).sort((o1, o2) => o1.codProperty - o2.codProperty);

        this.props.setWizardState({
            event,
            eventProperties
        });

        this.props.setSteps(
            eventProperties.map(eventProp => ({
                label: eventProp.desLabel,
                complete: true
            }))
        );

        this.setState({
            isLoading,
            firstStep
        }, () => {
            this.changeStep(firstStep);
        });
    }

    componentWillUnmount = () => {
        document.onkeydown = null;
    }

    renderStepper() {
        return (
            <ConectorStepper
                handleStep={this.changeStep}
                currentStep={this.state.wizardState.currentStep - 1}/>
        )
    }

    renderContent() {
        const {
            event,
            wizardState
        } = this.state;

        var desEventProperty;
        if (wizardState.currentStep) {
            desEventProperty = wizardState.eventProperties[wizardState.currentStep - 1].desEventProperty;
        }

        const StepComponent = StepsList(
            event.codConnectionAction,
            wizardState.currentStep,
            desEventProperty
        );
        
        return React.createElement(StepComponent, {
            key: wizardState.currentStep
        });
    }

    changeStep = newStep => {
        this.props.setWizardState({ currentStep: newStep });
    }

    goBack = () => {
        const { codInterface } = this.props;
        window.location.replace(`#/interfaces/events/${codInterface}`)
    }

    getConfigTitle = () => {
        const {
            wizardState
        } = this.state

        let label = Translate("config_event");

        if (!wizardState.currentStep && wizardState.eventType) {
            label = `${label} - ${wizardState.eventType}`
        }

        if (wizardState.currentStep && wizardState.eventProperties.length) {
            const currentStepProperty = wizardState.eventProperties[wizardState.currentStep - 1];
            label = `${label} - ${currentStepProperty.desLabel}`
        }

        return label;
    }

    getEventTitle = typeTitle => {
        const { event } = this.state.wizardState
        return event.desEventName
    }

    save = () => {
        const { event, detailsExtras, detailsExtrasAttributes } = this.state.wizardState;
        const { codInterface, desInterface } = this.props;
        const codInstance = Session().codInstance

        if(detailsExtras != undefined && detailsExtrasAttributes != undefined) this.generateNewCorrectExtraStructures()

        this.props.setWizardState({ disabledStep: true });

        this.setState({ hideContent: true }, () => {
            setTimeout(() => {
                const deletedExtras = event.deletedExtras;
                const deletedDetails = event.deletedDetails;
                const deletedFields = event.deletedFields;
                const deletedFieldTransforms = event.deletedFieldTransforms;
                const deleteStructure = event.deleteStructure;
                const changedDetails = event.changedDetails;
                const changedFields = event.changedFields;
                const deletedFieldTransformsCase = event.deletedFieldTransformsCase;

                delete event.deletedExtras;
                delete event.deletedDetails;
                delete event.deletedFields;
                delete event.deletedFieldTransforms;
                delete event.changedDetails;
                delete event.changedFields;
                delete event.deleteStructure;
                delete event.deletedFieldTransformsCase;

                this.props.saveEvent(event, deletedExtras, deletedDetails, deletedFields, deletedFieldTransforms,deletedFieldTransformsCase, deleteStructure, changedDetails, changedFields, Session().desCompany,Session().desInstance,Session().desName,this.props.codInterface, codInstance, desInterface)
                    .then(() => {
                        Toast.success(Translate("event_saved_success"));
                        window.location.replace(`#/interfaces/events/${codInterface}`)
                    }).catch(() => { Toast.error(Translate("error_saving_event")) });
            })
        })
    }

    generateNewCorrectExtraStructures = () => {
        const { wizardState } = this.state;
        let detailsExtras = wizardState.detailsExtras
        let detailsExtrasAttributes = wizardState.detailsExtrasAttributes

        if(wizardState.event.structures != undefined && wizardState.event.structures.length > 0){
            wizardState.event.structures.forEach(structure => {
                let eventStructureExtras = structure.structureExtras.filter(structureExtra => {
                    if(structureExtra.codStructureExtra !== 64 && structureExtra.codStructureExtra !== 65 && structureExtra.codStructureExtra !== 103) return structureExtra
                })

                structure.structureDetails.forEach(structureDetail => {
                    if(structureDetail.structureExtras != undefined && structureDetail.structureExtras.length > 0){
                        detailsExtrasAttributes.map(attr => {
                            let structureExtra = structureDetail.structureExtras.find(extra => {
                                if (!extra.codStructureExtraValues) {
                                    return extra.codAttribute === attr.codAttribute;
                                } else {
                                    const codStrucExtra = detailsExtras.find(extra => extra.codAttribute === attr.codAttribute).codStructureExtra;
                                    return extra.codStructureExtra === codStrucExtra
                                }
                            })
    
                            if(structureExtra != undefined){
                                structureExtra["codStructureDetail"] = structureDetail.codStructureDetail
                                eventStructureExtras.push(structureExtra)
                            }
                        })
                    }
                })

                structure.structureExtras = eventStructureExtras
            })

            wizardState.event.structures.forEach(structure => structure.structureDetails.forEach(structureDetail => structureDetail.structureExtras = new Array))
        }
    }

    render() {
        const {
            wizardState,
            hideContent,
            dialogOptions,
            firstStep,
            eventProperties,
            isLoading
        } = this.state

        /* não renderizar caso venha por acesso direto
        depende de store setada em interfaces */

        if (!eventProperties.length || isLoading) {
            return <Fragment></Fragment>;
        }

        return (
            <Fragment>
                <EventName title={this.getEventTitle()}/>
                <EventType title={this.getConfigTitle()}/>

                <StyledPaper>
                    <Grid2 container spacing={1}>
                        <Grid2 size={15}>
                            {this.renderStepper()}
                        </Grid2>
                    </Grid2>

                    <Grid2 container spacing={1}>
                        <Grid2 size={15}>
                            {
                                // hide content força will unmount antes de salvar
                                hideContent
                                    ? <Fragment></Fragment>
                                    : this.renderContent()
                            }
                        </Grid2>
                    </Grid2>

                    <Grid2 container spacing={1} justifyContent="space-between">
                        <Grid2>
                        {
                            wizardState.currentStep !== firstStep ? (
                                <StyledButton 
                                    variant="contained"
                                    onClick={() => { this.changeStep(wizardState.currentStep - 1) }}
                                    color="primary">
                                    {Translate("back")}
                                </StyledButton>
                                ) : ""
                        }
                        </Grid2>
                        <Grid2 container spacing={1}>
                            <StyledButton  
                                variant="contained"
                                onClick={() => { this.goBack() }} color="primary">
                                    {Translate("cancel")}
                            </StyledButton>
                            <StyledButton  
                                    className="no-margin"
                                    disabled={Boolean(wizardState.disabledStep) || Boolean(wizardState.disabledStepEdit)} 
                                    variant="outlined" 
                                    onClick={() => {
                                        wizardState.currentStep === wizardState.eventProperties.length
                                            ? this.save()
                                            : this.changeStep(wizardState.currentStep + 1)
                                    }}>
                                    {
                                        (wizardState.eventProperties.length && wizardState.currentStep === wizardState.eventProperties.length)
                                            ? Translate("save")
                                            : Translate("go_forward")
                                    }
                            </StyledButton>
                        </Grid2>
                    </Grid2>
                </StyledPaper>
                <ConectorDialogConfirmation dialogOptions={dialogOptions} />
            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
        setWizardState: (wizardState) => dispatch(setWizardState(wizardState)),
        setSteps: (steps) => dispatch(setSteps(steps)),
        setStepStatus: (step, isDisabled) => dispatch(setSteps(step, isDisabled)),
        clearWizardStore: () => dispatch(clearWizardStore())
    };
};

const mapStateToProps = store => {
    const [interfaces, codInterface, desInterface] = interfaceSelector.getInterfacesInfo(store);
    
    return {
        codInterface,
        desInterface,
        wizardState: wizardStore.getWizardState(store),
        eventProperties: wizardStore.getEventProperties(store),
        connectionActions: wizardStore.getConnectionActions(store),
        saveEvent: wizardStore.postEvent(store),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);