import React, { Component, Fragment } from 'react'
import Translate from '../../i18n/translate'
import StyledSnackbar from '../../componentsUI/styledComponents/styledToast'
import {
    MdInfo,
    MdError,
    MdCheckCircle
} from "react-icons/md"

const iconList = {
    success : MdCheckCircle,
    error : MdError,
    info : MdInfo,
}

class ToastComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props.options
        }
    }

    componentWillReceiveProps(props) {
        if (props.options
            && props.options.type
            && props.options.message) {

            this.setState({
                ...props.options
            });
        }
    }

    render() {
        const { type, message, timeout } = this.state
        const open = type && message ? true : false
        const Icon = iconList[type]

        return (
            <StyledSnackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                ContentProps={{ className: type }}
                message={
                    <span className={'verticalAlign'}>
                        <Icon /> {Translate(message)}
                    </span>
                }
            />
        )
    }
}

export default ToastComponent
