import styled from "styled-components";
import Typography from '@mui/material/Typography'

export const TimelineTitle = styled(Typography)`
    && {
        background-color: #4c637b;
        padding: 5px 15px;
        font-size: 1rem;
        color: #fff;
        width: calc(100% - 30px);
        z-index: 999;
        text-align: center;
        &&:first-child {
            top: 0;
        }
        &&:last-child {
            bottom: 0;
        }
    }
`

export const TimelineDiv = styled.div`
    border-left: 1px solid #4c637b;
    position: relative;
    height: 110vh;
    padding: 0;
    overflow-y: auto;
`

export const TimelineCard = styled.div`

    background-color: #f4f4f4;
    margin: 15px 20px 10px 35px;
    padding: 10px 10px 5px;
    box-shadow: 0 0;
    font-size: 20px;
    margin-bottom:5px;
    margin-top: 10px;
    text-align: left;
    min-height: 75px;
    position: relative;
    overflow: initial;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
    border-radius: 12px;
    border: 2px solid #b8c6d6;
    &:hover {
        background-color: #ffff;
    }


    &.warning {
        &:before {
            content: "priority_high";
            background-color: #ffa000;
        }
    }

    &.error {
        &:before {
            content: "close";
            background-color: #d32f2f;
        }
    }

    &:after {
        content: "";
        position: absolute;
        border-style: solid;
        display: inline-block;
        border-color: transparent #b8c6d6;
        border-width: 10px 10px 10px 0;
        left: -10px;
        top: calc(50% - 10px);
    }

    > div:last-child {
        width: 100%;
        border-top: 1px solid #b8c6d6;
    }
    
    h3,
    h4,
    h5 { font-weight: 300;
    color: #6f8296;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 10px;
    text-align: left;
    }
    h6 {
        margin: 0;
        margin-bottom: 8px;
        color: #6f8296;
        font-weight: lighter;  
    }

    h3 {
        font-size: 0.870rem;
        @media screen and (max-width: 600px) {
            margin-top: 40px;
        }
        @media screen and (max-width: 400px) {
            font-size: 0.6rem;
        }
    }
`

export const TimelineIcon = styled.div`
    position: relative;
`

export const DropZoneDiv = styled.div`
    width: calc(99% - 60px);
    height: 90%;
    float: right;
    background-color: #EEEEEE;
    border: 0px solid #4c637b;
    border-radius: 4px;
    position: center;
    box-sizing: border-box;
`
