import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './store/store'
import App from './components/app/app'
import automaticallyLogOut from './utils/automaticallyLogOut'

automaticallyLogOut()

ReactDOM.createRoot(document.getElementById('root'))
.render(
  <Provider store={store}>
    <App/>
  </Provider>
)