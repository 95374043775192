import styled from 'styled-components'
import Typography from '@mui/material/Typography'

const StyledInputLabel = styled(Typography)`
    && {
        text-align: left;
        font-size: 0.8rem;
        margin-bottom: 5px;
        color: #6F8296;
        &.hidden { display: none; }
        &.required {
            :after {
                content: " *";
                color: #CD5C5C;
            }
        }
        &.error {
            color: #CD5C5C;
        }
    }
`

export default StyledInputLabel