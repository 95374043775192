import request from "../../utils/request";
import Session from '../../utils/session-info';

export default {
    getAllByWorker
};

function getAllByWorker(codInstance = Session().codInstance) {
    return request.executeRequest(`${request.baseUrl}worker_panel/${codInstance}`, request.getOptions()).then(response => {
        if (!response.ok) {
            return Promise.reject(response.statusText);
        }

        return response.json();
    }).then(worker => {
        sessionStorage.setItem("worker", JSON.stringify(worker));

        return worker;
    });
}