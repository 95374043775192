const getItemByPath = (items, path) => {
    for (let i = items.length; i--;) {
        const item = items[i];

        if (item.path == path) {
            return item;
        } else if (item.children) {
            const target = getItemByPath(item.children, path);

            if (target != null) {
                return target;
            }
        }
    }

    return null;
}

const getItemsByParentPath = (sources, parentPath) => {
    let fields = [];

    for (let s = sources.length; s--;) {
        const source = sources[s];

        if (source.parentPath == parentPath && !source.children) {
            fields.push(source);
        } else if (source.children) {
            fields = fields.concat(getItemsByParentPath(source.children, parentPath));
        }
    }

    return fields;
}

const getAttributeByCode = (attributes, codAttribute) => {
    if (attributes) {
        const attribute = attributes.find(attribute => attribute.codAttribute == codAttribute);

        if (attribute) {
            return attribute;
        }
    }

    return null;
}

const getAttributeValueByCode = (attributes, codAttribute) => {
    const attribute = getAttributeByCode(attributes, codAttribute);
    if (attribute) {
        return (attribute.desValue || '').replace('?', '');
    }

    return null;
}

const getExtraByCode = (extras, codStructureExtra) => {
    if (extras) {
        const sortedExtras = [...extras].sort((a, b) => a.codStructureExtraValues - b.codStructureExtraValues);
        const extra = sortedExtras.find(extra => extra.codStructureExtra == codStructureExtra);

        if (extra) {
            return extra;
        }
    }

    return null;
}

const getExtraValueByCode = (extras, codStructureExtra) => {
    const extra = getExtraByCode(extras, codStructureExtra);
    if (extra) {
        return extra.value;
    }

    return null;
}

export default {
    getItemByPath: getItemByPath,
    getItemsByParentPath: getItemsByParentPath,
    getAttributeByCode: getAttributeByCode,
    getAttributeValueByCode: getAttributeValueByCode,
    getExtraByCode: getExtraByCode,
    getExtraValueByCode: getExtraValueByCode
}