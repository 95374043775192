let getDetailTreeHeightInPixels = details => {
    let numberOfDetails = 0;

    const countDetailsRecursively = detailList => {
        detailList.forEach(detailIn => {
            numberOfDetails += 1; 
            if (detailIn.hasOwnProperty("children") && detailIn.children.length > 0) {
                countDetailsRecursively(detailIn.children);
            }
        });
    };
    countDetailsRecursively(details);

    if (numberOfDetails <= 4) return 320;
    else return numberOfDetails * 60;
};


export default getDetailTreeHeightInPixels