import React, { Component, Fragment } from 'react'
import WizardService from '../../../../wizard-service'
import SecondStepList from './second-step/second-step-list'
import ObjectUtils from '../../../../../../utils/object-utils'
import Translate from "../../../../../../i18n/translate"
import { Grid2 } from '@mui/material'
import ConectorSelect from "../../../../../../componentsUI/conectorSelect"
import StyledButton from "../../../../../../componentsUI/styledComponents/styledButton"
import {StyledTextAttribute, DivButtons, StyledTitleAttribute} from "../../../../../../componentsUI/styledComponents/styledWizardStructureDetailsTabs"
import * as wizardStore from "../../../../../../store/wizard/wizard-store-reducer";
import { setWizardState } from "../../../../../../store/wizard/wizard-store-actions";
import { connect } from "react-redux";

class WizardStructureDetailsTabsFieldsModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            attributeGroups: [],
            openModal: false,
            currentStep: 1,
            ...props
        }
    }

    componentWillReceiveProps = props => {
        const { attributeGroups } = this.state;

        var selectedField = ObjectUtils.deepCopy(props.selectedField),
            fixedStep;

        if (selectedField.codAttributeGroup) {
            selectedField.attributeGroup = attributeGroups.find(attrGroup =>
                attrGroup.CodAttributeGroup === selectedField.codAttributeGroup
            );
            fixedStep = true;
        } else if (attributeGroups.length === 1) {
            fixedStep = true;
            selectedField.attributeGroup = attributeGroups[0];
            selectedField.codAttributeGroup = attributeGroups[0].CodAttributeGroup;
        }

        this.setState({
            attributeGroups: [],
            ...this.state,
            ...props,
            selectedField,
            fixedStep,
            currentStep: 1
        });
    }

    handleDismiss = () => {
        this.props.update({ openModal: false });
        this.props.setWizardState({disabledStepEdit: false})
    }

    handleClose = () => {
        this.doFixValues();

        let state = { ...this.state };

        this.state.setChangedField("CHANGED", state.selectedField);
        this.props.setWizardState({disabledStepEdit: false})
        this.props.update({
            openModal: false,
            selectedField: state.selectedField
        });
    }

    doFixValues = () => {
        let { selectedField } = this.state;

        let toAttribute = selectedField.fieldAttributes.find(attr => attr.description === "to");
        if (toAttribute) {
            let toValue = toAttribute.desValue.trim();

            selectedField.to = toValue;
            selectedField.fieldAttributes.find(attr => attr.description === "to").desValue = toValue;
        }

        let fromAttribute = selectedField.fieldAttributes.find(attr => attr.description === "from");
        if (fromAttribute) {
            let fromValue = fromAttribute.desValue.trim();

            selectedField.from = fromValue;
            selectedField.fieldAttributes.find(attr => attr.description === "from").desValue = fromValue;
        }

        let formatAttribute = selectedField.fieldAttributes.find(attr => attr.description === "format_to");
        if (formatAttribute) {
            let formatValue = formatAttribute.desValue.trim();

            selectedField.fieldAttributes.find(attr => attr.description === "format_to").desValue = formatValue;
        }
    }

    update = newState => {
        this.setState({
            ...this.state, ...newState
        });
    }

    renderStep = step => {
        const {
            attributeGroups,
            attrs,
            selectedField
        } = this.state;

        if (!selectedField.codAttributeGroup) {
            step = 1
        }
        switch (step) {
            case 1:
                let optionsSubaction = attributeGroups.map(attributeGroup => {
                    return {
                        label: attributeGroup.DesLabel,
                        value: attributeGroup.CodAttributeGroup
                    }
                })
                return (
                    <Fragment>
                        <StyledTextAttribute>
                            {Translate("select_subaction_field")}
                        </StyledTextAttribute>
                        <Grid2 container spacing={1}>
                            <Grid2>
                                <ConectorSelect
                                    required={true}
                                    name='CodAttributeGroup'
                                    placeholder='choose'
                                    onChange={this.handleChangeSubaction}
                                    options={optionsSubaction}
                                    value={(() => {
                                        if (selectedField.attributeGroup
                                            && selectedField.attributeGroup.CodAttributeGroup) {
                                            return optionsSubaction.find(subaction =>
                                                selectedField.attributeGroup.CodAttributeGroup === subaction.value
                                            );
                                        }
                                    })()}
                                    label='subaction'
                                    help='select_subaction'
                                />
                            </Grid2>
                        </Grid2>
                    </Fragment>
                )
            case 2:
                return (
                    React.createElement(
                        SecondStepList(selectedField.codAttributeGroup), {
                            ...this.state,
                            update: this.update.bind(this),
                            attrs
                        }
                    )
                )
            default:
                return (<Fragment></Fragment>)
        }
    }

    handleChangeSubaction = selectedObj => {
        const { selectedField, attributeGroups } = this.state;
        if (selectedField.attributeGroup || selectedField.codAttributeGroup) {
            selectedField.fieldAttributes = [];
        }

        selectedField.attributeGroup = attributeGroups.find(attrGroup =>
            attrGroup.CodAttributeGroup === selectedObj.value
        );

        selectedField.codAttributeGroup = selectedObj.value;
        this.setState({ selectedField });
    }

    changeStep = newStep => {
        if (newStep === 2) {
            const {
                attributeGroups,
                selectedField
            } = this.state;

            const attributeGroup = attributeGroups.find(attributeGroup => attributeGroup.CodAttributeGroup === selectedField.attributeGroup.CodAttributeGroup);
            const attributes = attributeGroup.AttributeGroupCodes || [];

            attributes.sort((o1, o2) => {
                return o1.indPosition - o2.indPosition
            });

            let uniqueIds = [];

            attributes.forEach(attr => {
                if (!uniqueIds.includes(attr.codAttribute)) {
                    uniqueIds.push(attr.codAttribute)
                }
            });

            WizardService.GetAttributesByIdArray(uniqueIds).then((attrs) => {
                let masterAttrs = attrs.filter(attr => !attr.CodMaster).map((masterAttr) => {
                    masterAttr.indPosition = attributes.find(attr =>
                        attr.codAttribute === masterAttr.codAttribute
                    ).indPosition;

                    return masterAttr;
                }).sort((o1, o2) => {
                    return o1.indPosition - o2.indPosition;
                });

                attrs.forEach(attr => {
                    if (attr.CodMaster) {
                        let master = masterAttrs.find(parent => parent.codAttribute === attr.CodMaster);

                        if (!master.options) {
                            master.options = [attr];
                        } else {
                            master.options.push(attr);
                        }
                    }
                });

                this.setState({
                    currentStep: newStep,
                    attrs: masterAttrs
                });
            });
        } else {
            this.setState({ currentStep: newStep });
        }
    }

    getButtonsToRender = _ => {

        const {
            fixedStep,
            stepBusy,
            currentStep,
            selectedField
        } = this.state;

        if (fixedStep) {
            return (
                <Fragment>
                    <Grid2 container spacing={1} justifyContent="space-between">
                        <StyledButton
                            variant="contained"
                            color="primary"
                            onClick={this.handleDismiss.bind(this)}>
                            {Translate("cancel")}
                        </StyledButton>
                        <StyledButton
                            variant="outlined"
                            disabled={stepBusy}
                            onClick={this.handleClose.bind(this)}>
                            {Translate("save")}
                        </StyledButton>
                    </Grid2>

                </Fragment>
            )
        }

        return (
            <Fragment>
                <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={
                        currentStep === 1
                            ? this.handleDismiss.bind(this)
                            : () => { this.changeStep(1) }
                    }
                >
                    {Translate(currentStep === 1 ? 'cancel' : 'back')}
                </StyledButton>
                <StyledButton
                    variant="contained"
                    disabled={currentStep === 1 ? !selectedField.hasOwnProperty("attributeGroup") : stepBusy}
                    onClick={
                        currentStep === 1
                            ? () => { this.changeStep(2) }
                            : this.handleClose.bind(this)
                    }
                >
                    {Translate(currentStep === 1 ? 'go_forward' : 'save')}
                </StyledButton>
            </Fragment>
        )
    }

    render() {
        const {
            currentStep,
            openModal,
            fixedStep
        } = this.state;

        if (fixedStep && currentStep === 1) {
            setTimeout(() => { this.changeStep(2) })
            return <Fragment></Fragment>
        }

        return (
            <Fragment>
                <div style={{ display: openModal ? 'block' : 'none' }}>
                    <StyledTitleAttribute>{Translate("field_attributes")}</StyledTitleAttribute>
                    <div>
                        {this.renderStep(currentStep)}
                    </div>
                    <DivButtons>
                        {this.getButtonsToRender()}
                    </DivButtons>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        wizardState: wizardStore.getWizardState(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setWizardState: wizardState => dispatch(setWizardState(wizardState))
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WizardStructureDetailsTabsFieldsModal)