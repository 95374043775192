
import * as types from './action-types';
import {detailService} from './service';

export const getAllDetailsIn =(codStructure) => {
    return async (dispatch, getState) => {
        try {
            const details = await detailService.getAll(codStructure);
            dispatch({ type: types.FETCH_DETAILS_IN, details });
        } catch (error) {
            console.error(error);
        }
    };
}