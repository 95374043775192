import ConectorSelect from "../../componentsUI/conectorSelect"
import ConectorCheckbox from "../../componentsUI/checkBox"
import ConectorInputText from "../../componentsUI/inputText"
import ConectorInputNumber from "../../componentsUI/inputNumber"
import ConectorDatePicker from "../../componentsUI/datePicker"

export default (codAttributeType) => {
    switch(codAttributeType) {
        case 1 : return ConectorInputText
        case 2 : return ConectorInputNumber
        case 3 : return ConectorInputNumber
        case 4 : return ConectorDatePicker
        case 5 : return ConectorCheckbox
        case 7 : return ConectorSelect
        case 'type_from' : return ConectorInputText
        default: return ConectorInputText
    }
}