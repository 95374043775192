import React, {
	Component,
	Fragment
} from "react";
import { connect } from "react-redux";
import moment from "moment";
import UserService from "./user-service";
import Translate from "../../i18n/translate";
import { setBreadcrumb } from "../../store/app-state/actions";
import * as appStateReducer from "../../store/app-state/reducer";
import Switch from '@mui/material/Switch'
import Loading from "../../componentsUI/loading"
import Toast from "../../components/toast/toast"
import ConectorDialogConfirmation from "../../componentsUI/dialogComponentConfirmation"
import ConectorTable from "../../componentsUI/conectorTable"

class Users extends Component {
	constructor(props) {
		super(props);

		props.setBreadcrumb([{
			label: "registrations"
		}, {
			label: "users"
		}]);

		this.state = {
			...props,
			users: [],
			loading: true,
			gridOptions: {
				hideBtnEdit: true,
				hideBtnDelete: true,
				hideBtnCopy: true,
				sortField: "desName",
				columns: [{
					title: "description",
					field: "desName"
				}, {
					title: "email",
					field: "desEmail"
				}, {
					title: "creation_date",
					field: "datCreation"
				}, {
					title: "modification_date",
					field: "datModification"
				}, {
					title: "language",
					field: "language"
				}, {
					title: "active",
					field: "isActive"
				}]
			},
			userServiceRequestCompleted: false,
			allowedUser: false
		};
	}

	componentWillMount() {
		this.getUsers();
	}

	async componentDidMount() {
        await this.updateButtonVisibility();
    }

	async updateButtonVisibility() {
		UserService.GetUserPermissionUser().then(res => {
			this.setState({
				userServiceRequestCompleted: true,
				allowedUser: res.hasPermission,
			});
		})
		.catch(error => {
			Toast.error(Translate(error.message));
			setTimeout(() => {
				window.location.href = '/';
			}, 2000);
		});
    }

	componentWillReceiveProps(props) {
		this.setState({...props});
	}

	activateUser = (targetUser) => {
		if(!targetUser.isActive){
			this.setState({
				confirmationModalOptions: {
					type: "danger",
					title: "user_update",
					message: "activate_user",
					open: true,
					onDismiss: this.onDismissModal(),
					onClose: this.onConfirmActivateUser.bind(this, UserService),
				},
				targetUser,
			})
		} else{
			this.setState({
				confirmationModalOptions: {
					type: "danger",
					title: "user_update",
					message: "inactivate_user",
					open: true,
					onDismiss: this.onDismissModal(),
					onClose: this.onConfirmActivateUser.bind(this, UserService),
				},
				targetUser,
			})
		}
    }

	onConfirmActivateUser = (UserService) => {
        const { targetUser } = this.state;
        UserService
            .ActivateUser(targetUser.codUser)
			.then(res => {
				if(res.ok){
                	Toast.success(Translate("user_status_succeeded"));
                	this.getUsers();
				} else if(res.status == 401) Toast.error(Translate("userWithoutPermissionInstance"));

				this.setState({
                	confirmationModalOptions: { open: false },
                    targetUser: {}
                });
			})
    }

	onDismissModal = () => {
        this.setState({
            confirmationModalOptions: {
                open: false
            }
        })
    }

	getUsers = _ => {
		UserService.FindAll().then((users) => {
			this.setState({
				users: users.map((user, i) => {
					let datModification = moment(user.datModification);

					if (datModification.year() === 0) {
						datModification = null;
					}

					return {
						...user,

						datCreation: moment(user.datCreation).format("DD/MM/YYYY"),
						datModification: datModification ? datModification.format("DD/MM/YYYY") : "",
						language: (user.language === "pt-BR" ? "Português" : "Inglês"), 
						isActive: (<div onClick={_=> {
									this.activateUser(user, i)
								}}> {
									user.isActive ?
										<Switch
											color="primary"
											value={user.isActive}
											id={i}
											checked={true}
											size="small"
											onChange={this.handleChange}
											inputProps={{ 'aria-label': 'controlled' }}
										/>:
										<Switch
											checked={false}
											value={user.isActive}
											id={i}
											size="small"
											onChange={this.handleChange}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
								}
							</div>
						)
					}
				}),
				loading: false
			});
		});
	}

	handleButtonAdd() {
		window.location.replace('#/users/add')
	}

	render() {
		const {
			users,
			loading,
			permission,
			gridOptions,
			confirmationModalOptions,
			allowedUser,
			userServiceRequestCompleted
		} = this.state;

		if (!userServiceRequestCompleted) {
			return (<Loading />)
		}

		return (
			<Fragment>
				<ConectorTable gridTitle={Translate("users")} gridBtn={Translate("new_user")}
					gridNewItem={this.handleButtonAdd} hideTopDiv={!allowedUser} gridOptions={gridOptions} dataSource={users}/>
					<ConectorDialogConfirmation dialogOptions={confirmationModalOptions}/>
			</Fragment>
		)
	}
}

const mapStateToProps = store => {
	return {
		permission: appStateReducer.getUserPermissionByView(store)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
	}
}

Users = connect(mapStateToProps, mapDispatchToProps)(Users);

export default Users;