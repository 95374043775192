import styled from "styled-components";

const StyledTitlePage = styled('h1')({
    textAlign: 'center',
    fontSize: '1.5rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: '#6F8296'
});

export default StyledTitlePage;