import React, { Component, Fragment } from "react";
import ConnectionService from "../../../connections/connections-service"
import { Grid2 } from "@mui/material"
import ConectorSelect from "../../../../componentsUI/conectorSelect"
import ConectorCheckbox from "../../../../componentsUI/checkBox"
import ConectorInputText from "../../../../componentsUI/inputText"

class ConnectionDetailsStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isChecked: false,
            isIgnoreParamStep: false,
            connection: null,
            connections: [],
            ...props
        };
    }

    componentWillMount() {
        const {
            codConnectionMode,
            targetInstance,
            isChecked,
            connection
        } = this.state;

        ConnectionService.GetConnectionsByInstanceAndConnectionMode(targetInstance, codConnectionMode).then((connections) => {
            let hasConnections = connections.length > 0;

            this.setState({
                connections: connections.map(({ id, description }) => {
                    return {
                        value: id,
                        label: description
                    }
                }),
                connection: hasConnections && connection == null ? connections[0].id : connection,
                isChecked: hasConnections ? isChecked : false
            }, this.updateParent)
        });
    }

    stepDisabled() {
        const {
            description,
            isChecked
        } = this.state;

        return (!isChecked && (!description || description.trim() === ""));
    }

    onChange({ target: { name, value } }) {
        this.setState({
            [name]: value
        }, this.updateParent);
    }

    onChangeCheckbox() {
        let {
            isChecked
        } = this.state;

        this.setState({
            isChecked: !isChecked,
            isIgnoreParamStep: !isChecked
        }, this.updateParent);
    }

    onChangeSelect({ value }) {
        this.setState({
            connection: value
        }, this.updateParent);
    }

    updateParent() {
        this.props.updateParent({
            ...this.state,
            stepDisabled: this.stepDisabled()
        });
    }

    render() {
        const {
            connections,
            description,
            connection,
            isChecked
        } = this.state;

        return (
            <Fragment>
                {
                    connections.length > 0 ? (
                        <Grid2 container spacing={1}>
                            <Grid2>
                                <ConectorCheckbox label={"existing_connection"} name="isChecked" color="default"
                                    value={isChecked} onChange={this.onChangeCheckbox.bind(this)} checked={isChecked}/>
                            </Grid2>
                        </Grid2>
                    ) : null
                }

                {
                    isChecked ? (
                        <Grid2 container spacing={1}>
                            <Grid2>
                                <ConectorSelect label={'connections'} name={'connection'} required={true}
                                    onChange={this.onChangeSelect.bind(this)} options={connections}
                                    value={connections.find((conn) => conn.value === connection)}/>
                            </Grid2>
                        </Grid2>
                    ) : (
                            <Grid2 container spacing={1}>
                                <Grid2>
                                    <ConectorInputText label="description" help={"enter_description"} name="description"
                                        onChange={this.onChange.bind(this)} value={description} required={true}/>
                                </Grid2>
                            </Grid2>
                        )
                }
            </Fragment>
        )
    }
}

export default ConnectionDetailsStep;