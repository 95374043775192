import React, { Component } from "react";
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField
} from "@mui/material";
import Translate from "../i18n/translate"
import { StyledDialog, StyledDialogTitle } from "./styledComponents/styledDialog"
import Loading from "./loadingSmall"
import CancelConfirmButtons from "./cancelConfirmButtons"

class ConectorDialogConfirmation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            open: false,
            randomNumber1: null,
            randomNumber2: null,
            userInput: "",
            isConfirmDisabled: false,
        }
    }

    componentWillReceiveProps(props) {
        const dialogOptions = props.dialogOptions || {}
        const { requiresMathValidation = false } = dialogOptions
    
        const randomNumber1 = requiresMathValidation ? Math.floor(Math.random() * 9) + 1 : null
        const randomNumber2 = requiresMathValidation ? Math.floor(Math.random() * 9) + 1 : null
    
        this.setState({
            ...dialogOptions,
            randomNumber1,
            randomNumber2,
            userInput: "",
            isConfirmDisabled: requiresMathValidation,
        })
    }
    

    handleInputChange = event => {
        const { randomNumber1, randomNumber2 } = this.state
        const userInput = event.target.value

        const isConfirmDisabled = parseInt(userInput, 10) !== randomNumber1 + randomNumber2

        this.setState({ userInput, isConfirmDisabled })
    }

    handleClose = _ => {
        if (this.state.onClose) {
            this.state.onClose()
        }
    }

    handleDismiss = _ => {
        if (this.state.onDismiss) {
            this.state.onDismiss()
        }

        this.setState({ open: false })
    }

    render() {
        const {
            randomNumber1,
            randomNumber2,
            userInput,
            isConfirmDisabled,
            requiresMathValidation,
        } = this.state

        return (
            <StyledDialog
                open={this.state.open}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
            >
                <StyledDialogTitle className={this.state.type} id="confirmation-dialog-title">
                    {Translate(this.state.title)}
                </StyledDialogTitle>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <DialogContent>
                        <DialogContentText id="confirmation-dialog-description">
                            {Translate(this.state.message)}
                        </DialogContentText>
                        {requiresMathValidation && (
                            <div style={{ marginTop: "1rem" }}>
                                <DialogContentText>
                                    {Translate("solve_the_sum")}: {randomNumber1} + {randomNumber2}
                                </DialogContentText>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    value={userInput}
                                    onChange={this.handleInputChange}
                                    placeholder={Translate("enter_result")}
                                />
                            </div>
                        )}
                    </DialogContent>
                )}
                <DialogActions>
                    <CancelConfirmButtons
                        onCancel={this.handleDismiss.bind(this)}
                        onConfirm={this.handleClose.bind(this)}
                        isConfirmDisabled={isConfirmDisabled}
                    />
                </DialogActions>
            </StyledDialog>
        )
    }
}

export default ConectorDialogConfirmation