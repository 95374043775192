import styled from "styled-components"
import StyledButton from "../../componentsUI/styledComponents/styledButton"

export const StyledLeftButton = styled(StyledButton)`
    && {
        margin-inline-end: auto;
        margin-left: 8px;
    }
`

export const StyledLoading = styled.div`
    && > div {
        height: 256px;
    }
`

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
`

export const FakeInputStyle = { opacity: 0, float: "left", border: "none", height: 0, width: 0 }