import { Tabs, Grid2 } from "@mui/material"
import styled from "styled-components"
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import { Typography } from '@mui/material'

export const StyledGrid = styled(Grid2)`
    && {
        position: relative;
        background-color: #fff;
        margin-top: 70px;
        width: 100%;
    }
`

export const StyledTabs = styled(Tabs)`
    && {
        position: absolute;
        width: 100%;
        top: -47px;
        overflow: visible;

        button {
            color: #6F8296;
            font-size: 0.75rem;
            overflow: visible;

            border-top: 1px solid #F2F2F2;
            border-left: 1px solid #F2F2F2;
            border-right: 1px solid #F2F2F2;

            &:hover, &.Mui-selected {
                background-color: #fff;
                border-top: 1px solid #B8C6D6;
                border-left: 1px solid #B8C6D6;
                border-right: 1px solid #B8C6D6;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }

        .MuiTabs-fixed {
            overflow-x: initial;
        }

        .MuiTabs-indicator {
            height: 0;
            background-color: #B8C6D6;
        }
    }
`

export const StyledDiv = styled.div`
    && {
        padding: 20px;
        border: 1px solid #B8C6D6;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        min-height: calc(100vh - 714px);
    }
`

export const StyledIconButton = styled(StyledButton)`
    && { 
        min-width: 70px; 
        margin-bottom: 20px;

        @media screen and (max-width:500px) {
            min-width: calc(50% - 13px);
            &:last-child { min-width: calc(100% - 15px); }
        }
    }
`

export const StyledTextAttribute = styled(Typography)`
    && { padding-bottom: 20px; }
`

export const DivButtons = styled.div`
    && { margin-top: 20px }
`

export const StyledTitleAttribute = styled(Typography)`
    && {
        width: 100%;
        padding: 3px 0;
        text-align: left;
        font-size: 1.3rem;
        color: #6F8296;
    }
`
