import styled from "styled-components";
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import { StyledDialog } from "../../componentsUI/styledComponents/styledDialog"

export const StyledDialogZIndex = styled(StyledDialog)`
    && {
        .MuiPaper-root {
            overflow-y: unset;
        }

        .MuiDialogContent-root {
            padding-top: 0;
            overflow-y: unset;
        }
    }`

export const StyledLeftButton = styled(StyledButton)`
    && {
        margin-inline-end: auto;
        margin-left: 8px;
    }`

export const StyledLoading = styled.div`
    && > div {
        height: 256px;
    }`
    
export const titleStyles = {
    marginBottom: "12px",
    fontSize: "19px",
    paddingBottom: "3px",
    fontWeight: "bold",
    color: "#949494",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    borderBottom: "1px solid #a7a7a7"
}

export const ContainerDiv = styled.div`
    && { 
        padding: 30px 0; 
    }
`

export const DivButtons = styled.div`
    && {
        width: 100%;
        display: inline-block;

        > button:nth-child(1),
        > button:nth-child(2) {
            margin-right: 0px;
        }

        .hidden { 
            display: none; 
        }
        .right { 
            float: right 
        }
    }
`