import React, { Component } from "react"
import ConectorSelect from "../../../../componentsUI/conectorSelect"
import StyledPaper from "../../../../componentsUI/styledComponents/styledPaper"
import ConectorInputText from "../../../../componentsUI/inputText"
import Translate from "../../../../i18n/translate"
import TransformUtil from "../transform-util"

class TransformFixedValue extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            systemFieldList: [{
                value: "datetime_now",
                label: "datetime_now"
            }, {
                value: "last_execution",
                label: "last_execution"
            }, {
                value: "next_execution",
                label: "next_execution"
            }, {
                value: "sync_date",
                label: "sync_date"
            }, {
                value: "next_sync_date",
                label: "next_sync_date"
            }, {
                value:"interface_name",
                label: "interface_name"
            }],
            typeList: [{
                value: "text",
                label: Translate("string")
            }, {
                value: "system",
                label: Translate("system")
            }]
        }
    }

    componentWillReceiveProps = props => {
        let state = {...this.state, ...props};

        this.setState(state)
    }

    handleChangeValue = (event) => {
        const {
            attributes,
            fieldTransform
        } = this.props;

        const transformAttributes = fieldTransform.fixedValues[0].fieldTransformAttributes;

        let attribute;
        let value;

        if (event.hasOwnProperty("target")) {
            attribute = event.target.name;
            value = event.target.value;
        } else {
            attribute = event.name;
            value = event.value;
        }

        TransformUtil.getAttributeByCode(transformAttributes, attributes[attribute]).desValue = value;

        this.setState({ fieldTransform: fieldTransform });
    }

    handleChangeType = (event) => {
        const { systemFieldList } = this.state;
        const {
            attributes,
            fieldTransform
        } = this.props;

        const transformAttributes = fieldTransform.fixedValues[0].fieldTransformAttributes;
        const isSystemField = (event.value == "system");

        TransformUtil.getAttributeByCode(transformAttributes, attributes.is_system).desValue = isSystemField.toString();

        if (isSystemField) {
            TransformUtil.getAttributeByCode(transformAttributes, attributes.from).desValue = systemFieldList[0].value;
        } else {
            TransformUtil.getAttributeByCode(transformAttributes, attributes.from).desValue = "";
            TransformUtil.getAttributeByCode(transformAttributes, attributes.format_from).desValue = "";
        }

        this.setState({ fieldTransform: fieldTransform }, this.props.updateEvent);
    }

    render() {
        const { attributes } = this.props;
        const {
            typeList,
            systemFieldList
        } = this.state;
        const transformAttributes = [...this.props.fieldTransform.fixedValues[0].fieldTransformAttributes];

        const fixedValue = TransformUtil.getAttributeValueByCode(transformAttributes, attributes.from);
        const isSystemField = TransformUtil.getAttributeValueByCode(transformAttributes, attributes.is_system) == "true";
        const fixedFormat = TransformUtil.getAttributeValueByCode(transformAttributes, attributes.format_from);

        return (
            <StyledPaper>
                <ConectorSelect name={"type"} label={"type"} placeholder={"choose"} options={typeList}
                    value={typeList.find(type => type.value == (isSystemField ? "system" : "text"))}
                    onChange={this.handleChangeType.bind(this)} />

                {
                    isSystemField ? (
                        <div>
                            <ConectorSelect name={"from"} label={"system"} placeholder={"choose"} options={systemFieldList}
                                value={systemFieldList.find(systemField => systemField.value == fixedValue)}
                                onChange={this.handleChangeValue.bind(this)} />

                            <ConectorInputText name="format_from" label={"format_from"} value={fixedFormat}
                                onChange={this.handleChangeValue.bind(this)} />
                        </div>
                    ) : (
                            <ConectorInputText name={"from"} label={"string"} type={"text"} value={fixedValue}
                                onChange={this.handleChangeValue.bind(this)} onBlur={this.props.updateEvent} />
                        )
                }
            </StyledPaper>
        )
    }

}

export default TransformFixedValue;