import styled from 'styled-components'
import Stepper from '@mui/material/Stepper'

const StyledStepper = styled(Stepper)`
  && {
    svg.MuiSvgIcon-root {
      font-size: 28px;
      color: #6F8296;
      transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      
      text {
        fill: #FFFFFF;
        transition: fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      &.MuiStepIcon-root.Mui-active {
        color: #009CDF;
        font-size: 40px;
        margin-top: -5px;
      }
    }

    .MuiStepConnector-root {
      top: 14px;
      left: calc(-50% + 42px);
      right: calc(50% + 42px);
      z-index: 99;
      height: 2px;
      background-color: #B8C6D6;
      transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      span { 
        border-color: #B8C6D6;
        background-color: #B8C6D6;
        transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      &.MuiStepConnector-active {
        left: calc(-50% + 10px);
        right: calc(50% + 10px);
        background-color: #009CDF;

        span {
          border-color: #009CDF;
          background-color: #009CDF;
        }
      }
    }

    .MuiStepLabel-labelContainer {
      span {
        color: #6F8296;
        border-radius: 4px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 10px;
      }

      span.MuiStepLabel-active {
        color: #009CDF;
        font-weight: bold;
        margin-top: 9px;
        font-size: 1rem;
        line-height: 1.2;
      }
    }

    button:hover {
      svg { color: #4C637B; }
      .MuiStepLabel-labelContainer { 
        span { color: #4C637B; }
        span.MuiStepLabel-active {
          color: #009CDF;
        } 
      }
    }

    .completed {
      button, button:hover {
        svg { color: #009CDF; }
        .MuiStepLabel-labelContainer { 
          span { color: #009CDF; }
        }
      }
      
      .MuiStepConnector-root {
        left: calc(-50% + 10px);
        right: calc(50% + 10px);
        background-color: #009CDF;

        span { 
          border-color: #009CDF;
          background-color: #009CDF;
        }
      }
    }
    
    .disabled { 
      opacity: 0.5;
      button { 
        cursor: not-allowed;
        &:hover {
          svg { color: #6F8296 !important; }
          .MuiStepLabel-labelContainer { 
            span { color: #6F8296 !important; } 
          }
        }
      }
    }
  }
`

export default StyledStepper