import { Button, Grid2 } from "@mui/material"
import Translate from "../i18n/translate"
import StyledButton from "../componentsUI/styledComponents/styledButton"

const CancelConfirmButtons = ({ onCancel, onConfirm, isConfirmDisabled }) => (
    <Grid2 container spacing={1}>
        <Grid2 item>
            <StyledButton variant="contained" onClick={onCancel}>
                {Translate("cancel")}
            </StyledButton>
        </Grid2>
        <Grid2 item>
            <StyledButton
                className="no-margin"
                variant="outlined"
                onClick={onConfirm}
                disabled={isConfirmDisabled}
            >
                {Translate("confirm")}
            </StyledButton>
        </Grid2>
    </Grid2>
)

export default CancelConfirmButtons