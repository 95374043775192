import React, { Component, Fragment } from 'react'
import VersionModal from "./version-modal"
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import VersionService from "./version-service"
import { StyledVersionText } from "../../../componentsUI/styledComponents/styledVersionModal"

class InfoSoftwareNavLink extends Component {
    constructor(props) {
        super(props)  

        this.state = {
            ...props.appState,
            openVersionModal: false,
            softwareVersions: undefined
        }
    }

    componentWillMount = () => this.getSoftwareVersions()

    getSoftwareVersions = () => {
        const { softwareSystemName } = this.props

        VersionService.getSoftwareVersions(softwareSystemName)
        .then(response => {
            this.setState({ softwareVersions: response })
        })
    }
    
    handleOpenModal = () => {
        this.setState({ openVersionModal: true });
    }

    handleDismissModal = () => {
        this.setState({ openVersionModal: false }, () => this.getSoftwareVersions())
    }

    getSystemName = systemName => {
        switch (systemName) {
            case "Portal":
                return "Portal"
            case "Worker":
                return "Worker"
            case "MicroBack":
                return "Microserviço de Backend"
            case "MicroFront":
                return "Microserviço de Frontend"
            case "MicroDash":
                return "Microserviço de Dash"
            default:
                break;
        }
    }

    render() {
        const { openVersionModal, softwareVersions } = this.state
        const { softwareSystemName } = this.props

        return (
            <Fragment>
                <div className={'btnVersion'} onClick={this.handleOpenModal}>
                    <div>
                        <Typography>
                            <StyledVersionText>
                                <Link style={{textDecoration: 'none'}} color="inherit">
                                    {`${this.getSystemName(softwareSystemName)}: ${softwareVersions}`}
                                </Link>
                            </StyledVersionText>
                        </Typography>
                    </div>
                </div>
                {(() => {
                    if (openVersionModal) {
                        return <VersionModal softwareSystemName={softwareSystemName} onDismiss={this.handleDismissModal.bind(this)} />
                    }
                })()}
            </Fragment>
        )
    }
}

export default InfoSoftwareNavLink