import React, {Component, Fragment} from "react";
import { Grid2, CircularProgress } from "@mui/material";
import ConnectionsService from "../../../connections/connections-service"
import ConectorSelect from "../../../../componentsUI/conectorSelect"
import ConectorCheckbox from "../../../../componentsUI/checkBox"
import ConectorDatePicker from "../../../../componentsUI/datePicker"
import ConectorInputNumber from "../../../../componentsUI/inputNumber"
import ConectorInputText from "../../../../componentsUI/inputText"
import { LoaderContainer, FakeInputStyle } from "../../../../componentsUI/styledComponents/styledInterfacesCopyModal"

class ConnectionParametersStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            loading: true,
            connectionAttributes: [],
            inputsByAttributeType: {
                1: {
                    component: ConectorInputText,
                    handler: this.onChange
                },
                2: {
                    component: ConectorInputNumber,
                    handler: this.onChange
                },
                3: {
                    component: ConectorInputNumber,
                    handler: this.onChange
                },
                4: {
                    component: ConectorDatePicker,
                    handler: this.onChange
                },
                5: {
                    component: ConectorCheckbox,
                    handler: this.onChangeCheckbox
                },
                7: {
                    component: ConectorSelect,
                    handler: this.onChangeSelect
                },
                10: {
                    component: ConectorInputText,
                    handler: this.onChange
                },
                12: {
                    component: ConectorInputText,
                    handler: this.onChange
                }
            }
        }
    }

    componentWillMount() {
        const {
            connectionAttributes
        } = this.state;

        if (connectionAttributes.length == 0) {
            this.getAttributes();
        }
    }

    getAttributes() {
        let {
            codConnectionMode,
            attributes
        } = this.state;

        ConnectionsService.GetAttributesByCodConnectionMode(codConnectionMode).then((connectionAttributes) => {
            this.setState({
                attributes,
                loading: false,
                connectionAttributes
            }, this.updateParent);
        });
    }

    updateParent() {
        this.props.updateParent({
            ...this.state,
            stepDisabled: this.stepDisabled()
        });
    }

    stepDisabled() {
        const {
            attributes,
            connectionAttributes
        } = this.state;

        if (!attributes || !attributes.length) {
            return true;
        }

        for (let c = connectionAttributes.length; c--;) {
            const {
                isRequired,
                codAttribute
            } = connectionAttributes[c];

            if (isRequired === "true") {
                const attributeValue = attributes.find((attribute) => attribute.codAttribute === codAttribute).value;

                if (!attributeValue && attributeValue.trim() === "") {
                    return true;
                }
            }
        }

        return false;
    }

    onChange({ target: { name, value } }) {
        const {
            attributes
        } = this.state;

        attributes.find((attr) => attr.codAttribute === Number(name.replace("attribute_", ""))).value = value;

        this.setState({
            attributes
        }, this.updateParent);
    }

    onChangeCheckbox({ target: { name } }) {
        const {
            attributes
        } = this.state;

        const attribute = attributes.find((attr) => attr.codAttribute === Number(name));
        attribute.value = !attribute.value;

        this.setState({
            attributes
        }, this.updateParent);
    }

    onChangeSelect({ name, value }) {
        const {
            attributes
        } = this.state;

        attributes.find((attr) => attr.codAttribute === Number(name)).value = value;

        this.setState({
            attributes
        }, this.updateParent);
    }

    renderAttribute({
        codAttribute,
        codAttributeType,
        label,
        isRequired
    }) {
        const {
            inputsByAttributeType,
            attributes
        } = this.state;

        let { value } = attributes.find((att) => att.codAttribute === codAttribute) || {};
        let options = [];

        return (
            <Grid2 key={`connection-parameters-${codAttribute}`}>
                {
                    React.createElement(
                        inputsByAttributeType[codAttributeType].component,
                        {
                            name: "attribute_" + codAttribute,
                            required: isRequired,
                            onChange: inputsByAttributeType[codAttributeType].handler.bind(this),
                            checked: Boolean(value),
                            showTime: true,
                            value,
                            options,
                            label
                        }
                    )
                }
            </Grid2>
        )
    }

    render() {
        const {
            connectionAttributes,
            loading
        } = this.state;

        if (loading) {
            return (
                <LoaderContainer>
                    <CircularProgress />
                </LoaderContainer>
            )
        }

        return (
            <Fragment>
                <input type="password"
                    name="fake-password"
                    autoComplete="new-password"
                    tabIndex="-1"
                    style={FakeInputStyle}
                />
                <Grid2 container spacing={1}>
                    {connectionAttributes.map(attribute => this.renderAttribute(attribute))}
                </Grid2>
            </Fragment>
        );
    }

}

export default ConnectionParametersStep;