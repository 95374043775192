import React, { Component } from "react"
import { Tab, Grid2 } from "@mui/material"
import Translate from "../../../../i18n/translate"
import WizardStructureDetailsTabsFields from "./tabs-components/wizard-structure-details-tabs-fields"
import WizardStructureDetailsTabsExtras from "./tabs-components/wizard-structure-details-tabs-extras"
import { StyledGrid, StyledTabs, StyledDiv } from "../../../../componentsUI/styledComponents/styledWizardStructureDetailsTabs"

const TabsComponents = [
    WizardStructureDetailsTabsFields,
    WizardStructureDetailsTabsExtras
];

class WizardStructureDetailsTabs extends Component {
    constructor(props) {
        super(props)

        var tabs = [{ label: Translate("detail_field") }];

        if (props.detailsExtras.length) {
            tabs.push({ label: Translate("extras") });
        }

        this.state = {
            ...props,
            selectedTab: props.selectedTab || 0,
            modelOptions: [],
            tabs
        }
    }

    renderTabs = () => {
        const { tabs } = this.state
        return tabs.map(
            (tab) => {
                if (tab.label === Translate("extras")) {
                    if (!this.state.selectedNode) {
                        return (<Tab disabled key={tab.label} label={tab.label} />);
                    }
                }
                return (<Tab key={tab.label} label={tab.label} />);
            }
        );
    }

    componentWillReceiveProps = props => {
        let state = { ...this.state }

        if (props.selectedNode && props.selectedNode.node
            && (
                !state.selectedNode ||
                !(props.selectedNode.node.uuid === state.selectedNode.node.uuid)
            )
        ) {
            state.selectedNode = props.selectedNode
            state.selectedTab = 0
        } else if (!props.selectedNode) {
            state.selectedNode = null
            state.selectedTab = 0
        }

        state.selectedInputFields = props.selectedInputFields;
        state.structureInInput = props.structureInInput;
        state.wizardState = props.wizardState;

        if (props.detailsExtras) {
            state.detailsExtras = props.detailsExtras
        }

        if (props.detailsExtrasAttributes) {
            state.detailsExtrasAttributes = props.detailsExtrasAttributes
        }

        this.setState(state)
    }

    handleChangeTab(_, v) {
        if (this.state.selectedTab === v) {
            return;
        }
        this.setState({ selectedTab: v })
    }

    render() {
        const {
            attributes,
            selectedTab,
            detailsExtras,
            selectedNode,
            detailsExtrasAttributes,
            stepProperties,
            selectedInputFields,
            structureInInput,
            attributeGroups,
            stepStructure,
            wizardState
        } = this.state

        return (
            <Grid2 size={15}>
                <StyledGrid>
                    <StyledTabs
                        value={selectedTab}
                        onChange={this.handleChangeTab.bind(this)}>
                        {this.renderTabs()}
                    </StyledTabs>
                    <StyledDiv>
                        {
                            React.createElement(TabsComponents[selectedTab], {
                                attributes,
                                detailsExtras,
                                attributeGroups,
                                stepStructure,
                                detailsExtrasAttributes,
                                wizardState,
                                selectedNode,
                                stepProperties,
                                selectedInputFields,
                                structureInInput,
                                update: this.props.update,
                                disabled: !selectedNode,
                                setChangedField: this.props.setChangedField
                            })
                        }
                    </StyledDiv>
                </StyledGrid>
            </Grid2>
        )
    }
}

export default WizardStructureDetailsTabs