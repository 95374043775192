import styled from "styled-components"

export const StyledCheckboxList_ul = styled("ul")`
    && {
        max-height: 300px;
        max-width: 530px;
        overflow-y: auto;
    }
`

export const StyledCheckboxList_li = styled("li")`
    && {
        list-style: none;
    }
`