import styled from "styled-components"
import { StyledDialog } from "./styledDialog"
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import { Grid2, DialogTitle } from "@mui/material"

export const StyledDialogZIndex = styled(StyledDialog)`
    && {
        .MuiPaper-root {
            overflow-y: unset;
        }
        .MuiDialogContent-root {
            overflow-y: unset;
        }
    }
`

export const StyledGrid = styled(Grid2)`
    && {
        padding: 20px;
    }
`

export const StyledButtonGrid = styled(Grid2)`
    && { 
        padding: 0;
        margin-top: 45px
    }
`

export const StyledContainer = styled.div`
    && {
        border                    : 1px solid #B8C6D6;
        border-top-right-radius   : 4px;
        border-bottom-left-radius : 4px;
        border-bottom-right-radius: 4px;
        background-color          : #ffffff;
        min-height                : 200px;
    }
`

export const StyledRow = styled.div`
    && {
        display: inline-flex;
        width  : 100%;
    }
`

export const StyledIconButton = styled(StyledButton)`
    && { 
        min-width: 70px; 
        margin-bottom: 20px;

        @media screen and (max-width:500px) {
            min-width: calc(50% - 13px);
            &:last-child { min-width: calc(100% - 15px); }
        }
    }
`

export const StyledTitle = styled(DialogTitle)`
    && {
        background-color: #efefef;
        border-bottom: 1px solid #B8C6D6;
        h2 {
            font-weight: 600;
            color: #6F8296;
        }
    }
`
