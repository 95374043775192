import styled from "styled-components"
import { Tabs, Grid2 } from "@mui/material"

export const StyledTabs = styled(Tabs)`
    && {
        position: absolute;
        width: 100%;
        top: -47px;
        
        button {
            color: #6F8296;
            font-size: 0.75rem;

            border-top: 1px solid #B8C6D6;
            border-left: 1px solid #B8C6D6;
            border-right: 1px solid #B8C6D6;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            &:hover, &.Mui-selected {
                background-color: #f4f4f4;
                border-top: 1px solid #B8C6D6;
                border-left: 1px solid #B8C6D6;
                border-right: 1px solid #B8C6D6;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }
        }

        .MuiTabs-fixed {
            overflow-x: initial;
        }

        .MuiTabs-indicator {
            height: 0;
            background-color: #B8C6D6;
        }
    }
`

export const StyledGrid = styled(Grid2)`
    && {
        padding: 20px;
        margin-top: 48px;
        border: 1px solid #B8C6D6;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        min-height: calc(100vh - 495px);
    }
`

export const StyledGridItem = styled(Grid2)`
    && {
        margin-top: 20px;

        @media screen and (min-width:1280px) {
            padding-left: 20px;
            margin-top: 0;
        }
    }
`