export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.Token) {
        return {
            'Authorization': 'Bearer ' + user.Token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
    } else {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
    }
}