import * as types from './action-types';

const initialState = {
    label: null,
    actions: [],
    isFetching: false,
    isFetched: false,
    position: 0,
    inputSelected: '',
    codMode: null,
    input: null,
    idDataLevel: 0
};

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.SET_COMPANY_SELECTED:
            return Object.assign({}, state, {
                company: action.company,
                companydesc: action.desc
            });
        case types.SET_INSTANCE_SELECTED:
            return Object.assign({}, state, {
                instance: action.instance,
                instancedesc: action.desc
            });
        case types.SET_ACCESS_LEVEL:
            return Object.assign({}, state, {
                accessLevel: action.accessLevel,
            });
        case types.SET_INPUTSELECTED:
            return Object.assign({}, state, {
                inputSelected: action.input,
            });
        default:
            return state;
    }
}

// selectors

export function getModeInfo(state) {
    const codMode = state.userInfo.codMode;
    const labelMode = state.userInfo.labelMode;
    return [codMode, labelMode];
}

export function getCompanySelected(state) {
    const company = state.userInfo.companydesc;
    const companyCode = state.userInfo.company;
    return [company, companyCode];
}

export function getAccessLevel(state) {
    const accessLevel = state.userInfo.accessLevel;
    return accessLevel;
}
export function getEventSelected(state) {
    const codEventSelected = state.userInfo.codEventSelected;
    return codEventSelected;
}
export function fieldInsert(state) {
    const fieldInsert = state.userInfo.fieldInsert;
    return fieldInsert;
}
export function getIdDataLevel(state) {
    const idDataLevel = state.userInfo.idDataLevel;
    return idDataLevel;
}

export function getInputList(state) {
    const inputList = state.userInfo.inputList;
    return [inputList];
}
export function getInstanceSelected(state) {
    const instance = state.userInfo.instancedesc;
    const codInstance = state.userInfo.instance;
    return [instance, codInstance];
}
export function getInstanceCode(state) {
    const codInstance = state.userInfo.instance;
    return codInstance;
}

export function getInputSelected(state) {
    const inputSelected = state.userInfo.inputSelected;
    return inputSelected;
}

export function getDetailSelected(state) {
    const detail = state.userInfo.detail;
    return detail;
}
export function getDescDetailSeleceted(state) {
    const descDetailSelected = state.userInfo.descDetailSelected;
    return descDetailSelected;
}
export function getCodMasterDetailSelected(state) {
    const codMasterDetailSelected = state.userInfo.codMasterDetailSelected;
    return codMasterDetailSelected;
}
export function getTypeSeleceted(state) {
    const codType = state.userInfo.codType;
    return codType;
}

export function getIndexEditSelected(state) {
    const indexEditSelected = state.userInfo.indexEditSelected;
    return indexEditSelected;
}
export function getInput(state) {
    const input = state.userInfo.input;
    return input;
}

export function getMenuFieldTypeCod(state) {
    const menufieldtypecod = state.userInfo.menufieldtypecod;
    return menufieldtypecod;
}

export function getProp(state) {
    const prop = state.userInfo.prop;
    return prop;
}

export function getSubActionSelected(state) {
    const codsubaction = state.userInfo.subactionSelected;
    return codsubaction;
}

export function getActions(state) {
    const actions = state.userInfo.actions;
    return actions;
}

export function getActionSelected(state) {
    const actions = state.userInfo.actionsSelected;
    return actions;
}


export function getPositionSelected(state) {
    const position = state.userInfo.positionSelected;
    return position;
}

export function getLabelMode(state) {
    const labelMode = state.userInfo.labelMode;
    return labelMode;
}
export function getLabelActions(state) {
    const labelAction = state.userInfo.labelAction;
    return labelAction;
}
export function getStructureSelected(state) {
    const codStructure = state.userInfo.structureSelected;
    return codStructure;
}

export function getFieldSelected(state) {
    const codField = state.userInfo.fieldSelected;
    return codField;
}

export function getInstanceConnectionSelected(state) {
    const instanceConnectionSelected = state.userInfo.instanceConnectionSelected;
    return instanceConnectionSelected;
}