import SortableTree from "@nosferatu500/react-sortable-tree";
import styled from "styled-components";

export const StyledSortableTree = styled(SortableTree)`
    && {
        background-color: #fff;
        border: 1px solid #B8C6D6;
        border-radius: 4px;
        padding-right: 8px;
        > div {height: auto; min-height: 10px;}
        &.rst__tree > div > div.ReactVirtualized__Grid.ReactVirtualized__List { 
            height: auto !important;
            min-height: calc(100vh - 690px);
            ::-webkit-scrollbar { height: 8px; }
            ::-webkit-scrollbar-track { 
                -webkit-border-radius: 4px; 
                background: #f5f5f5; 
                border-radius: 4px; 
            }
            ::-webkit-scrollbar-thumb { 
                -webkit-border-radius: 4px; 
                border-radius: 4px; 
                background: #009cdf;
            }
            ::-webkit-scrollbar-thumb:window-inactive { background-color: #009cdf; }
        }

        .rst__moveHandle { 
            border: 0;
            box-shadow: 0 0;
            background-color: #B8C6D6;
            border-radius: 2px 0 0 2px;
            &:hover { background-color: #94a4b8; }
        }

        .rst__rowContents { 
            min-width: 30px;
            box-shadow: 0 0;
            border: 1px solid #B8C6D6;
            border-radius: 0 2px 2px 0;
            cursor: pointer;
            &:hover { background-color: #f5f5f5; }
        }

        .rst__node { height: 59px !important; }
        .rst__collapseButton, .rst__expandButton { box-shadow: 0 0 0 1px #009CDF; }
        .rst__rowLabel { padding-right: 10px; color: #4C637B; }
        .rst__lineBlock, .rst__lineChildren { &::after, &::before { background-color: #B8C6D6; } }
        .rst__toolbarButton {
            button { 
                color: #6F8296;
                &:hover {
                    color: #009CDF;
                    background-color: transparent;    
                }
            }
        }

        .rst__rowTitle {
            font-size: 12px;
            font-weight: normal;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }

        .rst__row.selected {
            .rst__rowContents {
                cursor: default;
                background-color: #4C637B;
                .rst__rowLabel { color: #fff; }
                .rst__toolbarButton {
                    button {
                        color: #fff;
                        &:hover { color: #fff; }
                    }
                }
            }
        }
    }
`

export const StyledSortableTreeTransform = styled(SortableTree)`
    && {
        overflow: auto;
        background-color: #fff;
        border: 1px solid #B8C6D6;
        border-radius: 4px;
        padding-right: 8px;
        > div {height: auto; min-height: 10px;}
        &.rst__tree > div > div.ReactVirtualized__Grid.ReactVirtualized__List { 
            height: auto !important;
            min-height: calc(100vh - 690px);
            ::-webkit-scrollbar { height: 8px; }
            ::-webkit-scrollbar-track { 
                -webkit-border-radius: 4px; 
                background: #f5f5f5; 
                border-radius: 4px; 
            }
            ::-webkit-scrollbar-thumb { 
                -webkit-border-radius: 4px; 
                border-radius: 4px; 
                background: #009cdf;
            }
            ::-webkit-scrollbar-thumb:window-inactive { background-color: #009cdf; }
        }
        .rst__moveHandle { 
            border: 0;
            box-shadow: 0 0;
            background-color: #B8C6D6;
            border-radius: 2px 0 0 2px;
            &:hover { background-color: #94a4b6; }
        }
        .rst__rowContents { 
            min-width: 30px;
            box-shadow: 0 0;
            border: 1px solid #B8C6D6;
            border-radius: 0 2px 2px 0;
            cursor: pointer;
            &:hover {
                background-color: #f5f5f5;
            }
            &:hover .content-transform-label {
                background-color: #f5f5f5;
            }
        }
        .rst__node { height: 59px !important; }
        .rst__collapseButton, .rst__expandButton { box-shadow: 0 0 0 1px #009CDF; }
        .rst__rowLabel { padding-right: 10px; color: #4C637B; }
        .rst__lineBlock, .rst__lineChildren { &::after, &::before { background-color: #B8C6D6; } }
        .rst__toolbarButton {
            button { 
                color: #6F8296;
                &:hover {
                    color: #009CDF;
                    background-color: transparent;    
                }
            }
        }
        .rst__rowTitle {
            font-size: 12px;
            font-weight: normal;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
        .rst__row {
            &.content-field-fixed-value {
                min-width: 97px;
            }
            &.content-field-case {
                min-width: 62px;
            }
            &.content-field-concat {
                min-width: 124px;
            }
            & .content-transform-label {
                width: auto;
                padding: 0 5px;
                height: 16px;
                font-size: 8pt;
                position: absolute;
                top: -8px;
                right: -1px;
                font-weight: bold;
                line-height: 16px;
                margin: 0 auto;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-right: 1px solid #b8c6d6;
                text-transform: uppercase;
            }
            &:not(.selected):not(:hover) {
                & .content-transform-label {
                    background-color: #FFF;
                }
            }
            &:not(.selected) {
                & .content-transform-label {
                    color: #4C637B;
                    border-top: 1px solid #b8c6d6;
                    border-left: 1px solid #b8c6d6;
                    &:before {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 9px;
                        background-color: #FFF;
                        left: -1px;
                        bottom: 0;
                    }
                }
            }
            &.selected {
                & .content-transform-label {
                    color: #FFF;
                    background-color: #4c637b;
                }
                .rst__rowContents {
                    cursor: default;
                    background-color: #4C637B;
                    .rst__rowLabel { color: #fff; }
                    .rst__toolbarButton {
                        button { 
                            color: #fff;
                            &:hover { color: #fff; }
                        }
                    }
                }
            }
        }
        &.bg_darken_04 {
            background-color: #F2F2F2;
        }
        ::-webkit-scrollbar-corner { background-color: #eee; }
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: #eee;
            cursor: pointer;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
`;