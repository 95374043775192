import styled from "styled-components";

export const ButtonDownloadLessThan960 = styled.div`
	&&{
		position: absolute;
		top: 10.5em;
		left: 18em;
	}`

export const ButtonDownloadGreaterThan960 = styled.div`
	&&{
		position: absolute;
		top: 10.5em;
		left: 4em;
	}`