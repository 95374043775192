import styled from "styled-components";
import { List } from "@mui/material";

export const PanelTitle = styled.div`
    && {
        font-size: 20px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        padding-bottom: 10px;
        color: #6f8296;
    }
`;

export const StyledList = styled(List)`
    && {
        border: 2px solid #b8c6d6;
        border-radius: 6px;
        height: 200px;
        overflow: auto;
        margin-top: 10px;

        > li {
            padding: 5px 20px 15px;

            > span {
                font-size: 1rem;
                color: #6f8296;
                padding: 2px;
            }
        }
    }
`;
