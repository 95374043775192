
import * as types from './action-types';
import { interfaceService } from './service';

export const getAllInterfacesByInstance = (codInstance) => {
    return async (dispatch, getState) => {
        try {
            const interfaces = await interfaceService.getAllByInstance(codInstance);
            dispatch({ type: types.FETCH_INTERFACES, interfaces });
        } catch (error) {
            console.error(error);
        }
    };
}

export const getAllEventsByInterface = (codInterface) => {
    return async (dispatch, getState) => {
        try {
            const interfaceEvents = await interfaceService.getAllByInterface(codInterface)
            let outputStructures = new Array

            interfaceEvents.filter(event => event.structures)
                .forEach(event => {
                    event.structures.forEach(structure => {
                        structure.codInterfaceEvent = event.codInterfaceEvent;
                        if (structure.structureDetails) {
                            const outputStructure = structure.structureDetails.find(detail => {
                                detail.codInterfaceEvent = event.codInterfaceEvent;
                                return detail.output
                            });

                            if (outputStructure) outputStructures.push(outputStructure);
                        }
                    })
                })

            dispatch({ type: types.FETCH_EVENTS, interfaces: interfaceEvents });            
            dispatch({ type: types.SET_INPUTS, inputs: outputStructures });
        } catch (error) {
            console.error(error);
        }
    };
}

export const setInterface = (codInterface, desInterface) => ({
    type: types.SET_INTERFACE,
    codInterface,
    desInterface
});

export const setInterfaceEvents = (interfaceEvents) => ({
    type: types.SET_INTERFACE_EVENTS,
    interfaceEvents
});

export const setEvents = (events) => ({
    type: types.SET_EVENTS,
    events
});

export const clearEvents = () => ({
    type: types.CLEAR_EVENTS
})