import React, {Component} from "react"
import { connect } from "react-redux"
import Session from "../../../utils/session-info"
import EventsItems from "./events-menu/events-items"
import EventsTimeline from "./events-timeline/events-timeline"
import InterfacesService from "../interfaces-service"
import InterfacesEventService from "./interfaces-events-service"
import BanksService from "./../../bank-registration/bank-accounts-service"
import { getAllEventsByInterface, setInterface } from "../../../store/interfaces/actions"
import { clearEvents } from "../../../store/interfaces/actions"
import { setBreadcrumb } from "../../../store/app-state/actions"
import * as globalSelector from "../../../store/globals/reducer"
import Translate from "../../../i18n/translate"
import InternalPageTitle from "../../../componentsUI/internalPageTitle"
import Loading from "../../../componentsUI/loading"
import { Grid2 } from "@mui/material"
import {
    defineAttributes,
    defineEventProperties,
    defineConnectionActions,
    clearWizardStore,
    setWizardEventTypes
} from "../../../store/wizard/wizard-store-actions"

class InterfacesEvents extends Component {
    constructor(props) {
        super(props);

        let interfaceEventID = window.location.hash.substring(20)

        props.clearWizardStore();
        props.clearEvents();

        props.setBreadcrumb([{
            label: "integrations"
        }, {
            label: "interfaces",
            url: "#/interfaces"
        }, {
            label: "events"
        }]);

        const param = parseInt(interfaceEventID, 10);

        if (!param || typeof param !== "number") {
            window.location.replace("#/interfaces")
        } else {
            this.props.setInterface(param);
            this.props.getAllEventsByInterface(param);
        }

        this.state = {
			interfaces: [],
            banksList: [],
            bankAccounts: [],
            sendEvents: [],
            banksEvents: [],
            modifierEvents: [],
            selectedInterfaceCode: param,
            getAllInterfacesByInstanceRequestCompleted: false,
            getBanksRequestCompleted: false,
            getEventListRequestCompleted: false
        };
    }

	componentWillMount = () => {
		const {selectedInterfaceCode} = this.state;

		this.props.defineAttributes();
		this.props.defineEventProperties();
		this.props.defineConnectionActions();

        InterfacesService.GetAllInterfacesByInstance(Session().codInstance).then(({ interfaces }) => {
			const currentInterface = interfaces.find(itf => itf.codInstanceInterface === selectedInterfaceCode);

            this.props.setInterface(currentInterface.codInstanceInterface, currentInterface.description)
            return currentInterface.description
		}).then(currentInterfaceDescription => {
            this.setState({
				title: `${Translate("interface_event")} ${currentInterfaceDescription}`
			}, () => this.setState({getAllInterfacesByInstanceRequestCompleted: true}));
        })

        InterfacesEventService.GetEventList(Session().codInstance).then(eventList => {
            this.setState({
                eventList,
                sendEvents: eventList.find(e => e.codEvent === 1).children || [],
                modifierEvents: eventList.filter(e => e.codEvent !== 1 && e.codEvent !== 6)
            }, () => this.setState({getEventListRequestCompleted: true}))
        })

        BanksService.GetBanks().then(banks => this.setState({banksList: banks}, () => this.generateBankModifiers()))
	}

    getBankAccounts() {
        return BanksService.GetBankAccounts()
        .then(bankAccounts => {    
            return bankAccounts.filter(account => account.cod_instance == Session().codInstance)
        })
    }

    generateBankModifiers(){
        const { banksList } = this.state

        if(banksList != []){
            this.getBankAccounts().then(bankAccountsFilteredByInstance => {
                var listBanksFromAccounts = new Array
                
                bankAccountsFilteredByInstance.forEach(account => {
                    banksList.forEach(bank => {
                        if(bank.cod_bank == account.cod_bank) listBanksFromAccounts.push(bank)
                    })
                })
    
                let bankEvents = listBanksFromAccounts.filter(bank => {
                    return !this[JSON.stringify(bank)] && (this[JSON.stringify(bank)] = true);
                }, Object.create(null))
                .map(bank => {
                    return {
                        codConnectionType: 18,
                        codEvent: 6,
                        codBank: bank.cod_bank,
                        description: bank.trade_name,
                        label: bank.legal_name,
                    }
                })
    
                this.setState({bankAccounts: bankAccountsFilteredByInstance, banksEvents: bankEvents}, () => this.setState({getBanksRequestCompleted: true}))
            })
        }
    }
    
    componentWillReceiveProps = props => {
        if (props.interfaces && props.interfaces.interfaces) {
            this.setState({ interfaces: props.interfaces.interfaces })
        }

        if (props.eventTypes && Object.keys(props.eventTypes).length) {
            this.props.setWizardEventTypes(Array.from(Object.values(props.eventTypes)));
        }
    }

    goBack = () => window.location.replace('#/interfaces')

    hasDataInBanksEvents = () => {
        const { banksEvents } = this.state
        
        return banksEvents == undefined || banksEvents.length == 0 ? true : false
    }

    render() {
        const {
            selectedInterfaceCode,
            eventList,
            title,
            sendEvents,
            modifierEvents,
            banksEvents,
            banksList,
            bankAccounts,
            getAllInterfacesByInstanceRequestCompleted,
            getBanksRequestCompleted,
            getEventListRequestCompleted
        } = this.state;

        if(getAllInterfacesByInstanceRequestCompleted && getBanksRequestCompleted && getEventListRequestCompleted){
            return (
                <div>
                    <InternalPageTitle title={title}/>
                    <Grid2 container>
                        <Grid2 size={3}>
                            <Grid2>
                                <EventsItems disableEventComponent={false} title={"connections"} events={sendEvents}/>
                                <EventsItems disableEventComponent={false} title={"modifiers"} events={modifierEvents}/>
                                <EventsItems disableEventComponent={this.hasDataInBanksEvents()} title={"banks"} events={banksEvents}/>
                            </Grid2>
                        </Grid2>
                        <Grid2 size={9}>
                            <EventsTimeline 
                                selectedInterfaceCode={selectedInterfaceCode} 
                                eventList={eventList} 
                                openConnectionActionModal={this.openConnectionActionModal}
                                banksList={banksList}
                                bankAccounts={bankAccounts}/>
                        </Grid2>
                    </Grid2>
                </div>
            )
        } else return <Loading />
    }
}

const mapStateToProps = (state) => {
    const [eventTypes] = globalSelector.getEvents(state);

    return {
        eventTypes
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInterface: (codInterface, desInterface) => { dispatch(setInterface(codInterface, desInterface)) },
        getAllEventsByInterface: (codInterface) => { dispatch(getAllEventsByInterface(codInterface)) },
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps)),
        defineAttributes: () => dispatch(defineAttributes()),
        defineEventProperties: () => dispatch(defineEventProperties()),
        defineConnectionActions: () => dispatch(defineConnectionActions()),
        clearWizardStore: () => dispatch(clearWizardStore()),
        clearEvents: () => dispatch(clearEvents()),
        setWizardEventTypes: (eventTypes) => { dispatch(setWizardEventTypes(eventTypes)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InterfacesEvents);
