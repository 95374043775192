import request from '../../utils/request';
import Session from '../../utils/session-info'

export default {
    GetConnectionsByConnectionType: codConnectionType => {
        return request.executeRequest(
            `${request.baseUrl}connections/instances/${Session().codInstance}/connections/${codConnectionType}`,
            request.getOptions()
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText);
            }

            return res.json();
        })
    },
    GetAttrGroupsByCodConnectionActionCodEventProp: (
        codConnectionAction, codConnectionMode, codEventProperty
    ) => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/attributegroups/${codConnectionAction}/${codEventProperty}/${codConnectionMode}`,
            request.getOptions()
        ).then(res => {
            return res.json()
        });
    },
    GetExtrasAndAttrGroupsByCodConnectionActionCodEventProp: (
        codConnectionAction, codConnectionMode, codEventProperty
    ) => {
        let structureExtras = request.executeRequest(
            `${request.baseUrl}interfaces/structureextras/${codConnectionAction}/${codEventProperty}/${codConnectionMode}`,
            request.getOptions()
        );

        let attributeGroups = request.executeRequest(
            `${request.baseUrl}interfaces/attributegroups/${codConnectionAction}/${codEventProperty}/${codConnectionMode}`,
            request.getOptions()
        );

        return Promise
            .all([structureExtras, attributeGroups])
            .then(res => {
                return res;
            });
    },
    GetAttributesByIdArray: ids => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/returnattributesbyidarray`,
            {
                ...request.postOptions(),
                body: JSON.stringify(ids)
            }
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText);
            }
            return res.json();
        });
    },
    GetDetailsAndInputsByCodStructure: codStructure => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/detailByCodStructure/${codStructure}`,
            request.getOptions()
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText);
            }
            return res.json();
        })
    },
    GetDetailsExtraValues: codStructure => {
        return request.executeRequest(
            `${request.baseUrl}interfaces/structureDetailsExtraValues/${codStructure}`,
            request.getOptions()
        ).then(res => {
            if (!res.ok) {
                return Promise.reject(res.statusText);
            }
            return res.json();
        })
    }
}