import styled from 'styled-components'
import Select from 'react-select'

const StyledSelect = styled(Select)`
  && {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
    
    .select-default__control {
      cursor: pointer;
      border-color: #B8C6D6;
    }
    
    .select-default__menu {
      z-index: 1000 !important;
    }

    .select-default__control--is-focused,
    .select-default__control:hover {
      border-color: #B8C6D6;
      box-shadow: none;
    }

    .select-default__indicator-separator {
      background-color: #B8C6D6;
      margin-bottom: 0;
      margin-top: 0;
    }

    .select-default__indicator {
      padding: 0;
      min-width: 60px;
      min-height: 40px;
      align-items: center;
      justify-content: center;
      svg { color: #B8C6D6; }

      &:hover {
        background-color: #eee;
      }
    }

    .select-default__multi-value {
      border: 1px solid #B8C6D6;
      background-color: #F5F5F5;
      
      .select-default__multi-value__label { 
        color: #6F8296;
      }
      
      .select-default__multi-value__remove:hover {
        background-color: #009CDF;
        svg { color: #FFFFFF; }
      }
    }

    .select-default__menu-list { z-index: 99999 }
    .select-default__single-value, 
    .select-default__option { 
      cursor: pointer; 
      color: #6F8296;
    }

    .select-default__option:hover { 
      background-color: #009CDF;
      color: #FFFFFF;
    }

    .select-default__option--is-focused { 
      background-color: #FFFFFF;
    }

    .select-default__option--is-selected {
      background-color: #EEEEEE;
      color: #6F8296;
    }

    &.error {
      .select-default__control { border-color: #CD5C5C; }
      & ~ p { color: #CD5C5C; }
    }

    &.warning {
      .select-default__control { border-color: #FFC34B; }
      & ~ p { color: #FFC34B; }
    }

    &.success {
      .select-default__control { border-color: #009CDF; }
      & ~ p { color: #009CDF; }
    }

  }
`

export default StyledSelect