import React from 'react'
import { ContainerSmall, LoadingDefault } from "../componentsUI/styledComponents/styledLoading"

const Loading = () => {
  return (
    <ContainerSmall>
      <LoadingDefault />
    </ContainerSmall>
  )
}

export default Loading