import React, {
    Component,
    Fragment
} from "react"
import { connect } from "react-redux"
import Translate from "../../i18n/translate"
import { setBreadcrumb } from "../../store/app-state/actions"
import { DialogActions, Grid2 } from "@mui/material"
import Toast from "../../components/toast/toast"
import UserPrivilegesService from "./user-privileges-service"
import StyledButton from "../../componentsUI/styledComponents/styledButton"
import ConectorCheckbox from "../../componentsUI/checkBox"
import InputText from "../../componentsUI/inputText"
import { StyledCheckboxList_ul, StyledCheckboxList_li } from "../../componentsUI/styledComponents/styledUserPrivileges"
import { TbUserSearch } from "react-icons/tb"

class AddEditUserPrivilegesModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            userSelected: props.userSelected,
            privilegesLevels: props.privilegesLevels,
            inputUserEmail: undefined,
            userPrivilegesLevels: new Array
        }
    }

    componentWillMount = () => {
        const { userSelected } = this.state

        if (userSelected != undefined) {
            this.setState({ inputUserEmail: userSelected.desEmail }, this.setUserPrivilegesLevels())
        }
    }

    setUserPrivilegesLevels = () => {
        const { userSelected } = this.state

        let userPrivilegesLevels = userSelected.privileges.map(privilege => {
            return privilege.codTypePrivilege
        })

        this.setState({ userPrivilegesLevels })
    }

    setOrUnsetCheckboxPrivilegesLevels = checkboxElement => {
        let { userPrivilegesLevels } = this.state
        const codPrivilegeLevel = parseInt(checkboxElement.target.value)

        if (userPrivilegesLevels.includes(codPrivilegeLevel)) {
            userPrivilegesLevels = userPrivilegesLevels.filter(item => item != codPrivilegeLevel)
        } else userPrivilegesLevels.push(codPrivilegeLevel)

        this.setState({ userPrivilegesLevels })
    }

    saveUserPrivileges = () => {
        const { userSelected, userPrivilegesLevels } = this.state

        UserPrivilegesService
            .SaveUserPrivileges({ codUser: userSelected.codUser, privilegesLevels: userPrivilegesLevels })
            .then(response => {
                switch (response.status) {
                    case 200:
                        Toast.success("privilegesSuccessfullyRegistered")
                        break;
                    case 409:
                        throw new Error("userCannotRemoveOwnAdminPrivilege")
                        break;
                    default:
                        throw new Error("error_saving_record")
                        break;
                }
            })
            .then(() => this.props.closeDialogAndModal(true))
            .catch(error => {
                this.props.closeDialogAndModal(true)
                Toast.error(error.message)
            })
    }

    renderPrivilegesLevelsCheckboxes = () => {
        const { privilegesLevels, userPrivilegesLevels } = this.state

        return (
            <Grid2>
                <StyledCheckboxList_ul>
                    {privilegesLevels.map(privilegeLevel => {
                        return (
                            <StyledCheckboxList_li>
                                <ConectorCheckbox
                                    value={privilegeLevel.cod_privilege}
                                    onChange={this.setOrUnsetCheckboxPrivilegesLevels.bind(this)}
                                    label={privilegeLevel.des_privilege_type}
                                    checked={userPrivilegesLevels.find(codPrivilege => {
                                        return privilegeLevel.cod_privilege == codPrivilege
                                    })} />
                            </StyledCheckboxList_li>
                        )
                    })}
                </StyledCheckboxList_ul>
            </Grid2>
        )
    }

    getUser = () => {
        const { inputUserEmail } = this.state

        UserPrivilegesService.GetUser(inputUserEmail)
            .then(res => {
                if (res.status == 409) throw new Error("alreadyRegisteredUser")
                else {
                    return res.json()
                }
            })
            .then(user => {
                if (user != undefined) {
                    if (user.codUser == 0) throw new Error("userNotFoundOrDisabled")
                    else {
                        return user
                    }
                } else throw new Error("userNotFoundOrDisabled")
            })
            .then(user => this.setState({ userSelected: user }))
            .catch(error => Toast.error(Translate(error.message)))
    }

    renderSearchUser = withoutUser => {
        const { inputUserEmail } = this.state

        return (
            <Grid2 container justifyContent="center" spacing={1}>
                <Grid2 size={11}>
                    <InputText
                        label={Translate("user_email")}
                        value={inputUserEmail}
                        onChange={element => this.setState({ inputUserEmail: element.target.value })}
                        disabled={!withoutUser} />
                </Grid2>
                <Grid2 sx={{ paddingBottom: "10px" }}>
                    <StyledButton
                        sx={{ display: withoutUser ? "" : "none" }}
                        variant="outlined"
                        onClick={() => this.getUser()}>
                        <TbUserSearch size={25} />{Translate("searchUser")}
                    </StyledButton>
                </Grid2>
            </Grid2>
        )
    }

    render = () => {
        const { userSelected } = this.state

        let withoutUser = userSelected == undefined ? true : false

        return (
            <Fragment>
                {this.renderSearchUser(withoutUser)}
                {withoutUser ? "" : this.renderPrivilegesLevelsCheckboxes()}
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={() => this.props.closeDialogAndModal()}>
                        {Translate("cancel")}
                    </StyledButton>
                    <StyledButton
                        variant="outlined"
                        onClick={() => this.saveUserPrivileges()}>
                        {Translate("save")}
                    </StyledButton>
                </DialogActions>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setBreadcrumb: (steps) => dispatch(setBreadcrumb(steps))
    }
}

export default connect(null, mapDispatchToProps)(AddEditUserPrivilegesModal)