import React, { Component } from "react"
import { DialogContent, DialogActions, Grid2 } from "@mui/material"
import Translate from "../../../i18n/translate"
import InfoSoftwareNavLink from "./info-software-nav-links"
import { StyledDialog, StyledDialogTitle } from "../../../componentsUI/styledComponents/styledDialog"
import StyledButton from "../../../componentsUI/styledComponents/styledButton"

class AllVersionModal extends Component {
    constructor(props) {
        super(props);
    }

    renderVersion = () => {
        return (
            <div>
                <InfoSoftwareNavLink
				    variant="contained"
                    softwareSystemName="Portal">
				</InfoSoftwareNavLink>
                <InfoSoftwareNavLink
					variant="contained"
                    softwareSystemName="Worker">
				</InfoSoftwareNavLink>
                <InfoSoftwareNavLink
					variant="contained"
                    softwareSystemName="MicroBack">
				</InfoSoftwareNavLink>
                <InfoSoftwareNavLink
					variant="contained"
                    softwareSystemName="MicroFront">
				</InfoSoftwareNavLink>
                <InfoSoftwareNavLink
					variant="contained"
                    softwareSystemName="MicroDash">
				</InfoSoftwareNavLink>
            </div>
        );
    }

    render() {
        return (
            <StyledDialog
                open={this.props.open}
                disableEscapeKeyDown={true}
                disableBackdropClock={true}
                fullWidth={true}
                maxWidth={"sm"}>
                <StyledDialogTitle className="info">
                    {Translate("current_versions")}
                </StyledDialogTitle>
                <DialogContent>
                    <Grid2 container={true} spacing={24}>
                        <Grid2 item>
                            {this.renderVersion()}
                        </Grid2>
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        variant="contained"
                        onClick={this.props.onClose}>
                        {Translate("close")}
                    </StyledButton>
                </DialogActions>
            </StyledDialog>
        );
    }
}

export default AllVersionModal;
