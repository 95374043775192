import * as types from './action-types';

let user = JSON.parse(localStorage.getItem('user'));
let instances = JSON.parse(sessionStorage.getItem('instances'))

const initialState = {
    instances: instances?instances:[],
    codInstance: user?user.codInstance:'',
    events: [],
    isFetching: false,
    isFetched: false,
    codInstanceEditSelected: null,
};

export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_INSTANCES:
        return Object.assign({},state,{
            instances: action.instances,
            codInstance: state.codInstance,
            isFetched: true,
        });
    case types.SET_INSTANCES:
        return Object.assign({},state,{
            instances: action.instances,
            codInstance: action.id,
            isFetched: true,
        });
    case types.SET_INSTANCE:
        return Object.assign({},state,{
            codInstance: action.codInstance,
            isFetched: true,
        });
    case types.SET_INSTANCE_EDIT_SELECTED:
        return Object.assign({},state,{
            codInstanceEditSelected: action.codInstanceEditSelected,
            isFetched: true,
        });
    case types.ADD_EVENT:
        return Object.assign({},state,{
            events: [
                ...state.events, 
                action.event
            ]
        });
    case types.SET_EVENTS:
        return Object.assign({},state,{
            events: action.events,
        });
    default:
      return state;
  }
}